// Interface for all getters, mutations and actions
// Getters
export const GET_GROUP_FILTER = 'contacts/GET_GROUP_FILTER';
export const GET_CONTACT_LIST = 'contacts/GET_CONTACT_LIST';
export const GET_CAN_LOAD_MORE_CONTACTS = 'contacts/GET_CAN_LOAD_MORE_CONTACTS';

// Mutations
export const MUTATE_GROUP_FILTER = 'contacts/MUTATE_GROUP_FILTER';
export const MUTATE_CONTACT_LIST = 'contacts/MUTATE_CONTACT_LIST';
export const MUTATE_CAN_LOAD_MORE_CONTACTS = 'contacts/MUTATE_CAN_LOAD_MORE_CONTACTS';

// Actions
export const LOAD_GROUP_FILTER = 'contacts/LOAD_GROUP_FILTER';
export const LOAD_CONTACT_LIST = 'contacts/LOAD_CONTACT_LIST';
export const LOAD_CONTACT_PARENT = 'contacts/LOAD_CONTACT_PARENT';
