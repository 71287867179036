import { types } from '../types/types';
import { portal } from '../../../shared/assets/plugins/axios/axios';
import { mediaTypes } from '../../../shared/enums/mediaTypes';
import axios from 'axios';
import Vue from 'vue';
import { albumFilterType } from '../../../shared/enums/albumFilterType';
import { albumSortType } from '../../../shared/enums/albumSortType';
import { orderDirections } from '../../../shared/enums/orderDirections';

const state = {
  albums: [],
  noticeBoardMedia: [],
  moreAlbumsExist: false,
  activeAlbum: {}, // Details about the current album selected (from getMedia)
  albumDetails: [], // Media in the album
  moreMediasExist: false,
  mediaDetails: {}, // Details of a single media
  mediaCount: 0,
  mediasOfInstitutionProfileId: [],
  limitMedia: 1000,
  hasMediaChanged: false,
  filterBy: albumFilterType.ALL,
  sortOn: albumSortType.MEDIA_CREATED_AT,
  orderDirection: orderDirections.DESCENDING,
};

const getters = {
  [types.GET_ALBUMS]: state => state.albums,
  [types.GET_NOTICE_BOARD_MEDIA]: state => state.noticeBoardMedia,
  [types.GET_MORE_ALBUMS_EXIST]: state => state.moreAlbumsExist,
  [types.GET_ALBUM_DETAILS]: state => state.albumDetails,
  [types.GET_ACTIVE_ALBUM]: state => state.activeAlbum,
  [types.GET_MORE_MEDIAS_EXIST]: state => state.moreMediasExist,
  [types.GET_MEDIA_COUNT]: state => state.mediaCount,
  [types.GET_MEDIA_DETAILS]: state => state.mediaDetails,
  [types.GET_MEDIAS_OF_INSTITUTION_PROFILE_ID]: state => state.mediasOfInstitutionProfileId,
  [types.GET_LIMIT_MEDIA]: state => state.limitMedia,
  [types.GET_HAS_MEDIA_CHANGED]: state => state.hasMediaChanged,
  [types.GET_GALLERY_FILTER_BY]: state => state.filterBy,
  [types.GET_GALLERY_SORT_ON]: state => state.sortOn,
  [types.GET_GALLERY_ORDER_DIRECTION]: state => state.orderDirection,
};

const mutations = {
  [types.MUTATE_ALBUMS]: (state, payload) => {
    const albums = payload.data;
    if (Array.isArray(albums) === false) {
      state.moreAlbumsExist = false;
      return;
    }

    for (const album of albums) {
      album.selected = false;
    }

    // AULA-34760: Back-end returns an extra album for tagged media in the 1st page
    const isFirstPage = payload.index === 0;

    if (isFirstPage) {
      const hasMediasOfMe = albums.some(album => album.id == null);
      state.moreAlbumsExist = hasMediasOfMe ? albums.length > payload.limit : albums.length >= payload.limit;
    } else {
      state.moreAlbumsExist = albums.length >= payload.limit;
    }
    // Remove the extra album
    if (state.moreAlbumsExist) {
      albums.pop();
    }

    if (payload.index === 0) {
      state.albums = albums;
    } else {
      state.albums.push(...albums);
    }
  },
  [types.MUTATE_GALLERY_FILTER_BY]: (state, filterBy) => {
    state.filterBy = filterBy;
  },
  [types.MUTATE_GALLERY_SORT_ON]: (state, sortOn) => {
    state.sortOn = sortOn;
  },
  [types.MUTATE_GALLERY_ORDER_DIRECTION]: (state, orderDirection) => {
    state.orderDirection = orderDirection;
  },
  [types.MUTATE_RESET_ALBUMS]: state => {
    state.albums = [];
  },
  [types.MUTATE_HAS_MEDIA_CHANGED]: (state, hasMediaChanged) => {
    state.hasMediaChanged = hasMediaChanged;
  },
  [types.MUTATE_RESET_ALBUM_DETAILS]: state => {
    state.albumDetails = [];
  },
  [types.MUTATE_ALBUM_DETAILS]: (state, payload) => {
    state.activeAlbum = payload.data.album;
    state.mediaCount = payload.data.totalSize;
    const mediaList = payload.data.results;
    for (const media of mediaList) {
      media.selected = false;
      media.tags.sort((a, b) => a.name.localeCompare(b.name));
    }

    payload.index == 0 ? (state.albumDetails = mediaList) : state.albumDetails.push(...mediaList);

    // [Aula-42352] compare 2 variables after the albumDetails has been updated
    // put this after payload.index == 0 so that when you click on own filter, it will reset the index to 0 before adding
    state.moreMediasExist = payload.data.totalSize > state.albumDetails.length;
  },
  [types.MUTATE_MEDIA_DETAILS]: (state, payload) => {
    state.mediaDetails = payload;
  },
  [types.MUTATE_MEDIAS_OF_INSTITUTION_PROFILE_ID]: (state, payload) => {
    state.mediasOfInstitutionProfileId = payload.results;
  },
  [types.MUTATE_MEDIA_SELECTED]: (state, payload) => {
    const mediaIndex = state.albumDetails.findIndex(media => media.id === payload.id);
    Vue.set(state.albumDetails[mediaIndex], 'selected', payload.selected);
  },
  [types.MUTATE_CLEAR_MEDIAS]: state => {
    state.mediasOfInstitutionProfileId = [];
  },
  [types.MUTATE_DELETE_MEDIA]: (state, { mediaIds }) => {
    state.albumDetails = state.albumDetails.filter(media => !mediaIds.includes(media.id));
  },
  [types.MUTATE_DELETE_ALBUM]: (state, { albumId }) => {
    state.albums = state.albums.filter(album => !album.id === albumId);
  },
  [types.MUTATE_REMOVE_EXISTING_MEDIA]: state => {
    state.albumDetails = [];
  },
  [types.MUTATE_UPDATE_ALBUM]: (state, payload) => {
    state.activeAlbum.name = payload.title;
    state.activeAlbum.description = payload.description;
    state.activeAlbum.sharedWithGroups = payload.sharedWith;
    state.activeAlbum.sharedWithGroups.forEach(group => {
      group.portalRole != null ? (group.portalRoles = [group.portalRole]) : '';
    });
  },
  [types.MUTATE_NOTICE_BOARD_MEDIA]: (state, payload) => {
    payload.media.sort((a, b) => (a.id > b.id ? 1 : -1));
    // Add new media
    for (const media of payload.media) {
      const existingMedia = state.noticeBoardMedia.find(m => m.id === media.id);
      if (!existingMedia) {
        state.noticeBoardMedia.unshift(media);
      } else if (payload.updateMediaUrl) {
        existingMedia.thumbnailUrl = media.thumbnailUrl;
        existingMedia.file.url = media.file.url;
      }
    }
    // Remove deleted media
    for (const media of state.noticeBoardMedia) {
      if (!payload.media.find(m => m.id === media.id)) {
        state.noticeBoardMedia = state.noticeBoardMedia.filter(m => m.id !== media.id);
      }
    }

    state.noticeBoardMedia = state.noticeBoardMedia.filter(media => media.mediaType !== mediaTypes.SOUND);
  },
};

const actions = {
  [types.CREATE_ALBUM]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.createAlbum', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_ALBUM_CREATE');
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_ALBUM');
      }),
  [types.UPDATE_ALBUM]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.updateAlbum', payload)
      .then(response => {
        commit(types.MUTATE_UPDATE_ALBUM, payload);
        commit(types.MUTATE_HAS_MEDIA_CHANGED, true);
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_ALBUM_UPDATE');
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_ALBUM');
      }),
  [types.CREATE_MEDIA]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.createMedia', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MEDIA_CREATE');
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_MEDIA');
      }),
  [types.UPDATE_MEDIA]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.updateMedia', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_MEDIA_UPDATE');
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_MEDIA');
      }),
  [types.DELETE_MEDIA]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.deleteMedia', payload)
      .then(() => {
        commit(types.MUTATE_DELETE_MEDIA, payload);
        commit(types.MUTATE_HAS_MEDIA_CHANGED, true);
        const { mediaIds } = payload;
        if (mediaIds.length > 1) {
          commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_PLURAL_MEDIA_DELETE');
        } else {
          commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_SINGLE_MEDIA_DELETE');
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_MEDIA');
      }),
  [types.DELETE_ALBUM]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.deleteAlbum', payload)
      .then(() => {
        commit(types.MUTATE_DELETE_ALBUM, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_ALBUM_DELETE');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_ALBUM');
      }),
  [types.ACTION_GET_ALBUMS_ADMIN]: ({ commit, rootState }, payload) => {
    if (rootState.global.isPreviewMode) {
      commit(types.MUTATE_ALBUMS, { data: [] });
    } else {
      return portal
        .get('?method=galleryAdmin.getAlbums', payload)
        .then(response => {
          commit(types.MUTATE_ALBUMS, {
            ...response.data,
            index: payload.params.index,
            limit: payload.params.limit,
          });
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            return Promise.reject();
          }
          if (error.response.data.status.code === 403 && payload.groupId) {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_NOT_A_MEMBER');
            commit(types.MUTATE_REMOVE_GROUP_FROM_USER_GROUPS, payload.groupId);
          } else {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ALBUMS');
          }
        });
    }
  },
  [types.ACTION_GET_ALBUMS]: ({ commit, rootState }, payload) => {
    if (rootState.global.isPreviewMode) {
      commit(types.MUTATE_ALBUMS, { data: [] });
    } else {
      return portal
        .get('?method=gallery.getAlbums', payload)
        .then(response => {
          commit(types.MUTATE_ALBUMS, {
            ...response.data,
            index: payload.params.index,
            limit: payload.params.limit,
          });
        })
        .catch(error => {
          if (axios.isCancel(error)) {
            return Promise.reject();
          }
          if (error.response.data.status.code === 403 && payload.groupId) {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_NOT_A_MEMBER');
            commit(types.MUTATE_REMOVE_GROUP_FROM_USER_GROUPS, payload.groupId);
          } else {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ALBUMS');
          }
        });
    }
  },
  [types.ACTION_GET_ALBUM_DETAILS]: ({ commit }, payload) =>
    portal
      .get('?method=gallery.getMedia', { params: payload })
      .then(response => {
        commit(types.MUTATE_ALBUM_DETAILS, { ...response.data, ...payload });
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MEDIA');
      }),
  [types.ACTION_GET_ALBUM_DETAILS_ADMIN]: ({ commit }, payload) =>
    portal
      .get('?method=galleryAdmin.getMedia', { params: payload })
      .then(response => {
        commit(types.MUTATE_ALBUM_DETAILS, { ...response.data, ...payload });
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MEDIA');
      }),
  [types.REPORT_MEDIA]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.reportMedia', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'REPORTED_CONTENT_SUCCESS_TOASTR');
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REPORT_MEDIA');
      }),
  [types.LOAD_NOTICE_BOARD_MEDIA]: ({ commit }, payload) =>
    portal
      .get('?method=NoticeBoardDevice.getMedia', { params: payload })
      .then(response =>
        commit(types.MUTATE_NOTICE_BOARD_MEDIA, {
          media: response.data.data,
          index: payload.index,
          updateMediaUrl: payload.updateMediaUrl,
        })
      )
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_NOTICE_BOARD_MEDIA');
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
