export function fromTextKey(textKey, properties, texts) {
  let text = texts[textKey];
  if (!text) {
    return '';
  }
  // replace properties in text
  if (properties != null) {
    for (const property in properties) {
      if (typeof properties[property] === 'string' || typeof properties[property] === 'number') {
        const searchString = ['{', property, '}'].join('');
        const regex = new RegExp(searchString, 'g');
        text = text.replace(regex, properties[property]);
      }
    }
  }
  // remove any placeholders that has not been replaced
  const placeholderRegex = /{.+?}/g;
  const nonReplacedPlaceholders = text.match(placeholderRegex);
  if (nonReplacedPlaceholders) {
    nonReplacedPlaceholders.forEach(nonReplacedPlaceholder => {
      const errorMessage = ['Placeholder', nonReplacedPlaceholder, 'was not replaced in', textKey].join(' ');
      // eslint-disable-next-line no-console
      console.error(errorMessage);
    });

    text = text.replace(placeholderRegex, '');
  }
  return text;
}
