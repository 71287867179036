// Interface for all getters, mutations and actions

// Getters
export const GET_MAX_FILE_SIZE = 'fileImport/GET_MAX_FILE_SIZE';
export const GET_MAX_FILE_SIZE_LOADED = 'fileImport/GET_MAX_FILE_SIZE_LOADED';
export const GET_VALID_FILE_FORMAT = 'fileImport/GET_VALID_FILE_FORMAT';
export const GET_AUTHORIZED_FILE_FORMAT_LIST = 'fileImport/GET_AUTHORIZED_FILE_FORMAT_LIST';

// Mutations
export const MUTATE_MAX_FILE_SIZE = 'fileImport/MUTATE_MAX_FILE_SIZE';
export const MUTATE_MAX_FILE_SIZE_UPLOADED = 'fileImport/MUTATE_MAX_FILE_SIZE_UPLOADED';
export const MUTATE_AUTHORIZED_FILE_FORMAT_LIST = 'fileImport/MUTATE_AUTHORIZED_FILE_FORMAT_LIST';
export const MUTATE_REMOVE_AUTHORIZED_FILE_FORMAT = 'fileImport/MUTATE_REMOVE_AUTHORIZED_FILE_FORMAT';
export const MUTATE_CREATE_AUTHORIZED_FILE_FORMAT = 'fileImport/MUTATE_CREATE_AUTHORIZED_FILE_FORMAT';

// Actions
export const SET_MAX_FILE_SIZE = 'fileImport/SET_MAX_FILE_SIZE';
export const ACTIONS_CENTRAL_CONFIG_MAX_FILE_SIZE = 'fileImport/ACTIONS_CENTRAL_CONFIG_MAX_FILE_SIZE';
export const ACTIONS_CENTRAL_CONFIG_AUTHORIZED_FILE_FORMATS =
  'fileImport/ACTIONS_CENTRAL_CONFIG_AUTHORIZED_FILE_FORMATS';
export const REMOVE_AUTHORIZED_FILE_FORMAT = 'fileImport/REMOVE_AUTHORIZED_FILE_FORMAT';
export const CREATE_AUTHORIZED_FILE_FORMAT = 'fileImport/CREATE_AUTHORIZED_FILE_FORMAT';
