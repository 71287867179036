import { types } from '../../../src/store/types/types';
import { portal } from '../../assets/plugins/axios/axios';

const state = {
  myProfileConsents: [],
  myProfilePendingConsents: [],
  userConsents: [],
  showConsentMenu: true,
};

const getters = {
  [types.GET_MYPROFILE_CONSENTS]: state => state.myProfileConsents,
  [types.GET_MYPROFILE_PENDING_CONSENTS]: state => state.myProfilePendingConsents,
  [types.GET_PROFILE_CONSENTS]: state => state.userConsents,
  [types.GET_SHOW_CONSENT_MENU]: state => state.showConsentMenu,
};

const mutations = {
  [types.MUTATE_MYPROFILE_CONSENTS]: (state, payload) => {
    for (const consentGroup of payload) {
      const items = consentGroup.consentResponses;
      items.sort((a, b) => parseFloat(a.viewOrder) - parseFloat(b.viewOrder));
      consentGroup.consentResponses = items;
    }
    state.myProfileConsents = payload;
  },
  [types.MUTATE_MYPROFILE_PENDING_CONSENTS]: (state, payload) => {
    for (const consentGroup of payload) {
      const items = consentGroup.consentResponses;
      items.sort((a, b) => parseFloat(a.viewOrder) - parseFloat(b.viewOrder));
      consentGroup.consentResponses = items;
    }
    state.myProfilePendingConsents = payload;
  },
  [types.MUTATE_USER_CONSENTS]: (state, payload) => {
    for (const consentGroup of payload) {
      const items = consentGroup.consentResponses;
      items.sort((a, b) => parseFloat(a.viewOrder) - parseFloat(b.viewOrder));
      consentGroup.consentResponses = items;
    }
    state.userConsents = payload;
  },
  [types.MUTATE_RESET_MYPROFILE_CONSENT]: state => {
    state.myProfileConsents = [];
  },
  [types.MUTATE_RESET_MYPROFILE_PENDING_CONSENT]: state => {
    state.myProfilePendingConsents = [];
  },
  [types.MUTATE_RESET_PROFILE_CONSENT]: state => {
    state.userConsents = [];
  },
  [types.MUTATE_SHOW_CONSENT_MENU]: state => {
    let markedProfile = 0;
    const myProfileConsents = state.myProfileConsents;
    for (const profile of myProfileConsents) {
      if (
        profile.consentResponses.length > 0 &&
        profile.consentResponses.filter(consent => consent.editable).length > 0
      ) {
        markedProfile++;
      }
    }
    state.showConsentMenu = markedProfile > 0;
  },
};

const actions = {
  [types.ACTION_GET_CONSENTS_RESPONSE]: ({ commit }, payload) => {
    const params = {
      params: {
        returnOnlyPendingConsentResponses:
          payload && payload.returnOnlyPendingConsentResponses != null
            ? payload.returnOnlyPendingConsentResponses
            : false,
      },
    };
    const isGetPendingConsent = payload && payload.returnOnlyPendingConsentResponses ? true : false;
    return portal
      .get('?method=consents.getConsentResponses', params)
      .then(response => {
        if (!isGetPendingConsent) {
          commit(types.MUTATE_MYPROFILE_CONSENTS, response.data.data);
          commit(types.MUTATE_SHOW_CONSENT_MENU);
          return;
        } else {
          return commit(types.MUTATE_MYPROFILE_PENDING_CONSENTS, response.data.data);
        }
      })
      .catch(() => {
        if (payload.isOnboarding) {
          commit(types.MUTATE_ONBOARDING_ERROR);
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_CONSENT_REPSONSES');
      });
  },
  [types.ACTION_GET_CONSENTS_RESPONSE_BY_INSTITUTION_PROFILEID]: ({ commit }, payload) => {
    const params = {
      params: {
        institutionProfileId: payload.id,
        onlyCustodyChildren: false,
      },
    };
    return portal
      .get('?method=consents.getConsentResponses', params)
      .then(response => commit(types.MUTATE_USER_CONSENTS, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_CONSENT_REPSONSES');
      });
  },

  [types.ACTION_UPDATE_CONSENT_RESPONSES]: ({ commit }, payload) =>
    portal
      .post('?method=consents.updateConsentResponses', payload)
      .then(response => response.data)
      .catch(() => {
        if (payload.isOnboarding) {
          commit(types.MUTATE_ONBOARDING_ERROR);
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_CONSENT_REPSONSES');
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
