export enum moduleTypes {
  ADMINISTRATION = 'administration',
  CALENDAR = 'calendar',
  EVENT = 'event',
  DASHBOARD = 'dashboard',
  PRESENCE = 'presence',
  EMPLOYEE_DASHBOARD = 'employee_dashboard',
  DOCUMENTS = 'documents',
  SECURE_DOCUMENT = 'secure_document',
  GALLERY = 'gallery',
  MASTER_DATA = 'masterdata',
  MESSAGES = 'messages',
  GROUPS = 'groups',
  OVERVIEW = 'overview',
  RESOURCES = 'resources',
  CONTACTS = 'contacts',
  PERSONAL_REFERENCE_DATA = 'personal_reference_data',
  GROUP_ADMINISTRATION = 'group-administration',
  GROUP_MEMBERS = 'group-members',
  NOTICEBOARD = 'noticeboard',
  OTP_ADMINISTRATION = 'otp_administration',
}
