import * as global from './global';
import * as globalShared from '../../../shared/store/types/global';
import * as auth from '../../../shared/store/types/auth';
import * as settings from './settings';
import * as setup from './setup';
import * as users from './users';
import * as presence from './presence';
import * as usersShared from '../../../shared/store/types/users';
import * as groups from '../../../shared/store/types/groups';
import * as search from '../../../shared/store/types/search';
import * as presenceLocations from './presenceLocations';
import * as resources from './resources';
import * as fileImport from '../../../shared/store/types/fileImport';
import * as administrativeAuthorities from './administrativeAuthorities';
import * as communicationChannels from './communicationChannels';
import * as additionalData from './additionalData';
import * as accesscontrol from '../../../shared/store/types/accesscontrol';
import * as posts from '../../../src/store/types/posts';
import * as messages from '../../../shared/store/types/messages';
import * as calendar from '../../../shared/store/types/calendar';
import * as documents from '../../../shared/store/types/documents';
import * as files from '../../../shared/store/types/files';
import * as transferYear from './transferYear';
import * as consents from './consents';
import * as consentsShared from '../../../shared/store/types/consents';
import * as reportedContent from '../../../shared/store/types/reportedContent';
import * as commonFiles from './commonFiles';
import * as dashboards from './dashboards';
import * as notifications from '../../../shared/store/types/notifications';
import * as gallery from '../../../src/store/types/gallery';
import * as onboarding from '../../../src/store/types/onboarding';
import * as noticeBoards from '../../../shared/store/types/noticeBoards';
import * as documentsAdmin from './documents';
import * as toolbox from './toolbox';

export const types = Object.assign(
  {},
  global,
  globalShared,
  auth,
  settings,
  setup,
  users,
  usersShared,
  groups,
  search,
  presenceLocations,
  fileImport,
  resources,
  administrativeAuthorities,
  communicationChannels,
  additionalData,
  accesscontrol,
  notifications,
  transferYear,
  consents,
  consentsShared,
  commonFiles,
  dashboards,
  posts,
  messages,
  calendar,
  documents,
  reportedContent,
  files,
  gallery,
  presence,
  onboarding,
  noticeBoards,
  documentsAdmin,
  toolbox
);
