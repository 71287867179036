// Interface for all getters, mutations and actions

// Getters
export const GET_MENU_ITEMS = 'global/GET_MENU_ITEMS';
export const SHOW_SELECT_INSTITUTION = 'global/SHOW_SELECT_INSTITUTION';

// Mutations
export const MUTATE_ERROR_TEXT = 'global/MUTATE_ERROR_TEXT';
export const MUTATE_TOGGLE_SELECT_INSTITUTION = 'global/MUTATE_TOGGLE_SELECT_INSTITUTION';

// Actions
export const TOGGLE_SELECT_INSTITUTION = 'global/TOGGLE_SELECT_INSTITUTION';
