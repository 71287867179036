<template>
  <img
    :src="imgUrl"
    alt
    class="aula-img aula-img-variables"
    :crossorigin="crossorigin"
    @error="$emit('error', $event)"
    @load="$emit('load', $event)"
  />
</template>
<script>
import { browserUtil } from '../utils/browserUtil';

export default {
  name: 'AulaImg',
  props: {
    imgUrl: {
      required: true,
      type: String,
    },
  },
  computed: {
    crossorigin() {
      if (browserUtil.isSafari()) {
        return false;
      }
      return 'anonymous';
    },
  },
};
</script>

<style scoped lang="scss">
.aula-img-variables {
  --width: auto;
  --height: auto;
  --object-fit: cover;
  --background-color: transparent;
}
.aula-img {
  object-fit: var(--object-fit);
  overflow: hidden;
  width: var(--width);
  height: var(--height);
  background-color: var(--background-color);
}
</style>
