// Interface for all getters, mutations and actions

// Getters
export const GET_MYPROFILE_CONSENTS = 'consent/GET_MYPROFILE_CONSENTS';
export const GET_MYPROFILE_PENDING_CONSENTS = 'consent/GET_MYPROFILE_PENDING_CONSENTS';
export const GET_PROFILE_CONSENTS = 'consent/GET_PROFILE_CONSENTS';
export const GET_SHOW_CONSENT_MENU = 'consent/GET_SHOW_CONSENT_MENU';
// Mutations
export const MUTATE_MYPROFILE_CONSENTS = 'consent/MUTATE_MYPROFILE_CONSENTS';
export const MUTATE_MYPROFILE_PENDING_CONSENTS = 'consent/MUTATE_MYPROFILE_PENDING_CONSENTS';
export const MUTATE_USER_CONSENTS = 'consent/MUTATE_USER_CONSENTS';
export const MUTATE_RESET_MYPROFILE_CONSENT = 'consent/MUTATE_RESET_MYPROFILE_CONSENT';
export const MUTATE_RESET_MYPROFILE_PENDING_CONSENT = 'consent/MUTATE_RESET_MYPROFILE_PENDING_CONSENT';
export const MUTATE_RESET_PROFILE_CONSENT = 'consent/MUTATE_RESET_PROFILE_CONSENT';
export const MUTATE_SHOW_CONSENT_MENU = 'consent/MUTATE_SHOW_CONSENT_MENU';
// Actions
export const ACTION_GET_CONSENTS_RESPONSE = 'consent/ACTION_GET_CONSENTS_RESPONSE';
export const ACTION_GET_CONSENTS_RESPONSE_BY_INSTITUTION_PROFILEID =
  'consent/ACTION_GET_CONSENTS_RESPONSE_BY_INSTITUTION_PROFILEID';

export const ACTION_UPDATE_CONSENT_RESPONSES = 'consent/ACTION_UPDATE_CONSENT_RESPONSES';
