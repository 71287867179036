<template>
  <b-container class="login-by-id-provider mt-4">
    <b-row>
      <b-col>
        <div class="title">
          <span>{{ 'MUNICIPALITY_IDP' | fromTextKey }}</span>
        </div>
      </b-col>
      <b-col cols="12" class="mb-5">
        <aula-select
          v-model="selectedMunipality"
          filterable
          :placeholder="'MUNICIPALITY_SELECT_PLACEHOLDER' | fromTextKey"
          class="kommnue-ddown-list"
        >
          <aula-option
            v-for="item in getUniqueMunicipalList"
            :key="item.municipalityCode"
            :label="item.municipalityName"
            :value="item.municipalityCode"
          />
        </aula-select>
      </b-col>
      <b-col cols="12" class="mb-5">
        <aula-select
          v-model="selectedInstitution"
          filterable
          :placeholder="'SCHOOL_SELECT_PLACEHOLDER' | fromTextKey"
          class="institution-ddown-list"
        >
          <aula-option
            v-for="item in getInstitutionList"
            :key="item.institutionCode"
            :label="item.institutionName"
            :value="item.institutionCode"
          />
        </aula-select>
      </b-col>
      <b-col cols="12" class="submit-button-container">
        <b-btn type="submit" class="submit-button" variant="primary" @click="submitSelections">
          {{ 'OK' | fromTextKey }}
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { types } from '../../../src/store/types/types';
import { mapActions } from 'vuex';
import { mapGetters } from 'vuex';
import { Cookie } from '../../assets/plugins/cookie';
import $ from 'jquery';

export default {
  data: function () {
    return {
      selectedInstitution: null,
      selectedMunipality: null,
      status: false,
    };
  },
  computed: {
    ...mapGetters({
      municipalList: types.GET_MUNICIPAL_IPS,
    }),
    getUniqueMunicipalList() {
      const filterMunicipalList = [];
      for (const municipal of this.municipalList) {
        if (filterMunicipalList.filter(obj => obj.municipalityCode == municipal.municipalityCode).length == 0) {
          filterMunicipalList.push(municipal);
        }
      }
      filterMunicipalList.sort(function (a, b) {
        const nameA = a.municipalityName.toLowerCase();
        const nameB = b.municipalityName.toLowerCase();
        if (nameA < nameB) {
          return -1;
        }
        if (nameA > nameB) {
          return 1;
        }
        return 0;
      });
      return filterMunicipalList;
    },
    getInstitutionList() {
      let filterInstitution = [];
      if (this.selectedMunipality) {
        filterInstitution = this.municipalList.filter(obj => obj.municipalityCode == this.selectedMunipality);
        const filterInstitutionLogin = [];
        for (const institution of filterInstitution) {
          if (filterInstitutionLogin.filter(obj => obj.municipalityCode == this.selectedMunipality)) {
            filterInstitutionLogin.push(institution);
          }
        }
        filterInstitution = filterInstitutionLogin;
        if (filterInstitution.length == 1) {
          // eslint-disable-next-line vue/no-side-effects-in-computed-properties
          this.selectedInstitution = filterInstitution[0].institutionCode;
          return filterInstitution;
        }
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.selectedInstitution = null;

        filterInstitution.sort(function (a, b) {
          const nameA = a.institutionName.toLowerCase();
          const nameB = b.institutionName.toLowerCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        return filterInstitution;
      }
      return [];
    },
  },
  methods: {
    ...mapActions({
      getMunicipalList: types.SET_MUNICIPAL_IPS,
    }),
    selectRole(portalRole) {
      this.emit('afterSelectPortalRole', portalRole);
    },
    submitSelections() {
      const remember = Cookie.Read('rememberLoginMethod') == 'true' ? true : false;
      const selectedMunipality = this.selectedMunipality;
      const selectedInstitution = this.selectedInstitution;
      const idp = this.municipalList.find(
        m => m.municipalityCode == selectedMunipality && m.institutionCode == selectedInstitution
      );
      if (idp !== null) {
        const payload = {
          entityId: idp.entityId,
          institutionCode: idp.institutionCode,
          institutionName: idp.institutionName,
          institutionType: idp.institutionType,
          municipalityCode: idp.municipalityCode,
          municipalityName: idp.municipalityName,
          type: idp.identityProviderType,
        };
        if (remember) {
          Cookie.Create('IDP', JSON.stringify(payload), 365);
        }
        const fullHost =
          window.location.protocol +
          '//' +
          window.location.hostname +
          (window.location.port ? ':' + window.location.port : '');
        window.location.href = fullHost + '/auth/login.php?' + $.param(payload);
      }
    },
  },
  mounted() {
    this.getMunicipalList();
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/login/login.scss';
.login-by-id-provider {
  padding: 27px 50px;
  height: 371px;
  width: 371px;
  border: 1px solid $color-white;
  @include breakpoint-sm-down() {
    padding: 27px 40px;
    width: 100%;
  }
  .title {
    height: 29px;
    width: 216px;
    color: $color-white;
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    margin-bottom: 50px;
  }
  .submit-button-container {
    margin-bottom: 50px;
    .submit-button {
      float: right;
      height: 50px;
      width: 100%;
      border-radius: 5px;
      background-color: $color-grey-lighter;
      color: $color-primary-dark;
    }
  }
  /deep/ .kommnue-ddown-list {
    .el-input__inner {
      border-bottom: 1px solid $color-white;
      border-radius: 0;
      background-color: transparent;
      color: $color-white;
      &:focus-visible {
        outline: 2px solid white !important;
      }
    }
    .el-input__inner::placeholder {
      color: $color-white !important;
    }
  }
  /deep/ .institution-ddown-list {
    .el-input__inner {
      border-bottom: 1px solid $color-white;
      border-radius: 0;
      background-color: transparent;
      color: $color-white;
      &:focus-visible {
        outline: 2px solid white !important;
      }
    }
    .el-input__inner::placeholder {
      color: $color-white !important;
    }
  }
}
</style>
