// Interface for all getters, mutations and actions

// Getters
export const GET_USERS_FROM_SEARCH = 'users/GET_USERS_FROM_SEARCH';
export const GET_SELECTED_PROFILE = 'users/GET_SELECTED_PROFILE';
export const GET_PROFILE_ADDITIONAL_DATA = 'users/GET_PROFILE_ADDITIONAL_DATA';
export const GET_CONTACT_INFO = 'users/GET_CONTACT_INFO';
export const GET_RTBF_REQUESTS = 'users/GET_RTBF_REQUESTS';

// Mutations
export const MUTATE_SEARCH_USERS = 'users/MUTATE_SEARCH_USERS';
export const MUTATE_SELECT_PROFILE = 'users/MUTATE_SELECT_PROFILE';
export const MUTATE_LOAD_PROFILE_ADDITIONAL_DATA = 'users/MUTATE_LOAD_PROFILE_ADDITIONAL_DATA';
export const MUTATE_RESET_SELECTED_PROFILE = 'users/MUTATE_RESET_SELECTED_PROFILE';
export const MUTATE_CONTACT_INFO = 'users/MUTATE_CONTACT_INFO';
export const MUTATE_LOAD_RTBF_REQUESTS = 'users/MUTATE_LOAD_RTBF_REQUESTS';

// Actions
export const SEARCH_USERS = 'users/SEARCH_USERS';
export const SELECT_PROFILE = 'users/SELECT_PROFILE';
export const UPDATE_PROFILE = 'users/UPDATE_PROFILE';
export const LOAD_CONTACT_INFO = 'users/LOAD_CONTACT_INFO';
export const RESET_SELECTED_USER = 'users/RESET_SELECTED_USER';
export const LOAD_PROFILE_ADDITIONAL_DATA = 'users/LOAD_PROFILE_ADDITIONAL_DATA';
export const EDIT_BLOCK_OF_COMMUNICATION_FOR_SELECTED_USER = 'users/EDIT_BLOCK_OF_COMMUNICATION_FOR_SELECTED_USER';
export const DELETE_USER_DATA = 'users/DELETE_USER_DATA';
export const HANDLE_ENTITIES_RTBF = 'users/HANDLE_ENTITIES_RTBF';
export const LOAD_RTBF_REQUESTS = 'users/LOAD_RTBF_REQUESTS';
