import { types } from '../types/types';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';
import moment from 'moment-timezone';
import { presenceDashboardContext } from '../../../shared/enums/presenceDashboardContext';
import { presenceModuleStatus } from '../../../shared/enums/presenceModuleStatus';
import { pickupTypes } from '../../../shared/enums/pickupTypes';
import { presenceStates } from '../../../shared/enums/presenceStates';
import cloneDeep from 'lodash/cloneDeep';

const state = {
  presenceTimes: [],
  presenceStates: [],
  suggestedPickupName: [],
  pickupResponsibles: [],
  goHomeWithList: [],
  presenceConfiguration: {},
  presenceActiveModules: {},
  allPresenceModules: {},
  dailyOverview: [],
  vacationRegistrations: [],
  vacationAnnouncements: [],
  vacationRequestResponse: null,
  openHoursGuardianDashboard: null,
  generalOpeningHours: [],
  specificOpeningHours: [],
  holidayClosedDays: [],
  selectedPresenceWeek: {},
  overlappingPresenceTemplates: {},
};

const getters = {
  [types.GET_PICKUP_RESPONSIBLES]: state => state.pickupResponsibles,
  [types.GET_PRESENCE_CONFIGURATION]: state => state.presenceConfiguration,
  [types.GET_GO_HOME_WITH_LIST]: state => state.goHomeWithList,
  [types.GET_DAILY_OVERVIEW]: state => state.dailyOverview,
  [types.GET_PRESENCE_TIMES]: state => state.presenceTimes,
  [types.GET_PRESENCE_ACTIVE_MODULES]: state => state.presenceActiveModules,
  [types.GET_ALL_PRESENCE_MODULES]: state => state.allPresenceModules,
  [types.GET_PRESENCE_STATES_GUARDIAN]: state => state.presenceStates,
  [types.GET_SUGGESTED_PICKUP_NAME]: state => state.suggestedPickupName,
  [types.GET_VACATION_REGISTRATIONS_BY_CHILDREN]: state => state.vacationRegistrations,
  [types.GET_VACATION_ANNOUNCEMENTS_BY_CHILDREN]: state => state.vacationAnnouncements,
  [types.GET_VACATION_REQUEST_RESPONSE]: state => state.vacationRequestResponse,
  [types.GET_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD]: state => state.openHoursGuardianDashboard,
  [types.GET_GENERAL_OPENING_HOURS_FOR_GUARDIAN]: state => state.generalOpeningHours,
  [types.GET_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN]: state => state.specificOpeningHours,
  [types.GET_HOLIDAY_CLOSED_DAY]: state => state.holidayClosedDays,
  [types.GET_SELECTED_PRESENCE_WEEK]: state => state.selectedPresenceWeek,
  [types.GET_OVERLAPPING_PRESENCE_TEMPLATES]: state => state.overlappingPresenceTemplates,
};

const mutations = {
  [types.MUTATE_PRESENCE_TIMES]: (state, payload) => {
    if (payload.results.presenceWeekTemplates.length > 0) {
      for (const template of payload.results.presenceWeekTemplates) {
        if (payload.children.find(c => c.id === template.institutionProfile.id)?.isMultipleInstitution) {
          template.institutionProfile.isMultipleInstitution = true;
        }
        for (const dayTemplate of template.dayTemplates) {
          dayTemplate.byDate = moment(dayTemplate.byDate).format('DD-MM-YYYY');
          dayTemplate.entryTime =
            dayTemplate.entryTime != null ? moment(dayTemplate.entryTime, 'HH:mm:ss').format('HH:mm') : null;
          dayTemplate.exitTime =
            dayTemplate.exitTime != null ? moment(dayTemplate.exitTime, 'HH:mm:ss').format('HH:mm') : null;
          dayTemplate.selfDeciderStartTime =
            dayTemplate.selfDeciderStartTime != null
              ? moment(dayTemplate.selfDeciderStartTime, 'HH:mm:ss').format('HH:mm')
              : null;
          dayTemplate.selfDeciderEndTime =
            dayTemplate.selfDeciderEndTime != null
              ? moment(dayTemplate.selfDeciderEndTime, 'HH:mm:ss').format('HH:mm')
              : null;
          if (dayTemplate.spareTimeActivity != null) {
            dayTemplate.spareTimeActivity.startTime =
              dayTemplate.spareTimeActivity.startTime != null
                ? moment(dayTemplate.spareTimeActivity.startTime, 'HH:mm:ss').format('HH:mm')
                : null;
            dayTemplate.spareTimeActivity.endTime =
              dayTemplate.spareTimeActivity.endTime != null
                ? moment(dayTemplate.spareTimeActivity.endTime, 'HH:mm:ss').format('HH:mm')
                : null;
          }
        }
      }
    }
    state.presenceTimes = payload.results;
  },
  [types.MUTATE_PRESENCE_CONFIGURATIONS]: (state, payload) => {
    state.presenceActiveModules = {};
    state.allPresenceModules = {};
    for (const child of state.presenceStates) {
      const config = payload.find(c => c.uniStudentId == child.uniStudentId);
      if (config != null) {
        for (const pickupType in pickupTypes) {
          if (config.presenceConfiguration[pickupTypes[pickupType].paramName]) {
            if (state.presenceActiveModules[pickupTypes[pickupType].paramName] == null) {
              state.presenceActiveModules[pickupTypes[pickupType].paramName] = [];
            }
            state.presenceActiveModules[pickupTypes[pickupType].paramName].push(config.uniStudentId);
          }
        }
        state.allPresenceModules = cloneDeep(state.presenceActiveModules);
        const dashboardModuleSettings = config.presenceConfiguration.dashboardModuleSettings.find(
          m => m.presenceDashboardContext == presenceDashboardContext.GUARDIAN_DASHBOARD
        );
        if (dashboardModuleSettings != null && dashboardModuleSettings.presenceModules.length > 0) {
          for (const module of dashboardModuleSettings.presenceModules) {
            if (state.presenceActiveModules[module.moduleType] == null) {
              state.presenceActiveModules[module.moduleType] = [];
            }

            if (state.allPresenceModules[module.moduleType] == null) {
              state.allPresenceModules[module.moduleType] = [];
            }

            if (module.permission == presenceModuleStatus.EDITABLE) {
              state.presenceActiveModules[module.moduleType].push(config.uniStudentId);
            }

            state.allPresenceModules[module.moduleType].push(config.uniStudentId);
          }
        }
      }
    }
  },
  [types.MUTATE_PRESENCE_STATES]: (state, payload) => {
    state.presenceStates = payload;
  },
  [types.MUTATE_DAILY_OVERVIEW]: (state, payload) => {
    state.dailyOverview = payload;
  },
  [types.MUTATE_PRESENCE_CONFIGURATION]: (state, payload) => {
    state.presenceConfiguration = payload;
  },
  [types.MUTATE_SUGGESTED_PICKUP_NAME]: (state, payload) => {
    state.suggestedPickupName = payload;
  },
  [types.MUTATE_PICKUP_RESPONSIBLES]: (state, payload) => {
    state.pickupResponsibles = payload;
  },
  [types.MUTATE_GO_HOME_WITH_LIST]: (state, payload) => {
    state.goHomeWithList = payload;
  },
  [types.MUTATE_REMOVE_PICUP_RESPONSIBLE]: (state, payload) => {
    const index = state.pickupResponsibles.findIndex(child => child.uniStudentId == payload.uniStudentId);
    if (index != -1) {
      const picupIndex = state.pickupResponsibles[index].pickupSuggestions.findIndex(
        pickup => pickup.id == payload.pickupId
      );
      if (picupIndex != -1) {
        state.pickupResponsibles[index].pickupSuggestions.splice(picupIndex, 1);
      }
    }
  },
  [types.MUTATE_VACATION_REGISTRATIONS_BY_CHILDREN]: (state, payload) => {
    state.vacationRegistrations = payload;
  },
  [types.MUTATE_VACATION_ANNOUNCEMENTS_BY_CHILDREN]: (state, payload) => {
    state.vacationAnnouncements = payload;
  },
  [types.MUTATE_VACATION_REQUEST_RESPONSE]: (state, payload) => {
    state.vacationRequestResponse = payload;
  },
  [types.MUTATE_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD]: (state, payload) => {
    state.openHoursGuardianDashboard = payload;
  },
  [types.MUTATE_GENERAL_OPENING_HOURS_FOR_GUARDIAN]: (state, payload) => {
    const institutionOpeningHours = payload.data.institutionOpeningHours;
    const openingHourInstitutionCodes = institutionOpeningHours.map(openingHour => openingHour.institutionCode);

    for (const institutionCode of payload.institutionCodes) {
      if (openingHourInstitutionCodes.includes(institutionCode) === false) {
        const emptyOpeningHourData = {
          institutionCode,
          openingHours: [],
        };
        institutionOpeningHours.push(emptyOpeningHourData);
      }
    }

    state.generalOpeningHours = institutionOpeningHours;
  },
  [types.MUTATE_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN]: (state, payload) => {
    state.specificOpeningHours = payload.data.specificOpeningHoursWithInstitutions;
  },
  [types.MUTATE_HOLIDAY_CLOSED_DAY]: (state, payload) => {
    state.holidayClosedDays = payload.data.institutionClosedDays;
  },
  [types.MUTATE_SELECTED_PRESENCE_WEEK]: (state, payload) => {
    state.selectedPresenceWeek = payload;
  },
  [types.MUTATE_OVERLAPPING_PRESENCE_TEMPLATES]: (state, overlappingPresenceTemplates) => {
    state.overlappingPresenceTemplates = overlappingPresenceTemplates;
  },
};

const actions = {
  [types.LOAD_PRESENCE_TIMES]: ({ commit, rootState }, payload) => {
    if (payload.institutionProfileIds.length > 0) {
      return portal
        .get('?method=presence.getPresenceTemplates', {
          params: {
            filterInstitutionProfileIds: payload.institutionProfileIds,
            fromDate: payload.fromDate,
            toDate: payload.toDate,
          },
        })
        .then(response => {
          commit(types.MUTATE_PRESENCE_TIMES, {
            results: response.data.data,
            children: rootState.global.children,
          });
        })
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PRESENCE_TEMPLATES');
        });
    }
  },
  [types.LOAD_PRESENCE_CONFIGURATION]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getPresenceConfiguration', {
        params: {
          institutionCode: payload.institutionCode,
        },
      })
      .then(response => commit(types.MUTATE_PRESENCE_CONFIGURATION, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PRESENCE_CONFIGURATION');
      }),
  [types.UPDATE_SELECTED_CHILDREN_STATUS]: ({ commit }, payload) =>
    portal
      .post('?method=presence.bulkUpdatePresenceStatus', payload)
      .then(() => commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_UPDATE_STATUS'))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_PRESENCE_STATUS');
      }),
  [types.LOAD_PRESENCE_CONFIGURATIONS_CHILD_IDS]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getPresenceConfigurationByChildIds', {
        params: {
          childIds: payload.childIds,
        },
      })
      .then(response => commit(types.MUTATE_PRESENCE_CONFIGURATIONS, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PRESENCE_CONFIGURATION');
      }),
  [types.LOAD_PRESENCE_STATES]: ({ commit }, payload) => {
    if (payload.institutionProfileIds.length > 0) {
      return portal
        .get('?method=presence.getPresenceStates', {
          params: {
            institutionProfileIds: payload.institutionProfileIds,
          },
        })
        .then(response => {
          commit(types.MUTATE_PRESENCE_STATES, response.data.data);
        })
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PRESENCE_STATES');
        });
    } else {
      commit(types.MUTATE_PRESENCE_STATES, []);
    }
  },
  [types.UPDATE_PRESENCE_TEMPLATE]: ({ commit }, payload) =>
    portal
      .post('?method=presence.updatePresenceTemplate', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_UPDATE_TEMPLATE');
        return response;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_PRESENCE_TEMPLATES');
      }),
  [types.ADD_ABSENCE]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.addVacation', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_REGISTER_ABSENCE');
        return response;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_ADD_VACATION');
      }),
  [types.UPDATE_SICK]: ({ commit }, { institutionProfileIds, status }) =>
    portal
      .post('?method=presence.updateStatusByInstitutionProfileIds', { institutionProfileIds, status })
      .then(response => {
        const successMessage =
          status === presenceStates.SICK ? 'SUCCESS_TOAST_REGISTER_SICK' : 'SUCCESS_TOAST_UNREGISTER_SICK';
        commit(types.MUTATE_SUCCESS_TEXT, successMessage);
        return response;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_PRESENCE_STATUS');
      }),
  [types.LOAD_PICKUP_RESPONSIBLES]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getPickupResponsibles', {
        params: { uniStudentIds: payload.uniStudentIds },
      })
      .then(response => {
        commit(types.MUTATE_PICKUP_RESPONSIBLES, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PICKUP_RESPONSIBLES');
      }),
  [types.LOAD_GO_HOME_WITH_LIST]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getGoHomeWithList', { params: payload })
      .then(response => {
        commit(types.MUTATE_GO_HOME_WITH_LIST, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_GO_HOME_WITH');
      }),
  [types.LOAD_DAILY_OVERVIEW]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getDailyOverview', { params: payload })
      .then(response => {
        commit(types.MUTATE_DAILY_OVERVIEW, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_GO_HOME_WITH');
      }),
  [types.SAVE_PICKUP_RESPONSIBLES]: ({ commit }, payload) =>
    portal
      .post('?method=presence.savePickupNames', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_SAVED_PICKUP_RESPONSIBLE');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SAVE_PICKUP_RESPONSIBLE');
      }),
  [types.DELETE_PICKUP_RESPONSIBLES]: ({ commit }, payload) =>
    portal
      .post('?method=presence.deletePickupResponsible', {
        presencePickupSuggestionId: payload.pickupId,
      })
      .then(() => {
        commit(types.MUTATE_REMOVE_PICUP_RESPONSIBLE, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_DELETED_PICKUP_RESPONSIBLE');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_PICKUP_RESPONSIBLE');
      }),
  [types.DELETE_REPEATING_PRESENCE_TEMPLATE]: ({ commit }, payload) =>
    portal
      .post('?method=presence.deleteRepeatingPresenceTemplate', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_DELETED_REPEATING_PRESENCE_TEMPLATE');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_REPEATING_PRESENCE_TEMPLATE');
      }),
  [types.LOAD_VACATION_REGISTRATIONS_BY_CHILDREN]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getVacationRegistrationsByChildren', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_VACATION_REGISTRATIONS_BY_CHILDREN, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_VACATION_REGISTRATIONS');
      }),
  [types.LOAD_VACATION_ANNOUCEMENTS_BY_CHILDREN]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getVacationAnnouncementsByChildren', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_VACATION_ANNOUNCEMENTS_BY_CHILDREN, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_VACATION_ANNOUNCEMENTS');
      }),
  [types.LOAD_VACATION_REQUEST_RESPONSE]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getVacationRegistrationResponse', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_VACATION_REQUEST_RESPONSE, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_VACATION_RESPONSE');
      }),
  [types.LOAD_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getOpeningHoursByInstitutionCodes', {
        params: {
          institutionCodes: payload.institutionCodes,
          startDate: payload.startDate,
          endDate: payload.endDate,
        },
      })
      .then(response => {
        commit(types.MUTATE_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_OPEN_HOURS');
      }),
  [types.LOAD_GENERAL_OPENING_HOURS_FOR_GUARDIAN]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getGeneralOpeningHours', { params: payload })
      .then(response => {
        commit(types.MUTATE_GENERAL_OPENING_HOURS_FOR_GUARDIAN, {
          data: response.data.data,
          institutionCodes: payload.institutionCodes,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_GENERAL_OPENING_HOURS');
      }),
  [types.LOAD_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getSpecificOpeningHourOverview', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN, {
          data: response.data.data,
          institutionCodes: payload.institutionCodes,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_GENERAL_OPENING_HOURS');
      }),
  [types.LOAD_HOLIDAY_CLOSED_DAY]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getClosedDays', { params: payload })
      .then(response => {
        commit(types.MUTATE_HOLIDAY_CLOSED_DAY, {
          data: response.data.data,
          institutionCodes: payload.institutionCodes,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_HOLIDAY_CLOSED_DAYS');
      }),
  [types.LOAD_OVERLAPPING_PRESENCE_TEMPLATES]: ({ commit }, { startDate, endDate, institutionProfileId }) =>
    portal
      .get('?method=presence.getOverlappingPresenceTemplates', { params: { startDate, endDate, institutionProfileId } })
      .then(response => commit(types.MUTATE_OVERLAPPING_PRESENCE_TEMPLATES, response.data.data)),
  [types.RESET_OVERLAPPING_PRESENCE_TEMPLATES]: ({ commit }) => commit(types.MUTATE_OVERLAPPING_PRESENCE_TEMPLATES, {}),
};
export default {
  state,
  mutations,
  actions,
  getters,
};
