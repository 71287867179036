class VideoCacheUtil {
  getExpirationDateFromUrl(url) {
    const regexMatch = url.match(/Expires=(\d+)/);
    if (regexMatch != null) {
      const expiration = parseInt(regexMatch[1]);
      if (!isNaN(expiration)) {
        // AWS timestamp is in seconds, not milliseconds.
        return expiration * 1000;
      }
    }
    const now = new Date().getTime();
    return now + 30 * 60 * 1000; // 30 minutes ahead
  }

  downloadFileAsBlob(url) {
    return new Promise((resolve, reject) => {
      const request = new XMLHttpRequest();
      request.responseType = 'blob';
      request.onload = () => resolve(request.response);
      request.onerror = reject;
      request.open('GET', url, true);
      request.send();
    });
  }
}

export const videoCacheUtil = new VideoCacheUtil();
