import { types } from '../types/types';
import Vue from 'vue';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';

const state = {
  administrativeAuthorities: [],
  administrativeAuthority: {
    institutionCodes: [],
    name: '',
  },
  institutions: [],
};

const getters = {
  [types.GET_ADMINISTRATIVE_AUTHORITIES]: state => state.administrativeAuthorities,
  [types.GET_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS]: state => state.institutions,
  [types.GET_SELECTED_ADMINISTRATIVE_AUTHORITY]: state => state.administrativeAuthority,
};

const mutations = {
  [types.MUTATE_GET_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS]: (state, payload) => {
    state.institutions = payload;
  },
  [types.MUTATE_GET_ADMINISTRATIVE_AUTHORITIES]: (state, payload) => {
    for (const institutionIndex in payload) {
      const institution = payload[institutionIndex];

      const id = institution.administrativeAuthority.id;
      const name = institution.administrativeAuthority.name;
      const institutionType = Vue.filter('fromTextKey')(
        'ADMINISTRATIVE_AUTHORITY_INSTITUTION_TYPE_' + institution.type.toUpperCase()
      );
      const administrativeAuthority = {
        id: id,
        name: name,
        institutionName: institution.institutionName + ' (' + institutionType + ')',
        institutionType: institutionType,
      };
      if (institutionIndex < 1) {
        state.administrativeAuthorities = [];
        state.administrativeAuthorities.push(administrativeAuthority);
      } else {
        let administrativeAuthorityAppears = false;
        for (let i = 0; i < state.administrativeAuthorities.length; i++) {
          if (
            state.administrativeAuthorities[i].id === id &&
            state.administrativeAuthorities[i].institutionType.indexOf(administrativeAuthority.institutionType) !== -1
          ) {
            state.administrativeAuthorities[i].institutionName += ', ' + administrativeAuthority.institutionName;
            administrativeAuthorityAppears = true;
            break;
          } else if (state.administrativeAuthorities[i].id === id) {
            state.administrativeAuthorities[i].institutionName += ', ' + administrativeAuthority.institutionName;
            state.administrativeAuthorities[i].institutionType += ', ' + administrativeAuthority.institutionType;
            administrativeAuthorityAppears = true;
            break;
          }
        }
        if (!administrativeAuthorityAppears) {
          state.administrativeAuthorities.push(administrativeAuthority);
        }
      }
    }
  },

  [types.MUTATE_SELECT_ADMINISTRATIVE_AUTHORITY]: (state, payload) => {
    // Payload consists of all institutions with the same administrative authority.
    // From this the name and id can be found from the first institution available being from index '0'.
    state.administrativeAuthority.name = payload['0'].administrativeAuthority.name;
    state.administrativeAuthority.id = payload['0'].administrativeAuthority.id;
    state.administrativeAuthority.institutions = [];

    for (const institutionIndex in payload) {
      const institution = payload[institutionIndex];

      let value = '';
      let name = '';
      let institutionType = '';
      let institutionCode = '';
      let id = '';
      let administrativeAuthority = [];

      name = institution.institutionName;
      value = institution.institutionCode;
      institutionType = institution.type;
      institutionCode = institution.institutionCode;
      id = institution.id;
      administrativeAuthority = institution.administrativeAuthority;

      state.administrativeAuthority.institutions.push({
        value: value,
        name: name,
        institutionType: institutionType,
        institutionCode: institutionCode,
        id: id,
        administrativeAuthority: administrativeAuthority,
      });
    }
  },
};

const actions = {
  [types.LOAD_ADMINISTRATIVE_AUTHORITIES]: ({ commit }, payload) =>
    portal
      .get('?method=municipalConfiguration.getInstitutionsAndAdministrativeAuthorities', {
        params: { municipalityCode: payload },
      })
      .then(response => {
        commit(types.MUTATE_GET_ADMINISTRATIVE_AUTHORITIES, response.data.data);
        commit(types.MUTATE_GET_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS, response.data.data);
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_INSTITUTION_AND_ADMINISTRATIVE_AUTHORITIES');
      }),

  [types.SELECT_ADMINISTRATIVE_AUTHORITY]: ({ commit }, payload) =>
    portal
      .get('?method=municipalConfiguration.getInstitutionsByAdministrativeAuthority', {
        params: {
          administrativeAuthorityId: payload.administrativeAuthorityId,
          municipalityCode: payload.municipalityCode,
        },
      })
      .then(response => {
        commit(types.MUTATE_SELECT_ADMINISTRATIVE_AUTHORITY, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_INSTITUTION_BY_ADMINISTRATIVE_AUTHORITY');
      }),

  [types.SAVE_ADMINISTRATIVE_AUTHORITY]: ({ commit }, payload) =>
    portal
      .post('?method=municipalConfiguration.saveAdministrativeAuthority', {
        institutionCodes: payload.institutionCodes,
        name: payload.name,
        municipalInstitutionCode: payload.municipalInstitutionCode,
        id: payload.id,
      })
      .then(response => {
        commit(types.MUTATE_GET_ADMINISTRATIVE_AUTHORITIES, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SAVE_ADMINISTRATIVE_AUTHORITY');
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
