// Interface for all getters, mutations and actions

// Getters
export const GET_CHILDREN = 'global/GET_CHILDREN';
export const GET_HAS_CUSTODY_OR_EXTENDED_ACCESS = 'global/GET_HAS_CUSTODY_OR_EXTENDED_ACCESS';
export const GET_CURRENT_UNILOGIN = 'global/GET_CURRENT_UNILOGIN';
export const GET_ACTIVE_CHILDREN = 'global/GET_ACTIVE_CHILDREN';
export const GET_ACTIVE_CHILDREN_UNILOGIN = 'global/GET_ACTIVE_CHILDREN_UNILOGIN';
export const GET_ACTIVE_INSTITUTIONS = 'global/GET_ACTIVE_INSTITUTIONS';
export const GET_ACTIVE_MAILBOXES = 'global/GET_ACTIVE_MAILBOXES';
export const GET_ALERTS = 'global/GET_ALERTS';
export const GET_MENU_ITEMS = 'global/GET_MENU_ITEMS';
export const GET_BROWSER_CHECK = 'global/GET_BROWSER_CHECK';
export const GET_SHOW_GROUPS_DROPDOWN = 'global/GET_SHOW_GROUPS_DROPDOWN';
export const GET_SHOW_SEARCH_DROPDOWN = 'global/GET_SHOW_SEARCH_DROPDOWN';
export const GET_SHOW_PROFILE_DROPDOWN = 'global/GET_SHOW_PROFILE_DROPDOWN';
export const GET_SHOW_MOBILE_PROFILE_DROPDOWN = 'global/GET_SHOW_MOBILE_PROFILE_DROPDOWN';
export const GET_SHOW_HEADER = 'global/GET_SHOW_HEADER';
export const GET_IS_PREVIEW_MODE = 'global/GET_IS_PREVIEW_MODE';
export const GET_SHOW_CONSENT_WARNING_MODAL = 'global/GET_SHOW_CONSENT_WARNING_MODAL';
export const GET_SAME_AUTHORITY_INSTITUTIONS = 'messages/GET_SAME_AUTHORITY_INSTITUTIONS';
export const GET_GROUP_HOMES = 'global/GET_GROUP_HOMES';
export const GET_ACTIVE_GROUP_HOME = 'global/GET_ACTIVE_GROUP_HOME';

// Mutations
export const MUTATE_SET_ACTIVE_CHILDREN = 'global/MUTATE_SET_ACTIVE_CHILDREN';
export const MUTATE_SET_ACTIVE_CHILDREN_UNILOGIN = 'global/MUTATE_SET_ACTIVE_CHILDREN_UNILOGIN';
export const MUTATE_SET_ACTIVE_INSTITUTIONS = 'global/MUTATE_SET_ACTIVE_INSTITUTIONS';
export const MUTATE_SET_ACTIVE_FILTERS_DIRECT = 'global/MUTATE_SET_ACTIVE_FILTERS_DIRECT';
export const MUTATE_SET_ACTIVE_MAILBOXES = 'global/MUTATE_SET_ACTIVE_MAILBOXES';
export const MUTATE_TOGGLE_HEADER_DROPDOWN = 'global/MUTATE_TOGGLE_HEADER_DROPDOWN';
export const MUTATE_HIDE_MENU = 'global/MUTATE_HIDE_MENU';
export const MUTATE_HIDE_PROFILE_DROPDOWN = 'global/MUTATE_HIDE_PROFILE_DROPDOWN';
export const MUTATE_HIDE_SEARCH_DROPDOWN = 'global/MUTATE_HIDE_SEARCH_DROPDOWN';
export const MUTATE_HIDE_GROUPS_DROPDOWN = 'global/MUTATE_HIDE_GROUPS_DROPDOWN';
export const MUTATE_SET_SHOW_HEADER = 'global/MUTATE_SET_SHOW_HEADER';
export const MUTATE_CALCULATE_MENU_ITEMS = 'global/MUTATE_CALCULATE_MENU_ITEMS';
export const MUTATE_SET_MUNICIPAL_IPS = 'global/MUTATE_SET_MUNICIPAL_IPS';
export const MUTATE_IS_PREVIEW_MODE = 'global/MUTATE_IS_PREVIEW_MODE';
export const MUTATE_PREVIEW_MODULES_WIDGETS_CONFIG = 'global/MUTATE_PREVIEW_MODULES_WIDGETS_CONFIG';
export const MUTATE_SET_SAME_AUTHORITY_INSTITUTIONS = 'global/MUTATE_SET_SAME_AUTHORITY_INSTITUTIONS';
export const MUTATE_SET_ACTIVE_ENTITIES_FOR_CURRENT_PROFILE = 'global/MUTATE_SET_ACTIVE_ENTITIES_FOR_CURRENT_PROFILE';
export const MUTATE_SET_GROUP_HOMES = 'global/MUTATE_SET_GROUP_HOMES';
export const MUTATE_SET_ACTIVE_GROUP_HOME = 'global/MUTATE_SET_ACTIVE_GROUP_HOME';

// Actions
export const SET_ACTIVE_CHILDREN = 'global/SET_ACTIVE_CHILDREN';
export const SET_ACTIVE_INSTITUTIONS = 'global/SET_ACTIVE_INSTITUTIONS';
export const SET_ACTIVE_FILTERS_DIRECT = 'global/SET_ACTIVE_FILTERS_DIRECT';
export const ALERT_IS_DONE = 'global/ALERT_IS_DONE';
export const TOGGLE_HEADER_DROPDOWN = 'global/TOGGLE_HEADER_DROPDOWN';
export const ACTIONS_SHOW_HEADER = 'global/ACTIONS_SHOW_HEADER';
export const ACTIONS_HIDE_HEADER = 'global/ACTIONS_HIDE_HEADER';
export const CALCULATE_MENU_ITEMS = 'global/CALCULATE_MENU_ITEMS';
export const SET_MUNICIPAL_IPS = 'global/SET_MUNICIPAL_IPS';
export const RESET_MUNICIPAL_AND_SCHOOL = 'global/RESET_MUNICIPAL_AND_SCHOOL';
export const SET_IS_PREVIEW_MODE = 'global/SET_IS_PREVIEW_MODE';
export const LOAD_PREVIEW_MODULES_WIDGETS_CONFIG = 'global/LOAD_PREVIEW_MODULES_WIDGETS_CONFIG';
export const ACTION_GET_SAME_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS =
  'messages/ACTION_GET_SAME_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS';
export const SET_GROUP_HOMES = 'global/SET_GROUP_HOMES';
export const SET_ACTIVE_GROUP_HOME = 'global/SET_ACTIVE_GROUP_HOME';
