// Interface for all getters, mutations and actions

// Getters
export const GET_SELECTED_RESOURCE_CATEGORY = 'resources/GET_SELECTED_RESOURCE_CATEGORY';
export const GET_SELECTED_RESOURCE = 'resources/GET_SELECTED_RESOURCE';
export const GET_RESOURCE_CATEGORIES = 'resources/GET_RESOURCE_CATEGORIES';
export const RESOURCE_CATEGORIES = 'resources/GET_RESOURCE_CATEGORIES';
export const GET_RESOURCE_CATEGORY_ITEMS = 'resources/GET_RESOURCE_CATEGORY_ITEMS';

// Mutations
export const MUTATE_SELECT_RESOURCE_CATEGORY = 'resources/MUTATE_SELECT_RESOURCE_CATEGORY';
export const MUTATE_SELECT_RESOURCE = 'resources/MUTATE_SELECT_RESOURCE';
export const MUTATE_UPDATE_RESOURCE_CATEGORY = 'resources/MUTATE_UPDATE_RESOURCE_CATEGORY';
export const MUTATE_UPDATE_RESOURCE = 'resources/MUTATE_UPDATE_RESOURCE';
export const MUTATE_ADD_RESOURCE_CATEGORY = 'resources/MUTATE_ADD_RESOURCE_CATEGORY';
export const MUTATE_ADD_RESOURCE = 'resources/MUTATE_ADD_RESOURCE';
export const MUTATE_GET_RESOURCE_CATEGORIES = 'resources/MUTATE_GET_RESOURCE_CATEGORIES';
export const MUTATE_REMOVE_RESOURCE_CATEGORY = 'resources/MUTATE_REMOVE_RESOURCE_CATEGORY';
export const MUTATE_REMOVE_RESOURCE = 'resources/MUTATE_REMOVE_RESOURCE';
export const MUTATE_UPDATE_RESOURCES = 'resources/MUTATE_UPDATE_RESOURCES';
export const MUTATE_SET_RESOURCE_CATEGORY = 'resources/MUTATE_SET_RESOURCE_CATEGORY';
export const MUTATE_SEARCH_RESOURCES = 'resources/MUTATE_SEARCH_RESOURCES';

// Actions
export const SELECT_RESOURCE_CATEGORY = 'resources/SELECT_RESOURCE_CATEGORY';
export const SELECT_RESOURCE = 'resources/SELECT_RESOURCE';
export const SAVE_RESOURCE_CATEGORY = 'resources/SAVE_RESOURCE_CATEGORY';
export const REMOVE_RESOURCE_CATEGORY = 'resources/REMOVE_RESOURCE_CATEGORY';
export const REMOVE_RESOURCE = 'resources/REMOVE_RESOURCE';
export const SAVE_RESOURCE = 'resources/SAVE_RESOURCE';
export const LOAD_RESOURCE_CATEGORY_ITEMS = 'resources/LOAD_RESOURCE_CATEGORY_ITEMS';
export const SEARCH_RESOURCES = 'resources/SEARCH_RESOURCES';
