// Interface for all getters, mutations and actions

// Getters
export const GET_DOCUMENTS = 'documents/GET_DOCUMENTS';
export const GET_SHAREABLE_SECURE_DOCUMENTS = 'documents/GET_SHAREABLE_SECURE_DOCUMENTS';
export const GET_DOCUMENTS_IS_DIRTY = 'documents/GET_DOCUMENTS_IS_DIRTY';
export const GET_DOCUMENTS_LIVE = 'documents/GET_DOCUMENTS_LIVE';
export const GET_DOCUMENTS_REVISION = 'documents/GET_DOCUMENTS_REVISION';
export const GET_SELECTED_ITEM = 'documents/GET_SELECTED_ITEM';
export const GET_SECURE_DOCUMENT_REGARDING_INSTITUTION_PROFILE =
  'documents/SECURE_DOCUMENT_REGARDING_INSTITUTION_PROFILE';
export const GET_ARCHIVE_DOCUMENT_TRACK = 'documents/GET_ARCHIVE_DOCUMENT_TRACK';
export const GET_ARCHIVE_DOCUMENT_PROGRESS = 'documents/GET_ARCHIVE_DOCUMENT_PROGRESS';
export const GET_DOCUMENT_CATEGORIES_AND_TEMPLATES = 'documents/GET_DOCUMENT_CATEGORIES_AND_TEMPLATES';
export const GET_DOCUMENT_CATEGORY_AND_TEMPLATES_IS_LOADING =
  'documents/GET_DOCUMENT_CATEGORY_AND_TEMPLATES_IS_LOADING';

// Mutations
export const MUTATE_DOCUMENTS_SET_LIVE = 'documents/MUTATE_DOCUMENTS_SET_LIVE';
export const MUTATE_DOCUMENTS_SET_LAST_SAVE = 'documents/MUTATE_DOCUMENTS_SET_LAST_SAVE';
export const MUTATE_GET_DOCUMENTS = 'documents/MUTATE_GET_DOCUMENTS';
export const MUTATE_GET_SHAREABLE_DOCUMENTS = 'documents/MUTATE_GET_SHAREABLE_DOCUMENTS';
export const MUTATE_DOCUMENT_CATEGORIES_AND_TEMPLATES = 'documents/MUTATE_DOCUMENT_CATEGORIES_AND_TEMPLATES';
export const MUTATE_RESET_SHAREABLE_DOCUMENT_ITEMS = 'documents/MUTATE_RESET_SHAREABLE_DOCUMENT_ITEMS';
export const MUTATE_GET_MORE_DOCUMENTS = 'documents/MUTATE_GET_MORE_SHAREABLE_DOCUMENTS';
export const MUTATE_GET_MORE_SHAREABLE_DOCUMENTS = 'documents/MUTATE_GET_MORE_DOCUMENTS';
export const MUTATE_GET_DOCUMENT_REVISIONS = 'documents/MUTATE_GET_DOCUMENT_REVISIONS';
export const MUTATE_GET_MORE_DOCUMENT_REVISIONS = 'documents/MUTATE_GET_MORE_DOCUMENT_REVISIONS';
export const MUTATE_DOCUMENTS_SET_SELECTED_ITEM = 'documents/MUTATE_DOCUMENTS_SET_SELECTED_ITEM';
export const MUTATE_SECURE_DOCUMENT_REGARDING_INSTITUTION_PROFILE =
  'documents/MUTATE_SECURE_DOCUMENT_REGARDING_INSTITUTION_PROFILE';
export const MUTATE_UPDATE_DOCUMENT_SHARED_WITH = 'documents/MUTATE_UPDATE_DOCUMENT_SHARED_WITH';
export const MUTATE_UPDATE_DOCUMENT_IN_LIST = 'documents/MUTATE_UPDATE_DOCUMENT_IN_LIST';
export const MUTATE_ARCHIVE_DOCUMENT_TRACK = 'documents/MUTATE_ARCHIVE_DOCUMENT_TRACK';
export const MUTATE_ARCHIVE_DOCUMENT_PROGRESS = 'documents/MUTATE_ARCHIVE_DOCUMENT_PROGRESS';
export const MUTATE_RESET_DOCUMENT_ITEMS = 'documents/MUTATE_RESET_DOCUMENT_ITEMS';
export const MUTATE_DOCUMENT_CATEGORY_AND_TEMPLATES_IS_LOADING =
  'documents/MUTATE_DOCUMENT_CATEGORY_AND_TEMPLATES_IS_LOADING';

// Actions
export const ACTION_DOCUMENTS_SET_LIVE = 'documents/ACTION_DOCUMENTS_SET_LIVE';
export const LOAD_IMPLICIT_SHARINGS = 'documents/LOAD_IMPLICIT_SHARINGS';
export const ACTION_GET_DOCUMENTS = 'documents/ACTION_GET_DOCUMENTS';
export const ACTION_GET_DELETED_DOCUMENTS_ADMIN = 'documents/ACTION_GET_DELETED_DOCUMENTS_ADMIN';
export const ACTION_GET_SHAREABLE_DOCUMENTS = 'documents/ACTION_GET_SHAREABLE_DOCUMENTS';
export const ACTION_GET_MORE_DOCUMENTS = 'documents/ACTION_GET_MORE_DOCUMENTS';
export const ACTION_GET_MORE_DELETED_DOCUMENTS_ADMIN = 'documents/ACTION_GET_MORE_DELETED_DOCUMENTS_ADMIN';
export const ACTION_GET_MORE_SHAREABLE_DOCUMENTS = 'documents/ACTION_GET_MORE_SHAREABLE_DOCUMENTS';
export const ACTION_GET_DOCUMENT_CATEGORIES_AND_TEMPLATES = 'documents/ACTION_GET_DOCUMENT_CATEGORIES_AND_TEMPLATES';
export const ACTIONS_DOCUMENTS_SECURE_INTERNAL_CREATE = 'documents/ACTIONS_DOCUMENTS_SECURE_INTERNAL_CREATE';
export const ACTIONS_DOCUMENTS_SECURE_INTERNAL_UPDATE = 'documents/ACTIONS_DOCUMENTS_SECURE_INTERNAL_UPDATE';
export const ACTIONS_DOCUMENTS_SECURE_EXTERNAL_CREATE = 'documents/ACTIONS_DOCUMENTS_SECURE_EXTERNAL_CREATE';
export const ACTIONS_DOCUMENTS_SECURE_EXTERNAL_UPDATE = 'documents/ACTIONS_DOCUMENTS_SECURE_EXTERNAL_UPDATE';
export const ACTIONS_GET_DOCUMENT_REVISIONS = 'documents/ACTIONS_GET_DOCUMENT_REVISIONS';
export const ACTIONS_GET_MORE_DOCUMENT_REVISIONS = 'documents/ACTIONS_GET_MORE_DOCUMENT_REVISIONS';
export const ACTIONS_GET_DOCUMENT_REVISION = 'documents/ACTIONS_GET_DOCUMENT_REVISION';
export const ACTIONS_DOCUMENTS_SET_SELECTED_ITEM = 'documents/ACTIONS_DOCUMENTS_SET_SELECTED_ITEM';
export const ACTION_EDIT_SHARINGS = 'documents/ACTION_EDIT_SHARINGS';
export const ACTION_LOAD_INTERNAL_DOCUMENT = 'documents/ACTION_LOAD_INTERNAL_DOCUMENT';
export const ACTION_LOAD_EXTERNAL_DOCUMENT = 'documents/ACTION_LOAD_EXTERNAL_DOCUMENT';
export const ACTION_DISCARD_DOCUMENT = 'documents/ACTION_DISCARD_DOCUMENT';
export const ACTION_GET_SECURE_DOCUMENTS_REGARDING_INSTITUTION_PROFILE =
  'documents/ACTION_GET_SECURE_DOCUMENTS_REGARDING_INSTITUTION_PROFILE';
export const ACTION_CREATE_ARCHIVE_SECURE_DOCUMENTS = 'documents/ACTION_CREATE_ARCHIVE_SECURE_DOCUMENTS';
export const ACTION_TRACK_ARCHIVE_SECURE_DOCUMENTS = 'documents/ACTION_TRACK_ARCHIVE_SECURE_DOCUMENTS';
export const LOCK_DOCUMENT = 'documents/LOCK_DOCUMENT';
export const ACTION_DELETE_DOCUMENT = 'documents/ACTION_DELETE_DOCUMENT';
export const ACTION_RESTORE_DELETED_DOCUMENT_ADMIN = 'documents/ACTION_RESTORE_DELETED_DOCUMENT_ADMIN';
