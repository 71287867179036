export enum institutionRole {
  GUARDIAN = 'guardian',
  DAYCARE = 'daycare',
  EARLY_STUDENT = 'early-student',
  MIDDLE_LATE_STUDENT = 'middle-late-student',
  TEACHER = 'teacher',
  PRESCHOOL_TEACHER = 'preschool-teacher',
  OTHER = 'other',
  LEADER = 'leader',
  CHILD = 'child',
}
