<template>
  <div id="aula-login">
    <div v-if="isPresenceLogin" class="main-toastr-container">
      <toastr ref="errorToastr" variant="warning" icon-font-size="1em" circle-background="white">
        {{ errorText | fromTextKey }}
      </toastr>
    </div>
    <div id="main">
      <b-container>
        <b-btn
          v-if="!(isLoginPage || $route.name.includes('institution'))"
          variant="link"
          class="aula-login-back"
          :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
          @click="goBack"
        >
          <i class="icon-Aula_app_arrow_slideback" />
        </b-btn>
        <a class="login-guide" href="https://aulainfo.dk/" target="_blank">
          <div class="background" />
          <span class="intro">
            {{ 'LOGIN_GUIDE' | fromTextKey }}<br />
            {{ 'LOGIN_GUIDE_2' | fromTextKey }}
          </span>
        </a>
        <div class="aula-logo" role="heading" aria-level="1">
          <span class="sr-only">{{ 'ARIA_LABEL_AULA_LOGIN' | fromTextKey }}</span>
          <i class="icon-Logo" aria-hidden="true" />
        </div>
        <template v-if="isInstitutionLoginPath">
          <institution-login />
        </template>
        <b-row>
          <b-col v-if="$route.name === 'login'" cols="12" class="login-method">
            {{ 'LOGIN_PAGE_CHOOSE_LOGIN_METHOD' | fromTextKey }}
          </b-col>
          <b-col v-else-if="$route.name === 'presenceLogin'" cols="12" class="employee-login-method">
            {{ 'LOGIN_PAGE_CHOOSE_LOGIN_METHOD_EMPLOYEE' | fromTextKey }}
          </b-col>
        </b-row>
        <b-row class="justify-content-md-center mt-2">
          <b-col cols="12" class="remember">
            <b-form-checkbox
              v-if="$route.name == 'otherOptionsLogin'"
              id="rememberSelection"
              v-model="rememberLoginMethod"
            >
              {{ 'REMEMBER_PROFILE_ROLE' | fromTextKey }}
            </b-form-checkbox>
          </b-col>
        </b-row>
        <template v-if="isLoginPage">
          <b-row class="justify-content-md-center mt-4 box-container">
            <b-col cols="12" lg="4">
              <div
                class="square-box uni-login"
                tabindex="0"
                role="button"
                @click="selectMethod('unilogin')"
                @keydown.enter="selectMethod('unilogin')"
              >
                <div class="content">
                  {{ 'UNI_LOGIN' | fromTextKey }}<br />
                  <div>
                    <img :src="imageUrl" class="mit-id-logo" :alt="'UNI_LOGIN' | fromTextKey" />
                  </div>
                  {{ 'LOKALT_LOGIN' | fromTextKey }}<br />
                </div>
                <i v-if="!isMobile" class="icon-Aula_forward-arrow" />
              </div>
            </b-col>
            <b-col cols="12" lg="4">
              <div
                class="square-box"
                tabindex="0"
                role="button"
                @click="selectMethod('other')"
                @keydown.enter="selectMethod('other')"
              >
                <div class="content">
                  {{ 'LOGIN_OTHER_OPTIONS' | fromTextKey }}<br />
                  <span class="employee-only">{{ 'LOGIN_OPTIONS_FOR_EMPLOYEE_ONLY' | fromTextKey }}</span>
                </div>
                <b-form-checkbox
                  v-if="showCookie"
                  id="remember"
                  v-model="rememberLoginMethod"
                  class="remember-checkbox"
                  @click.native.stop
                >
                  <span class="text">{{ 'USE_SAVED_CHOICE' | fromTextKey }}</span>
                </b-form-checkbox>
                <i v-if="!isMobile" class="icon-Aula_forward-arrow" />
              </div>
            </b-col>
          </b-row>
        </template>
        <template v-if="$route.name == 'otherOptionsLogin'">
          <b-row class="justify-content-md-center mt-4">
            <b-col cols="12" lg="4">
              <div
                class="square-box"
                tabindex="0"
                role="button"
                @click="selectOptionLogin('contextHandler')"
                @keydown.enter="selectOptionLogin('contextHandler')"
              >
                <div class="content">
                  {{ 'CONTEXT_HANDLER' | fromTextKey }}
                </div>
                <i class="icon-Aula_forward-arrow" />
              </div>
            </b-col>
            <b-col cols="12" lg="4">
              <div
                class="square-box last"
                tabindex="0"
                role="button"
                @click="selectOptionLogin('municipalIdp')"
                @keydown.enter="selectOptionLogin('municipalIdp')"
              >
                <div class="content">
                  {{ 'MUNICIPALITY_IDP' | fromTextKey }}
                </div>
                <i class="icon-Aula_forward-arrow" />
              </div>
            </b-col>
          </b-row>
        </template>
        <municipal-login v-if="$route.name == 'municipalIdP'" />
      </b-container>
      <div class="footer-links">
        <a role="button" href="https://www.was.digst.dk/aula-dk" target="_blank">
          {{ 'ACCESSIBILITY_STATEMENT' | fromTextKey }}
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { types } from '../../../src/store/types/types';
import { mapGetters } from 'vuex';
import KommunalIDPLogin from './KommunalIDPLogin.vue';
import { Cookie } from '../../assets/plugins/cookie';
import $ from 'jquery';
import InstitutionLogin from './InstitutionLogin';
import { providerKeyTypes } from '../../enums/providerKeyTypes';

export default {
  inject: {
    getCanUseInstitutionLogin: {
      from: providerKeyTypes.canUseInstitutionLogin,
      default: () => () => false,
    },
  },
  data: function () {
    return {
      rememberLoginMethod: false,
      storedCookie: false,
    };
  },
  computed: {
    ...mapGetters({
      isProfileLoaded: types.IS_PROFILE_LOADED,
      isMobile: types.GET_IS_MOBILE,
      errorText: types.GET_ERROR_TEXT,
    }),
    imageUrl() {
      return window.location.origin + '/static/MitID_LOGO_CLEAN_WHITE.png';
    },
    isLoginPage() {
      return ['login', 'presenceLogin'].includes(this.$route.name);
    },
    isPresenceLogin() {
      return this.$route.name === 'presenceLogin';
    },
    showCookie() {
      return Cookie.Read('rememberLoginMethod') == 'true';
    },
    isInstitutionLoginPath() {
      return this.$route.name == 'institution';
    },
  },
  watch: {
    errorText() {
      if (this.errorText != '' && this.$refs.errorToastr != undefined) {
        this.$refs.errorToastr.show();
      }
    },
  },
  mounted() {
    this.rememberLoginMethod = Cookie.Read('rememberLoginMethod') === 'true';
    this.storedCookie = Cookie.Read('rememberLoginMethod') == 'true';
    if (this.$route.name !== 'presenceLogin') {
      let domain;
      if (window.location.hostname.indexOf('aula.dk') !== -1) {
        domain = '.' + window.location.hostname.split('.').slice(-2).join('.');
      }
      Cookie.Erase('EmployeeLoginToCheckinDashboard', domain);
      Cookie.Erase('PresenceConfigurationChecked');
    }
    if (this.shouldRedirect()) {
      this.$router.push({ name: 'overblik' });
    }
  },
  methods: {
    shouldRedirect() {
      let redirect = false;
      if (this.isProfileLoaded) {
        redirect = true;
      }

      if (this.isInstitutionLoginPath && this.getCanUseInstitutionLogin()) {
        redirect = false;
      }

      return redirect;
    },
    clearCookies() {
      Cookie.Erase('rememberLoginMethod');
      Cookie.Erase('loginMethod');
      if (Cookie.Read('IDP') !== null) {
        Cookie.Erase('IDP');
      }
    },
    selectMethod(method) {
      if (method == 'unilogin') {
        if (!this.rememberLoginMethod && this.storedCookie) {
          this.clearCookies();
        }
        window.location.href = '/auth/login.php?type=unilogin';
      } else {
        if (this.rememberLoginMethod && this.storedCookie) {
          if (Cookie.Read('loginMethod') == 'contextHandler') {
            window.location.href = '/auth/login.php?type=context_handler';
          } else {
            if (Cookie.Read('IDP') !== null) {
              const payload = JSON.parse(Cookie.Read('IDP'));
              const fullHost =
                window.location.protocol +
                '//' +
                window.location.hostname +
                (window.location.port ? ':' + window.location.port : '');
              window.location.href = fullHost + '/auth/login.php?' + $.param(payload);
            } else {
              this.$router.push({ name: 'municipalIdP' });
            }
          }
        } else {
          this.$router.push({ name: 'otherOptionsLogin' });
        }
      }
    },
    selectOptionLogin(method) {
      if (this.rememberLoginMethod) {
        Cookie.Create('loginMethod', method, 365);
        Cookie.Create('rememberLoginMethod', 'true', 365);
      } else {
        this.clearCookies();
      }
      if (method == 'contextHandler') {
        window.location.href = '/auth/login.php?type=context_handler';
      } else {
        this.$router.push({ name: 'municipalIdP' });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
  components: {
    'municipal-login': KommunalIDPLogin,
    'institution-login': InstitutionLogin,
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/login/login.scss';

.main-toastr-container {
  position: fixed;
  top: 20px;
  right: 20px;
  z-index: 9999;

  @include breakpoint-sm-down() {
    width: auto;
    top: 20px;
    right: 20px;
    left: 20px;
  }
}
</style>
