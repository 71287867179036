// Interface for all getters, mutations and actions
// Getters
export const GET_PRESENCE_TOKEN = 'presence/GET_PRESENCE_TOKEN';
export const GET_PRESENCE_CONFIGURATION = 'presence/GET_PRESENCE_CONFIGURATION';
export const GET_PRESENCE_LOCATION = 'presence/GET_PRESENCE_LOCATION';
export const GET_SUGGESTED_PICKUP_NAME = 'presence/GET_SUGGESTED_PICKUP_NAME';
export const GET_STATISTICS_FILTER_GROUPS = 'presence/GET_STATISTICS_FILTER_GROUPS';
export const GET_STATISTICS = 'presence/GET_STATISTICS';
export const GET_PRESENCE_EXPORT_TRACK = 'presence/GET_PRESENCE_EXPORT_TRACK';
export const GET_PRESENCE_EXPORT_STATUS = 'presence/GET_PRESENCE_EXPORT_STATUS';
export const GET_OPEN_HOURS = 'presence/GET_PRESENCE_GET_OPEN_HOURS';
export const GET_SPECIFIC_OPEN_HOURS = 'presence/GET_SPECIFIC_OPEN_HOURS';
export const GET_CLOSED_DAYS = 'presence/GET_CLOSED_DAYS';

// Mutations
export const MUTATE_PRESENCE_TOKEN = 'presence/MUTATE_PRESENCE_TOKEN';
export const MUTATE_PRESENCE_CONFIGURATION = 'presence/MUTATE_PRESENCE_CONFIGURATION';
export const MUTATE_MAIN_GROUPS_BY_INSTITUTION = 'presence/MUTATE_MAIN_GROUPS_BY_INSTITUTION';
export const MUTATE_SUGGESTED_PICKUP_NAME = 'presence/MUTATE_SUGGESTED_PICKUP_NAME';
export const MUTATE_PRESENCE_LOCATION = 'presence/MUTATE_PRESENCE_LOCATION';
export const MUTATE_STATISTICS = 'presence/MUTATE_STATISTICS';
export const RESET_STATISTICS = 'presence/RESET_STATISTICS';
export const RESET_OPEN_HOURS = 'presence/RESET_OPEN_HOURS';
export const RESET_SPECIFIC_OPEN_HOURS = 'presence/RESET_SPECIFIC_OPEN_HOURS';
export const RESET_CLOSED_DAYS = 'presence/RESET_CLOSED_DAYS';
export const MUTATE_STATISTICS_FILTER_GROUPS = 'presence/MUTATE_STATISTICS_FILTER_GROUPS';
export const MUTATE_PRESENCE_EXPORT_TRACK = 'presence/MUTATE_PRESENCE_EXPORT_TRACK';
export const MUTATE_PRESENCE_EXPORT_STATUS = 'presence/MUTATE_PRESENCE_EXPORT_STATUS';
export const MUTATE_PRESENCE_OPEN_HOURS = 'presence/MUTATE_PRESENCE_OPEN_HOURS';
export const MUTATE_PRESENCE_SPECIFIC_OPEN_HOURS = 'presence/MUTATE_PRESENCE_SPECIFIC_OPEN_HOURS';
export const MUTATE_PRESENCE_CLOSED_DAYS = 'presence/MUTATE_PRESENCE_CLOSED_DAYS';

// Actions
export const GENERATE_PRESENCE_TOKEN = 'presence/GENERATE_PRESENCE_TOKEN';
export const LOAD_PRESENCE_CONFIGURATION = 'presence_administration/LOAD_PRESENCE_CONFIGURATION';
export const LOAD_SUGGESTED_PICKUP_NAME = 'presence/LOAD_SUGGESTED_PICKUP_NAME';
export const LOAD_PRESENCE_LOCATION = 'presence/LOAD_PRESENCE_LOCATION';
export const SET_PRESENCE_CONFIGURATION = 'presence/SET_PRESENCE_CONFIGURATION';
export const LOAD_STATISTICS = 'presence/LOAD_STATISTICS';
export const LOAD_STATISTICS_FILTER_GROUPS = 'presence/LOAD_STATISTICS_FILTER_GROUPS';
export const UNREGISTER_DEVICES = 'presence/UNREGISTER_DEVICES';
export const START_PRESENCE_EXPORT = 'presence/START_PRESENCE_EXPORT';
export const GET_PRESENCE_EXPORT = 'presence/GET_PRESENCE_EXPORT';
export const LOAD_PRESENCE_OPEN_HOURS = 'presence/LOAD_PRESENCE_OPEN_HOURS';
export const EDIT_PRESENCE_OPEN_HOURS = 'presence/EDIT_PRESENCE_OPEN_HOURS';
export const LOAD_PRESENCE_SPECIFIC_OPEN_HOURS = 'presence/LOAD_PRESENCE_SPECIFIC_OPEN_HOURS';
export const DELETE_SPECIFIC_OPEN_HOURS = 'presence/DELETE_SPECIFIC_OPEN_HOURS';
export const CREATE_SPECIFIC_OPEN_HOURS = 'presence/CREATE_SPECIFIC_OPEN_HOURS';
export const EDIT_SPECIFIC_OPEN_HOURS = 'presence/EDIT_SPECIFIC_OPEN_HOURS';
export const LOAD_PRESENCE_CLOSED_DAYS = 'presence/LOAD_PRESENCE_CLOSED_DAYS';
export const DELETE_CLOSED_DAYS = 'presence/DELETE_CLOSED_DAYS';
export const EDIT_CLOSED_DAYS = 'presence/EDIT_CLOSED_DAYS';
export const CREATE_CLOSED_DAYS = 'presence/CREATE_CLOSED_DAYS';
