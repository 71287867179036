import { types } from '../../../src_adm/store/types/types';
import { portal } from '../../assets/plugins/axios/axios.js';
import Vue from 'vue';

const state = {
  maxFileSize: '',
  authorizedFileFormatList: [],
  maxFileSizeLoaded: false,
};

const getters = {
  [types.GET_MAX_FILE_SIZE]: state => state.maxFileSize,
  [types.GET_MAX_FILE_SIZE_LOADED]: state => state.maxFileSizeLoaded,
  [types.GET_AUTHORIZED_FILE_FORMAT_LIST]: state => state.authorizedFileFormatList,
  [types.GET_VALID_FILE_FORMAT]: state => extension => {
    if (state.authorizedFileFormatList.length === 0) {
      return false;
    }
    return state.authorizedFileFormatList.filter(item => item.fileFormat === extension).length !== 0;
  },
};

const mutations = {
  [types.MUTATE_MAX_FILE_SIZE]: (state, payload) => {
    state.maxFileSize = payload;
  },
  [types.MUTATE_MAX_FILE_SIZE_UPLOADED]: (state, payload) => {
    state.maxFileSizeLoaded = payload;
  },
  [types.MUTATE_AUTHORIZED_FILE_FORMAT_LIST]: (state, payload) => {
    state.authorizedFileFormatList = payload;
  },

  [types.MUTATE_REMOVE_AUTHORIZED_FILE_FORMAT]: (state, payload) => {
    state.authorizedFileFormatList = state.authorizedFileFormatList.filter(format => format.id !== payload);
  },

  [types.MUTATE_CREATE_AUTHORIZED_FILE_FORMAT]: (state, payload) => {
    state.authorizedFileFormatList.push(payload);
  },
};

const actions = {
  [types.SET_MAX_FILE_SIZE]: ({ commit }, payload) =>
    portal
      .post('?method=centralConfiguration.setMaxFileSize', {
        maxFileSize: payload,
      })
      .then(() => {
        commit(types.MUTATE_MAX_FILE_SIZE, payload);
        Vue.set(state, 'maxFileSize', payload);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_MAX_FILE_SIZE');
      }),

  [types.ACTIONS_CENTRAL_CONFIG_MAX_FILE_SIZE]: ({ commit }) =>
    portal
      .get('?method=centralConfiguration.getMaxFileSize', {})
      .then(response => {
        commit(types.MUTATE_MAX_FILE_SIZE, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MAX_FILE_SIZE');
      }),

  [types.ACTIONS_CENTRAL_CONFIG_AUTHORIZED_FILE_FORMATS]: ({ commit }) =>
    portal
      .get('?method=centralConfiguration.getAuthorizedFileFormats', {})
      .then(response => {
        commit(types.MUTATE_AUTHORIZED_FILE_FORMAT_LIST, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_AUTHORIZED_FILE_FORMATS');
      }),

  [types.REMOVE_AUTHORIZED_FILE_FORMAT]: ({ commit }, payload) =>
    portal
      .post('?method=centralConfiguration.removeAuthorizedFileFormat', {
        id: payload,
      })
      .then(() => {
        commit(types.MUTATE_REMOVE_AUTHORIZED_FILE_FORMAT, payload);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REMOVE_AUTHORIZED_FILE_FORMAT');
      }),

  [types.CREATE_AUTHORIZED_FILE_FORMAT]: ({ commit }, payload) =>
    portal
      .post('?method=centralConfiguration.createAuthorizedFileFormat', {
        fileFormat: payload.fileFormat,
        name: payload.name,
      })
      .then(response => {
        const newFormat = {
          id: response.data.data.id,
          fileFormat: payload.fileFormat,
          name: payload.name,
        };
        commit(types.MUTATE_CREATE_AUTHORIZED_FILE_FORMAT, newFormat);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_AUTHORIZED_FILE_FORMAT');
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
