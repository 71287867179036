import { types } from '../../../src_adm/store/types/types';
import { portal } from '../../assets/plugins/axios/axios.js';
import { roleEnum } from '../../enums/roleEnum';
import { portalRoles } from '../../enums/portalRoles';
import { docTypes } from '../../enums/docTypes';
import { groupDeactivationEnum } from '../../../shared/enums/groupDeactivationEnum';
import { groupTypes } from '../../enums/groupTypes';
import { groupStatus } from '../../enums/groupStatus';
import moment from 'moment-timezone';
import Vue from 'vue';
import { errorSubCodeTypes } from '../../enums/errorSubCodeTypes';
import { messageOwnerTypes } from '../../enums/messageOwnerTypes';
const state = {
  isSearchComponentLoading: false,
  groupsFromSearch: [],
  deactivatedGroups: [],
  activatedGroups: [],
  activeGroup: {},
  allModules: [],
  allWidgets: [],
  assignedUserRoles: [],
  assignedGroupRoles: {},
  userRolesGroup: [],
  tooManyGroupsFromSearch: false,
  zeroGroupsFromSearch: false,
  groupMemberships: [],
  groupMembershipsLight: [],
  isOpennedGroupFromGlobalSearch: false,
  groupAdministrators: [],
  postPermissions: [],
  eventPermissions: [],
  messagePermissions: [],
  mediaPermissions: [],
  totalSize: 0,
};

const getters = {
  [types.IS_SEARCH_COMPONENT_LOADING]: state => state.isSearchComponentLoading,
  [types.GET_ACTIVE_GROUP]: state => state.activeGroup,
  [types.GET_GROUP_MEMBERSHIPS]: state => state.groupMemberships,
  [types.GET_GROUP_MEMBERSHIPS_LIGHT]: state => state.groupMembershipsLight,
  [types.GET_GROUPS_FROM_SEARCH]: state => state.groupsFromSearch,
  [types.GET_ALL_MODULES]: state => state.allModules,
  [types.GET_ALL_WIDGETS]: state => state.allWidgets,
  [types.GET_ASSIGNED_USER_ROLES_FOR_GROUPS]: state => state.assignedUserRoles,
  [types.GET_ASSIGNED_GROUP_ROLES]: state => state.assignedGroupRoles,
  [types.GET_USER_ROLES_GROUP]: state => state.userRolesGroup,
  [types.GET_TOO_MANY_GROUP_RESULTS_FROM_SEARCH]: state => state.tooManyGroupsFromSearch,
  [types.GET_ZERO_GROUPS_RESULTS_FROM_SEARCH]: state => state.zeroGroupsFromSearch,
  [types.GET_IS_OPENNED_GROUP_FROM_GLOBAL_SEARCH]: state => state.isOpennedGroupFromGlobalSearch,
  [types.GET_TOTAL_SIZE_OF_GROUPS]: state => state.totalSize,
};

const mutations = {
  [types.MUTATION_GROUPS_SET_LIST_ADMIN]: (state, payload) => {
    const tableGroupArray = [];
    state.zeroGroupsFromSearch = false;
    state.tooManyGroupsFromSearch = false;
    state.totalSize = payload.totalSize;
    if (payload.totalSize === 0) {
      state.zeroGroupsFromSearch = true;
    }
    for (const group of payload.list) {
      let userRoles = [];
      for (const userRole of group.roleDefinitions) {
        userRoles.push(userRole.roleName);
      }
      userRoles = userRoles.join(', ');
      let administrator = '';
      if (Array.isArray(group.admins) && group.admins.length > 0) {
        administrator = group.admins.map(e => Vue.filter('displayProfileNameWithMetadata')(e)).join(', ');
      }
      if (
        group.status === groupStatus.ACTIVE &&
        state.deactivatedGroups.length > 0 &&
        state.deactivatedGroups.includes(parseInt(group.id))
      ) {
        group.status = groupStatus.DEACTIVATED;
      } else if (
        group.status === groupStatus.DEACTIVATED &&
        state.activatedGroups.length > 0 &&
        state.activatedGroups.includes(parseInt(group.id))
      ) {
        group.status = groupStatus.ACTIVE;
      }
      tableGroupArray.push({
        dashboardLink: [
          {
            variant: 'primary',
            method: 'linkToGroup',
            icon: 'icon-Aula_group',
            parameters: { groupId: group.id },
            class: group.status === groupStatus.DEACTIVATED ? 'deactivated' : 'activated',
          },
        ],
        hasBadge:
          group.importantSettingsUpdatedAt && moment().diff(moment(group.importantSettingsUpdatedAt), 'days') < 7
            ? true
            : false,
        institutionCode: group.institutionCode,
        id: group.id,
        name: group.name,
        type: group.type,
        status:
          group.status === groupStatus.DEACTIVATED
            ? Vue.filter('fromTextKey')('GROUP_STATUS_DEACTIVATED')
            : Vue.filter('fromTextKey')('GROUP_STATUS_ACTIVE'),
        dashboardEnabled: group.dashboardEnabled,
        typeLabel: Vue.filter('fromTextKey')('GROUP_FILTER_' + group.type.toUpperCase()),
        administrator: administrator,
        institution: group.institutionName,
        municipality: group.municipalityName,
        userRoles: userRoles,
        edit: {
          method: 'groupCheckboxClicked',
          checkboxType: 'groups',
          disabled: group.institutionCode != payload.selectedInstitution.institutionCode,
        },
        itemId: group,
        role: 'group',
      });
    }
    state.deactivatedGroups = [];
    state.activatedGroups = [];
    state.groupsFromSearch = tableGroupArray;
    return state.groupsFromSearch;
  },
  [types.MUTATE_SEARCH_COMPONENT_LOADING]: (state, payload) => {
    state.isSearchComponentLoading = payload;
  },
  [types.MUTATE_DELETE_GROUP]: (state, payload) => {
    state.groupsFromSearch = state.groupsFromSearch.filter(group => group.id != payload.groupId);
  },
  [types.MUTATE_UPDATE_GROUP]: (state, payload) => {
    const updatedGroup = state.groupsFromSearch.find(g => g.id == payload.id);
    if (updatedGroup) {
      updatedGroup.name = payload.name;
      updatedGroup.administrator = payload.administrators.map(adm => adm.label).join(', ');
    }
  },
  [types.MUTATE_LOAD_ALL_MODULES]: (state, payload) => {
    state.allModules = payload;
  },
  [types.MUTATE_LOAD_ALL_WIDGETS]: (state, payload) => {
    state.allWidgets = payload;
  },
  [types.MUTATE_LOAD_ASSIGNED_USER_ROLES_FOR_GROUPS]: (state, payload) => {
    state.assignedUserRoles = payload;
  },
  [types.MUTATE_LOAD_ASSIGNED_GROUP_ROLES]: (state, payload) => {
    state.assignedGroupRoles = {
      portalRoleAssignments: payload.profileTypeAssignments,
      profileAssignments: payload.profileAssignments,
      groupAssignments: payload.groupAssignments,
    };
  },
  [types.MUTATE_RESET_ACTIVE_GROUP]: state => {
    state.activeGroup = {};
  },
  [types.MUTATE_LOAD_GROUP_MEMBERSHIPS]: (state, payload) => {
    state.groupMemberships = payload.memberships;
  },
  [types.MUTATE_LOAD_GROUP_MEMBERSHIPS_LIGHT]: (state, payload) => {
    state.groupMembershipsLight = payload;
    state.isSearchComponentLoading = false;
  },
  [types.MUTATE_LOAD_GROUP_MEMBERSHIPS_ADMIN]: (state, payload) => {
    state.groupAdministrators = [];
    state.postPermissions = [];
    state.eventPermissions = [];
    state.messagePermissions = [];
    state.mediaPermissions = [];
    const memberships = [];

    for (const membership of payload) {
      let communicationBlock = false;
      if (membership.groupRole != 'inactive' && membership.groupRole != 'removed') {
        let entry = {};
        let type = '';
        let name = '';
        let portalRole = null;
        let relations = '';
        let value = '';
        let label = '';
        let memberId = '';

        if (membership.otpInboxMembershipDisplayName !== null) {
          entry = {
            type: docTypes.PROFILE.toLowerCase(),
            id: membership.otpInboxId,
            name: membership.otpInboxMembershipDisplayName,
            value: messageOwnerTypes.OTP_INBOX + membership.otpInboxId,
            label: membership.otpInboxMembershipDisplayName,
            portalRole: portalRoles.OTP,
            groupRole: membership.groupRole,
          };
        } else {
          if (membership.memberGroup !== null) {
            type = docTypes.GROUP.toLowerCase();
            // Is the membership regarding the entire group or is it for a specified profile role
            let groupMembershipForSpecificRole = '';
            let nameExtension = '';
            if (membership.institutionRole != null) {
              // If the group membership is for a specific profile role, add a descriptive extension to the name
              groupMembershipForSpecificRole = '-' + membership.institutionRole.toLowerCase();

              switch (membership.institutionRole) {
                case portalRoles.GUARDIAN:
                  nameExtension = `Forældre`;
                  portalRole = portalRoles.GUARDIAN;
                  break;
                case portalRoles.CHILD:
                  nameExtension = `Børn`;
                  portalRole = portalRoles.CHILD;
                  break;
                case portalRoles.EMPLOYEE:
                  nameExtension = `Medarbejdere`;
                  portalRole = portalRoles.EMPLOYEE;
                  break;
                default:
                  break;
              }
            }
            if (nameExtension.length > 0) {
              nameExtension += ' - ';
            }
            nameExtension += membership.memberGroup.institutionName;
            name = `${membership.memberGroup.name} (${nameExtension})`;
            label = name;
            value = docTypes.GROUP.toLowerCase() + membership.memberGroup.id + groupMembershipForSpecificRole;
            memberId = membership.memberGroup.id;
          } else {
            const institutionProfile = membership.institutionProfile;
            let labelMetadata = institutionProfile.metadata;
            if (
              institutionProfile.role !== portalRoles.EMPLOYEE &&
              state.activeGroup &&
              state.activeGroup.type === groupTypes.MUNICIPAL
            ) {
              labelMetadata = `${institutionProfile.metadata} - ${institutionProfile.institution.institutionName}`;
            }
            type = docTypes.PROFILE.toLowerCase();
            name = institutionProfile.fullName;
            label = `${institutionProfile.fullName} (${labelMetadata})`;
            value = docTypes.PROFILE.toLowerCase() + institutionProfile.id;
            portalRole = institutionProfile.role;
            relations = institutionProfile.relations;
            memberId = institutionProfile.id;
            communicationBlock = institutionProfile.communicationBlock;
          }

          entry = {
            type: type,
            id: memberId,
            name: name,
            value: value,
            label: label,
            portalRole: portalRole,
            relations: relations,
            groupRole: membership.groupRole,
            communicationBlock: communicationBlock,
            membershipId: membership.id,
          };
        }

        memberships.push(entry);

        checkIfMemberShouldBeAddedToPermissionArrays(entry);
      }
    }
    state.activeGroup.administrators = state.groupAdministrators;
    state.activeGroup.postPermissions = state.postPermissions;
    state.activeGroup.eventPermissions = state.eventPermissions;
    state.activeGroup.messagePermissions = state.messagePermissions;
    state.activeGroup.mediaPermissions = state.mediaPermissions;
    state.activeGroup.memberships = memberships;
  },
  [types.MUTATE_SET_ACTIVE_GROUP]: (state, payload) => {
    state.activeGroup = payload.group;
    if (payload.membershipInstitutions) {
      state.activeGroup.membershipInstitutions = payload.membershipInstitutions;
    }
    const moduleConfigurations = [];
    const widgetConfigurations = [];
    for (const config of state.activeGroup.validGroupModules) {
      if (config.showOnDashboard) {
        moduleConfigurations.push(config.module.id);
      }
    }
    for (const config of state.activeGroup.validGroupWidgets) {
      if (config.showOnDashboard) {
        widgetConfigurations.push(config.widget.id);
      }
    }
    state.activeGroup.moduleConfigurations = moduleConfigurations;
    state.activeGroup.widgetConfigurations = widgetConfigurations;
  },
  [types.MUTATE_LOAD_USER_ROLES_GROUP]: (state, { loadedUserRoles, totalSelectedUsers }) => {
    const assignedRoleIds = [];
    for (const assignedRole of state.assignedUserRoles) {
      for (const role of assignedRole.roles) {
        assignedRoleIds[role.roleDefinition.id] =
          assignedRoleIds[role.roleDefinition.id] == null ? 1 : assignedRoleIds[role.roleDefinition.id] + 1;
      }
    }
    const assignable = loadedUserRoles.filter(
      role => assignedRoleIds[role.id] == null || assignedRoleIds[role.id] < totalSelectedUsers
    );
    state.userRolesGroup = assignable;
  },
  [types.MUTATE_RESET_USER_ROLES_GROUP]: (state, payload) => {
    state.userRolesGroup = payload;
  },
  [types.MUTATE_SEARCH_GROUPS_AFTER_EDIT_ROLE]: (state, payload) => {
    if (!payload) {
      return;
    }
    for (const group of payload.data) {
      const updatedGroup = state.groupsFromSearch.find(g => g.itemId.id == group.groupId);
      if (updatedGroup) {
        updatedGroup.userRoles = group.roleDefinitions.map(r => r.name).join(', ');
      }
    }
  },
  [types.MUTATE_IS_OPENNED_GROUP_FROM_GLOBAL_SEARCH]: state => {
    state.isOpennedGroupFromGlobalSearch = true;
  },
  [types.MUTATE_DEACTIVATE_GROUPS]: (state, payload) => {
    for (const groupId of payload) {
      state.deactivatedGroups.push(parseInt(groupId));
    }
  },
  [types.MUTATE_ACTIVATE_GROUPS]: (state, payload) => {
    for (const groupId of payload) {
      state.activatedGroups.push(parseInt(groupId));
    }
  },
};

function checkIfMemberShouldBeAddedToPermissionArrays(entry) {
  const portalRoleAssignments = state.assignedGroupRoles.portalRoleAssignments;
  const profileAssignments = state.assignedGroupRoles.profileAssignments;
  const groupAssignments = state.assignedGroupRoles.groupAssignments;
  if (portalRoleAssignments && entry.type === docTypes.PROFILE.toLowerCase()) {
    const assignedGroupRoleProfileType = portalRoleAssignments.find(p => p.profileType === entry.portalRole);
    if (assignedGroupRoleProfileType) {
      addMemberToPermissionArrays(assignedGroupRoleProfileType.roles, entry, false);
    }
  }
  if (profileAssignments && entry.type === docTypes.PROFILE.toLowerCase()) {
    const assignedGroupRoleProfile = profileAssignments.find(p => p.profileId == entry.id);
    if (assignedGroupRoleProfile) {
      addMemberToPermissionArrays(assignedGroupRoleProfile.roles, entry, true);
    }
  }
  if (groupAssignments && entry.type === docTypes.GROUP.toLowerCase()) {
    const assignedGroupRoleGroup = groupAssignments.find(
      g => g.groupId == entry.id && g.portalRole === entry.portalRole
    );
    if (assignedGroupRoleGroup) {
      addMemberToPermissionArrays(assignedGroupRoleGroup.roles, entry, false);
    }
  }
}

function addMemberToPermissionArrays(roles, entry, checkForAdministrator) {
  for (const role of roles) {
    if (role.roleDefinition.id == roleEnum.WRITE_MESSAGE_GROUP) {
      state.messagePermissions.push(entry);
    } else if (role.roleDefinition.id == roleEnum.WRITE_POST_GROUP) {
      state.postPermissions.push(entry);
    } else if (role.roleDefinition.id == roleEnum.SHARE_MEDIA_WITH_GROUP) {
      state.mediaPermissions.push(entry);
    } else if (role.roleDefinition.id == roleEnum.INVITE_GROUP_TO_EVENT) {
      state.eventPermissions.push(entry);
    } else if (checkForAdministrator && role.roleDefinition.id == roleEnum.GROUP_ADMINISTRATOR) {
      state.groupAdministrators.push(entry);
    }
  }
}

const actions = {
  [types.SET_ACTIVE_GROUP]: ({ commit, rootState, dispatch }, payload) => {
    if (payload.isOpennedGroupFromGlobalSearch) {
      commit(types.MUTATE_IS_OPENNED_GROUP_FROM_GLOBAL_SEARCH, true);
    } else {
      commit(types.MUTATE_IS_OPENNED_GROUP_FROM_GLOBAL_SEARCH, false);
    }
    return portal
      .get('?method=groups.getGroupById', {
        params: { groupId: payload.groupId, includeRestrictedWidgets: payload.includeRestrictedWidgets },
      })
      .then(async function (response) {
        const group = response.data.data;
        const groupPayload = { group: group };
        if (group.type === groupTypes.MUNICIPAL || group.type === groupTypes.CROSS_INSTITUTIONAL) {
          const userGroup = rootState.globalShared.userGroups.find(userGroup => userGroup.id == group.id);
          if (userGroup != null) {
            groupPayload.membershipInstitutions = userGroup.membershipInstitutions;
          }
        }
        commit(types.MUTATE_SET_ACTIVE_GROUP, groupPayload);
        if (payload.fromAdministration) {
          await dispatch(types.LOAD_ASSIGNED_GROUP_ROLES, payload);
          await dispatch(types.LOAD_GROUP_MEMBERSHIPS_ADMIN, payload);
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_GROUP_BY_ID');
      });
  },
  [types.RESET_ACTIVE_GROUP]: ({ commit }) => {
    commit(types.MUTATE_RESET_ACTIVE_GROUP);
  },
  [types.LOAD_GROUP_MEMBERSHIPS]: ({ commit }, payload) =>
    portal
      .get('?method=groups.getMemberships', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_LOAD_GROUP_MEMBERSHIPS, response.data.data);
      })
      .catch(error => {
        if (error.response.data.status.code === 403) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_NOT_A_MEMBER');
          commit(types.MUTATE_REMOVE_GROUP_FROM_USER_GROUPS, payload.groupId);
        } else {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MEMBERSHIPS');
        }
      }),
  [types.LOAD_GROUP_MEMBERSHIPS_ADMIN]: ({ commit }, payload) =>
    portal
      .get('?method=groups.getDirectMemberships', {
        params: { groupId: payload.groupId },
      })
      .then(response => {
        commit(types.MUTATE_LOAD_GROUP_MEMBERSHIPS_ADMIN, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MEMBERSHIPS');
      }),
  [types.LOAD_GROUP_MEMBERSHIPS_LIGHT]: ({ commit }, payload) => {
    if (payload.isSearchComponentLoading) {
      commit(types.MUTATE_SEARCH_COMPONENT_LOADING, payload.isSearchComponentLoading);
    }
    return portal
      .get('?method=groups.getMembershipsLight', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_LOAD_GROUP_MEMBERSHIPS_LIGHT, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MEMBERSHIPS');
      });
  },
  [types.CREATE_GROUP]: ({ commit }, payload) =>
    portal.post('?method=groups.createGroup', payload).catch(error => {
      commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_GROUP');
      throw error;
    }),
  [types.UPDATE_GROUP]: ({ commit }, payload) =>
    portal
      .post('?method=groups.updateGroup', payload)
      .then(() => {
        commit(types.MUTATE_UPDATE_GROUP, payload);
      })
      .catch(error => {
        if (error.response.data.status.subCode !== errorSubCodeTypes.invalidCrossInstitutionGroupMember) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_GROUP');
        }
        throw error;
      }),
  [types.DELETE_GROUP]: ({ commit }, payload) =>
    portal
      .post('?method=groupsAdmin.deleteGroup', payload)
      .then(() => {
        commit(types.MUTATE_DELETE_GROUP, payload);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_GROUP');
      }),
  [types.LOAD_ALL_MODULES]: ({ commit }) =>
    portal
      .get('?method=dashboardsAdmin.getAllModules', {})
      .then(response => {
        const modules = response.data.data;
        commit(types.MUTATE_LOAD_ALL_MODULES, modules);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ALL_MODULES');
      }),
  [types.LOAD_ALL_WIDGETS]: ({ commit }) =>
    portal
      .get('?method=dashboardsAdmin.getAllWidgets', {})
      .then(response => {
        const widgets = response.data.data;
        commit(types.MUTATE_LOAD_ALL_WIDGETS, widgets);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ALL_WIDGETS');
      }),
  [types.LOAD_USER_ROLES_GROUP]: ({ commit }, payload) => {
    portal
      .get('?method=roleAdministration.listroledefinitions', {
        params: {
          portalRoles: payload.portalRoles,
          scopeTypes: payload.scopeTypes,
        },
      })
      .then(response => {
        commit(types.MUTATE_LOAD_USER_ROLES_GROUP, {
          loadedUserRoles: response.data.data,
          totalSelectedUsers: payload.groups.length,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LIST_ROLE_DEFINITIONS');
      });
  },
  [types.LOAD_ASSIGNED_USER_ROLES_FOR_GROUPS]: ({ commit, dispatch }, payload) => {
    portal
      .post('?method=roleAdministration.listassignedrolesforgroups', {
        groupIds: payload.groupIds,
      })
      .then(response => {
        commit(types.MUTATE_LOAD_ASSIGNED_USER_ROLES_FOR_GROUPS, response.data.data);
        dispatch(types.LOAD_USER_ROLES_GROUP, payload);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LIST_ROLE_DEFINITIONS');
      });
  },
  [types.LOAD_ASSIGNED_GROUP_ROLES]: ({ commit }, payload) =>
    portal
      .get('?method=roleAdministration.listassignedgrouproles', {
        params: { groupId: payload.groupId },
      })
      .then(response => commit(types.MUTATE_LOAD_ASSIGNED_GROUP_ROLES, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LIST_ROLE_DEFINITIONS');
      }),
  [types.DEACTIVATE_GROUP]: ({ commit }, payload) => {
    portal
      .post('?method=GroupsAdmin.setAdminDeactivationForGroups', {
        groupIds: payload.groupIds,
        operation: payload.operation,
      })
      .then(() => {
        if (payload.operation == groupDeactivationEnum.DEACTIVATE) {
          commit(types.MUTATE_SUCCESS_TEXT, 'GROUP_DEACTIVATE_SUCCESS');
          return commit(types.MUTATE_DEACTIVATE_GROUPS, payload.groupIds);
        } else {
          commit(types.MUTATE_SUCCESS_TEXT, 'GROUP_ACTIVATE_SUCCESS');
          return commit(types.MUTATE_ACTIVATE_GROUPS, payload.groupIds);
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_ADMIN_DEACTIVATION_FOR_GROUPS');
      });
  },
  [types.CHECK_GROUPS_IN_USE]: ({ commit }, payload) =>
    portal
      .get('?method=GroupsAdmin.isInUse', {
        params: { groupIds: payload.groupIds },
      })
      .then(response => response.data.data)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_GROUPS_IN_USE');
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
