// Interface for all getters, mutations and actions

// GETTERS
export const GET_CONSENT_LIST = 'consents/GET_CONSENT_LIST';
export const GET_SELECTED_CONSENT = 'consents/GET_SELECTED_CONSENT';

// MUTATIONS
export const MUTATE_GET_CONSENT_LIST = 'consents/MUTATE_GET_CONSENT_LIST';
export const MUTATE_SELECT_CONSENT = 'consents/MUTATE_SELECT_CONSENT';
export const MUTATE_UPDATE_CONSENT = 'consents/MUTATE_UPDATE_CONSENT';

// ACTIONS
export const LOAD_CONSENT_LIST = 'consents/LOAD_CONSENT_LIST';
export const SELECT_CONSENT = 'consents/SELECT_CONSENT';
export const UPDATE_CONSENT = 'consents/UPDATE_CONSENT';
