import Vue from 'vue';
import DatePicker from 'element-ui/lib/date-picker';
import Select from 'element-ui/lib/select';
import Option from 'element-ui/lib/option';
import OptionGroup from 'element-ui/lib/option-group';
import Dialog from 'element-ui/lib/dialog';
import Switch from 'element-ui/lib/switch';
import Popover from 'element-ui/lib/popover';
import Button from 'element-ui/lib/button';
import TimePicker from 'element-ui/lib/time-picker';
import lang from 'element-ui/lib/locale/lang/da';
import locale from 'element-ui/lib/locale';
import 'element-ui/lib/theme-chalk/index.css';

lang.el.datepicker.weeks = {
  sun: 'S',
  mon: 'M',
  tue: 'T',
  wed: 'O',
  thu: 'T',
  fri: 'F',
  sat: 'L',
};

lang.el.datepicker.months = {
  jan: 'Januar',
  feb: 'Februar',
  mar: 'Marts',
  apr: 'April',
  may: 'Maj',
  jun: 'Juni',
  jul: 'Juli',
  aug: 'August',
  sep: 'September',
  oct: 'Oktober',
  nov: 'November',
  dec: 'December',
};

/**
 * This fixes an issue with keyboard not showing up on iOS devices
 * The issue and solution can found here: {@link https://github.com/ElemeFE/element/issues/11397}
 */
Select.computed.readonly = function () {
  const isIE = !this.$isServer && !Number.isNaN(Number(document.documentMode));
  const isIOS =
    ['iPad Simulator', 'iPhone Simulator', 'iPod Simulator', 'iPad', 'iPhone', 'iPod'].includes(navigator.platform) ||
    // iPad on iOS 13 detection
    (navigator.userAgent.includes('Mac') && 'ontouchend' in document);

  return !this.filterable || this.multiple || (!isIE && !this.visible && !isIOS);
};

Vue.use(DatePicker);
Vue.use(Select);
Vue.use(Option);
Vue.use(OptionGroup);
Vue.use(Dialog);
Vue.use(Switch);
Vue.use(Popover);
Vue.use(Button);
Vue.use(TimePicker);
locale.use(lang);
