<template>
  <b-container class="login-by-id-provider mt-4">
    <b-row>
      <b-col>
        <div class="title">
          <span>{{ 'INSTITUTION_SELECT_CHECKIN_DASHBOARD' | fromTextKey }}</span>
        </div>
      </b-col>
      <b-col cols="12" class="mb-5">
        <aula-select
          v-model="selectedInstitution"
          filterable
          :placeholder="'SCHOOL_SELECT_PLACEHOLDER' | fromTextKey"
          class="institution-down-list"
        >
          <aula-option
            v-for="item in institutions"
            :key="item.institutionCode"
            :label="item.name"
            :value="item.institutionCode"
          />
        </aula-select>
      </b-col>
      <b-col cols="12" class="submit-button-container">
        <b-btn
          type="button"
          class="submit-button"
          variant="primary"
          :disabled="!isInstitutionChosen"
          @click="submitSelections"
        >
          {{ 'OK' | fromTextKey }}
        </b-btn>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { types } from '../../../src/store/types/types';
import { mapActions, mapGetters } from 'vuex';
import { Cookie } from '../../assets/plugins/cookie';

export default {
  data: function () {
    return {
      selectedInstitution: null,
    };
  },
  computed: {
    ...mapGetters({
      institutions: types.GET_INSTITUTIONS,
      profile: types.GET_CURRENT_PROFILE,
    }),
    isInstitutionChosen() {
      return this.selectedInstitution;
    },
  },
  methods: {
    ...mapActions({
      loginInstitutionDevice: types.EMPLOYEE_LOGIN_CHECKIN_DASHBOARD,
    }),
    submitSelections() {
      let domain;
      if (window.location.hostname.indexOf('aula.dk') !== -1) {
        domain = '.' + window.location.hostname.split('.').slice(-2).join('.');
      }
      Cookie.Erase('EmployeeLoginToCheckinDashboard', domain);
      Cookie.Erase('PresenceConfigurationChecked');
      this.loginInstitutionDevice({ institutionCode: this.selectedInstitution });
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import '../../assets/scss/core/variables.scss';
@import '../../assets/scss/core/breakpoints.scss';
@import '../../assets/scss/components/login/login.scss';
.login-by-id-provider {
  padding: 27px 50px;
  height: 371px;
  width: 371px;
  border: 1px solid var(--color-white);
  @include breakpoint-sm-down() {
    padding: 27px 40px;
    width: 100%;
  }
  .title {
    height: 29px;
    width: 216px;
    color: var(--color-white);
    font-family: Lato;
    font-size: 24px;
    font-weight: bold;
    line-height: 29px;
    margin-bottom: 50px;
  }
  .submit-button-container {
    margin-bottom: 50px;
    .submit-button {
      float: right;
      height: 50px;
      width: 100%;
      border-radius: 5px;
      background-color: var(--color-grey-lighter);
      color: var(--color-primary-dark);
    }
  }
  /deep/ .institution-down-list {
    --caret-color: var(--color-grey-base);

    .el-input__inner {
      border-bottom: 1px solid var(--color-white);
      border-radius: 0;
      background-color: transparent;
      color: var(--color-white);
      &:focus {
        outline: 2px solid var(--color-white) !important;
      }
    }
    .el-input__inner::placeholder {
      color: var(--color-white) !important;
    }
  }
}
</style>
