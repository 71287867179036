export const Cookie = {
  Create: function (name, value, days, domain) {
    let expires = '';
    let cookieDomain = '';
    if (days) {
      const date = new Date();
      date.setDate(date.getDate() + days);
      expires = '; expires=' + date.toGMTString();
    }
    if (domain) {
      cookieDomain = '; domain=' + domain;
    }
    document.cookie = name + '=' + encodeURIComponent(value) + expires + cookieDomain + '; path=/';
  },

  Read: function (name) {
    const nameEQ = name + '=';
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) == 0) {
        return decodeURIComponent(c.substring(nameEQ.length, c.length));
      }
    }
    return null;
  },

  Erase: function (name, domain = null) {
    Cookie.Create(name, '', -1, domain);
  },
};
