export const GETTER_COMMON_FILES_LIST = 'common_files/GETTER_COMMON_FILES_LIST';
export const GETTER_COMMON_FILES_INSTITUTION_DATA_POLICY_NAME =
  'common_files/GETTER_COMMON_FILES_INSTITUTION_DATA_POLICY_NAME';
export const GETTER_COMMON_FILES_SET_FOLDERS = 'common_files/GETTER_COMMON_FILES_SET_FOLDERS';

export const MUTATION_COMMON_FILES_SET_LIST = 'common_files/MUTATION_COMMON_FILES_SET_LIST';
export const MUTATE_GET_MORE_COMMON_FILES = 'common_files/MUTATE_GET_MORE_COMMON_FILES';
export const MUTATION_COMMON_FILES_UPDATE = 'common_files/MUTATION_COMMON_FILES_UPDATE';
export const MUTATION_DELETE_COMMON_FILES = 'common_files/MUTATION_DELETE_COMMON_FILES';
export const MUTATION_COMMON_FILES_INSTITUTION_HAS_DATA_POLICY =
  'common_files/MUTATION_COMMON_FILES_INSTITUTION_HAS_DATA_POLICY';

export const ACTION_COMMON_FILES_DELETE = 'common_files/ACTION_COMMON_FILES_DELETE';
export const ACTION_GET_COMMON_FILES_LIST = 'common_files/ACTION_GET_COMMON_FILES_LIST';
export const ACTION_GET_MORE_COMMON_FILES = 'common_files/ACTION_GET_MORE_COMMON_FILES';
export const ACTION_COMMON_FILES_UPDATE = 'common_files/ACTION_COMMON_FILES_UPDATE';
export const ACTION_COMMON_FILES_CREATE = 'common_files/ACTION_COMMON_FILES_CREATE';
export const ACTION_COMMON_FILES_GET_ONE = 'common_files/ACTION_COMMON_FILES_GET_ONE';
export const ACTION_COMMON_FILES_CHECK_EXISTING_TITLE = 'common_files/ACTION_COMMON_FILES_CHECK_EXISTING_TITLE';
export const ACTION_COMMON_FILES_INSTITUTION_HAS_DATA_POLICY =
  'common_files/ACTION_COMMON_FILES_INSTITUTION_HAS_DATA_POLICY';
