// Interface for all getters, mutations and actions

// Getters
export const VC_GET_VIDEO = 'videoCache/VC_GET_VIDEO';

// Mutations
export const VC_MUTATE_REMOVE_OUTDATED_VIDEOS = 'videoCache/VC_MUTATE_REMOVE_OUTDATED_VIDEOS';
export const VC_MUTATE_ADD_VIDEO = 'videoCache/VC_MUTATE_ADD_VIDEO';

// Actions
export const VC_LOAD_VIDEO_FROM_SOURCE = 'videoCache/VC_LOAD_VIDEO_FROM_SOURCE';
