import * as global from './global';
import * as globalShared from '../../../shared/store/types/global';
import * as messages from '../../../shared/store/types/messages';
import * as gallery from './gallery';
import * as onboarding from './onboarding';
import * as presence from './presence';
import * as presence_employee from './presence_employee';
import * as documents from '../../../shared/store/types/documents';
import * as posts from './posts';
import * as users from '../../../shared/store/types/users';
import * as calendar from '../../../shared/store/types/calendar';
import * as fileImport from '../../../shared/store/types/fileImport';
import * as search from './search';
import * as commonFiles from '../../../src_adm/store/types/commonFiles';
import * as administrativeAuthorities from '../../../src_adm/store/types/administrativeAuthorities';
import * as groups from './groups';
import * as groupsShared from '../../../shared/store/types/groups';
import * as notifications from '../../../shared/store/types/notifications';
import * as accesscontrol from '../../../shared/store/types/accesscontrol';
import * as files from '../../../shared/store/types/files';
import * as reportedContent from '../../../shared/store/types/reportedContent';
import * as consents from '../../../shared/store/types/consents';
import * as contacts from './contacts';
import * as comments from '../../../shared/store/types/comments';
import * as personalReferenceData from './personalReferenceData';
import * as auth from '../../../shared/store/types/auth';
import * as videoCache from '../../../shared/store/types/videoCache';

export const types = Object.assign(
  {},
  global,
  auth,
  globalShared,
  messages,
  gallery,
  onboarding,
  presence,
  presence_employee,
  documents,
  posts,
  users,
  calendar,
  search,
  groups,
  notifications,
  accesscontrol,
  files,
  commonFiles,
  administrativeAuthorities,
  groupsShared,
  reportedContent,
  consents,
  contacts,
  comments,
  fileImport,
  personalReferenceData,
  videoCache
);
