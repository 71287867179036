// Interface for all getters, mutations and actions
// Getters
export const GET_POSTS = 'posts/GET_POSTS';
export const GET_SINGLE_POST = 'posts/GET_SINGLE_POST';
export const GET_DRAFT_POST = 'feed/GET_DRAFT_POST';
export const GET_MORE_POSTS_EXIST = 'posts/GET_MORE_POSTS_EXIST';
export const GET_LAST_SEEN_DATE = 'posts/GET_LAST_SEEN_DATE';

// Mutations
export const MUTATE_ADD_NEW_POST = 'posts/MUTATE_ADD_NEW_POST';
export const MUTATE_EDIT_POST = 'posts/MUTATE_EDIT_POST';
export const MUTATE_SAVE_NEW_POSTS_LOCALLY = 'posts/MUTATE_SAVE_NEW_POSTS_LOCALLY';
export const MUTATE_INIT_LOCAL_NEW_POSTS = 'posts/MUTATE_INIT_LOCAL_NEW_POSTS';
export const MUTATE_REMOVE_POSTS_LOCALLY = 'posts/MUTATE_REMOVE_POSTS_LOCALLY';
export const MUTATE_LOAD_POSTS = 'posts/MUTATE_LOAD_POSTS';
export const MUTATE_LOAD_SINGLE_POST = 'posts/MUTATE_LOAD_SINGLE_POST';
export const MUTATE_EMPTY_POSTS = 'posts/MUTATE_EMPTY_POSTS';
export const MUTATE_DELETE_POST = 'posts/MUTATE_DELETE_POST';
export const MUTATE_LAST_INSTITUTION_PROFILE_ID_FILTER = 'posts/MUTATE_LAST_INSTITUTION_PROFILE_ID_FILTER';
export const MUTATE_ClEAR_NEW_POST = 'posts/MUTATE_ClEAR_NEW_POST';
export const MUTATE_SLICE_ATTACHMENT_FROM_POST = 'posts/MUTATE_SLICE_ATTACHMENT_FROM_POST';

// Actions
export const ACTIONS_ADD_NEW_POST = 'posts/ACTIONS_ADD_NEW_POST';
export const ACTIONS_EDIT_POST = 'posts/ACTIONS_EDIT_POST';
export const ACTIONS_SAVE_NEW_POSTS_LOCALLY = 'posts/ACTIONS_SAVE_NEW_POSTS_LOCALLY';
export const ACTION_GET_POSTS = 'posts/ACTION_GET_POSTS';
export const ACTION_GET_POSTS_ADMIN = 'posts/ACTION_GET_POSTS_ADMIN';
export const ACTION_GET_POST = 'posts/ACTION_GET_POST';
export const ACTIONS_INIT_LOCAL_NEW_POSTS = 'posts/ACTIONS_INIT_LOCAL_NEW_POSTS';
export const ACTIONS_DELETE_POST = 'posts/ACTIONS_DELETE_POST';
export const REPORT_POST = 'posts/REPORT_POST';
export const SLICE_ATTACHMENT_FROM_POST = 'posts/SLICE_ATTACHMENT_FROM_POST';
export const LOAD_NOTICE_BOARD_POSTS = 'posts/LOAD_NOTICE_BOARD_POSTS';
export const ACTIONS_CLEAR_NEW_POST = 'posts/ACTIONS_CLEAR_NEW_POST';
