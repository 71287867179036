import moment from 'moment-timezone';
import $ from 'jquery';
// Add this function to Vue Prototype to call it from everywhere
export function datePickerModifier() {
  // using jQuery to make timePicker
  const checkExist = setInterval(function () {
    const listDatePicker = $('.el-picker-panel');
    if (listDatePicker.length > 0) {
      for (const datePicker of listDatePicker) {
        if ($(datePicker).css('display') === 'block') {
          calculateWeekNumbers($(datePicker).find('.el-picker-panel__icon-btn'), datePicker);
          $(datePicker)
            .find('.el-picker-panel__icon-btn')
            .click(function () {
              calculateWeekNumbers($(this), datePicker);
            });
          $(datePicker)
            .find('.el-date-picker__header-label')
            .click(function () {
              $(datePicker).find('.datepicker-weeknumbers,.datepicker-weeknumbers-seperator').hide();
            });
          $(datePicker)
            .find('.el-month-table .cell')
            .click(function () {
              $(datePicker).find('.datepicker-weeknumbers,.datepicker-weeknumbers-seperator').show();
              calculateWeekNumbers($(datePicker).find('.el-picker-panel__icon-btn'), datePicker);
            });
        }
      }
      clearInterval(checkExist);
    }
  }, 50);
}

function calculateWeekNumbers(jObj, datePicker) {
  setTimeout(function () {
    let date = '';
    jObj
      .closest('.el-date-picker__header')
      .find('.el-date-picker__header-label')
      .each(function () {
        const value = $(this).html().split(' ')[0];
        if (Number.isInteger(parseInt(value))) {
          date += value + '-';
        } else {
          date += monthNameToNumber(value);
        }
      });

    const momentDate = moment(date);

    let firstWeek = momentDate.startOf('month').week();
    let lastWeek = momentDate.endOf('month').week();
    const datePickerWeeknumbers = [];
    if (lastWeek == 1) {
      lastWeek = 52;
    }
    if (firstWeek > lastWeek) {
      firstWeek = 1;
      datePickerWeeknumbers.push('');
    }
    for (let i = firstWeek; i < lastWeek + 1; i++) {
      datePickerWeeknumbers.push(i);
    }
    if ($(datePicker).find('.datepicker-weeknumbers').length === 0) {
      $(datePicker)
        .find('.el-picker-panel__content')
        .prepend("<ul class='datepicker-weeknumbers'></ul><div class='datepicker-weeknumbers-seperator'></div>");
    }
    $(datePicker).find('.el-picker-panel__content').find('.datepicker-weeknumbers').html('');
    for (const week of datePickerWeeknumbers) {
      $(datePicker)
        .find('.el-picker-panel__content')
        .find('.datepicker-weeknumbers')
        .append("<li style='height:50px;'>" + week + '</li>');
    }
  }, 20);
}

function monthNameToNumber(date) {
  switch (date) {
    case 'Januar': {
      return '01';
    }
    case 'Februar': {
      return '02';
    }
    case 'Marts': {
      return '03';
    }
    case 'April': {
      return '04';
    }
    case 'Maj': {
      return '05';
    }
    case 'Juni': {
      return '06';
    }
    case 'Juli': {
      return '07';
    }
    case 'August': {
      return '08';
    }
    case 'September': {
      return '09';
    }
    case 'Oktober': {
      return '10';
    }
    case 'November': {
      return '11';
    }
    case 'December': {
      return '12';
    }
  }
}
