export const exitTypes = Object.freeze({
  PICKED_UP_BY: 0,
  SELF_DECIDER: 1,
  SEND_HOME: 2,
  GO_HOME_WITH: 3,
  DROP_OFF_TIME: 4,
  SPARE_TIME: 5,
  CHECK_IN: 6,
  CHECK_OUT: 7,
  SLEEPING: 8,
});
