import { types } from '../../../src/store/types/types';
import { videoCacheUtil } from '../../utils/videoCacheUtil';

const state = {
  cachedVideoMap: new Map(),
};

const getters = {
  [types.VC_GET_VIDEO]: state => video => {
    const now = new Date().getTime();
    const cachedVideo = state.cachedVideoMap.get(video.id);
    if (cachedVideo != null && cachedVideo.ttl > now) {
      return cachedVideo.video;
    }
    return null;
  },
};

const mutations = {
  [types.VC_MUTATE_ADD_VIDEO]: (state, { video, ttl }) => {
    state.cachedVideoMap.set(video.id, { ttl, video });
    state.cachedVideoMap = new Map(state.cachedVideoMap);
  },
  [types.VC_MUTATE_REMOVE_OUTDATED_VIDEOS]: state => {
    const now = new Date().getTime();
    state.cachedVideoMap.forEach(({ ttl }, key) => {
      if (ttl < now) {
        state.cachedVideoMap.delete(key);
      }
    });
    state.cachedVideoMap = new Map(state.cachedVideoMap);
  },
};

const actions = {
  [types.VC_LOAD_VIDEO_FROM_SOURCE]: ({ getters, commit }, mediaFile) =>
    new Promise((resolve, reject) => {
      commit(types.VC_MUTATE_REMOVE_OUTDATED_VIDEOS);
      const video = getters[types.VC_GET_VIDEO](mediaFile);
      if (video != null) {
        resolve(video.url);
        return;
      }

      // here we download it entirely
      videoCacheUtil.downloadFileAsBlob(mediaFile.url).then(blob => {
        const createObjectURL = URL.createObjectURL(blob);
        const mediaFileCopy = {
          ...mediaFile,
          url: createObjectURL,
        };
        const ttl = videoCacheUtil.getExpirationDateFromUrl(mediaFile.url);

        commit(types.VC_MUTATE_ADD_VIDEO, { video: mediaFileCopy, ttl });
        resolve(createObjectURL);
      }, reject);
    }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
