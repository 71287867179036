// Interface for all getters, mutations and actions

// Getters
export const HAS_PERMISSION = 'accesscontrol/HAS_PERMISSION';
export const HAS_PERMISSION_ON_INSTITUTION = 'accesscontrol/HAS_PERMISSION_ON_INSTITUTION';
export const HAS_PERMISSION_ON_GROUP = 'accesscontrol/HAS_PERMISSION_ON_GROUP';
export const HAS_PERMISSION_ON_INSTITUTION_GROUP = 'accesscontrol/HAS_PERMISSION_ON_INSTITUTION_GROUP';

// Mutations
export const MUTATE_INIT_PERMISSIONS = 'accesscontrol/MUTATE_INIT_PERMISSIONS';

// Actions
export const INIT_PERMISSIONS = 'accesscontrol/INIT_PERMISSIONS';
