// Interface for all getters, mutations and actions

// Getters
export const GET_SEARCH_STRING_GROUP = 'groups/GET_SEARCH_STRING_GROUP';
export const GET_GROUP_SEARCH_RESULTS = 'groups/GET_GROUP_SEARCH_RESULTS';
export const GET_NUMBER_OF_RESULTS_GROUP = 'groups/GET_NUMBER_OF_RESULTS_GROUP';
export const GET_SHOW_RESULT_GROUP = 'groups/GET_SHOW_RESULT_GROUP';
export const GET_SHOW_SUBRESULTS_GROUP = 'groups/GET_SHOW_SUBRESULTS_GROUP';
export const GET_SEARCH_OFFSET_GROUP = 'groups/GET_SEARCH_OFFSET_GROUP';
export const GET_LOAD_MORE_GROUP = 'groups/GET_LOAD_MORE_GROUP';

// Mutations
export const MUTATE_LOAD_GROUP_SEARCH_RESULTS = 'groups/MUTATE_LOAD_GROUP_SEARCH_RESULTS';
export const MUTATE_SET_SEARCH_STRING_GROUP = 'groups/MUTATE_SET_SEARCH_STRING_GROUP';
export const MUTATE_SHOW_RESULT_GROUP = 'groups/MUTATE_SHOW_RESULT_GROUP';
export const MUTATE_SHOW_SUBRESULTS_GROUP = 'groups/MUTATE_SHOW_SUBRESULTS_GROUP';
export const MUTATE_LOAD_MORE_GROUP_RESULTS = 'groups/MUTATE_LOAD_MORE_GROUP_RESULTS';
export const MUTATE_CLEAR_SEARCH_RESULTS = 'groups/MUTATE_CLEAR_SEARCH_RESULTS';

// Actions
export const SET_MEMBERSHIP = 'groups/SET_MEMBERSHIP';
export const SET_JOIN_OR_LEAVE_GROUP = 'groups/SET_JOIN_OR_LEAVE_GROUP';
export const SET_SEARCH_STRING_GROUP = 'groups/SET_SEARCH_STRING_GROUP';
export const LOAD_GROUP_SEARCH_RESULTS = 'groups/LOAD_GROUP_SEARCH_RESULTS';
export const SET_SHOW_RESULT_GROUP = 'groups/SET_SHOW_RESULT_GROUP';
export const SET_SHOW_SUBRESULTS_GROUP = 'groups/SET_SHOW_SUBRESULTS_GROUP';
export const LOAD_MORE_GROUP_RESULTS = 'groups/LOAD_MORE_GROUP_RESULTS';
