import { types } from '../types/types';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { docTypes } from '../../../shared/enums/docTypes';
import { groupTypes } from '../../../shared/enums/groupTypes';

const state = {
  searchResults: [],
  searchString: '',
  numberOfResults: [],
  numberOfPortalRolesResults: [],
  numberOfGroupTypesResults: [],
  showResult: 'Profile',
  showSubresults: [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE],
  searchLimit: 20,
  loadMore: false,
  searchOffset: 0,
  childRelations: [],
  statusSearchBar: false,
  isLoadingSearchResults: false,
};

const getters = {
  [types.GET_STATUS_SEARCH_BAR]: state => state.statusSearchBar,
  [types.GET_GLOBAL_SEARCH_RESULTS]: state => state.searchResults,
  [types.GET_SEARCH_STRING]: state => state.searchString,
  [types.GET_NUMBER_OF_RESULTS]: state => {
    if (state.numberOfResults != null) {
      let countTotal = 0;
      for (const obj of state.numberOfResults) {
        countTotal += obj.count;
      }
      if (countTotal > 0) {
        const countGroups =
          state.numberOfResults.find(res => res.name === docTypes.GROUP) != null
            ? state.numberOfResults.find(res => res.name === docTypes.GROUP).count
            : 0;
        let countInstitutionalGroups = 0;
        let countMunicipalGroups = 0;
        if (countGroups > 0) {
          if (state.numberOfGroupTypesResults != null) {
            const institutionalGroupCount = state.numberOfGroupTypesResults.find(
              res => res.name === groupTypes.INSTITUTIONAL
            );
            countInstitutionalGroups = institutionalGroupCount != null ? institutionalGroupCount.count : 0;
          }
          if (state.numberOfGroupTypesResults) {
            const municipalGroupCount = state.numberOfGroupTypesResults.find(res => res.name === groupTypes.MUNICIPAL);
            countMunicipalGroups = municipalGroupCount != null ? municipalGroupCount.count : 0;
          }
        }
        const countProfiles =
          state.numberOfResults.find(res => res.name === docTypes.PROFILE) != null
            ? state.numberOfResults.find(res => res.name === docTypes.PROFILE).count
            : 0;
        let countChildren = 0;
        let countEmployees = 0;
        let countGuardians = 0;
        if (countProfiles > 0) {
          countChildren =
            state.numberOfPortalRolesResults.find(res => res.name === portalRoles.CHILD) != null
              ? state.numberOfPortalRolesResults.find(res => res.name === portalRoles.CHILD).count
              : 0;
          countEmployees =
            state.numberOfPortalRolesResults.find(res => res.name === portalRoles.EMPLOYEE) != null
              ? state.numberOfPortalRolesResults.find(res => res.name === portalRoles.EMPLOYEE).count
              : 0;
          countGuardians =
            state.numberOfPortalRolesResults.find(res => res.name === portalRoles.GUARDIAN) != null
              ? state.numberOfPortalRolesResults.find(res => res.name === portalRoles.GUARDIAN).count
              : 0;
        }
        const countThreadMessages =
          state.numberOfResults.find(res => res.name === docTypes.THREAD_MESSAGE) != null
            ? state.numberOfResults.find(res => res.name === docTypes.THREAD_MESSAGE).count
            : 0;
        const countEvents =
          state.numberOfResults.find(res => res.name === docTypes.EVENT) != null
            ? state.numberOfResults.find(res => res.name === docTypes.EVENT).count
            : 0;
        const countPosts =
          state.numberOfResults.find(res => res.name === docTypes.POST) != null
            ? state.numberOfResults.find(res => res.name === docTypes.POST).count
            : 0;
        const countInternalDocuments =
          state.numberOfResults.find(res => res.name === docTypes.INTERNAL_SECURE_FILE) != null
            ? state.numberOfResults.find(res => res.name === docTypes.INTERNAL_SECURE_FILE).count
            : 0;
        const countExternalDocuments =
          state.numberOfResults.find(res => res.name === docTypes.EXTERNAL_SECURE_FILE) != null
            ? state.numberOfResults.find(res => res.name === docTypes.EXTERNAL_SECURE_FILE).count
            : 0;
        const countCommonFiles =
          state.numberOfResults.find(res => res.name === docTypes.IMPORTANT_FILE) != null
            ? state.numberOfResults.find(res => res.name === docTypes.IMPORTANT_FILE).count
            : 0;
        const countMedias =
          state.numberOfResults.find(res => res.name === docTypes.MEDIA) != null
            ? state.numberOfResults.find(res => res.name === docTypes.MEDIA).count
            : 0;

        return {
          countTotal,
          countGroups,
          countInstitutionalGroups,
          countMunicipalGroups,
          countProfiles,
          countChildren,
          countGuardians,
          countEmployees,
          countThreadMessages,
          countEvents,
          countPosts,
          countInternalDocuments,
          countExternalDocuments,
          countCommonFiles,
          countMedias: countMedias,
        };
      } else {
        return { countTotal };
      }
    } else {
      return { total: 0 };
    }
  },
  [types.GET_LOAD_MORE]: state => state.loadMore,
  [types.GET_SHOW_RESULT]: state => state.showResult,
  [types.GET_SHOW_SUBRESULTS]: state => state.showSubresults,
  [types.GET_SEARCH_OFFSET]: state => state.searchOffset,
  [types.GET_CHILD_RELATIONS]: state => state.childRelations,
  [types.GET_SEARCH_LOADING_STATE]: state => state.isLoadingSearchResults,
};

const mutations = {
  [types.MUTATE_STATUS_SEARCH_BAR]: (state, payload) => {
    state.statusSearchBar = payload;
  },
  [types.MUTATE_LOAD_GLOBAL_SEARCH_RESULTS]: (state, payload) => {
    if (payload.searchResults != null) {
      if (payload.showResult === docTypes.MEDIA) {
        state.searchResults = payload.searchResults.mediaResults;
      } else {
        state.searchResults = payload.searchResults.results;
      }
      state.numberOfResults = payload.searchResults.docTypeCount;
      state.numberOfPortalRolesResults = payload.searchResults.profileTypeCount;
      state.numberOfGroupTypesResults = payload.searchResults.groupTypeCount;
    }
    state.loadMore = payload.loadMore;
  },
  [types.MUTATE_LOAD_MORE_GLOBAL_RESULTS]: (state, payload) => {
    state.searchOffset = state.searchOffset + state.searchLimit;
    if (state.showResult === docTypes.MEDIA) {
      state.searchResults = state.searchResults.concat(payload.searchResults.mediaResults);
    } else {
      state.searchResults = state.searchResults.concat(payload.searchResults.results);
    }
    state.loadMore = payload.loadMore;
  },
  [types.MUTATE_SET_SEARCH_STRING]: (state, payload) => {
    state.searchString = payload;
    state.searchOffset = 0;
  },
  [types.MUTATE_SHOW_RESULT]: (state, payload) => {
    if (payload.showResult === docTypes.MEDIA) {
      state.searchResults = payload.searchResults.mediaResults;
    } else {
      state.searchResults = payload.searchResults.results;
    }
    state.numberOfResults = payload.searchResults.docTypeCount;
    state.numberOfPortalRolesResults = payload.searchResults.profileTypeCount;
    state.numberOfGroupTypesResults = payload.searchResults.groupTypeCount;
    state.showResult = payload.showResult;
    state.loadMore = payload.loadMore;
    state.searchOffset = 0;
  },
  [types.MUTATE_SHOW_RESULT_ONLY]: (state, payload) => {
    state.showResult = payload;
  },
  [types.MUTATE_SHOW_SUBRESULTS]: (state, payload) => {
    state.searchResults = payload.searchResults.results;
    state.numberOfResults = payload.searchResults.docTypeCount;
    state.numberOfPortalRolesResults = payload.searchResults.profileTypeCount;
    state.numberOfGroupTypesResults = payload.searchResults.groupTypeCount;
    state.loadMore = payload.loadMore;
    state.showSubresults = payload.showSubresults;
    state.searchOffset = 0;
  },
  [types.MUTATE_RESET_SEARCH_RESULTS]: state => {
    state.searchResults = [];
    state.searchString = '';
    state.numberOfResults = [];
    state.numberOfPortalRolesResults = [];
    state.numberOfGroupTypesResults = [];
    state.loadMore = false;
    state.searchOffset = 0;
  },
  [types.MUTATE_SEARCH_LOADING_STATE]: (state, isLoading) => {
    state.isLoadingSearchResults = isLoading;
  },
  [types.MUTATE_CLEAR_SEARCH_RESULTS]: state => {
    state.searchResults = [];
  },
};

const actions = {
  [types.SET_STATUS_SEARCH_BAR]: ({ commit }, payload) => {
    commit(types.MUTATE_STATUS_SEARCH_BAR, payload);
  },
  [types.LOAD_GLOBAL_SEARCH_RESULTS]: ({ commit, state, dispatch, rootState }, payload) => {
    commit(types.MUTATE_SEARCH_LOADING_STATE, true);
    portal
      .get('?method=search.findGeneric', {
        params: {
          limit: state.searchLimit,
          institutionProfileIds: rootState.global.activeInstitutionProfiles,
          activeChildrenInstitutionProfileIds: rootState.global.activeChildren,
          text: payload.query,
          docType: payload.docType,
          docTypeCount: true,
        },
      })
      .then(response => {
        commit(types.MUTATE_SEARCH_LOADING_STATE, false);
        if (state.searchString !== '') {
          const searchResults = response.data.data;
          if (searchResults.results.length === 0 && searchResults.docTypeCount.length > 0) {
            dispatch(types.SET_SHOW_RESULT, searchResults.docTypeCount[0].name);
          } else {
            const loadMore = searchResults.totalSize > state.searchLimit;
            commit(types.MUTATE_LOAD_GLOBAL_SEARCH_RESULTS, {
              searchResults: searchResults,
              loadMore: loadMore,
              showResult: payload.docType,
            });
          }
        } else {
          const searchResult = {
            results: {},
            totalHits: 0,
          };
          commit(types.MUTATE_LOAD_GLOBAL_SEARCH_RESULTS, searchResult);
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_GENERIC');
      });
  },
  [types.SET_SEARCH_STRING]: ({ commit }, payload) => {
    commit(types.MUTATE_SET_SEARCH_STRING, payload);
  },
  [types.SET_SHOW_RESULT]: ({ commit, state, rootState }, payload) => {
    commit(types.MUTATE_SEARCH_LOADING_STATE, true);
    commit(types.MUTATE_CLEAR_SEARCH_RESULTS);
    portal
      .get('?method=search.findGeneric', {
        params: {
          limit: state.searchLimit,
          institutionProfileIds: rootState.global.activeInstitutionProfiles,
          activeChildrenInstitutionProfileIds: rootState.global.activeChildren,
          text: state.searchString,
          docType: payload,
          docTypeCount: true,
        },
      })
      .then(response => {
        commit(types.MUTATE_SEARCH_LOADING_STATE, false);
        const searchResults = response.data.data;
        const loadMore = searchResults.totalSize > state.searchLimit;
        commit(types.MUTATE_SHOW_RESULT, {
          searchResults: searchResults,
          showResult: payload,
          loadMore: loadMore,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_GENERIC');
      });
  },
  [types.SET_SHOW_SUBRESULTS]: ({ commit, state, rootState }, payload) => {
    commit(types.MUTATE_SEARCH_LOADING_STATE, true);
    portal
      .get('?method=search.findGeneric', {
        params: {
          limit: state.searchLimit,
          text: state.searchString,
          institutionProfileIds: rootState.global.activeInstitutionProfiles,
          activeChildrenInstitutionProfileIds: rootState.global.activeChildren,
          docType: payload.docType,
          groupTypes: payload.groupTypes,
          docTypeCount: true,
          roles: payload.roles,
        },
      })
      .then(response => {
        commit(types.MUTATE_SEARCH_LOADING_STATE, false);
        const searchResults = response.data.data;
        const loadMore = searchResults.totalSize > state.searchLimit;
        commit(types.MUTATE_SHOW_SUBRESULTS, {
          showSubresults: payload.roles,
          searchResults: searchResults,
          loadMore: loadMore,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_GENERIC');
      });
  },
  [types.LOAD_MORE_GLOBAL_RESULTS]: ({ commit, state, rootState }) =>
    portal
      .get('?method=search.findGeneric', {
        params: {
          limit: state.searchLimit,
          offset: state.searchOffset + state.searchLimit,
          institutionProfileIds: rootState.global.activeInstitutionProfiles,
          activeChildrenInstitutionProfileIds: rootState.global.activeChildren,
          text: state.searchString,
          docType: state.showResult,
          docTypeCount: true,
          roles: state.showSubresults,
        },
      })
      .then(response => {
        const searchResults = response.data.data;
        const totalLoaded = state.searchOffset + state.searchLimit + searchResults.results.length;
        const loadMore = searchResults.totalSize > totalLoaded;
        return commit(types.MUTATE_LOAD_MORE_GLOBAL_RESULTS, {
          searchResults: searchResults,
          loadMore: loadMore,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_GENERIC');
      }),
};

export default {
  state,
  getters,
  mutations,
  actions,
};
