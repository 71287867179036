export const errorSubCodeTypes = Object.freeze({
  communicationChannelBlocked: 5,
  mediaUploadExceeded: 14,
  invalidMediaTag: 19,
  datesOverlapping: 20,
  errorShareWithOtherMunicipalities: 13,
  coOrganizerAsInvitee: 21,
  invalidCrossInstitutionGroupMember: 22,
  sessionExpired: 23,
  notImpersonating: 24,
  exceedingMaximumParticipants: 25,
  conflictingRepeatingEventInSameSeries: 26,
  canNotAddMunicipalGroupAsGroupMember: 27,
  canNotAddCrossInstitutionalGroupAsGroupMember: 28,
  duplicatedRoomName: 29,
  duplicatedRoomDisplayName: 30,
  firstRepeatingEventExceptionOutOfRange: 31,
  lastRepeatingEventExceptionOutOfRange: 32,
  deactivatedInstitutionProfile: 33,
  invalidDelegatedAccess: 35,
  accessDeniedProfileMasterData: 36,
  canNotExportDocumentsFromMultipleDifferentMunicipalities: 37,
});
