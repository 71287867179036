import { types } from '../../../src/store/types/types';
import Vue from 'vue';
import { portal } from '../../assets/plugins/axios/axios.js';
import moment from 'moment-timezone';

const state = {
  comments: { post: {}, media: {} },
  commentsCount: { post: {}, media: {} },
  commentableInstitutionProfiles: { post: {}, media: {} },
  writingComment: false,
};

const getters = {
  [types.GET_COMMENTS]: state => state.comments,
  [types.GET_COMMENTS_COUNT]: state => state.commentsCount,
  [types.GET_WRITING_COMMENT]: state => state.writingComment,
  [types.GET_COMMENTABLE_INSTITUTION_PROFILES]: state => state.commentableInstitutionProfiles,
};

const mutations = {
  [types.MUTATE_LOAD_COMMENTS]: (state, payload) => {
    const data = payload.data;
    const type = data.parentObjectType.toLowerCase();
    const id = data.parentObjectId;

    if (data.parentCommentId) {
      for (let i = 0; i < state.comments[type][id].length; i++) {
        const comment = state.comments[type][id][i];
        if (comment.id == data.parentCommentId) {
          if (comment.comments == null) {
            comment.comments = [];
          }
          for (const loadedComment of payload.comments) {
            if (comment.comments.find(c => c.id == loadedComment.id) == null) {
              comment.comments.push(loadedComment);
            }
          }
          comment.comments.sort((a, b) =>
            a.createdAt.localeCompare(b.createdAt, 'da', {
              sensitivity: 'base',
            })
          );
          if (payload.created) {
            comment.commentCount++;
          }
          Vue.set(state.comments[type][id], i, comment);
        }
      }
    } else {
      if (state.comments[type][id] == null) {
        Vue.set(state.comments[type], id, payload.comments);
      } else {
        Vue.set(state.comments[type], id, state.comments[type][id].concat(payload.comments));
      }
      if (payload.commentsCount) {
        Vue.set(state.commentsCount[type], id, payload.commentsCount);
      } else {
        Vue.set(state.commentsCount[type], id, state.commentsCount[type][id] + 1);
      }
      if (payload.commentableInstitutionProfiles) {
        Vue.set(state.commentableInstitutionProfiles[type], id, payload.commentableInstitutionProfiles);
      }
    }
  },
  [types.MUTATE_DELETE_COMMENT]: (state, payload) => {
    const type = payload.parentObjectType.toLowerCase();
    const id = payload.parentObjectId;
    if (payload.subCommentId) {
      for (let i = 0; i < state.comments[type][id].length; i++) {
        const comment = state.comments[type][id][i];
        if (comment.id == payload.parentCommentId) {
          for (const subComment of comment.comments) {
            if (subComment.id == payload.subCommentId) {
              if (payload.report) {
                subComment.isReported = true;
              } else {
                subComment.isDeleted = true;
              }
            }
          }
        }
        Vue.set(state.comments[type][id], i, comment);
      }
    } else {
      if (state.comments[type][id] != null) {
        for (let i = 0; i < state.comments[type][id].length; i++) {
          const comment = state.comments[type][id][i];
          if (comment.id == payload.parentCommentId) {
            if (payload.report) {
              comment.isReported = true;
            } else {
              comment.isDeleted = true;
            }
          }
          Vue.set(state.comments[type][id], i, comment);
        }
      }
    }
  },
  [types.MUTATE_UPDATE_COMMENT]: (state, payload) => {
    const type = payload.parentObjectType.toLowerCase();
    const id = payload.parentObjectId;
    if (state.comments[type][id] != null) {
      if (payload.subCommentId) {
        for (const comment of state.comments[type][id]) {
          if (comment.id == payload.parentCommentId) {
            const subComment = comment.comments.find(com => com.id == payload.subCommentId);
            subComment.content = payload.content;
            subComment.updatedAt = moment().format();
            break;
          }
        }
      } else {
        for (const comment of state.comments[type][id]) {
          if (comment.id == payload.parentCommentId) {
            comment.content = payload.content;
            comment.updatedAt = moment().format();
            break;
          }
        }
      }
    }
  },
  [types.MUTATE_CLEAR_COMMENTS]: state => {
    state.comments = { post: {}, media: {} };
    state.commentsCount = { post: {}, media: {} };
  },
  [types.MUTATE_WRITING_COMMENT]: (state, payload) => {
    state.writingComment = payload;
  },
};

const actions = {
  [types.LOAD_COMMENTS]: ({ commit }, payload) =>
    portal
      .get('?method=comments.getComments', { params: payload })
      .then(response =>
        commit(types.MUTATE_LOAD_COMMENTS, {
          comments: response.data.data.comments,
          commentsCount: response.data.data.totalResultCount,
          data: payload.data,
          commentableInstitutionProfiles: response.data.data.commentableInstitutionProfiles,
        })
      )
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_COMMENTS');
      }),
  [types.CREATE_COMMENT]: ({ commit }, payload) =>
    portal
      .post('?method=comments.addComment', payload)
      .then(response => {
        const createdComment = {
          content: payload.content,
          id: response.data.data.id,
          creator: response.data.data.creator,
          createdAt: payload.createdAt,
          canEdit: true,
          canDelete: true,
          canReport: false,
          commentCount: 0,
        };
        return commit(types.MUTATE_LOAD_COMMENTS, {
          comments: [createdComment],
          data: payload.data,
          created: true,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_COMMENT');
      }),
  [types.UPDATE_COMMENT]: ({ commit }, payload) =>
    portal
      .post('?method=comments.updateComment', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'COMMENTS_COMMENT_IS_UPDATED');
        return commit(types.MUTATE_UPDATE_COMMENT, payload.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_COMMENT');
      }),
  [types.DELETE_COMMENT]: ({ commit }, payload) =>
    portal
      .post('?method=comments.deleteComment', payload)
      .then(() => commit(types.MUTATE_DELETE_COMMENT, payload.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_COMMENT');
      }),
  [types.REPORT_COMMENT]: ({ commit }, payload) =>
    portal
      .post('?method=comments.reportComment', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_REPORTED_COMMENT');
        return commit(types.MUTATE_DELETE_COMMENT, payload.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REPORT_COMMENT');
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
