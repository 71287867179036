<template>
  <div class="mobile-topbar-container" :class="cssClass" :style="'background:' + backgroundColor + ';color:' + color">
    <div class="column first-column text-truncate" :class="{ 'has-text': leftText != null }">
      <template v-if="showCancel">
        <i
          v-if="showLeftIcon"
          v-button
          :class="leftIcon"
          :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
          @click="$emit('leftIconClicked')"
        />
        <span
          v-if="leftText != null"
          v-button
          :class="{ 'pl-1': leftIcon != null }"
          :aria-label="'ARIA_LABEL_BACK' | fromTextKey"
          @click="$emit('leftTextClicked')"
        >
          <i v-if="leftTextIcon != null" :class="leftTextIcon" />
          {{ leftText | fromTextKey }}
        </span>
      </template>
    </div>
    <div v-if="showHeader" class="text-truncate mid-column text-center">
      <i v-if="headerIcon != null" :class="headerIcon" class="pr-2" />
      <template v-if="!headerTextFromTextkey">
        {{ text }}
      </template>
      <template v-if="headerTextFromTextkey">
        {{ textKey | fromTextKey }}
      </template>
    </div>
    <div class="column last-column d-flex justify-content-end">
      <template v-if="showOk">
        <div class="d-flex align-items-center">
          <i v-if="rightTextIcon != null" class="mr-1" :class="rightTextIcon" />
          <span
            v-if="rightText != null"
            v-button
            class="last-column-text-container d-flex align-items-center justify-content-center"
            @click="$emit('rightTextClicked')"
          >
            <aula-spinner v-if="isLoading" class="aula-spinner" />

            {{ rightText | fromTextKey }}
          </span>
        </div>
        <i v-if="rightIcon != null" v-button :class="rightIcon" @click="$emit('rightIconClicked')" />
      </template>
    </div>
  </div>
</template>

<script>
import button from '../directives/button';

export default {
  directives: {
    button,
  },
  props: {
    isLoading: { type: Boolean, default: false },
    headerIcon: { type: String, default: null },
    showOk: { type: Boolean, default: true },
    showCancel: { type: Boolean, default: true },
    showHeader: { type: Boolean, default: true },
    showLeftIcon: { type: Boolean, default: true },
    leftIcon: { type: String, default: 'icon-Aula_app_arrow_slideback' },
    leftText: { type: String, default: null },
    leftTextIcon: { type: String, default: null },
    rightIcon: { type: String, default: null },
    rightText: { type: String, default: null },
    rightTextIcon: { type: String, default: null },
    text: { type: String, default: '' },
    textKey: { type: String, default: '' },
    headerTextFromTextkey: { type: Boolean, default: true },
    cssClass: { type: String, default: '' },
    backgroundColor: { type: String, default: '' },
    color: { type: String, default: '#ffffff' },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/_variables.scss';
@import '../assets/scss/core/_breakpoints.scss';
@import '../assets/scss/components/alerts/_alerts.scss';

.mobile-topbar-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 58px;
  height: 58px;
  width: 100vw;
  text-transform: uppercase;
  padding: 0 20px;
  background-color: var(--color-primary-darker);

  .first-column {
    max-width: 75px;
    margin-right: auto;
    padding: 0 5px;
  }

  .mid-column {
    padding: 0 5px;
    flex: 1;
    min-width: 0;
  }

  .last-column {
    max-width: 80px;

    .last-column-text-container {
      position: relative;
      min-height: 40px;
      min-width: 35px;
    }

    .aula-spinner {
      position: absolute;
      right: calc(50% - 30px);
      top: -10px;
      width: 60px;
      height: 60px;
    }
  }
}
</style>
