export const presenceStates = Object.freeze({
  NOT_PRESENT: 0, // Ikke kommet
  SICK: 1, // Syg
  REPORTED_ABSENT: 2, // Ferie/fri
  PRESENT: 3, // Til stede
  FIELDTRIP: 4, // På tur
  SLEEPING: 5, // Sover
  SPARE_TIME_ACTIVITY: 6, // Til aktivitet
  PHYSICAL_PLACEMENT: 7, // Fysisk placering
  CHECKED_OUT: 8, // Gået
});
