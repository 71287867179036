import { types } from '../types/types';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';

const state = {
  folders: [],
  files: [],
  getInstitutionDataPolicyName: false,
};

const getters = {
  [types.GETTER_COMMON_FILES_SET_FOLDERS]: state => state.folders,
  [types.GETTER_COMMON_FILES_LIST]: state => state.files,
  [types.GETTER_COMMON_FILES_INSTITUTION_DATA_POLICY_NAME]: state => state.getInstitutionDataPolicyName,
};

const mutations = {
  [types.MUTATION_COMMON_FILES_SET_LIST]: (state, payload) => {
    state.files = payload;
  },
  [types.MUTATE_GET_MORE_COMMON_FILES]: (state, payload) => {
    const currentDocuments = state.files.commonFiles;
    const newDocuments = payload.commonFiles;
    state.files.commonFiles = currentDocuments.concat(newDocuments);
  },
  [types.MUTATION_COMMON_FILES_UPDATE]: (state, payload) => {
    const file = state.files.filter(item => item.id === payload.id)[0];
    if (file) {
      file.title = payload.title;
      file.groupRestrictions = payload.groupRestrictions;
      file.profileTypeRestrictions = payload.profileTypeRestrictions;
    }
  },
  [types.MUTATION_DELETE_COMMON_FILES]: (state, payload) => {
    state.files = state.files.filter(item => item.id !== payload.id);
  },
  [types.MUTATION_COMMON_FILES_INSTITUTION_HAS_DATA_POLICY]: (state, payload) => {
    state.getInstitutionDataPolicyName = payload;
  },
};

const actions = {
  [types.ACTION_GET_COMMON_FILES_LIST]: ({ commit }, payload) =>
    portal
      .get('?method=commonFiles.getCommonFiles', { params: payload })
      .then(response => commit(types.MUTATION_COMMON_FILES_SET_LIST, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_COMMON_FILES');
      }),
  [types.ACTION_GET_MORE_COMMON_FILES]: ({ commit }, payload) =>
    portal
      .get('?method=commonFiles.getCommonFiles', { params: payload })
      .then(response => commit(types.MUTATE_GET_MORE_COMMON_FILES, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_COMMON_FILES');
      }),
  [types.ACTION_COMMON_FILES_DELETE]: ({ commit }, payload) =>
    portal
      .post('?method=commonFilesAdmin.deleteCommonFile', { id: payload.id })
      .then(() => {
        commit(types.MUTATION_DELETE_COMMON_FILES, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'FILE_DELETED_SUCCESS');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_COMMON_FILE');
      }),
  [types.ACTION_COMMON_FILES_UPDATE]: ({ commit }, payload) =>
    portal
      .post('?method=commonFilesAdmin.updateCommonFile', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'FILE_UPDATED_SUCCESS');
        return response.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_COMMON_FILE');
      }),
  [types.ACTION_COMMON_FILES_CREATE]: ({ commit }, payload) =>
    portal
      .post('?method=commonFilesAdmin.createCommonFile', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'FILE_CREATED_SUCCESS');
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_COMMON_FILE');
      }),
  [types.ACTION_COMMON_FILES_CHECK_EXISTING_TITLE]: ({ commit }, payload) =>
    portal
      .get('?method=commonFilesAdmin.titleExistsOnInstitution', {
        params: payload,
      })
      .then(response => response.data.data)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_TITLE_EXIST_ON_INSTITUTION');
      }),
  [types.ACTION_COMMON_FILES_GET_ONE]: ({ commit }, payload) =>
    portal
      .get('?method=commonFilesAdmin.getCommonFileById', { params: payload })
      .then(response => response.data.data)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_COMMON_FILE');
      }),
  [types.ACTION_COMMON_FILES_INSTITUTION_HAS_DATA_POLICY]: ({ commit }, payload) =>
    portal
      .get('?method=commonFilesAdmin.getInstitutionDataPolicyName', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATION_COMMON_FILES_INSTITUTION_HAS_DATA_POLICY, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_INSTITUTION_POLICY_NAME');
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
