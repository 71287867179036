import { types } from '../../../src/store/types/types';
import { eventTypeEnum } from '../../enums/eventTypeEnum';
import { eventClasses } from '../../enums/eventClasses';
import { portalRoles } from '../../enums/portalRoles';
import { docTypes } from '../../enums/docTypes';
import { parentTypes } from '../../enums/parentTypes';
import { lessonStatus } from '../../enums/lessonStatus';
import { httpStatus } from '../../enums/httpStatus';
import moment from 'moment-timezone';
import { portal } from '../../assets/plugins/axios/axios';
import Vue from 'vue';
import { errorSubCodeTypes } from '../../enums/errorSubCodeTypes';
import union from 'lodash/union';
import * as dateUtil from '../../utils/dateUtil';
import { dateFormatEnum } from '../../enums/dateFormatEnum';

const state = {
  currentWeek: moment().isoWeekYear() + '-W' + moment().isoWeek(),
  calendarActiveInstProfileId: null,
  calendarEvents: [],
  birthdayEvents: [],
  eventTypes: [],
  eventTypesForFilter: [],
  toggleEventForm: false,
  conflictEventByAttendees: [],
  isEditEvent: false,
  summedEvents: {},
  importantDates: {},
  eventSelectedFromSearch: {},
  currentEvent: {},
  assignedDelegatedAccesses: [],
  myDelegatedAccesses: [],
  delegatedContext: {},
  dateTimeSelectedByClickedTimeSlot: null,
  resources: [],
  myVacationRequests: [],
  unavailableResources: [],
  calendarSyncConsent: [],
  calendarFeeds: [],
  vacationRequestResponses: [],
  calenderFeedEnabled: [],
  calendarFeedEvents: [],
  isLoadingEvents: false,
};

const getters = {
  [types.GET_VACATION_REQUEST_RESPONSES]: state => state.vacationRequestResponses,
  [types.GET_DATETIME_SELECTED_BY_CLICKED_TIMESLOT]: state => state.dateTimeSelectedByClickedTimeSlot,
  [types.GET_CALENDAR_ACTIVE_INST_PROFILE_ID]: state => state.calendarActiveInstProfileId,
  [types.GET_EVENTS]: state => state.calendarEvents,
  [types.GET_BIRTHDAY_EVENTS]: state => state.birthdayEvents,
  [types.GET_WARNING_CONFLICT_EVENT_BY_ATTENDEES]: state => state.conflictEventByAttendees,
  [types.GET_CURRENT_EVENT]: state => state.currentEvent,
  [types.GET_EVENT_SELECTED_FROM_SEARCH]: state => state.eventSelectedFromSearch,
  [types.CALENDAR_GET_EVENT_TYPES_FOR_FILTER]: state => state.eventTypesForFilter,
  [types.GET_IMPORTANT_DATES]: state => state.importantDates,
  [types.GET_ASSIGNED_DELEGATED_ACCESSES]: state => state.assignedDelegatedAccesses,
  [types.GET_MY_DELEGATED_ACCESSES]: state => state.myDelegatedAccesses,
  [types.GET_CURRENT_WEEK]: state => state.currentWeek,
  [types.GET_DELEGATED_CONTEXT]: state => state.delegatedContext,
  [types.GET_RESOURCES]: state => state.resources,
  [types.GET_MY_VACATION_REQUESTS]: state => state.myVacationRequests,
  [types.GET_UNAVAILABLE_RESOURCES]: state => state.unavailableResources,
  [types.GET_CALENDAR_SYNC_CONSENT]: state => state.calendarSyncConsent,
  [types.GET_CALENDAR_FEEDS]: state => state.calendarFeeds,
  [types.GET_MUNICIPAL_CALENDER_FEED]: state => state.calenderFeedEnabled,
  [types.GET_CALENDAR_FEED_EVENTS]: state => state.calendarFeedEvents,
  [types.IS_LOADING_EVENTS]: state => state.isLoadingEvents,
};

const mutations = {
  [types.MUTATE_RESET_ACTIVE_EVENT]: state => {
    state.currentEvent = {};
  },
  [types.MUTATE_VACATION_REQUEST_RESPONSES]: (state, payload) => {
    state.vacationRequestResponses = payload.responses;
  },
  [types.MUTATE_CALENDAR_FEED_EVENTS]: (state, payload) => {
    state.calendarFeedEvents = payload;
  },
  [types.MUTATE_DATETIME_SELECTED_BY_CLICKED_TIMESLOT]: (state, payload) => {
    state.dateTimeSelectedByClickedTimeSlot = payload;
  },
  [types.MUTATE_CALENDAR_ACTIVE_INST_PROFILE_ID]: (state, payload) => {
    state.calendarActiveInstProfileId = payload;
  },
  [types.MUTATE_CLEAR_EVENTS]: (state, payload) => {
    if (payload != null && payload.id != null) {
      state.calendarEvents = state.calendarEvents.filter(event => event.id != payload.id);
    } else {
      state.calendarEvents = [];
      state.summedEvents = [];
    }
  },
  [types.MUTATE_EVENT_SELECTED_FROM_SEARCH]: (state, payload) => {
    state.eventSelectedFromSearch = payload;
  },
  [types.MUTATE_GET_EVENT_BY_ID]: (state, payload) => {
    payload.start = payload.startDateTime;
    payload.end = payload.endDateTime;
    state.currentEvent = payload;
  },
  [types.MUTATE_LOAD_EVENT_TYPES]: (state, payload) => {
    state.eventTypes = payload;
  },
  [types.MUTATE_LOAD_EVENT_TYPES_FOR_FILTER]: (state, payload) => {
    state.eventTypesForFilter = payload;
  },
  [types.MUTATE_WARNING_CONFLICT_EVENT_BY_ATTENDEES]: (state, payload) => {
    state.conflictEventByAttendees = payload;
  },
  [types.MUTATE_LOAD_BIRTHDAY_EVENTS]: (state, payload) => {
    if (payload.source === 'overview') {
      state.birthdayEvents = [];
    }
    const birthdayProfileIds = [];
    for (const event of payload.events) {
      if (!birthdayProfileIds.includes(event.institutionProfileId)) {
        birthdayProfileIds.push(event.institutionProfileId);
        event.title = event.name + ' (' + event.mainGroupName + ')';
        event.type = eventTypeEnum.BIRTHDAY;
        event.allDay = true;
        if (payload.groupId) {
          // Use for Group Calendar
          event.resourceIds = [docTypes.GROUP.toLowerCase() + '-' + payload.groupId];
          event.belongsTo = [docTypes.GROUP.toLowerCase() + '-' + payload.groupId];
        } else {
          event.resourceIds = [docTypes.PROFILE.toLowerCase() + '-' + event.institutionProfileId];
          event.belongsTo = [docTypes.PROFILE.toLowerCase() + '-' + payload.calendarInstProfileId];

          if (event.relatedChildrenIds && event.relatedChildrenIds.length > 0) {
            event.resourceIds = event.relatedChildrenIds.map(Id => docTypes.PROFILE.toLowerCase() + '-' + Id);
          }
        }
        event.start = moment(event.birthday).year(moment(payload.start).year()).format('YYYY-MM-DD');
        event.end = moment(event.birthday).year(moment(payload.start).year()).format('YYYY-MM-DD');
        event.id = event.institutionProfileId;
        if (payload.source === 'calendar') {
          addEventToState(event, payload.start);
        } else {
          state.birthdayEvents.push(event);
        }
      }
    }
    addSummedEventsToCalendar();
  },
  [types.MUTATE_LOAD_EVENTS_BY_PROFILEID]: (state, payload) => {
    for (const event of payload.events) {
      event.eventId = event.id;
      const belongsTo = [];
      if (payload.showVacationRegistrationEvents === false && event.type === eventTypeEnum.VACATION_REGISTRATION) {
        continue;
      }

      if (event.type === eventTypeEnum.PRESENCE_HOLIDAY) {
        event.type = eventTypeEnum.HOLIDAY;
      }

      // If the event is set to all day, remove the hour and minutes from the start and end
      if (event.allDay) {
        // start and end is the format that fullcalendar accepts, whereas startDateTime and endDateTime is the format we send to backend
        event.start = moment(event.startDateTime).format('YYYY-MM-DD');
        event.end = moment(event.endDateTime).add(1, 'minutes').format('YYYY-MM-DD');
      } else {
        event.start = moment(event.startDateTime).format('YYYY-MM-DDTHH:mm:ss');
        event.end = moment(event.endDateTime).format('YYYY-MM-DDTHH:mm:ss');
      }

      event.className = event.type;
      // Set belongsTo on the event. Needed to know who (profile,group,resource) the event belongs to
      if (payload.parent === parentTypes.GROUP) {
        event.belongsTo = [docTypes.GROUP.toLowerCase() + '-' + payload.groupId];
      } else if (payload.parent === parentTypes.NOTICE_BOARDS) {
        event.belongsTo = [parentTypes.NOTICE_BOARDS + '-' + payload.noticeBoardId];
      } else {
        event.belongsTo = event.belongsToProfiles
          .map(res => docTypes.PROFILE.toLowerCase() + '-' + res)
          .concat(event.belongsToResources.map(res => docTypes.RESOURCE.toLowerCase() + '-' + res));
      }
      // If the event if fetched through an institution profile that is not the active one, set the event resource id to the active institution profile id
      for (let instProfileId of event.belongsTo) {
        if (
          payload.institutions.filter(
            inst => docTypes.PROFILE.toLowerCase() + '-' + inst.institutionProfileId == instProfileId
          ).length > 0 &&
          payload.parent === parentTypes.PROFILE
        ) {
          instProfileId = docTypes.PROFILE.toLowerCase() + '-' + payload.profile.id;
        }
        if (!belongsTo.includes(instProfileId)) {
          belongsTo.push(instProfileId);
        }
        if (
          payload.children != null &&
          payload.children.filter(child => docTypes.PROFILE.toLowerCase() + '-' + child.id == instProfileId).length >
            0 &&
          payload.parent === parentTypes.PROFILE
        ) {
          const childObj = payload.children.find(
            child => docTypes.PROFILE.toLowerCase() + '-' + child.id === instProfileId
          );
          // Check if child has more than one institution profile
          const childProfiles = payload.children.filter(child => child.profileId === childObj.profileId);
          if (childProfiles.length > 0) {
            childProfiles.forEach(profile => {
              const profileId = docTypes.PROFILE.toLowerCase() + '-' + profile.id;
              if (!belongsTo.includes(profileId)) {
                belongsTo.push(profileId);
              }
            });
          }
        }
      }
      // If event belongs to institution
      if (event.addedToInstitutionCalendar) {
        belongsTo.push(event.institutionCode);
        event.belongsTo.push(event.institutionCode);
      }
      event.resourceIds = belongsTo;
      if (event.type === eventTypeEnum.VACATION_REGISTRATION) {
        event.eventClass = eventClasses.BASIC;
        for (const vacationDate of event.vacationChildrenCountByDates) {
          if (moment(vacationDate.date).isAfter(payload.start) && moment(vacationDate.date).isBefore(payload.end)) {
            const vacationEvent = Object.assign({}, event);
            vacationEvent.start = vacationDate.date;
            vacationEvent.end = vacationDate.date;
            vacationEvent.title = `${Vue.filter('fromTextKey')('CALENDAR_VACATION_REQUEST_ON_VACATION_1')} ${
              vacationDate.childrenAreComing
            }/${vacationDate.total}
              ${Vue.filter('fromTextKey')('CALENDAR_VACATION_REQUEST_ON_VACATION_2')} (${event.institutionName})`;
            addEventToState(vacationEvent, payload.start);
          }
        }
      } else if (event.lesson != null) {
        // If the event is a lesson event we just add it to the calendar
        event.eventClass = eventClasses.LESSON;
        if (event.lesson.lessonStatus === lessonStatus.CANCELLED) {
          event.className += ' deleted-lesson';
        }
        addEventToState(event, payload.start);
      } else if (event.lesson == null && event.timeSlot == null) {
        if (event.requiresNewAnswer && event.responseRequired) {
          event.className += ' event-waiting-response';
        }
        if (
          event.oldStartDateTime != null &&
          event.oldStartDateTime != event.startDateTime &&
          event.requiresNewAnswer &&
          event.responseRequired
        ) {
          const oldEvent = Object.assign({}, event);
          oldEvent.allDay = oldEvent.oldAllDay;
          if (oldEvent.allDay) {
            oldEvent.start = moment(oldEvent.oldStartDateTime).format('YYYY-MM-DD');
            oldEvent.end = moment(oldEvent.oldEndDateTime).add(1, 'minutes').format('YYYY-MM-DD');
          } else {
            oldEvent.start = moment(oldEvent.oldStartDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
            oldEvent.end = moment(oldEvent.oldEndDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
          }
          // We need a dummy id for the old event
          oldEvent.id = oldEvent.id + '-old';
          oldEvent.className += ' old-event';
          addEventToState(oldEvent, payload.start);
          event.className += ' new-event';
        }
        // Add the original event to calendar
        event.eventClass = eventClasses.BASIC;
        addEventToState(event, payload.start);
      } else if (event.timeSlot != null) {
        event.eventClass = eventClasses.TIMESLOT;
        calculateTimeslotEvents(event, payload);
      }
    }

    addSummedEventsToCalendar();
  },
  [types.MUTATE_LOAD_IMPORTANT_DATES]: (state, payload) => {
    state.importantDates = [];
    for (const importantDate of payload.events) {
      importantDate.belongsTo = [];
      if (importantDate.timeSlot != null) {
        calculateTimeslotEvents(importantDate, payload, true);
      } else {
        importantDate.start = moment(importantDate.startDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        importantDate.end = moment(importantDate.endDateTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
        addEventToState(importantDate, payload.start, true);
      }
    }
  },
  [types.MUTATE_LOAD_ASSIGNED_DELEGATED_ACCESSES]: (state, payload) => {
    state.assignedDelegatedAccesses = payload;
  },
  [types.MUTATE_LOAD_EVENTS_FOR_ADMINISTRATION]: (state, payload) => {
    state.calendarEvents = payload;
  },
  [types.MUTATE_LOAD_MY_DELEGATED_ACCESSES]: (state, payload) => {
    state.myDelegatedAccesses = payload;
  },
  [types.MUTATE_CURRENT_WEEK]: (state, payload) => {
    if (payload.week != null) {
      state.currentWeek = payload.week;
    } else {
      const startDate = moment(payload.start).format('YYYY-MM-DD');
      const startWeek = moment(startDate).isoWeek();
      const startYear = moment(startDate).isoWeekYear();

      state.currentWeek = startYear + '-W' + startWeek;
    }
  },
  [types.MUTATE_LOAD_DELEGATED_CONTEXT]: (state, delegatedContext) => {
    state.delegatedContext = delegatedContext;
  },
  [types.MUTATE_LIST_RESOURCES]: (state, payload) => {
    state.resources = payload;
  },
  [types.MUTATE_MY_VACATION_REQUESTS]: (state, payload) => {
    state.myVacationRequests = payload;
  },
  [types.MUTATE_UPDATE_MY_VACATION_REQUESTS_AFTER_REMOVING]: (state, payload) => {
    state.myVacationRequests = state.myVacationRequests.filter(v => v.id != payload.id);
  },
  [types.MUTATE_UNAVAILABLE_RESOURCES]: (state, payload) => {
    state.unavailableResources = payload;
  },
  [types.MUTATE_CALENDAR_SYNC_CONSENT]: (state, payload) => {
    state.calendarSyncConsent = payload;
  },
  [types.MUTATE_CALENDAR_FEEDS]: (state, payload) => {
    state.calendarFeeds = payload;
  },
  [types.MUTATE_GET_MUNICIPAL_CALENDER_FEED]: (state, payload) => {
    state.calenderFeedEnabled = payload;
  },
  [types.MUTATE_IS_LOADING_EVENTS]: (state, payload) => {
    state.isLoadingEvents = payload;
  },
};

function calculateTimeslotEvents(event, payload, importantDates = false) {
  for (const timeSlot of event.timeSlot.timeSlots) {
    // If the current profile is the owner of the timeslot event, show it in calendar
    const isActiveInstitutionProfileCreator =
      payload.institutions.find(inst => inst.institutionProfileId == payload.calendarActiveInstProfileId) != null &&
      payload.institutions.find(inst => inst.institutionProfileId == event.creatorInstProfileId) != null;
    const isDisposalCalendarCreator =
      payload.institutions.find(inst => inst.institutionProfileId == payload.calendarActiveInstProfileId) == null &&
      payload.calendarActiveInstProfileId == event.creatorInstProfileId;
    const isEventCreator =
      payload.instProfileIds && payload.instProfileIds.some(id => id == event.creatorInstProfileId);
    const isAddedToInstitutionCalendar =
      event.addedToInstitutionCalendar &&
      payload.institutions.find(inst => inst.institutionProfileId == payload.calendarActiveInstProfileId) == null;
    const isTimeslotForEvent =
      event.belongsToResources &&
      event.belongsToResources.length > 0 &&
      event.belongsToResources.includes(timeSlot.belongsToResource);
    const isEventOwner =
      event.directlyRelated && event.belongsToProfiles.some(profileId => payload.instProfileIds.includes(profileId));
    const isEmployee = payload.profile.role === portalRoles.EMPLOYEE;
    const canHandleModifyTimeSlot =
      isActiveInstitutionProfileCreator ||
      isDisposalCalendarCreator ||
      isEventCreator ||
      isAddedToInstitutionCalendar ||
      isTimeslotForEvent ||
      isEventOwner;
    if (canHandleModifyTimeSlot && isEmployee) {
      const timeSlotEvent = Object.assign({}, event);
      if (timeSlotEvent.resourceIds != null) {
        // Make sure that the timeslot only relates to the resource that is booked on the timeslot
        timeSlotEvent.resourceIds = timeSlotEvent.resourceIds.filter(res => !res.includes('resource'));
        timeSlotEvent.belongsTo = timeSlotEvent.belongsTo.filter(res => !res.includes('resource'));
        timeSlotEvent.resourceIds.push('resource-' + timeSlot.belongsToResource);
        timeSlotEvent.belongsTo.push('resource-' + timeSlot.belongsToResource);
      }
      // Use the start and end time from the timeslot
      timeSlotEvent.start = moment(timeSlot.startDate).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      timeSlotEvent.end = moment(timeSlot.endDate).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
      addEventToState(timeSlotEvent, payload.start, importantDates);

      // handle profile resources in answered timeslots of an event
      if (payload.instProfileIds.length > 1) {
        populateEventFromAnsweredTimeSlotEvents(event, timeSlot, payload, importantDates);
      }
    } else {
      populateEventFromAnsweredTimeSlotEvents(event, timeSlot, payload, importantDates);
    }
  }
}

function populateEventFromAnsweredTimeSlotEvents(event, timeSlot, payload, importantDates) {
  const timeSlotResourcesId = {};
  for (const answer of timeSlot.answers) {
    let hasConcerningProfile = false;
    // Find answer for open calendars (other employees)
    if (
      payload.instProfileIds.find(id => id == answer.concerningProfileId) != null ||
      payload.instProfileIds.find(id => id == answer.instProfileId) != null
    ) {
      hasConcerningProfile = payload.instProfileIds.find(id => id == answer.concerningProfileId) != null;
      // Find answer for own profile
    } else if (
      payload.institutions.find(inst => inst.institutionProfileId == payload.calendarActiveInstProfileId) != null
    ) {
      hasConcerningProfile =
        payload.institutions.find(inst => inst.institutionProfileId == answer.concerningProfileId) != null;
    } else {
      // Find answer for delegated access
      hasConcerningProfile = payload.calendarActiveInstProfileId == answer.concerningProfileId;
    }
    if (hasConcerningProfile) {
      timeSlotResourcesId[answer.selectedTimeSlotIndex] === undefined
        ? (timeSlotResourcesId[answer.selectedTimeSlotIndex] = [])
        : '';
    }

    if (
      hasConcerningProfile &&
      timeSlotResourcesId[answer.selectedTimeSlotIndex].find(profileId => profileId == answer.concerningProfileId) !==
        null
    ) {
      timeSlotResourcesId[answer.selectedTimeSlotIndex].push(parseInt(answer.concerningProfileId));
    }

    if (event.type == eventTypeEnum.SCHOOL_HOME_MEETING || event.type == eventTypeEnum.PARENTAL_MEETING) {
      if (payload.children != null && payload.children.length > 0) {
        const concerningProfileId = payload.children.find(child => child.id === answer.concerningProfileId)?.profileId;
        const concerningInstitutionProfileIds = payload.children
          .filter(child => child.profileId === concerningProfileId)
          .map(child => child.id);

        hasConcerningProfile =
          event.belongsToProfiles.includes(answer.concerningProfileId) && concerningProfileId != null;
        if (hasConcerningProfile) {
          timeSlotResourcesId[answer.selectedTimeSlotIndex] = union(
            timeSlotResourcesId[answer.selectedTimeSlotIndex],
            concerningInstitutionProfileIds
          );
        }
      } else {
        hasConcerningProfile =
          event.belongsToProfiles.includes(answer.concerningProfileId) &&
          payload.institutions.find(inst => inst.institutionProfileId == answer.concerningProfileId) != null;
        if (
          hasConcerningProfile &&
          timeSlotResourcesId[answer.selectedTimeSlotIndex] != null &&
          timeSlotResourcesId[answer.selectedTimeSlotIndex].find(instId => instId == answer.concerningProfileId) == null
        ) {
          timeSlotResourcesId[answer.selectedTimeSlotIndex].push(parseInt(answer.concerningProfileId));
        }
      }
    }
  }
  for (const timeslotIndex in timeSlotResourcesId) {
    const basicEvent = Object.assign({}, event);
    basicEvent.selectedTimeSlotId = timeSlot.id;
    // we re-calculate the resourceIds & belongsTo
    // because only the resource that relates to timeSlot Answer should see the event.
    if (payload.parent == parentTypes.GROUP) {
      basicEvent.resourceIds = [docTypes.GROUP.toLowerCase() + '-' + payload.groupId];
      basicEvent.belongsTo = [docTypes.GROUP.toLowerCase() + '-' + payload.groupId];
    } else {
      const belongsTo = [];
      for (const resource of timeSlotResourcesId[timeslotIndex]) {
        if (
          payload.institutions.filter(inst => inst.institutionProfileId == resource).length > 0 &&
          payload.parent == parentTypes.PROFILE &&
          !belongsTo.includes(String('profile-' + payload.profile.id))
        ) {
          belongsTo.push(String('profile-' + payload.profile.id));
        } else if (!belongsTo.includes(String('profile-' + resource))) {
          belongsTo.push(String('profile-' + resource));
        }
      }
      basicEvent.resourceIds = belongsTo;
      basicEvent.belongsTo = belongsTo;
    }

    basicEvent.start = moment(timeSlot.timeSlotIndexes[timeslotIndex].startTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
    basicEvent.end = moment(timeSlot.timeSlotIndexes[timeslotIndex].endTime).format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
    // Fullcalendar need a unique id for each timeslot
    basicEvent.id = `${basicEvent.id}-${timeSlot.id}-${basicEvent.belongsTo}`;

    basicEvent.eventClass = eventClasses.BASIC;
    addEventToState(basicEvent, payload.start, importantDates);
  }
}

// Add the event to the calendar
function addEventToState(event, calendarStartDate, importantDates = false) {
  event.uniqueId = event.id + '-' + moment(event.start).unix();
  if (importantDates) {
    if (
      state.importantDates.filter(evt => evt.uniqueId == event.uniqueId).length === 0 &&
      moment(event.start).isAfter(moment(), 'day')
    ) {
      state.importantDates.push(event);
    }
  } else {
    if (state.calendarEvents.filter(evt => evt.uniqueId == event.uniqueId).length === 0) {
      const index = dateUtil.format(event.start, 'DD-MM-YYYY');
      if (event.resourceIds != null) {
        event.parentId = index + '-' + event.type;
      }
      state.calendarEvents.push(event);
      addEventToSummedEvents(index, event, event.start);
      // If the event last more than 1 day, we need to add a summed event for each day
      if (moment(event.end).diff(moment(event.start), 'days') >= 1) {
        addSummedEventForEachDayEventSpans(event, calendarStartDate, index);
      }
    } else {
      const existingEvent = state.calendarEvents.find(evt => evt.uniqueId == event.uniqueId);
      if (existingEvent.resourceIds != null && event.resourceIds != null) {
        existingEvent.resourceIds = [...new Set(existingEvent.resourceIds.concat(event.resourceIds))];
      }
      if (existingEvent.belongsTo != null && event.belongsTo != null) {
        existingEvent.belongsTo = [...new Set(existingEvent.belongsTo.concat(event.belongsTo))];
      }
      if (existingEvent.belongsToProfiles != null && event.belongsToProfiles != null) {
        existingEvent.belongsToProfiles = [...new Set(existingEvent.belongsToProfiles.concat(event.belongsToProfiles))];
      }
      if (existingEvent.belongsToResources != null && event.belongsToResources != null) {
        existingEvent.belongsToResources = [
          ...new Set(existingEvent.belongsToResources.concat(event.belongsToResources)),
        ];
      }
    }
  }
}

function addSummedEventForEachDayEventSpans(event, calendarStartDate, index) {
  const multipleDaysEvent = Object.assign({}, event);
  if (multipleDaysEvent.allDay) {
    multipleDaysEvent.end = moment(multipleDaysEvent.end).subtract(1, 'minutes').format('YYYY-MM-DD');
  }
  if (dateUtil.isBefore(multipleDaysEvent.start, calendarStartDate)) {
    multipleDaysEvent.start = dateUtil.format(calendarStartDate, dateFormatEnum.COMPLETE_DATE);
  }
  const maxShownCalendarDays = 42;
  const eventDays =
    Math.min(dateUtil.getDaysDifference(multipleDaysEvent.start, multipleDaysEvent.end), maxShownCalendarDays) + 1;
  for (let i = 0; i < eventDays; i++) {
    const date = dateUtil.add(multipleDaysEvent.start, i, 'days');
    index = dateUtil.format(date, 'DD-MM-YYYY');
    addEventToSummedEvents(index, multipleDaysEvent, date);
  }
}

// Summed events are the events shown in the monthly view that shows a count of each event type
function addEventToSummedEvents(index, event, startDate) {
  if (typeof state.summedEvents[index] === 'undefined') {
    state.summedEvents[index] = [{ type: event.type, events: [event], startDate: startDate }];
  } else {
    let typeExists = false;
    for (const obj of state.summedEvents[index]) {
      if (obj.type == event.type) {
        typeExists = true;
        if (obj.events.filter(evt => evt.id == event.id).length == 0) {
          obj.events.push(event);
        }
      }
    }
    if (!typeExists) {
      state.summedEvents[index].push({
        type: event.type,
        events: [event],
        startDate: startDate,
      });
    }
  }
}

function addSummedEventsToCalendar() {
  // Run through all the summed events and add them to the calendar
  for (const key in state.summedEvents) {
    for (const sumevt of state.summedEvents[key]) {
      const resources = sumevt.events.map(evt => evt.resourceIds);
      for (const resource of resources) {
        const count = sumevt.events.length;
        const directlyRelated = sumevt.events.filter(evt => evt.directlyRelated).length > 0;
        const calSumEvent = {
          creatorProfileId: sumevt.events[0].creatorProfileId,
          creatorInstProfileId: sumevt.events[0].creatorInstProfileId,
          belongsTo: sumevt.events[0].belongsTo,
          resourceIds: resource,
          start: sumevt.startDate,
          title: sumevt.type,
          count: count,
          type: 'sum-' + sumevt.type,
          id: key + '-' + sumevt.type,
          uniqueId: key + '-' + sumevt.type,
          response: 'accepted',
          displayEventTime: false,
          allDay: true,
          className: 'sum-event',
          directlyRelated: directlyRelated,
        };
        const sumCalendarEvent = state.calendarEvents.filter(evt => evt.id === calSumEvent.id);
        if (sumCalendarEvent.length === 0) {
          state.calendarEvents.push(calSumEvent);
        } else {
          sumCalendarEvent[0].resourceIds = [...new Set([...sumCalendarEvent[0].resourceIds, ...resource])];
        }
      }
    }
  }
}

const actions = {
  [types.RESET_ACTIVE_EVENT]: ({ commit }) => {
    commit(types.MUTATE_RESET_ACTIVE_EVENT);
  },
  [types.CHECK_VACATION_REQUEST_ANSWERED]: ({ commit }, payload) =>
    portal
      .get('?method=calendar.isVacationRequestAnswered', { params: payload })
      .then(response => response.data.data)
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
        throw error;
      }),
  [types.RESPOND_TO_EVENT]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.respondToEvent', payload)
      .then(response => response)
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_RESPOND_TO_EVENT');
        return error.response.data.status;
      }),
  [types.MODIFY_DATETIME_SELECTED_BY_CLICKED_TIMESLOT]: ({ commit }, payload) => {
    commit(types.MUTATE_DATETIME_SELECTED_BY_CLICKED_TIMESLOT, payload);
  },
  [types.SET_EVENT_SELECTED_FROM_SEARCH]: ({ commit }, payload) => {
    commit(types.MUTATE_EVENT_SELECTED_FROM_SEARCH, payload);
  },
  [types.SET_CURRENT_WEEK]: ({ commit }, payload) => {
    commit(types.MUTATE_CURRENT_WEEK, payload);
  },
  [types.CLEAR_EVENTS]: ({ commit }, payload) => {
    commit(types.MUTATE_CLEAR_EVENTS, payload);
  },
  [types.LOAD_VACATION_REQUEST_RESPONSES]: ({ commit }, payload) =>
    portal
      .get('?method=calendar.getVacationRequestResponses', { params: payload })
      .then(response =>
        commit(types.MUTATE_VACATION_REQUEST_RESPONSES, {
          responses: response.data.data,
        })
      )
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
      }),
  [types.LOAD_EVENTS_BY_GROUPHOME]: ({ commit, rootState }, payload) =>
    portal
      .post('?method=calendar.getEventsByGroupHomeChildPairs', {
        start: payload.start.format('YYYY-MM-DD HH:mm:ss.SSSSZ'),
        end: payload.end.format('YYYY-MM-DD HH:mm:ss.SSSSZ'),
        groupHomeChildPairs: payload.groupHomeChildPairs,
      })
      .then(response => {
        commit(types.MUTATE_CURRENT_WEEK, { start: payload.start });
        return commit(types.MUTATE_LOAD_EVENTS_BY_PROFILEID, {
          events: response.data.data,
          parent: 'profile',
          instProfileIds: payload.instProfileIds,
          profile: rootState.globalShared.currentProfile,
          children: rootState.global.children,
          institutions: rootState.globalShared.institutions,
          end: payload.end,
          start: payload.start,
          calendarActiveInstProfileId:
            payload.calendarActiveInstProfileId != null ? payload.calendarActiveInstProfileId : null,
          showVacationRegistrationEvents: payload.showVacationRegistrationEvents,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
      }),
  [types.LOAD_EVENTS_BY_PROFILEID]: ({ commit, rootState }, payload) => {
    const startFormat = payload.start.format('YYYY-MM-DD HH:mm:ss.SSSSZ');
    const endFormat = payload.end.format('YYYY-MM-DD HH:mm:ss.SSSSZ');
    if (rootState.global.isPreviewMode) {
      commit(types.MUTATE_LOAD_EVENTS_BY_PROFILEID, {
        events: [],
        parent: 'profile',
        instProfileIds: payload.instProfileIds,
        profile: rootState.globalShared.currentProfile,
        children: rootState.global.children,
        institutions: rootState.globalShared.institutions,
        end: payload.end,
        start: payload.start,
      });
    } else if (startFormat != null && endFormat != null) {
      commit(types.MUTATE_IS_LOADING_EVENTS, true);
      return portal
        .post('?method=calendar.getEventsByProfileIdsAndResourceIds', {
          instProfileIds: payload.instProfileIds,
          resourceIds: payload.resourceIds,
          start: startFormat,
          end: endFormat,
        })
        .then(response => {
          commit(types.MUTATE_CURRENT_WEEK, { start: payload.start });
          commit(types.MUTATE_IS_LOADING_EVENTS, false);
          return commit(types.MUTATE_LOAD_EVENTS_BY_PROFILEID, {
            events: response.data.data,
            parent: 'profile',
            instProfileIds: payload.instProfileIds,
            profile: rootState.globalShared.currentProfile,
            children: rootState.global.children,
            institutions: rootState.globalShared.institutions,
            end: payload.end,
            start: payload.start,
            calendarActiveInstProfileId:
              payload.calendarActiveInstProfileId != null ? payload.calendarActiveInstProfileId : null,
            showVacationRegistrationEvents: payload.showVacationRegistrationEvents,
          });
        })
        .catch(error => {
          if (error.response?.data.status.subCode !== errorSubCodeTypes.deactivatedInstitutionProfile) {
            commit(types.MUTATE_IS_LOADING_EVENTS, false);
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
          }
          return Promise.reject(error);
        });
    } else {
      commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
    }
  },
  [types.LOAD_EVENTS_FOR_ADMINISTRATION]: ({ commit }, payload) =>
    portal
      .get('?method=calendarAdmin.getEventsByProfileId', {
        params: {
          instProfileId: payload.instProfileId,
          start: payload.start.format('YYYY-MM-DD HH:mm:ss.SSSSZ'),
          end: payload.end.format('YYYY-MM-DD HH:mm:ss.SSSSZ'),
        },
      })
      .then(response => {
        commit(types.MUTATE_LOAD_EVENTS_FOR_ADMINISTRATION, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
      }),
  [types.LOAD_EVENTS_BY_INSTITUTIONS]: ({ commit, rootState }, payload) =>
    portal
      .get('?method=calendar.getEventsForInstitutions', {
        params: {
          instCodes: payload.instCodes,
          start: payload.start.format('YYYY-MM-DD HH:mm:ss.SSSSZ'),
          end: payload.end.format('YYYY-MM-DD HH:mm:ss.SSSSZ'),
        },
      })
      .then(response =>
        commit(types.MUTATE_LOAD_EVENTS_BY_PROFILEID, {
          events: response.data.data,
          parent: 'profile',
          instProfileIds: [],
          profile: rootState.globalShared.currentProfile,
          children: rootState.global.children,
          institutions: rootState.globalShared.institutions,
          end: payload.end,
          start: payload.start,
          calendarActiveInstProfileId:
            payload.calendarActiveInstProfileId != null ? payload.calendarActiveInstProfileId : null,
        })
      )
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
      }),
  [types.GET_EVENT_BY_ID]: ({ commit }, { eventId, occurrenceDateTime }) =>
    portal
      .get('?method=calendar.getEventById', {
        params: { eventId, occurrenceDateTime },
      })
      .then(response => {
        commit(types.MUTATE_GET_EVENT_BY_ID, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENT');
      }),
  [types.LOAD_BIRTHDAY_EVENTS]: ({ commit, rootState }, payload) => {
    let url = '?method=calendar.getBirthdayEventsForInstitutions';
    if (payload.groupId != null) {
      url = '?method=calendar.getBirthdayEventsForGroup';
    }
    if (rootState.global.isPreviewMode) {
      commit(types.MUTATE_LOAD_BIRTHDAY_EVENTS, {
        events: [],
        profile: rootState.globalShared.currentProfile,
        source: payload.source,
        groupId: payload.groupId,
        end: payload.end,
        start: payload.start,
        calendarInstProfileId: payload.calendarInstProfileId,
      });
    } else {
      return portal
        .get(url, {
          params: {
            groupId: payload.groupId,
            start: payload.start.format('YYYY-MM-DDT00:00:00.SSSSZ'),
            end: payload.end.format('YYYY-MM-DDT23:59:59.SSSSZ'),
            instCodes: payload.instCodes,
          },
        })
        .then(response =>
          commit(types.MUTATE_LOAD_BIRTHDAY_EVENTS, {
            events: response.data.data,
            profile: rootState.globalShared.currentProfile,
            source: payload.source,
            groupId: payload.groupId,
            end: payload.end,
            start: payload.start,
            calendarInstProfileId: payload.calendarInstProfileId,
          })
        )
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
        });
    }
  },
  [types.LOAD_EVENTS_BY_GROUPID]: ({ commit, rootState }, payload) => {
    if (rootState.global.isPreviewMode) {
      commit(types.LOAD_EVENTS_BY_GROUPID, {
        events: [],
        profile: rootState.globalShared.currentProfile,
        institutions: rootState.globalShared.institutions,
        parent: 'group',
        end: payload.end,
        start: payload.start,
      });
    } else {
      return portal
        .get('?method=calendar.geteventsbygroupid', {
          params: {
            groupId: payload.groupId,
            start: payload.start.format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
            end: payload.end.format('YYYY-MM-DDTHH:mm:ss.SSSSZ'),
          },
        })
        .then(response =>
          commit(types.MUTATE_LOAD_EVENTS_BY_PROFILEID, {
            events: response.data.data,
            profile: rootState.globalShared.currentProfile,
            institutions: rootState.globalShared.institutions,
            instProfileIds: [],
            parent: 'group',
            groupId: payload.groupId,
            end: payload.end,
            start: payload.start,
            calendarActiveInstProfileId:
              payload.calendarActiveInstProfileId != null ? payload.calendarActiveInstProfileId : null,
          })
        )
        .catch(error => {
          if (error.response.data.status.code === 403) {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_NOT_A_MEMBER');
            commit(types.MUTATE_REMOVE_GROUP_FROM_USER_GROUPS, payload.groupId);
          } else {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
          }
        });
    }
  },
  [types.CREATE_VACATION_REQUEST]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.createVacationRequest', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'CALENDAR_TOOLBAR_REQUEST_VACATION_SUCCESS');
        return response;
      })
      .catch(error => {
        createErrorMessage(error, commit);
        throw error;
      }),
  [types.LOAD_GUARDIAN_WITH_PENDING_VACATION_REQUEST_RESPONSES]: ({ commit }, payload) =>
    portal
      .get('?method=calendar.getGuardiansWithPendingVacationRequestResponses', {
        params: payload,
      })
      .then(response => response.data.data)
      .catch(error => {
        createErrorMessage(error, commit);
        throw error;
      }),
  [types.CREATE_SIMPLE_EVENT]: ({ commit }, payload) => {
    const clonedPayload = prepareEventPayload(payload);
    return portal
      .post('?method=calendar.createSimpleEvent', clonedPayload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, createToastMessage(clonedPayload));
        return response;
      })
      .catch(error => {
        createErrorMessage(error, commit);
        throw error;
      });
  },
  [types.UPDATE_SIMPLE_EVENT]: ({ commit }, payload) => {
    const clonedPayload = prepareEventPayload(payload);
    return portal
      .post('?method=calendar.updateSimpleEvent', clonedPayload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, createToastMessage(clonedPayload));
        return response;
      })
      .catch(error => {
        createErrorMessage(error, commit);
        return Promise.reject(error);
      });
  },
  [types.CREATE_REPEATING_EVENT]: ({ commit }, payload) => {
    const clonedPayload = prepareEventPayload(payload);
    return portal
      .post('?method=calendar.createRepeatingEvent', clonedPayload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, createToastMessage(clonedPayload));
        return response;
      })
      .catch(error => {
        createErrorMessage(error, commit);
        throw error;
      });
  },
  [types.UPDATE_REPEATING_EVENT]: ({ commit }, payload) => {
    const clonedPayload = prepareEventPayload(payload);
    return portal
      .post('?method=calendar.updateRepeatingEvent', clonedPayload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, getUpdatePresenceToastMessage(clonedPayload));
        return response;
      })
      .catch(error => {
        if (!error.response?.data.status.subCode) {
          createErrorMessage(error, commit);
        }
        return Promise.reject(error);
      });
  },
  [types.CREATE_TIMESLOT_EVENT]: ({ commit }, payload) => {
    const clonedPayload = prepareEventPayload(payload);
    return portal
      .post('?method=calendar.createTimeSlotEvent', clonedPayload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, createToastMessage(clonedPayload));
        return response;
      })
      .catch(error => {
        if (error.response.data.status.subCode === errorSubCodeTypes.coOrganizerAsInvitee) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_CO_ORGANIZER_AS_INVITEE');
        } else {
          createErrorMessage(error, commit);
          throw error;
        }
      });
  },
  [types.UPDATE_TIMESLOT_EVENT]: ({ commit }, payload) => {
    const clonedPayload = prepareEventPayload(payload);
    return portal
      .post('?method=calendar.updateTimeSlotEvent', clonedPayload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, createToastMessage(clonedPayload));
        return response;
      })
      .catch(error => {
        if (error.response.data.status.subCode === errorSubCodeTypes.coOrganizerAsInvitee) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_CO_ORGANIZER_AS_INVITEE');
        } else {
          createErrorMessage(error, commit);
          throw error;
        }
      });
  },
  [types.UPDATE_LESSON_EVENT]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.updateLessonEvent', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, createToastMessage(payload));
        return response;
      })
      .catch(error => {
        createErrorMessage(error, commit);
        throw error;
      }),
  [types.WARNING_CONFLICT_EVENT_BY_ATTENDEES]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.checkConflictEventForAttendees', payload)
      .then(response => {
        commit(types.MUTATE_WARNING_CONFLICT_EVENT_BY_ATTENDEES, response.data.data);
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CHECK_CONFLICTING_EVENTS');
      }),
  [types.CLEAN_WARNING_CONFLICT_EVENT_BY_ATTENDEES]: ({ commit }) => {
    commit(types.MUTATE_WARNING_CONFLICT_EVENT_BY_ATTENDEES, []);
  },
  [types.DELETE_EVENT]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.deleteEvent', {
        id: payload.eventForm.id,
        occurrenceDateTime: payload.occurrenceDateTime,
      })
      .then(() => {
        payload.isDeleteEvent = true;
        if (payload.type == eventTypeEnum.VACATION_REGISTRATION) {
          commit(types.MUTATE_UPDATE_MY_VACATION_REQUESTS_AFTER_REMOVING, {
            id: payload.eventForm.id,
          });
        }
        commit(types.MUTATE_SUCCESS_TEXT, createToastMessage(payload));
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_EVENT');
      }),
  [types.LOAD_EVENT_TYPES]: ({ commit }, payload) =>
    portal
      .get('?method=calendar.getEventTypes', {
        params: { filterInstitutionCodes: payload.filterInstitutionCodes },
      })
      .then(response => {
        if (payload.use == 'filter') {
          return commit(types.MUTATE_LOAD_EVENT_TYPES_FOR_FILTER, response.data.data);
        } else {
          return commit(types.MUTATE_LOAD_EVENT_TYPES, response.data.data);
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENT_TYPES');
      }),
  [types.EDIT_TIME_BY_DRAGGING_EVENT]: ({ dispatch }, payload) => {
    payload.oldStartDateTime = payload.startDateTime;
    payload.oldEndDateTime = payload.endDateTime;
    payload.startDateTime = payload.start;
    payload.endDateTime = payload.end;
    payload.primaryResourceId = null;
    payload.additionalResources = [];

    delete payload.source;

    if (payload.repeating) {
      return dispatch(types.UPDATE_REPEATING_EVENT, payload);
    }
    return dispatch(types.UPDATE_SIMPLE_EVENT, payload);
  },
  [types.UPDATE_DELEGATED_ACCESS]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.setDelegatedAccesses', {
        delegatedAccess: payload.delegatedAccess,
      })
      .then(() => {})
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_DELEGATED_ACCESS');
      }),
  [types.LOAD_IMPORTANT_DATES]: ({ commit, rootState }, payload) => {
    if (rootState.global.isPreviewMode) {
      commit(types.MUTATE_LOAD_IMPORTANT_DATES, { events: [] });
    } else {
      return portal
        .get('?method=calendar.getImportantDates', {
          params: { limit: payload.limit, include_today: false },
        })
        .then(response =>
          commit(types.MUTATE_LOAD_IMPORTANT_DATES, {
            events: response.data.data,
            parent: 'profile',
            instProfileIds: [],
            profile: rootState.globalShared.currentProfile,
            children: rootState.global.children,
            institutions: rootState.globalShared.institutions,
            end: moment().add(6, 'days'),
            start: moment(),
            calendarActiveInstProfileId: rootState.globalShared.currentProfile.id,
          })
        )
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_IMPORTANT_DATES');
        });
    }
  },
  [types.RESPOND_TO_SIMPLE_EVENT]: (
    { commit },
    {
      eventId,
      responseType,
      institutionProfileId,
      occurrenceDateTime,
      numberOfAdultParticipants,
      numberOfChildParticipants,
      hasNumberOfParticipantsChanged,
    }
  ) => {
    const params = {
      eventId: eventId,
      responseType: responseType,
      institutionProfileId: institutionProfileId,
      occurrenceDateTime,
      numberOfAdultParticipants,
      numberOfChildParticipants,
    };
    return portal
      .post('?method=calendar.respondToSimpleEvent', params)
      .then(response => {
        if (hasNumberOfParticipantsChanged) {
          commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_INVITATIONS_CHANGED');
        } else {
          commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_INVITATIONS_' + responseType.toUpperCase());
        }
        return response;
      })
      .catch(error => {
        if (error.response.data.status.subCode !== errorSubCodeTypes.exceedingMaximumParticipants) {
          if (error.response.data.status.code == 412) {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_RESPOND_TO_EVENT_DEADLINE');
          } else {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_RESPOND_TO_EVENT');
          }
        }
        return error;
      });
  },
  [types.RESPOND_TO_TIMESLOT_EVENT]: ({ commit, rootState }, payload) => {
    const activeGroupHome = rootState.global.activeGroupHome;
    payload.activeGroupHomeId = activeGroupHome ? activeGroupHome.id : null;
    return portal
      .post('?method=calendar.respondToTimeSlotEvent', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_INVITATIONS_' + payload.responseType.toUpperCase());
        return response;
      })
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_RESPOND_TO_EVENT');
        return error.response.data.status;
      });
  },
  [types.UPDATE_RESPONSE_TO_TIMESLOT_EVENT]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.updateResponseToTimeSlotEvent', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_INVITATIONS_' + payload.responseType.toUpperCase());
        return response;
      })
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_EVENT');
        return error.response.data.status;
      }),
  [types.BLOCK_TIMESLOT]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.blockTimeSlot', payload)
      .then(response => response)
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_BLOCK_TIMESLOT');
        return error.response.data.status;
      }),
  [types.REMOVE_BLOCKING_OR_RESPONSE]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.removeBlockingOrResponseToTimeSlot', payload)
      .then(response => response)
      .catch((...args) => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REMOVE_BLOCK_TIMESLOT');
        return Promise.reject(...args);
      }),
  [types.LOAD_DELEGATED_CONTEXT]: ({ commit }) =>
    portal
      .get('?method=calendar.getDelegatedContext')
      .then(response => commit(types.MUTATE_LOAD_DELEGATED_CONTEXT, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_DELEGATED_CONTEXT');
      }),
  [types.SET_DELEGATED_CONTEXT]: ({ commit, dispatch }, payload) =>
    portal
      .post('?method=calendar.setDelegatedContext', payload)
      .then(() => dispatch(types.LOAD_DELEGATED_CONTEXT))
      .catch(error => {
        if (error.response?.data.status.subCode !== errorSubCodeTypes.invalidDelegatedAccess) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_DELEGATED_CONTEXT');
        }
        return Promise.reject();
      }),
  [types.LOAD_ASSIGNED_DELEGATED_ACCESSES]: ({ commit, rootState }) => {
    if (rootState.global.isPreviewMode) {
      commit(types.MUTATE_LOAD_ASSIGNED_DELEGATED_ACCESSES, []);
    } else {
      return portal
        .get('?method=calendar.getDelegatedAccesses', {})
        .then(response => {
          commit(types.MUTATE_LOAD_ASSIGNED_DELEGATED_ACCESSES, response.data.data);
        })
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_DELEGATED_ACCESS');
        });
    }
  },
  [types.LOAD_MY_DELEGATED_ACCESSES]: ({ commit, rootState }) => {
    if (rootState.global.isPreviewMode) {
      commit(types.MUTATE_LOAD_MY_DELEGATED_ACCESSES, []);
    } else {
      return portal
        .get('?method=calendar.getInstitutionProfilesWithDelegatedAccess', {})
        .then(response => {
          commit(types.MUTATE_LOAD_MY_DELEGATED_ACCESSES, response.data.data);
        })
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PROFILES_WITH_DELEGATED_ACCESS');
        });
    }
  },
  [types.LIST_AVAILABLE_RESOURCES]: ({ commit }, payload) =>
    portal
      .get('?method=resources.listAvailableResources', { params: payload })
      .then(response => response.data.data)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LIST_AVAILABLE_RESOURCES');
      }),
  [types.LIST_RESOURCES]: ({ commit }, payload) =>
    portal
      .get('?method=resources.listResources', { params: payload })
      .then(response => {
        commit(types.MUTATE_LIST_RESOURCES, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LIST_RESOURCES');
      }),
  [types.UPDATE_EVENT_RESOURCES]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.updateEventResources', payload)
      .then(response => response.data.data)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_EVENT_RESOURCES');
      }),
  [types.REQUEST_VACATION]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.requestVacation', {
        institutionCode: payload.institutionCode,
        startDate: payload.startDate,
        endDate: payload.endDate,
        responseDeadline: payload.responseDeadline,
      })
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'CALENDAR_TOOLBAR_REQUEST_VACATION_SUCCESS');
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REQUEST_VACATION');
      }),
  [types.UPDATE_REQUEST_VACATION]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.updateVacationRequest', payload)
      .then(() => commit(types.MUTATE_SUCCESS_TEXT, 'CALENDAR_TOOLBAR_UPDATE_REQUEST_VACATION_SUCCESS'))
      .catch(() => commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REQUEST_VACATION')),
  [types.ADD_VACATION]: ({ commit }, payload) =>
    portal
      .post('?method=calendar.respondToVacationRegistrationRequest', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'CALENDAR_TOOLBAR_REQUEST_VACATION_UPDATE_SUCCESS');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REQUEST_VACATION');
      }),
  [types.LOAD_MY_VACATION_REQUESTS]: ({ commit }, payload) =>
    portal
      .get('?method=calendar.getFutureVacationRequests', { params: payload })
      .then(response => {
        commit(types.MUTATE_MY_VACATION_REQUESTS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_VACATION_REQUESTS');
      }),
  [types.LOAD_NOTICE_BOARD_EVENTS]: ({ commit }, payload) =>
    portal
      .get('?method=NoticeBoardDevice.getCalendarEvents', {
        params: {
          start: payload.start.format('YYYY-MM-DD HH:mm:ss.SSSSZ'),
          end: payload.end.format('YYYY-MM-DD HH:mm:ss.SSSSZ'),
          limit: payload.limit,
        },
      })
      .then(response =>
        commit(types.MUTATE_LOAD_EVENTS_BY_PROFILEID, {
          events: response.data.data,
          parent: parentTypes.NOTICE_BOARDS,
          instProfileIds: [],
          profile: {},
          children: [],
          institutions: [],
          end: payload.end,
          start: payload.start,
          calendarActiveInstProfileId: null,
          noticeBoardId: payload.noticeBoardId,
        })
      )
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_NOTICE_BOARD_EVENTS');
      }),
  [types.LOAD_NOTICE_BOARD_EVENT]: ({ commit }, payload) =>
    portal
      .get('?method=NoticeBoardDevice.getCalendarEvent', {
        params: { eventId: payload.eventId },
      })
      .then(response => {
        commit(types.MUTATE_GET_EVENT_BY_ID, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_EVENTS');
      }),
  [types.LOAD_CALENDAR_SYNC_CONSENT]: ({ commit }) =>
    portal
      .get('?method=CalendarFeed.getPolicyAnswer')
      .then(response => {
        commit(types.MUTATE_CALENDAR_SYNC_CONSENT, response.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_CALENDAR_SYNC_CONSENT_ENABLED');
      }),
  [types.LOAD_CALENDAR_FEEDS]: ({ commit }) =>
    portal
      .get('?method=CalendarFeed.getFeedConfigurations')
      .then(response => {
        commit(types.MUTATE_CALENDAR_FEEDS, response.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_CALENDAR_FEEDS');
      }),
  [types.UPDATE_CALENDAR_SYNC_CONSENT]: ({ commit }, payload) =>
    portal
      .post('?method=CalendarFeed.setPolicyAnswer', payload)
      .then(response => {
        commit(types.MUTATE_CALENDAR_SYNC_CONSENT, {
          data: { policyAccepted: payload.policyAccepted },
        });
        return response;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_CALENDAR_SYNC_CONSENT_ENABLED');
      }),
  [types.UPDATE_CALENDAR_FEED]: ({ commit }, payload) =>
    portal
      .post('?method=CalendarFeed.updateFeedConfiguration', payload)
      .then(response => response)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_CALENDAR_FEED');
      }),
  [types.CREATE_CALENDAR_FEED]: ({ commit }, payload) =>
    portal
      .post('?method=CalendarFeed.createFeedConfiguration', payload)
      .then(response => response)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_CALENDAR_FEED');
      }),
  [types.DELETE_CALENDAR_FEED]: ({ commit }, payload) =>
    portal
      .post('?method=CalendarFeed.removeFeedConfiguration', payload)
      .then(response => response)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_CALENDAR_FEED');
      }),
  [types.UPDATE_MUNICIPAL_CALENDER_FEED]: ({ commit }, payload) =>
    portal
      .post('?method=municipalConfiguration.updateCalendarFeedEnabled', payload)
      .then(response => response)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_CALENDAR_FEED_ENABLED');
      }),
  [types.LOAD_MUNICIPAL_CALENDER_FEED]: ({ commit }, payload) => {
    const params = {
      municipalityCodes: payload.municipalityCodes,
    };
    return portal
      .get('?method=municipalConfiguration.getCalendarFeedEnabled', { params })
      .then(response => {
        commit(types.MUTATE_GET_MUNICIPAL_CALENDER_FEED, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_CALENDAR_FEED_ENABLED');
      });
  },
  [types.LOAD_CALENDAR_FEED_EVENTS]: ({ commit }, payload) => {
    const params = {
      PortalRole: payload.PortalRole,
    };
    return portal
      .get('?method=calendarFeed.getEventTypesRelevantForPortalRole', {
        params,
      })
      .then(response => {
        commit(types.MUTATE_CALENDAR_FEED_EVENTS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_CALENDAR_FEED_EVENTS');
      });
  },
};

function prepareEventPayload(payload) {
  const clonedPayload = { ...payload };
  if (clonedPayload.hideInOwnCalendar) {
    clonedPayload.addToInstitutionCalendar = clonedPayload.hideInOwnCalendar;
  }
  return clonedPayload;
}
function createToastMessage(payload) {
  if (payload.isDeleteEvent) {
    return getDeletePresenceToastMessage(payload);
  } else if (payload.isEditEvent) {
    return getUpdatePresenceToastMessage(payload);
  }

  return getCreatePresenceToastMessages(payload);
}

function getCreatePresenceToastMessages(presencePayload) {
  switch (presencePayload.type) {
    case eventTypeEnum.PERFORMANCE_MEETING:
      return 'SUCCESS_TOAST_PERFORMANCE_MEETING_CREATE';
    case eventTypeEnum.PARENTAL_MEETING:
      return 'SUCCESS_TOAST_PARENTAL_MEETING_CREATE';
    case eventTypeEnum.SCHOOL_HOME_MEETING:
      return 'SUCCESS_TOAST_SCHOOL_HOME_MEETING_CREATE';
    case eventTypeEnum.LESSON:
      return 'SUCCESS_TOAST_LESSON_CREATE';
    case eventTypeEnum.VACATION_REGISTRATION:
      return 'SUCCESS_TOAST_VACATION_REGISTRATION_CREATE';
    case eventTypeEnum.PRESENCE_HOLIDAY:
      return 'SUCCESS_TOAST_PRESENCE_HOLIDAY_CREATE';
    default:
      return 'SUCCESS_TOAST_EVENT_CREATE';
  }
}

function getUpdatePresenceToastMessage(presencePayload) {
  switch (presencePayload.type) {
    case eventTypeEnum.PERFORMANCE_MEETING:
      return 'SUCCESS_TOAST_PERFORMANCE_MEETING_UPDATE';
    case eventTypeEnum.PARENTAL_MEETING:
      return 'SUCCESS_TOAST_PARENTAL_MEETING_UPDATE';
    case eventTypeEnum.SCHOOL_HOME_MEETING:
      return 'SUCCESS_TOAST_SCHOOL_HOME_MEETING_UPDATE';
    case eventTypeEnum.LESSON:
      return 'SUCCESS_TOAST_LESSON_UPDATE';
    case eventTypeEnum.VACATION_REGISTRATION:
      return 'SUCCESS_TOAST_VACATION_REGISTRATION_UPDATE';
    case eventTypeEnum.PRESENCE_HOLIDAY:
      return 'SUCCESS_TOAST_PRESENCE_HOLIDAY_UPDATE';
    default:
      return 'SUCCESS_TOAST_EVENT_UPDATE';
  }
}

function getDeletePresenceToastMessage(presencePayload) {
  switch (presencePayload.type) {
    case eventTypeEnum.PERFORMANCE_MEETING:
      return 'SUCCESS_TOAST_PERFORMANCE_MEETING_DELETE';
    case eventTypeEnum.PARENTAL_MEETING:
      return 'SUCCESS_TOAST_PARENTAL_MEETING_DELETE';
    case eventTypeEnum.SCHOOL_HOME_MEETING:
      return 'SUCCESS_TOAST_SCHOOL_HOME_MEETING_DELETE';
    case eventTypeEnum.LESSON:
      return 'SUCCESS_TOAST_LESSON_DELETE';
    case eventTypeEnum.VACATION_REGISTRATION:
      return 'SUCCESS_TOAST_VACATION_REGISTRATION_DELETE';
    case eventTypeEnum.PRESENCE_HOLIDAY:
      return 'SUCCESS_TOAST_PRESENCE_HOLIDAY_DELETE';
    default:
      return 'SUCCESS_TOAST_EVENT_DELETE';
  }
}

function createErrorMessage(error, commit) {
  if (
    (error.response.data.status.code == 70 && error.response.data.status.subCode == 0) ||
    (error.response.data.status.code == 0 && error.response.data.status.subCode == 0)
  ) {
    commit(types.MUTATE_ERROR_TEXT, 'CALENDAR_RESOURCES_ALREADY_BOOKED');
  } else if (
    (error.response.data.status.code == httpStatus.UNAUTHORIZED && error.response.data.status.subCode == 1) ||
    (error.response.data.status.code == httpStatus.UNAUTHORIZED && error.response.data.status.subCode == 0) ||
    (error.response.data.status.code == httpStatus.FORBIDDEN && error.response.data.status.subCode == 0)
  ) {
    commit(types.MUTATE_ERROR_TEXT, 'CALENDAR_RESOURCES_NO_PERMISSION');
  } else if (error.response.data.status.code == 10 && error.response.data.status.subCode == 0) {
    commit(types.MUTATE_UNAVAILABLE_RESOURCES, error.response.data.status.errorInformation.unavailableResourceIds);
  } else {
    commit(types.MUTATE_ERROR_TEXT, 'CALENDAR_CREATE_EVENT_ERROR');
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
};
