// Interface for all getters, mutations and actions

// Getters
export const GET_PERSONAL_DATA_POLICIES = 'onboarding/GET_PERSONAL_DATA_POLICIES';
export const IS_ONBOARDING_NEEDED = 'onboarding/IS_ONBOARDING_NEEDED';
export const GET_ONBOARDING_STEPS = 'onboarding/GET_ONBOARDING_STEPS';
export const GET_ADDITIONAL_DATA = 'onboarding/GET_ADDITIONAL_DATA';
export const GET_ADDITIONAL_DATA_FOR_ONBOARDING = 'onboarding/GET_ADDITIONAL_DATA_FOR_ONBOARDING';
export const IS_ONBOARDING_ERROR = 'onboarding/IS_ONBOARDING_ERROR';

// Mutations
export const MUTATE_IS_ONBOARDING_NEEDED = 'onboarding/MUTATE_IS_ONBOARDING_NEEDED';
export const MUTATE_PERSONAL_DATA_POLICIES = 'onboarding/MUTATE_PERSONAL_DATA_POLICIES';
export const MUTATE_ADDITIONAL_DATA = 'onboarding/MUTATE_ADDITIONAL_DATA';
export const MUTATE_ANY_UNANSWERED_ADDITIONAL_DATA_RESPONSES =
  'onboarding/MUTATE_ANY_UNANSWERED_ADDITIONAL_DATA_RESPONSES';
export const MUTATE_ONBOARDING_ERROR = 'onboarding/MUTATE_ONBOARDING_ERROR';
export const MUTATE_ADDITIONAL_DATA_FOR_ONBOARDING = 'onboarding/MUTATE_ADDITIONAL_DATA_FOR_ONBOARDING';

// Actions
export const GET_ONBOARDING_PROFILES = 'onboarding/GET_ONBOARDING_PROFILES';
export const LOAD_PERSONAL_DATA_POLICIES = 'onboarding/LOAD_PERSONAL_DATA_POLICIES';
export const CHECK_IS_ONBOARDING_NEEDED = 'onboarding/CHECK_IS_ONBOARDING_NEEDED';
export const UPDATE_PROFILE_MASTER_DATA = 'onboarding/UPDATE_PROFILE_MASTER_DATA';
export const LOAD_ADDITIONAL_DATA = 'onboarding/LOAD_ADDITIONAL_DATA';
export const LOAD_ADDITIONAL_DATA_ADMIN = 'onboarding/LOAD_ADDITIONAL_DATA_ADMIN';
export const LOAD_UNANSWERED_ADDITIONAL_DATA_RESPONSES = 'onboarding/LOAD_UNANSWERED_ADDITIONAL_DATA_RESPONSES';
export const UPDATE_ADDITIONAL_DATA = 'onboarding/UPDATE_ADDITIONAL_DATA';
export const MARK_ONBOARDING_COMPLETED = 'onboarding/MARK_ONBOARDING_COMPLETED';
export const UPDATE_ADDITIONAL_DATA_ON_BEHALF = 'onboarding/UPDATE_ADDITIONAL_DATA_ON_BEHALF';
