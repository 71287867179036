// Interface for all getters, mutations and actions

// Getters
export const GET_PRESENCE_LOCATIONS = 'presenceLocations/GET_PRESENCE_LOCATIONS';
export const GET_SYMBOLS = 'presenceLocations/GET_SYMBOLS';
export const GET_CHECK_NAME_AVAILABLE = 'presenceLocations/GET_CHECK_NAME_AVAILABLE';
export const GET_CHECK_ICON_AVAILABLE = 'presenceLocations/GET_CHECK_ICON_AVAILABLE';
export const GET_SELECTED_PRESENCE_LOCATION = 'presenceLocations/GET_SELECTED_PRESENCE_LOCATION';

// Mutations
export const MUTATE_GET_PRESENCE_LOCATIONS = 'presenceLocations/MUTATE_GET_PRESENCE_LOCATIONS';
export const MUTATE_ADD_PRESENCE_LOCATION = 'presenceLocations/MUTATE_ADD_PRESENCE_LOCATION';
export const MUTATE_UPDATE_PRESENCE_LOCATION = 'presenceLocations/MUTATE_UPDATE_PRESENCE_LOCATION';
export const MUTATE_REMOVE_PRESENCE_LOCATION = 'presenceLocations/MUTATE_REMOVE_PRESENCE_LOCATION';
export const MUTATE_CHECK_NAME_AVAILABLE = 'presenceLocations/MUTATE_CHECK_NAME_AVAILABLE';
export const MUTATE_CHECK_ICON_AVAILABLE = 'presenceLocations/MUTATE_CHECK_ICON_AVAILABLE';
export const MUTATE_SELECT_PRESENCE_LOCATION = 'presenceLocations/MUTATE_SELECT_PRESENCE_LOCATION';

// Actions
export const LOAD_PRESENCE_LOCATIONS = 'presenceLocations/LOAD_PRESENCE_LOCATIONS';
export const SAVE_PRESENCE_LOCATION = 'presenceLocations/SAVE_PRESENCE_LOCATION';
export const REMOVE_PRESENCE_LOCATION = 'presenceLocations/REMOVE_PRESENCE_LOCATION';
export const CHECK_NAME_AVAILABLE = 'presenceLocations/CHECK_NAME_AVAILABLE';
export const CHECK_ICON_AVAILABLE = 'presenceLocations/CHECK_ICON_AVAILABLE';
export const SELECT_PRESENCE_LOCATION = 'presenceLocations/SELECT_PRESENCE_LOCATION';
