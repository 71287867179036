import axios from '../../../../node_modules/axios/dist/axios.js';
import moment from 'moment-timezone';
import { store } from '../../../../src/store/store';
import { types } from '../../../../src/store/types/types';
import { errorSubCodeTypes } from '../../../enums/errorSubCodeTypes';

window.aulaApiVersion = 'v20';

axios.defaults.withCredentials = true;

const portal = axios.create({
  baseURL: '/api/' + window.aulaApiVersion,
});

const excludedApiEndpointActivity = ['messaging.getNewThreads'];

function requestInExcludedListForKeepAlive(url) {
  const result = excludedApiEndpointActivity.some(
    excludedEndpoint => url.toLowerCase().indexOf(excludedEndpoint.toLowerCase()) > -1
  );
  return result;
}

portal.interceptors.request.use(
  function (config) {
    // Keep session alive every time an api is called
    if (config.baseURL == '/api/' + window.aulaApiVersion && requestInExcludedListForKeepAlive(config.url) === false) {
      store.commit(types.MUTATE_KEEP_ALIVE, moment());
    }
    // Do something before request is sent
    const sesId = getProfileChange();

    if (sesId.length > 0 && store.getters[types.GET_PROFILE_CHANGE] == null) {
      store.commit(types.MUTATE_PROFILE_CHANGE, sesId);
    } else if (sesId.length > 0 && store.getters[types.GET_PROFILE_CHANGE] != sesId) {
      store.commit(types.MUTATE_PROFILE_CHANGE, sesId);
      // Only reload page in portal
      if (
        location.href.search('/choosePortalRole/') !== -1 &&
        (window.location.href.includes('portal') || window.location.href.includes('localhost:8080'))
      ) {
        window.location.href = '/';
        return;
      }
    }

    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

portal.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const subCode = error.response?.data?.status?.subCode;
    const errorCode = error.response?.data?.status?.code;
    if (errorCode == 20 || subCode === errorSubCodeTypes.sessionExpired) {
      window.location.href = '/auth/logout.php';
    } else if (
      errorCode == 448 &&
      !error.request.responseURL.includes('profiles.getProfilesByLogin') &&
      !error.request.responseURL.includes('presenceDevice.getPresenceConfiguration') &&
      !error.request.responseURL.includes('presenceDevice.getPresenceRegistrationOverview') &&
      !error.request.responseURL.includes('NoticeBoardDevice.getNoticeBoardConfiguration') &&
      !error.request.responseURL.includes('centralConfiguration.getMaxFileSize')
    ) {
      window.location.href = getBaseUrl();
    }

    if (error.response?.data?.error && !subCode) {
      // The error data element is only sent from the backend in dev and nonprod modes, so this check ensures that
      // debug api debug error messages are NOT shown in preprod and prod

      const debugError = error.response.data.error;
      debugError.api = error.request.responseURL;
      store.commit(types.MUTATE_DEBUG_API_ERROR, debugError);
    }

    return Promise.reject(error);
  }
);

function getBaseUrl() {
  const location = window.location;
  let fullHost = [location.protocol, '://', location.hostname, location.port.length > 0 ? location.port : ''].join('');
  if (window.location.href.indexOf('portal') > -1) {
    fullHost += '/portal/';
  } else if (window.location.href.indexOf('presence') > -1) {
    fullHost += '/presence/';
  } else if (window.location.href.indexOf('infotavle') > -1) {
    fullHost += '/infotavle/';
  }
  return fullHost;
}

function getProfileChange() {
  const profileChange1 = document.cookie.match(/profile_change=[A-Za-z0-9]+;/i);
  const profileChange2 = document.cookie.match(/profile_change=[A-Za-z0-9]+/i);

  let profileChange = profileChange1;

  if (profileChange1 == null && profileChange2 != null) {
    profileChange = profileChange2;
  }

  if (profileChange == null) {
    return '';
  }

  if (typeof profileChange == 'undefined') {
    return '';
  }

  if (profileChange.length <= 0) {
    return '';
  }

  profileChange = profileChange[0];

  let end = profileChange.lastIndexOf(';');
  if (end == -1) {
    end = profileChange.length;
  }

  return profileChange.substring(15, end);
}

function setActiveProfile(profile) {
  return portal.post('?method=session.setProfileContext', profile);
}

export { portal, setActiveProfile, axios };
