export const soundExtensions = Object.freeze([
  'aac',
  'aif',
  'cda',
  'flac',
  'm4a',
  'mid',
  'midi',
  'mp3',
  'mpa',
  'ogg',
  'wav',
  'wma',
  'wpl',
]);
