<template>
  <div v-if="showToaster" class="toastr">
    <b-alert :variant="variant" dismissible :show="showToaster" class="clearfix">
      <div class="toastr-content">
        <div v-if="showIcon" class="circle" :style="'background:' + circleBackground">
          <i :class="icon" :style="'font-size:' + iconFontSize" />
        </div>
        <div class="text">
          <slot />
        </div>
      </div>
      <template slot="dismiss">
        <i class="icon-Aula_close" tabindex="0" role="button" :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey" />
      </template>
    </b-alert>
  </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';
import { types } from '../../src/store/types/types';

export default {
  props: {
    variant: { type: String, default: 'dark' },
    icon: { type: String, default: 'icon-Aula_exclamation_sign' },
    iconFontSize: { type: String, default: '2em' },
    circleBackground: { type: String, default: 'transparent' },
    showIcon: { type: Boolean, default: true },
  },
  data: function () {
    return {
      showToaster: false,
    };
  },
  computed: {
    ...mapGetters({
      toastTimer: types.GET_TOAST_TIMER,
    }),
  },
  methods: {
    ...mapMutations({
      clearErrorText: types.MUTATE_ERROR_TEXT,
      clearSuccessText: types.MUTATE_SUCCESS_TEXT,
      setToastTimer: types.MUTATE_SET_TOAST_TIMER,
    }),
    show() {
      this.showToaster = true;
      this.setToastTimer(5);
      const counDownFunction = setInterval(
        function () {
          if (this.toastTimer == 0) {
            this.showToaster = false;
            if (this.variant == 'warning') {
              this.clearErrorText('');
            } else if (this.variant == 'success') {
              this.clearSuccessText('');
            }
            clearInterval(counDownFunction);
          }
          if (this.toastTimer > 0) {
            this.setToastTimer(this.toastTimer - 1);
          }
        }.bind(this),
        1000
      );
    },
  },
};
</script>

<style lang="scss">
@import '../assets/scss/core/breakpoints.scss';
@import '../assets/scss/core/variables.scss';

.toastr {
  position: fixed;
  top: 20px;
  right: 20px;
  border-radius: 8px;
  z-index: 9999;

  .alert {
    padding: 25px 50px 25px 25px;
    border: none;
    font-size: 14px;
    text-align: center;
    font-weight: normal;
    box-shadow: 0 2px 4px 0 rgba(166, 166, 166, 0.5);

    .toastr-content {
      align-items: center;
      display: flex;

      .circle {
        align-items: center;
        background: white;
        border-radius: 15px;
        display: flex;
        height: 30px;
        justify-content: center;
        left: 35px;
        margin-right: 20px;
        top: 35px;
        width: 30px;
      }
    }

    .close {
      opacity: 1;
      top: 10px;
      right: 10px;
      padding: 0px;
      color: $color-white;
      font-size: 14px;
    }
  }

  @include breakpoint-sm-down() {
    width: 94%;
    top: 2%;
    right: 3%;
  }

  .alert-dark {
    background-color: #c8d6df;
    border-color: #c8d6df;
  }

  .alert-success {
    color: $color-white;
  }

  .alert-warning {
    background-color: #c8d6df;
    .theme-employee & {
      color: $color-primary-darker-employee;
    }
    .theme-guardian & {
      color: $color-primary-darker-guardian;
    }
    .theme-child & {
      color: $color-primary-darker-child;
    }
    .theme-admin & {
      color: $color-primary-darker-admin;
    }
    .circle i {
      color: $color-alert;
    }
  }
}

//Theme styling
.theme-guardian {
  .toastr {
    .alert-success {
      background-color: $color-primary-base-guardian;
    }
  }
}
.theme-child {
  .toastr {
    .alert-success {
      background-color: $color-primary-base-child;
    }
  }
}
.theme-employee {
  .toastr {
    .alert-success {
      background-color: $color-primary-base-employee;
    }
  }
}
.theme-admin {
  .toastr {
    .alert-success {
      background-color: $color-primary-base;
    }
  }
}

.main-toastr-container {
  .toastr {
    position: relative;
    top: 20px;
    right: 20px;
    border-radius: 8px;
    z-index: 9999;

    @include breakpoint-sm-down() {
      width: 100%;
      top: 0;
      right: 0;
      left: 0;
    }
  }
}
</style>
