export enum eventTypeEnum {
  EVENT = 'event',
  LESSON = 'lesson',
  HOLIDAY = 'holiday',
  PRESENCE_HOLIDAY = 'presence_holiday',
  BIRTHDAY = 'birthday',
  OTHER = 'other',
  EXCURSION = 'excursion',
  SCHOOL_HOME_MEETING = 'school_home_meeting',
  PARENTAL_MEETING = 'parental_meeting',
  PERFORMANCE_MEETING = 'performance_meeting',
  VACATION_REGISTRATION = 'vacation_registration',
}
