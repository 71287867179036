<template>
  <div :id="popoverContainer" class="help-box">
    <b-btn :id="id" variant="link" class="questionmark-icon ml-2 tooltip-btn" :aria-label="getAriaLabel()">
      <i :class="icon" />
    </b-btn>
    <AulaPopover :target="id" :placement="placement" data-popover="help-box" :container="popoverContainer">
      <template #title>
        {{ title }}
      </template>
      <template #content>
        <slot name="body">
          <template v-if="isHtmlContent">
            <div v-html="body" />
          </template>
          <p v-else>
            {{ body }}
          </p>
        </slot>
      </template>
    </AulaPopover>
  </div>
</template>

<script>
import Vue from 'vue';
import AulaPopover from './AulaPopover.vue';
export default {
  components: { AulaPopover },
  props: {
    title: { type: String, default: '' },
    id: { type: String, default: 'toggleHelpTip' },
    body: { type: String, default: '' },
    icon: {
      type: String,
      default: 'icon-Aula_questionmark',
    },
    isHtmlContent: { type: Boolean, default: false },
    alignRight: { type: Boolean, default: false },
    placement: { type: String, default: 'right' },
  },
  data() {
    return {
      show: false,
    };
  },
  computed: {
    popoverContainer() {
      return `helpBox${this.id}`;
    },
  },
  methods: {
    getAriaLabel() {
      if (this.title != null && this.title != '') {
        return Vue.filter('fromTextKey')('ARIA_LABEL_COLLAPSE_HELP_TO') + this.title;
      } else {
        return Vue.filter('fromTextKey')('ARIA_LABEL_COLLAPSE_HELP');
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

.help-box {
  display: inline-block;
  padding: 0;
}
</style>
