// Interface for all getters, mutations and actions

// Getters
export const GET_EDIT_DASHBOARD_CONFIG = 'dashboards/GET_EDIT_DASHBOARD_CONFIG';
export const GET_LIST_DASHBOARDS = 'dashboards/GET_LIST_DASHBOARDS';

// Mutations
export const MUTATE_EDIT_DASHBOARD_CONFIG = 'dashboards/MUTATE_EDIT_DASHBOARD_CONFIG';
export const MUTATE_EDIT_WIDGET_PLACEMENT = 'dashboards/MUTATE_EDIT_WIDGET_PLACEMENT';
export const MUTATE_EDIT_WIDGET_GROUPS = 'dashboards/MUTATE_EDIT_WIDGET_GROUPS';
export const MUTATE_REMOVE_WIDGET_CONFIGURATION = 'dashboards/MUTATE_REMOVE_WIDGET_CONFIGURATION';
export const MUTATE_UPDATE_WIDGET_PLACEMENT_ORDER = 'dashboards/MUTATE_UPDATE_WIDGET_PLACEMENT_ORDER';

// Actions
export const ACTION_GET_DASHBOARD_CONFIG = 'dashboards/ACTION_GET_DASHBOARD_CONFIG';
export const ACTION_SAVE_DASHBOARD_CONFIG = 'dashboards/ACTION_SAVE_DASHBOARD_CONFIG';
export const ACTION_RESET_DASHBOARD_CONFIG = 'dashboards/ACTION_RESET_DASHBOARD_CONFIG';
