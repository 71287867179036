// Interface for all getters, mutations and actions

// GETTERS
export const GET_ADDITIONAL_DATA_GROSS_LIST = 'additional_data/GET_ADDITIONAL_DATA_GROSS_LIST';
export const GET_SELECTED_ADDITIONAL_DATA_GROSS_LIST_ITEM =
  'additional_data/GET_SELECTED_ADDITIONAL_DATA_GROSS_LIST_ITEM';
export const GET_ADDITIONAL_DATA_LIST = 'additional_data/GET_ADDITIONAL_DATA_LIST';
export const GET_GROSS_LIST = 'additional_data/GET_GROSS_LIST';
export const GET_SELECTED_ADDITIONAL_DATA_ITEM = 'additional_data/GET_SELECTED_ADDITIONAL_DATA_ITEM';

// MUTATIONS
export const MUTATE_GET_ADDITIONAL_DATA_GROSS_LIST = 'additional_data/MUTATE_GET_ADDITIONAL_DATA_GROSS_LIST';
export const MUTATE_UPDATE_ADDITIONAL_DATA_GROSS_LIST_ITEM =
  'additional_data/MUTATE_UPDATE_ADDITIONAL_DATA_GROSS_LIST_ITEM';
export const MUTATE_ADD_ADDITIONAL_DATA_GROSS_LIST_ITEM = 'additional_data/MUTATE_ADD_ADDITIONAL_DATA_GROSS_LIST_ITEM';
export const MUTATE_SELECT_ADDITIONAL_DATA_GROSS_LIST_ITEM =
  'additional_data/MUTATE_SELECT_ADDITIONAL_DATA_GROSS_LIST_ITEM';
export const MUTATE_REMOVE_ADDITIONAL_DATA_GROSS_LIST_ITEM =
  'additional_data/MUTATE_REMOVE_ADDITIONAL_DATA_GROSS_LIST_ITEM ';
export const MUTATE_GET_ADDITIONAL_DATA_LIST = 'additional_data/MUTATE_GET_ADDITIONAL_DATA_LIST';
export const MUTATE_REMOVE_ADDITIONAL_DATA = 'additional_data/MUTATE_REMOVE_ADDITIONAL_DATA';
export const MUTATE_ADD_ADDITIONAL_DATA = 'additional_data/MUTATE_ADD_ADDITIONAL_DATA';
export const MUTATE_GET_GROSS_LIST = 'additional_data/MUTATE_GET_GROSS_LIST';
export const MUTATE_ADD_ADDITIONAL_DATA_FROM_GROSS_LIST = 'additional_data/MUTATE_ADD_ADDITIONAL_DATA_FROM_GROSS_LIST';
export const MUTATE_SELECT_ADDITIONAL_DATA = 'additional_data/MUTATE_SELECT_ADDITIONAL_DATA';
export const MUTATE_UPDATE_ADDITIONAL_DATA_ITEM = 'additional_data/MUTATE_UPDATE_ADDITIONAL_DATA_ITEM';

// ACTIONS
export const CREATE_ADDITIONAL_GROSS_LIST_ITEM = 'additional_data/CREATE_ADDITIONAL_GROSS_LIST_ITEM';
export const LOAD_ADDITIONAL_DATA_GROSS_LIST_ITEM = 'additional_data/LOAD_ADDITIONAL_DATA_GROSS_LIST_ITEM';
export const SELECT_ADDITIONAL_DATA_GROSS_LIST_ITEM = 'additional_data/SELECT_ADDITIONAL_DATA_GROSS_LIST_ITEM';
export const LOAD_ADDITIONAL_DATA_GROSS_LIST = 'additional_data/LOAD_ADDITIONAL_DATA_GROSS_LIST';
export const REMOVE_ADDITIONAL_DATA_GROSS_LIST_ITEM = 'additional_data/REMOVE_ADDITIONAL_DATA_GROSS_LIST_ITEM';
export const LOAD_ADDITIONAL_DATA = 'additional_data/LOAD_ADDITIONAL_DATA';
export const REMOVE_ADDITIONAL_DATA = 'additional_data/REMOVE_ADDITIONAL_DATA';
export const CREATE_ADDITIONAL_DATA = 'additional_data/CREATE_ADDITIONAL_DATA';
export const LOAD_GROSS_LIST = 'additional_data/LOAD_GROSS_LIST';
export const CREATE_DATA_FROM_GROSS_LIST = 'additional_data/CREATE_DATA_FROM_GROSS_LIST';
export const SELECT_ADDITIONAL_DATA = 'additional_data/SELECT_ADDITIONAL_DATA';
