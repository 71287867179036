// Interface for all getters, mutations and actions

// Getters
export const GET_NOTIFICATION_SETTINGS = 'notifications/GET_NOTIFICATION_SETTINGS';
export const GET_NOTIFICATIONS = 'notifications/GET_NOTIFICATIONS';
export const GET_ACTIVE_STEP_UP_NOTIFICATION = 'notifications/GET_ACTIVE_STEP_UP_NOTIFICATION';
export const GET_VALIDATE_UNSUBSCRIBE_NOTIFICATIONS = 'notifications/GET_VALIDATE_UNSUBSCRIBE_NOTIFICATIONS';

// Mutations
export const MUTATE_SET_NOTIFICATION_SETTINGS = 'notifications/MUTATE_SET_NOTIFICATION_SETTINGS';
export const MUTATE_SAVE_NOTIFICATION_SETTINGS = 'notifications/MUTATE_SAVE_NOTIFICATION_SETTINGS';
export const MUTATE_LOAD_NOTIFICATIONS = 'notifications/MUTATE_LOAD_NOTIFICATIONS';
export const MUTATE_DELETE_NOTIFICATIONS = 'notifications/MUTATE_DELETE_NOTIFICATIONS';
export const MUTATE_DELETE_MODULE_NOTIFICATIONS = 'notifications/MUTATE_DELETE_MODULE_NOTIFICATIONS';
export const MUTATE_SET_NOTIFICATION_STEP_UP = 'notifications/MUTATE_SET_NOTIFICATION_STEP_UP';
export const MUTATE_VALIDATE_UNSUBSCRIBE_NOTIFICATIONS = 'notifications/MUTATE_VALIDATE_UNSUBSCRIBE_NOTIFICATIONS';

// Actions
export const SET_NOTIFICATION_SETTINGS = 'notifications/SET_NOTIFICATION_SETTINGS';
export const SAVE_NOTIFICATION_SETTINGS = 'notifications/SAVE_NOTIFICATION_SETTINGS';
export const LOAD_NOTIFICATIONS = 'notifications/LOAD_NOTIFICATIONS';
export const DELETE_NOTIFICATIONS = 'notifications/DELETE_NOTIFICATIONS';
export const DELETE_MODULE_NOTIFICATIONS = 'notifications/DELETE_MODULE_NOTIFICATIONS';
export const UNREGISTER_ALL = 'notifications/UNREGISTER_ALL';
export const UNSUBSCRIBE_NOTIFICATIONS = 'notifications/UNSUBSCRIBE_NOTIFICATIONS';
export const VALIDATE_UNSUBSCRIBE_NOTIFICATIONS = 'notifications/VALIDATE_UNSUBSCRIBE_NOTIFICATIONS';
