// Interface for all getters, mutations and actions

// Getters
export const GET_ADMINISTRATIVE_AUTHORITIES = 'administrativeAuthorities/GET_ADMINISTRATIVE_AUTHORITIES';
export const GET_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS =
  'administrativeAuthorities/GET_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS';
export const GET_SELECTED_ADMINISTRATIVE_AUTHORITY = 'administrativeAuthorities/GET_SELECTED_ADMINISTRATIVE_AUTHORITY';

// Mutations
export const MUTATE_GET_ADMINISTRATIVE_AUTHORITIES = 'administrativeAuthorities/MUTATE_GET_ADMINISTRATIVE_AUTHORITIES';
export const MUTATE_GET_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS =
  'administrativeAuthorities/MUTATE_GET_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS';
export const MUTATE_SELECT_ADMINISTRATIVE_AUTHORITY =
  'administrativeAuthorities/MUTATE_SELECT_ADMINISTRATIVE_AUTHORITY';

// Actions
export const LOAD_ADMINISTRATIVE_AUTHORITIES = 'administrativeAuthorities/LOAD_ADMINISTRATIVE_AUTHORITIES';
export const SAVE_ADMINISTRATIVE_AUTHORITY = 'administrativeAuthorities/SAVE_ADMINISTRATIVE_AUTHORITY';
export const SELECT_ADMINISTRATIVE_AUTHORITY = 'administrativeAuthorities/SELECT_ADMINISTRATIVE_AUTHORITY';
