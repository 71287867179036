



import { PropType } from 'vue';
import { iconClassEnum } from '../enums/iconClassEnum';

export default {
  props: {
    name: {
      type: String as PropType<iconClassEnum>,
      required: true,
      validator(value) {
        return Object.values(iconClassEnum).includes(value);
      },
    },
  },
};
