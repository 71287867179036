export const PRESENCE_PAGETITLE = 'Komme/gå';
export const PRESENCE_ABSENCE_DESCRIPTION =
  'Vær opmærksom på, at når du melder sygdom eller ferie/fri, så er det kun medarbejdere i SFO og dagsinstitution, der bliver informeret. Du skal stadig melde sygdom og fravær til skolen, fx ved at sende en besked til dit barns lærer.';
export const PRESENCE_ABSENCE_DESCRIPTION_WARNING =
  'Bemærk, at dit barn automatisk raskmeldes, så hvis dit barn fortsat er syg, skal du registrere sygdom næste morgen.';
export const PRESENCE_VACATION_REQUEST_DESCRIPTION_1 =
  'Ferieanmodninger er ferie, fx efterårs- og vinterferie, som institutionen sender ud til jer forældre, som I skal besvare, så personalet kan planlægge bemanding i ferierne bedst muligt.';
export const PRESENCE_VACATION_REQUEST_DESCRIPTION_2 =
  'Vær opmærksom på, at ferieanmodningen KUN bliver sendt til de forældre, der har komme/gå modulet.';
export const PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_1 =
  'Ferie/fri er de dage, hvor jeres barn ikke kommer. Vær opmærksom på, at når du melder ferie/fri, så er det kun medarbejdere i SFO og daginstitution, der bliver informeret.';
export const PRESENCE_VACATION_ANNOUNCEMENT_DESCRIPTION_2 =
  'Du skal stadig melde ferie/fri til skolen, fx ved at sende en besked til dit barns lærer.';
export const PRESENCE_REGISTER_VACATION_DESCRIPTION =
  'Vær opmærksom på, at når du melder ferie/fri, så er det kun medarbejdere i SFO og dagsinstitution, der bliver informeret og ikke skolen.';
export const PRESENCE_TIME_TAG = 'TIDER';
export const PRESENCE_DAILY_OVERVIEW = 'DAGENS OVERBLIK';
export const PRESENCE_ABSENCE_TAG = 'FRAVÆR';
export const PRESENCE_PICKUP_RESPONSIBLE_TAG = 'HENTEANSVARLIGE';
export const PRESENCE_GENERAL_OPENING_HOURS_AND_CLOSED_DAYS = 'ÅBNINGSTIDER OG LUKKEDAGE';
export const PRESENCE_TIME = 'Tider';

export const PRESENCE_PICKUP_RESPONSIBLE_PARENT = 'Forældre';
export const PRESENCE_PICKUP_RESPONSIBLE_TITLE_MOBILE = 'Henteansvarlig';
export const PRESENCE_PICKUP_RESPONSIBLE_OTHERS = 'Andre';
export const PRESENCE_WARNING_PICKUP_NAME = 'Du skal angive et navn';
export const PRESENCE_WARNING_PICKUP_NAME_TOO_SHORT = 'Du skal mindst skrive 2 tegn';
export const PRESENCE_WARNING_CHOOSE_CHILD = 'Du skal vælge mindst ét barn';
export const PRESENCE_REMOVE_PICKUP_RESPONSIBLE = 'Fjern henteansvarlige';
export const PRESENCE_NEW_PICKUP_RESPONSIBLE = 'TILFØJ HENTEANSVARLIG';
export const PRESENCE_PICKUP_RESPONSIBLE_NAME = 'Angiv navn';
export const PRESENCE_PICKUP_CHILDREN = 'Vælg barn';
export const PRESENCE_WEEK = 'UGE';
export const PRESENCE_CURRENT_WEEK = 'Denne uge';
export const PRESENCE_REPORT_VACATION = 'Meld ferie/fri';
export const PRESENCE_SICK = 'Syg';
export const PRESENCE_REGISTER_VACATION_INTRO = 'Opret ferie/fri i Aula kalenderen her.';
export const PRESENCE_REGISTER_VACATION = 'Opret ferie/fri';
export const PRESENCE_UPDATE_VACATION = 'Rediger ferie/fri';
export const PRESENCE_ABSENCE = 'Fravær';
export const PRESENCE_PICKUP_INFORMATION = 'Henteinformationer';
export const PRESENCE_PICKUP_TYPE_PICKUP = 'Hentes af';
export const PRESENCE_PICKUP_TYPE_SEND_HOME = 'Send hjem';
export const PRESENCE_GO_HOME_WITH = 'Gå hjem med';
export const PRESENCE_PICKUP_TYPE_SELF_DECIDER = 'Selvbestemmer';
export const PRESENCE_SPARE_TIME = 'Fritidsaktivitet';
export const PRESENCE_PICKUP_TYPE_GO_HOME_WITH = 'Gå hjem med';
export const PRESENCE_PICKUP_TYPE_GO_HOME_WITH_STATUS = 'Gå hjem med';
export const PRESENCE_COMMENT = 'Bemærkninger';
export const PRESENCE_COMMENT_CHARACTER_LIMIT_REACHED = 'Du kan ikke sætte flere karakterer';
export const PRESENCE_SPARE_ACTIVITY_COMMENT = 'Note til fritidsaktivitet';
export const PRESENCE_DAILY_COMMENT = 'Bemærkninger';
export const PRESENCE_REPETITION = 'Gentag ugentligt';
export const PRESENCE_REPETITION_END_DATE = 'Slutdato for gentagelse';
export const ARIA_LABEL_PRESENCE_REPETITION_END_DATE = 'Vælg slutdatoen for gentagelse';
export const PRESENCE_COMMENT_PLACE_HOLDER = 'Skriv note';
export const PRESENCE_COMMENT_SPARETIME_ACTIVITY = 'Skriv note til fritidsaktivitet';
export const PRESENCE_COMMENT_MAX_CHARACTERS = '(max 250 tegn)';
export const PRESENCE_VACATION_FR0M = 'Fra';
export const PRESENCE_VACATION_TO = 'Til';
export const PRESENCE_VACATION_FR0M_DATE = 'Ferie fra';
export const PRESENCE_VACATION_TO_DATE = 'Ferie til';
export const PRESENCE_SLEEP = 'Sovetider:';
export const PRESENCE_DELIVERY_TIME = 'Kommer klokken:';
export const PRESENCE_DELIVERY_TIME_SHORT = 'Kommer kl.';
export const PRESENCE_PICK_UP_TIME = 'Går klokken:';
export const PRESENCE_DELIVERY_TIME_AFTER = 'Kom:';
export const PRESENCE_PICK_UP_TIME_AFTER = 'Gik:';
export const PRESENCE_STATUS = 'Status';
export const PRESENCE_SEND_TO_SPARETIME_ACTIVITY = 'Skal sendes til fritidsaktivitet';
export const PRESENCE_TIME_TITLE_FORM = 'TIDER';
export const PRESENCE_CHOSEN_DATE = 'Valgt dato';
export const PRESENCE_CHOSEN_DATE_MOBILE = 'Dato';
export const PRESENCE_VACATION_NOTE = 'Ferienote til institutionen';
export const PRESENCE_VACATION_NOTE_SHORT = 'Ferienote';
export const PRESENCE_VACATION_NOTE_PLACEHOLDER = 'Skriv note her...';
export const PRESENCE_NOTE = 'Bemærk';
export const PRESENCE_SPECIFIC_TIME_INFO = 'Ændrede åbningstider';
export const PRESENCE_ALERT_OUTSIDE_SPECIFIC_HOURS = 'Du har angivet tider, der ligger udenfor åbningstiderne';
export const PRESENCE_ALERT_OUTSIDE_OPEN_HOURS = 'Du har angivet en tid, der ligger udenfor åbningstiderne';
export const SUCCESS_TOAST_REGISTER_ABSENCE = 'Ferie/fri er oprettet og lagt i Aula kalenderen';
export const SUCCESS_TOAST_DELETED_PICKUP_RESPONSIBLE = 'Den henteansvarlige blev slettet';
export const SUCCESS_TOAST_SAVED_PICKUP_RESPONSIBLE = 'Den henteansvarlige blev gemt';
export const SUCCESS_TOAST_REGISTER_SICK = 'Vi har registeret, at dit barn er syg';
export const SUCCESS_TOAST_UNREGISTER_SICK = 'Vi har registreret, at dit barn er rask';
export const SUCCESS_TOAST_UPDATE_TEMPLATE = 'Tiden blev opdateret';
export const SUCCESS_TOAST_UPDATE_STATUS = 'Status blev opdateret';
export const PRESENCE_ALERT_ABSENCE_CHOOSE_CHILD = 'Du skal vælge mindst ét barn';
export const PRESENCE_ALERT_START_DATE = 'Indtast venligst en gyldig fra dato';
export const PRESENCE_PICKUP_TYPES = 'Vælg hentetype';
export const PRESENCE_ALERT_DELIVERY_TIME = 'Indtast ankomsttid';
export const PRESENCE_ALERT_NO_EXIT_WITH = 'Du skal vælge en person';
export const PRESENCE_ALERT_PICK_UP_TIME = 'Indtast hentetid';
export const PRESENCE_ALERT_INVALID_PICK_UP_TIME = 'Tjek ud tiden er ugyldig';
export const PRESENCE_ALERT_INVALID_SELF_DECIDER_START_TIME = 'Start tiden er ugyldig';
export const PRESENCE_ALERT_INVALID_START_AND_END_TIME = 'Slut tid skal være efter start tid';
export const PRESENCE_ALERT_INVALID_NO_TIMES_SELECTED = 'Du mangler at vælge en tid';
export const PRESENCE_ALERT_INVALID_ENTRY_TIME = 'Tiden skal være efter Kommer tidspunktet';
export const PRESENCE_ALERT_MAX_SIZE_COMMENT = 'Grænsen er 250 tegn';
export const PRESENCE_ALERT_END_DATE = 'Indtast venligst en gyldig til dato';
export const PRESENCE_ALERT_TIME_OUTSIDE_OPEN_HOURS = 'Du har angivet en tid, der ligger udenfor åbningstiderne';
export const PRESENCE_WARNING_VACATION_REQUEST_UNEDITABLE =
  'Deadlinen er overskredet eller du har ikke rettighed til at ændre ferien. Kontakt en medarbejder, hvis du ønsker at ændre i ferien.';
export const PRESENCE_WARNING_OVERWRITTEN = 'De fremtidige værdier overskrives';
export const PRESENCE_WARNING_HAS_NOT_PRESENCE_CHILD = 'Komme/gå-modulet er ikke slået til for de valgte børn.';
export const PRESENCE_WARNING_NOT_MEMBER_OF_PRESENCE =
  'Du har ikke adgang til at se komme/gå-modulet. Det kan skyldes at du ikke er med i komme/gå-gruppen eller at institutionen ikke har et komme/gå-modul.';
export const PRESENCE_WARNING_HAS_NOT_VACATION =
  'Du har ikke adgang til at se komme/gå-modulet. Det kan skyldes, at du ikke er med i komme/gå-gruppen eller at institutionen ikke har valgt et komme/gå-modul.';
export const PRESENCE_WARNING_NO_RESULTS_FOUND = 'Der er ingen resultater at vise';
export const PRESENCE_WARNING_VACATION_REGISTRATIONS_NO_RESULTS = 'Der er ingen ferieanmodninger i øjeblikket.';
export const PRESENCE_WARNING_VACATION_ANNOUNCEMENTS_NO_RESULTS = 'Der er ingen ferie/fri registreringer i øjeblikket.';
export const PRESENCE_WARNING_REMOVE_PICKUP_RESPONSIBLE = 'Er du sikker på, at du vil slette';
export const PRESENCE_WARNING_REMOVE_PICKUP_RESPONSIBLE_2 = 'som henteansvarlig';
export const PRESENCE_WARNING_REMOVE_VACATION =
  'Du er ved at fjerne registreret ferie. Er du sikker på, at du vil fortsætte?';
export const PRESENCE_WARNING_INCOMPATIBLE_STATUS =
  'Følgende børns status bliver ikke opdateret, da du foretager en handling der ikke er mulig at udføre.';
export const PRESENCE_WARNING_INCOMPATIBLE_STATUS_2 = 'Ønsker du at ændre status for de resterende børn?';
export const PRESENCE_DASHBOARD_STATISTIC = '{numberOfChildrenPresent} ud af {totalNumberOfChildren} børn er til stede';
export const PRESENCE_DASHBOARD_STATISTIC_WITH_FILTERING = '{totalNumberOfChildren} børn matcher de valgte filtre';
export const PRESENCE_DASHBOARD_VIEW_MESSAGE = 'Se besked';
export const PRESENCE_DASHBOARD_NO_STATISTIC = 'Der er ikke nogen tilstedeværelse';
export const PRESENCE_DASHBOARD_FILTER_BY_GROUP = 'Filtrer på gruppe';
export const PRESENCE_DASHBOARD_FILTER_BY_STATUS = 'Filtrer på status';
export const PRESENCE_DASHBOARD_FILTER_BY_ACTIVITY = 'Filtrer på aktivitet';
export const PRESENCE_DASHBOARD_UPDATE_STATUS = 'REDIGER STATUS';
export const PRESENCE_DASHBOARD_UPDATE_TEMPLATE = 'REDIGER TIDER';
export const PRESENCE_DASHBOARD_REFRESH_LIST = 'Opdater liste';
export const PRESENCE_DASHBOARD_SELECT_ALL = 'Vælg alle';
export const PRESENCE_DASHBOARD_COMMENT = 'Kommentar';
export const PRESENCE_DASHBOARD_SELECTED_STATISTIC = '{numberOfSelected} ud af {numberOfChildren} valgt';
export const PRESENCE_DASHBOARD_REPEATED_FROM = 'Gentages ugentligt indtil ';
export const PRESENCE_DASHBOARD_REPEATED_WEEKLY = 'Gentages ugentligt';
export const PRESENCE_ARIA_LABEL_DELETE_REPETITION = 'Fjern denne gentagelse';
export const PRESENCE_WARNING_DELETE_A_REPETITION_WITHOUT_END_DATE =
  'Er du sikker på, at du vil slette gentagelsen fra d. {byDate} og fremadrettet?';
export const PRESENCE_WARNING_DELETE_A_REPETITION_WITH_END_DATE =
  'Er du sikker på, at du vil slette gentagelsen fra d. {byDate} til d. {repeatToDate}?';
export const PRESENCE_ACTIVITY_LIST_SHOW_ALL_CHILD_ACTIVITIES = 'Vis alle aktiviteter i {childName}';
export const PRESENCE_ACTIVITY_LIST_SHOW_ALL_CHILDREN_ACTIVITIES = 'Udvid alle børns næste aktiviteter';
export const PRESENCE_ACTIVITY_LIST_SHOW_LESS_CHILD_ACTIVITIES = 'Vis færre aktiviteter i {childName}';
export const PRESENCE_ACTIVITY_LIST_SHOW_LESS_CHILDREN_ACTIVITIES = 'Vis færre børns næste aktiviteter';
export const PRESENCE_ACTIVITY_LIST_SHOW_LESS_ACTIVITIES = 'Vis færre næste aktiviteter';
export const ACTIVITY_CHILDREN = 'NAVN';
export const ACTIVITY_STATUS = 'STATUS';
export const ACTIVITY_NAME = 'NÆSTE AKTIVITET';
export const ACTIVITY_TIME = 'TID';
export const PRESENCE_STATE_PRESENT = 'Til stede';
export const PRESENCE_STATE_NOT_PRESENT = 'Ikke til stede';
export const PRESENCE_STATE_OFF_PREMISES = 'Ude af huset';
export const PRESENCE_STATE_SICK = 'Syg';
export const PRESENCE_STATE_ABSENCE = 'Fravær';
export const PRESENCE_STATE_VACATION = 'Ferie/fri';
export const PRESENCE_STATE_FIELDTRIP = 'På tur';
export const PRESENCE_STATE_SLEEPING = 'Sover';
export const PRESENCE_STATE_SLEEPING_PAST = 'Sov';
export const PRESENCE_WITHOUT_END_TIME_SLEEPING = 'Ingen slut tid';
export const PRESENCE_STATE_SPARE_TIME_ACTIVITY = 'Til fritidsaktivitet';
export const PRESENCE_STATE_PHYSICAL_PLACEMENT = 'Fysisk placering';
export const PRESENCE_STATE_REPORTED_ABSENT = 'Ferie/fri';
export const PRESENCE_STATE_NOT_CHECKED_IN = 'Ikke kommet';
export const ACTIVITY_LIST_SORT_NAME = 'Navn';
export const ACTIVITY_LIST_SORT_STATUS = 'Status';
export const ACTIVITY_LIST_SORT_CHRONOLOGY = 'Kronologisk';
export const PRESENCE_ACTIVITY_PICKED_UP_BY = 'Hentes af';
export const PRESENCE_ACTIVITY_PICKED_UP = 'Hentes';
export const PRESENCE_ACTIVITY_SELF_DECIDER = 'Selvbestemmer';
export const PRESENCE_ACTIVITY_SEND_HOME = 'Send hjem';
export const PRESENCE_ACTIVITY_GO_HOME_WITH = 'Gå hjem med';
export const PRESENCE_ACTIVITY_GO_HOME = 'Gå hjem';
export const PRESENCE_ACTIVITY_DROP_OFF_TIME = 'Kommer';
export const PRESENCE_ACTIVITY_SPARE_TIME = 'Til fritidsaktivitet';
export const PRESENCE_CLOSED_DAY_DAILY_OVERVIEW = 'Lukket';
export const PRESENCE_WEEK_OVERVIEW_PRESS_TO_EDIT = 'Tryk for at redigere';

export const PRESENCE_STATUS_CHECK_IN = 'TJEK IND';
export const PRESENCE_STATUS_CHECK_OUT = 'TJEK UD';
export const PRESENCE_SELF_DECIDER_START_TIME = 'Fra klokken';
export const PRESENCE_SELF_DECIDER_END_TIME = 'Til klokken';
export const PRESENCE_SELECT_PICK_UP_TIME = 'Hentes klokken';
export const PRESENCE_SELECT_PICK_UP_TIME_SHORT = 'Hentes kl.';
export const PRESENCE_SELECT_GO_HOME_WITH = 'VÆLG HENTEANSVARLIG';
export const PRESENCE_SELECT_EXIT_TYPE = 'VÆLG HENTETYPE';

export const PRESENCE_ACTIVITY_CHECK_OUT = 'Gået';
export const PRESENCE_ACTIVITY_CHECK_IN = 'Kommet';
export const PRESENCE_ACTIVITY_CHECK_IN_PAST = 'Kom ';
export const PRESENCE_ACTIVITY_CHECK_IN_FUTURE = 'Kommer ';
export const PRESENCE_ACTIVITY_CHECK_OUT_PAST = 'Gik ';
export const PRESENCE_ACTIVITY_CHECK_OUT_FUTURE = 'Går ';
export const PRESENCE_FILTERING_STATUS = 'Status';
export const PRESENCE_FILTERING_ACTIVITY = 'Næste aktivitet';
export const PRESENCE_FILTERING_DAILY_NOTE_DESCRIPTION = 'Vis kun børn med dagens bemærkning';
export const PRESENCE_FILTERING_DAILY_NOTE = 'Dagens bemærkning';
export const PRESENCE_FILTERING_CHILDREN_WITH_DAILY_NOTE = 'Børn med dagens bemærkning';
export const PRESENCE_FILTERING_ALL = 'Alle';
export const PRESENCE_REPORT_SICK = 'Meld sygdom';
export const PRESENCE_REPORT_SICK_SHORT = 'Sygdom';
export const PRESENCE_REPORT_VACATION_REQUEST = 'Ferieanmodninger';
export const PRESENCE_REPORT_VACATION_ANNOUNCEMENT = 'Ferie/fri';
export const PRESENCE_REPORT_PERIOD = 'Periode';
export const PRESENCE_REPORT_DEADLINE = 'Deadline';
export const PRESENCE_FILTERING_DEPARTMENT = 'Afdeling';
export const PRESENCE_FILTERING_GROUP = 'Gruppe';
export const PRESENCE_FILTERING_GROUPS = 'Grupper';
export const PRESENCE_FILTERING_DATE = 'Dato';
export const PRESENCE_VIEW_CHILD_CONTACT_PAGE = 'Se barnets kontaktoplysninger';
export const PRESENCE_FILTERING_MENU = 'Filtrering';
export const PRESENCE_STATUS_AND_ACTIVITY_FILTER = 'Status og aktivitet';
export const PRESENCE_TOTAL_VACATIONS = 'børn har ferie/fri';
export const PRESENCE_ACTIVITY_LIST_TODAY = 'I dag';
export const PRESENCE_NO_EXIT_WITH_WARNING_FOR_CHILD = 'Du skal vælge en person';
export const PRESENCE_ACTIVITY_LIST_WEEK = 'UGEOVERBLIK';
export const PRESENCE_OPENING_HOURS_AND_CLOSED_DAYS = 'Åbningstider og lukkedage';

export const PRESENCE_WEEK_OVERVIEW_COMING_CHILDREN = '{numberOfChildren} ud af {totalNumberOfChildren} børn forventes';
export const PRESENCE_WEEK_OVERVIEW_COME_CHILDREN = '{numberOfChildren} ud af {totalNumberOfChildren} børn kom';
export const PRESENCE_WEEK_OVERVIEW_COMING_CHILDREN_MOBILE = '{numberOfChildren}/{totalNumberOfChildren} forventes';
export const PRESENCE_WEEK_OVERVIEW_COME_CHILDREN_MOBILE = '{numberOfChildren}/{totalNumberOfChildren} kom';
export const PRESENCE_WEEK_OVERVIEW_CHECK_IN_PAST = 'Kom';
export const PRESENCE_WEEK_OVERVIEW_CHECK_IN_FUTURE = 'Kommer';
export const PRESENCE_WEEK_OVERVIEW_CHECK_IN_FUTURE_NOT_SPECIFIED = 'Kommer ikke angivet';
export const PRESENCE_WEEK_OVERVIEW_CHECK_OUT_PAST = 'Gik';
export const PRESENCE_WEEK_OVERVIEW_CHECK_OUT_FUTURE = 'Går';
export const PRESENCE_WEEK_OVERVIEW_CHECK_OUT_FUTURE_NOT_SPECIFIED = 'Går ikke angivet';
export const PRESENCE_MISSING_CHECK_OUT_TIME = 'Tjek ud mangler';
export const PRESENCE_STATUS_PREPOSITION = 'kl.';
export const PRESENCE_COMING_CHILDREN_LABEL_FUTURE = 'Forventet antal børn';
export const PRESENCE_COMING_CHILDREN_LABEL_PAST = 'Faktisk antal børn';
export const PRESENCE_TIME_CONFIGURATION_DISABLED_WARNING =
  'Fordelingen kan ikke vises, da afleveringstider og/eller hentetider er slået fra i komme/gå konfigurationen.';

export const PRESENCE_VACATION_REGISTRATION_TITLE = 'Titel';
export const PRESENCE_VACATION_REGISTRATION_DEPARTMENT_GROUPS = 'Afdeling/grupper';
export const PRESENCE_VACATION_REGISTRATION_PERIOD = 'Periode';
export const PRESENCE_VACATION_REGISTRATION_DEADLINE = 'Deadline';

export const PRESENCE_OPENING_HOURS = 'Åbningstid';
export const PRESENCE_GENERAL_OPENING_HOURS = 'Generelle åbningstider';
export const PRESENCE_SPECIFIC_OPENING_HOURS = 'Særlige åbningstider';
export const PRESENCE_OPENING_HOUR_NOT_GIVEN = 'Ikke angivet';
export const PRESENCE_WEEKDAY_LABEL = 'Ugedag';
export const PRESENCE_DATE_LABEL = 'Dato';
export const PRESENCE_VACATION_CLOSED_DAYS_NAME_PLACEHOLDER = 'Personaledag';
export const PRESENCE_VACATION_CLOSED_DAYS_NAME_CLOSED_PLACEHOLDER = 'Lukket:';
export const PRESENCE_VACATION_SPECIFIC_OPENING_HOURS_NAME_PLACEHOLDER = 'Ændrede åbningstider:';
export const PRESENCE_VACATION_SPECIFIC_OPENING_HOURS_PLACEHOLDER = 'Bemærk:';
export const PRESENCE_HOLIDAY_CLOSED_DAYS = 'Lukkedage';
export const PRESENCE_HOLIDAY_CLOSED_DAYS_NAME = 'Årsag';

export const PRESENCE_REPETITION_HELP_TEXT =
  'De valgte indstillinger vil blive gentaget ugentligt indtil den valgte slutdato. Vælges der ikke en slutdato, bliver indstillingerne gentaget indtil der gemmes nye indstillinger.';
export const PRESENCE_REPETITION_HELP_TITLE = 'Gentag ugentligt';
export const PRESENCE_WARNING_DATES_OVERLAPPING =
  'Du er ved at gemme en gentagelse, der overlapper med en tidligere gemt gentagelse for perioden {start} - {end}. Hvis du fortsætter, vil den nye gentagelse overskrive de tider, der allerede er oprettet.';
export const PRESENCE_WARNING_DATES_OVERLAPPING_MISSING_END_DATE =
  'Du er ved at gemme en gentagelse, der overlapper med en eller flere tidligere gemte tider fra {dateStart} uden slutdato. Hvis du fortsætter, vil den nye gentagelse overskrive de tider, der allerede er oprettet.';
export const PRESNECE_CANNOT_UPDATE_TEMPLATE_IN_THE_PAST =
  'Du kan ikke gemme komme/gå tiden, da dagen allerede har fundet sted.';
export const ARIA_LABEL_PRESENCE_CHOOSE_PICKUP_TYPE = 'Vælg henteansvarlig';
export const PRESENCE_CHOOSE_PICKUP_TYPE_LABEL = 'Vælg henteansvarlig';
export const PRESENCE_CHOOSE_DEPARTMENT_PLACEHOLDER = 'Vælg afdeling';
export const PRESENCE_CHOOSE_NO_DEPARTMENT_PLACEHOLDER = 'Der er ingen afdelinger';
export const ARIA_LABEL_PRESENCE_CHOOSE_WEEK = 'Vælg uge';
export const ARIA_LABEL_PRESENCE_PREVIOUS_WEEK = 'Forrige uge';
export const ARIA_LABEL_PRESENCE_NEXT_WEEK = 'Næste uge';
export const ARIA_LABEL_PRESENCE_PREVIOUS_DAY = 'Forrige dag';
export const ARIA_LABEL_PRESENCE_NEXT_DAY = 'Næste dag';
export const ARIA_LABEL_PRESENCE_PICKUP_TYPES = 'Vælg hentetype';
export const ARIA_LABEL_PRESENCE_SELF_DECIDER_START_TIME = 'Vælg starttid';
export const ARIA_LABEL_PRESENCE_SELF_DECIDER_END_TIME = 'Vælg sluttid';
export const ARIA_LABEL_CHOOSE_CHECK_OUT_TIME = 'Vælg tjek ud';
export const ARIA_LABEL_CHOOSE_CHECK_IN_TIME = 'Vælg tjek ind';
export const ARIA_LABEL_PRESENCE_PICKUP_RESPONSIBLE_NAME = 'Vælg navn';
export const ARIA_LABEL_PRESENCE_CHOOSE_GO_HOME_WITH = 'Vælg et navn';
export const ARIA_LABEL_PRESENCE_DELIVERY_TIME = 'Kommer klokken:';
export const ARIA_LABEL_PRESENCE_PICK_UP_TIME = 'Går klokken:';
export const ARIA_LABEL_PRESENCE_SPARE_ACTIVITY_FROM = 'Vælg starttid';
export const ARIA_LABEL_PRESENCE_SPARE_ACTIVITY_TO = 'Vælg sluttid';
export const ARIA_LABEL_PRESENCE_COMMENT_SPARETIME_ACTIVITY = 'Skriv note til fritidsaktivitet';
export const ARIA_LABEL_PRESENCE_COMMENT_PLACE_HOLDER = 'Skriv note';
export const ARIA_LABEL_PRESENCE_CHOOSE_DEPARTMENT = 'Vælg afdeling';
export const ARIA_LABEL_PRESENCE_CHOOSE_GROUP = 'Vælg gruppe';
export const ARIA_LABEL_PRESENCE_DASHBOARD_FILTER_BY_STATUS = 'Vælg status';
export const ARIA_LABEL_PRESENCE_DASHBOARD_FILTER_BY_ACTIVITY = 'Vælg aktivitet';
export const ARIA_LABEL_PRESENCE_DASHBOARD_REMOVE_CHILD = 'Fjern dette barn';
export const ARIA_LABEL_PRESENCE_ACITIVITY_SORT_ASCENDING_BY_NAME = 'Sorter stigende efter børns navn';
export const ARIA_LABEL_PRESENCE_ACITIVITY_SORT_DESCENDING_BY_NAME = 'Sorter faldende efter børns navn';
export const ARIA_LABEL_PRESENCE_ACITIVITY_SORT_ASCENDING_BY_STATUS = 'Sorter stigende efter børns status';
export const ARIA_LABEL_PRESENCE_ACITIVITY_SORT_DESCENDING_BY_STATUS = 'Sorter faldende efter børns status';
export const ARIA_LABEL_PRESENCE_ACITIVITY_SORT_ASCENDING_BY_NEXT_ACTIVITIES =
  'Sorter stigende efter næste aktiviteter';
export const ARIA_LABEL_PRESENCE_ACITIVITY_SORT_DESCENDING_BY_NEXT_ACTIVITIES =
  'Sorter faldende efter næste aktiviteter';

export const PRESENCE_ENTRY_TIME_NOT_SET = 'Kommer kl. (ikke angivet)';
export const PRESENCE_EXIT_TIME_NOT_SET = 'Går kl. (ikke angivet)';
export const PRESENCE_EXIT_TIME_NOT_SET_PICKED_UP_BY = 'Hentes af: {exitWith} kl. (ikke angivet)';
export const PRESENCE_EXIT_TIME_NOT_SET_PICKED_UP_BY_UNDEFINED = 'Hentes: kl. (ikke angivet)';
export const PRESENCE_EXIT_TIME_NOT_SET_GO_HOME_WITH = 'Gå hjem med: {exitWith} kl. (ikke angivet)';
export const PRESENCE_PLACEHOLDER = 'Vælg';
export const PRESENCE_WARNING_NO_EXIT_TIME = 'Du mangler at vælge en tid';
export const PRESENCE_TIME_NOT_SPECIFIED = 'ikke angivet';
export const PRESENCE_TIME_NOT_SPECIFIED_PARENTHESIS = '(ikke angivet)';
export const PRESENCE_CHILD_VACATION_CREATED = 'Ferien blev oprettet';
export const PRESENCE_WARNING_FILL_OUT_TIMES_FOR_ENTIRE_WEEK = `Der er allerede angivet tider for ugen. \nEr du sikker på, at du overskrive disse?`;

export const PRESENCE_TEMPLATE_EDIT_SINGLE_DAY = 'Rediger kun denne tid';
export const PRESENCE_TEMPLATE_EDIT_SERIES = 'Rediger alle tider i serien';
