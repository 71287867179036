// Interface for all getters, mutations and actions

// Getters
export const GET_ATTACHMENT_UPLOAD_FAILED = 'settings/GET_ATTACHMENT_UPLOAD_FAILED';
export const GET_SETTING_MENU_ITEMS = 'settings/GET_SETTING_MENU_ITEMS';
export const GET_INBOXES = 'settings/GET_INBOXES';
export const GET_SELECTED_INBOX = 'settings/GET_SELECTED_INBOX';
export const GET_SELECTED_PARTICIPANTS = 'settings/GET_SELECTED_PARTICIPANTS';
export const GET_SERVICE_REQUEST_MESSAGE = 'settings/GET_SERVICE_REQUEST_MESSAGE';
export const GET_SERVICE_REQUEST_DISCLAIMER = 'settings/GET_SERVICE_REQUEST_DISCLAIMER';
export const GET_SERVICE_REQUEST_CATALOG = 'settings/GET_SERVICE_REQUEST_CATALOG';
export const GET_SERVICE_REQUESTS = 'settings/GET_SERVICE_REQUESTS';
export const GET_SERVICE_REQUEST_DESCRIPTION = 'settings/GET_SERVICE_REQUEST_DESCRIPTION';
export const GET_SERVICE_DESK_CASES = 'settings/GET_SERVICE_DESK_CASES';
export const GET_CASE_DESCRIPTION = 'settings/GET_CASE_DESCRIPTION';
export const GET_CASE_ATTACHMENTS = 'settings/GET_CASE_ATTACHMENTS';
export const IS_COMMON_INBOX_EMAIL_IN_USE = 'settings/IS_COMMON_INBOX_EMAIL_IN_USE';

// Group templates
export const GET_GROUP_TEMPLATES = 'settings/GET_GROUP_TEMPLATES';
export const GET_ACTIVE_GROUP_TEMPLATE = 'settings/GET_ACTIVE_GROUP_TEMPLATE';
export const GET_MATCHING_GROUPS_FOR_GROUP_TEMPLATE = 'settings/GET_MATCHING_GROUPS_FOR_GROUP_TEMPLATE';
export const GET_OVERLAPPING_GROUP_TEMPLATES = 'settings/GET_OVERLAPPING_GROUP_TEMPLATES';

// Mutations
export const MUTATE_ATTACHMENT_UPLOAD_FAILED = 'messages/MUTATE_ATTACHMENT_UPLOAD_FAILED';
export const MUTATE_INIT_AUTOREPLY_ADM = 'messages/MUTATE_INIT_AUTOREPLY_ADM';
export const MUTATE_GET_INBOXES = 'settings/MUTATE_GET_INBOXES';
export const MUTATE_ADD_INBOX = 'settings/MUTATE_ADD_INBOX';
export const MUTATE_UPDATE_INBOX = 'settings/MUTATE_UPDATE_INBOX';
export const MUTATE_REMOVE_INBOX = 'settings/MUTATE_REMOVE_INBOX';
export const MUTATE_SELECT_INBOX = 'settings/MUTATE_SELECT_INBOX';
export const MUTATE_SELECT_INBOX_PARTICIPANTS = 'settings/MUTATE_SELECT_INBOX_PARTICIPANTS';
export const MUTATE_SERVICE_REQUEST_MESSAGE = 'settings/MUTATE_SERVICE_REQUEST_MESSAGE';
export const MUTATE_SERVICE_REQUEST_DISCLAIMER = 'settings/MUTATE_SERVICE_REQUEST_DISCLAIMER';
export const MUTATE_SERVICE_REQUEST_CATALOG = 'settings/MUTATE_SERVICE_REQUEST_CATALOG';
export const MUTATE_SERVICE_REQUESTS = 'settings/MUTATE_SERVICE_REQUESTS';
export const MUTATE_SERVICE_REQUEST_DESCRIPTION = 'settings/MUTATE_SERVICE_REQUEST_DESCRIPTION';
export const MUTATE_SERVICE_DESK_CASES = 'settings/MUTATE_SERVICE_DESK_CASES';
export const MUTATE_CASE_DESCRIPTION = 'settings/MUTATE_CASE_DESCRIPTION';
export const MUTATE_CASE_ATTACHMENTS = 'settings/MUTATE_CASE_ATTACHMENTS';
export const MUTATE_COMMON_INBOX_EMAIL_IN_USE = 'settings/MUTATE_COMMON_INBOX_EMAIL_IN_USE';

// Group templates
export const MUTATE_GROUP_TEMPLATES = 'settings/MUTATE_GROUP_TEMPLATES';
export const MUTATE_GROUP_TEMPLATE_BY_ID = 'settings/MUTATE_GROUP_TEMPLATE_BY_ID';
export const MUTATE_MATCHING_GROUPS_FOR_GROUP_TEMPLATE = 'settings/MUTATE_MATCHING_GROUPS_FOR_GROUP_TEMPLATE';
export const MUTATE_OVERLAPPING_GROUP_TEMPLATES = 'settings/MUTATE_OVERLAPPING_GROUP_TEMPLATES';

// Actions
export const LOAD_INBOXES = 'settings/LOAD_INBOXES';
export const COMMON_INBOX_CHECK_EMAIL_EXISTED = 'settings/COMMON_INBOX_CHECK_EMAIL_EXISTED';
export const SAVE_INBOX = 'settings/SAVE_INBOX';
export const REMOVE_INBOX = 'settings/REMOVE_INBOX';
export const SELECT_INBOX = 'settings/SELECT_INBOX';
export const SELECT_INBOX_PARTICIPANTS = 'settings/SELECT_INBOX_PARTICIPANTS';
export const LOAD_APPROVED_RECIPIENTS = 'settings/LOAD_APPROVED_RECIPIENTS';
export const REMOVE_APPROVED_RECIPIENT = 'settings/REMOVE_APPROVED_RECIPIENT';
export const SELECT_APPROVED_RECIPIENT = 'settings/SELECT_APPROVED_RECIPIENT';
export const SAVE_APPROVED_RECIPIENT = 'settings/SAVE_APPROVED_RECIPIENT';
export const SEARCH_APPROVED_RECIPIENTS = 'settings/SEARCH_APPROVED_RECIPIENTS';
export const SEND_ERROR_REPORT = 'settings/SEND_ERROR_REPORT';
export const SEND_SERVICE_REQUEST = 'settings/SEND_SERVICE_REQUEST';
export const LOAD_SERVICE_REQUEST_MESSAGE = 'settings/LOAD_SERVICE_REQUEST_MESSAGE';
export const LOAD_SERVICE_REQUEST_DISCLAIMER = 'settings/LOAD_SERVICE_REQUEST_DISCLAIMER';
export const SET_SERVICE_REQUEST_MESSAGES = 'settings/SET_SERVICE_REQUEST_MESSAGES';
export const LOAD_SERVICE_REQUEST_CATALOG = 'settings/LOAD_SERVICE_REQUEST_CATALOG';
export const LOAD_SERVICE_REQUESTS = 'settings/LOAD_SERVICE_REQUESTS';
export const LOAD_SERVICE_REQUEST_DESCRIPTION = 'settings/LOAD_SERVICE_REQUEST_DESCRIPTION';
export const ADD_SERVICE_REQUEST_COMMENT = 'settings/ADD_SERVICE_REQUEST_COMMENT';
export const SEND_SERVICE_DESK_CASE = 'settings/SEND_SERVICE_DESK_CASE';
export const SEND_APPLICATION_HELP_REPORT = 'settings/SEND_APPLICATION_HELP_REPORT';
export const LOAD_SERVICE_DESK_CASES = 'settings/LOAD_SERVICE_DESK_CASES';
export const LOAD_CASE_DESCRIPTION = 'settings/LOAD_CASE_DESCRIPTION';
export const ADD_CASE_COMMENT = 'settings/ADD_CASE_COMMENT';
export const LOAD_CASE_ATTACHMENTS = 'settings/LOAD_CASE_ATTACHMENTS';
export const ADD_CASE_ATTACHMENT = 'settings/ADD_CASE_ATTACHMENT';
export const CHECK_COMMON_INBOX_EMAIL_IN_USE = 'settings/CHECK_COMMON_INBOX_EMAIL_IN_USE';
export const CHECK_COMMON_INBOX_NAME_IN_USE = 'settings/CHECK_COMMON_INBOX_NAME_IN_USE';
export const UPDATE_DATA_POLICY = 'settings/UPDATE_DATA_POLICY';

// Group templates
export const LOAD_GROUP_TEMPLATES = 'settings/LOAD_GROUP_TEMPLATES';
export const LOAD_MATCHING_GROUPS_FOR_GROUP_TEMPLATE = 'settings/LOAD_MATCHING_GROUPS_FOR_GROUP_TEMPLATE';
export const APPLY_MATCH_GROUPS_FOR_GROUP_TEMPLATE = 'settings/APPLY_MATCH_GROUPS_FOR_GROUP_TEMPLATE';
export const LOAD_GROUP_TEMPLATE_BY_ID = 'settings/LOAD_GROUP_TEMPLATE_BY_ID';
export const CREATE_GROUP_TEMPLATE = 'settings/CREATE_GROUP_TEMPLATE';
export const UPDATE_GROUP_TEMPLATE = 'settings/UPDATE_GROUP_TEMPLATE';
export const DELETE_GROUP_TEMPLATE = 'settings/DELETE_GROUP_TEMPLATE';
export const LOAD_OVERLAPPING_GROUP_TEMPLATES = 'settings/LOAD_OVERLAPPING_GROUP_TEMPLATES';

// Configuration Access control
// Getter
export const GET_EXTERNAL_ACCESS_SYSTEMS = 'settings/GET_EXTERNAL_ACCESS_SYSTEMS';
export const GET_WORKING_ACCESS_CONTROL_CONFIGURATION = 'settings/GET_WORKING_ACCESS_CONTROL_CONFIGURATION';
// Mutation
export const MUTATE_EXTERNAL_ACCESS_SYSTEMS = 'settings/MUTATE_EXTERNAL_ACCESS_SYSTEMS';
export const MUTATE_WORKING_ACCESS_CONFIGURATION = 'settings/MUTATE_WORKING_ACCESS_CONFIGURATION';
// Action
export const LOAD_EXTERNAL_ACCESS_SYSTEMS = 'settings/LOAD_EXTERNAL_ACCESS_SYSTEMS';
export const SAVE_ACCESS_CONTROL_CONFIGURATION = 'settings/SAVE_ACCESS_CONTROL_CONFIGURATION';

// Data Export Configuration
// Getter
export const GET_DATA_EXPORT_CONFIGURATION = 'settings/GET_DATA_EXPORT_CONFIGURATION';
export const GET_ACTIVE_DATA_EXPORT_RECIPIENT = 'settings/GET_ACTIVE_DATA_EXPORT_RECIPIENT';
// Mutation
export const MUTATE_DATA_EXPORT_CONFIGURATION = 'settings/MUTATE_DATA_EXPORT_CONFIGURATION';
export const MUTATE_ACTIVE_DATA_EXPORT_RECIPIENT = 'settings/MUTATE_ACTIVE_DATA_EXPORT_RECIPIENT';
// Action
export const LOAD_DATA_EXPORT_CONFIGURATION = 'settings/LOAD_DATA_EXPORT_CONFIGURATION';
export const UPDATE_DATA_EXPORT_CONFIGURATION = 'settings/UPDATE_DATA_EXPORT_CONFIGURATION';
