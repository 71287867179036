import { types } from '../types/types';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { groupMembershipRoles } from '../../../shared/enums/groupMembershipRoles';
import { docTypes } from '../../../shared/enums/docTypes';

const state = {
  searchResults: {},
  searchString: '',
  numberOfResults: '',
  numberOfPortalRolesResults: '',
  showResult: 'Profile',
  showSubresults: [portalRoles.CHILD, portalRoles.GUARDIAN, portalRoles.EMPLOYEE],
  searchLimit: 20,
  loadMore: false,
  searchOffset: 0,
};

const getters = {
  [types.GET_SEARCH_STRING_GROUP]: state => state.searchString,
  [types.GET_GROUP_SEARCH_RESULTS]: state => state.searchResults,
  [types.GET_NUMBER_OF_RESULTS_GROUP]: state => {
    if (state.numberOfResults != null) {
      let countTotal = 0;
      for (const obj of state.numberOfResults) {
        countTotal += obj.count;
      }
      if (countTotal > 0) {
        const countGroups =
          state.numberOfResults.find(res => res.name === docTypes.GROUP) != null
            ? state.numberOfResults.find(res => res.name === docTypes.GROUP).count
            : 0;
        const countProfiles =
          state.numberOfResults.find(res => res.name === docTypes.PROFILE) != null
            ? state.numberOfResults.find(res => res.name === docTypes.PROFILE).count
            : 0;
        let countChildren = 0;
        let countEmployees = 0;
        let countGuardians = 0;
        if (countProfiles > 0) {
          countChildren =
            state.numberOfPortalRolesResults.find(res => res.name === portalRoles.CHILD) != null
              ? state.numberOfPortalRolesResults.find(res => res.name === portalRoles.CHILD).count
              : 0;
          countEmployees =
            state.numberOfPortalRolesResults.find(res => res.name === portalRoles.EMPLOYEE) != null
              ? state.numberOfPortalRolesResults.find(res => res.name === portalRoles.EMPLOYEE).count
              : 0;
          countGuardians =
            state.numberOfPortalRolesResults.find(res => res.name === portalRoles.GUARDIAN) != null
              ? state.numberOfPortalRolesResults.find(res => res.name === portalRoles.GUARDIAN).count
              : 0;
        }
        const countEvents =
          state.numberOfResults.find(res => res.name === docTypes.EVENT) != null
            ? state.numberOfResults.find(res => res.name === docTypes.EVENT).count
            : 0;
        const countPosts =
          state.numberOfResults.find(res => res.name === docTypes.POST) != null
            ? state.numberOfResults.find(res => res.name === docTypes.POST).count
            : 0;
        const countMedias =
          state.numberOfResults.find(res => res.name === docTypes.MEDIA) != null
            ? state.numberOfResults.find(res => res.name === docTypes.MEDIA).count
            : 0;

        return {
          countTotal,
          countGroups,
          countProfiles,
          countChildren,
          countGuardians,
          countEmployees,
          countEvents,
          countPosts,
          countMedias,
        };
      } else {
        return { countTotal };
      }
    } else {
      return { total: 0 };
    }
  },
  [types.GET_SHOW_RESULT_GROUP]: state => state.showResult,
  [types.GET_SHOW_SUBRESULTS_GROUP]: state => state.showSubresults,
  [types.GET_LOAD_MORE_GROUP]: state => state.loadMore,
  [types.GET_SEARCH_OFFSET_GROUP]: state => state.searchOffset,
};

const mutations = {
  [types.MUTATE_SET_SEARCH_STRING_GROUP]: (state, payload) => {
    state.searchString = payload;
    state.searchOffset = 0;
  },
  [types.MUTATE_LOAD_GROUP_SEARCH_RESULTS]: (state, payload) => {
    if (payload.showResult == docTypes.MEDIA) {
      state.searchResults = payload.searchResults.mediaResults;
    } else {
      state.searchResults = payload.searchResults.results;
    }
    state.numberOfResults = payload.searchResults.docTypeCount;
    state.numberOfPortalRolesResults = payload.searchResults.profileTypeCount;
    state.loadMore = payload.loadMore;
  },
  [types.MUTATE_LOAD_MORE_GROUP_RESULTS]: (state, payload) => {
    if (state.showResult == docTypes.MEDIA) {
      state.searchResults.push(...payload.searchResults.mediaResults);
    } else {
      state.searchResults.push(...payload.searchResults.results);
    }
    state.searchOffset = state.searchOffset + state.searchLimit;
    state.numberOfResults = payload.searchResults.docTypeCount;
    state.numberOfPortalRolesResults = payload.searchResults.profileTypeCount;
    state.loadMore = payload.searchResults.totalSize > state.searchResults.length;
  },
  [types.MUTATE_SHOW_RESULT_GROUP]: (state, payload) => {
    if (payload.showResult == docTypes.MEDIA) {
      state.searchResults = payload.searchResults.mediaResults;
    } else {
      state.searchResults = payload.searchResults.results;
    }
    state.showResult = payload.showResult;
    state.numberOfResults = payload.searchResults.docTypeCount;
    state.numberOfPortalRolesResults = payload.searchResults.profileTypeCount;
    state.loadMore = payload.loadMore;
  },
  [types.MUTATE_SHOW_SUBRESULTS_GROUP]: (state, payload) => {
    state.showSubresults = payload.showSubresults;
    state.searchResults = payload.searchResults.results;
    state.numberOfResults = payload.searchResults.docTypeCount;
    state.numberOfPortalRolesResults = payload.searchResults.profileTypeCount;
    state.loadMore = payload.loadMore;
  },
  [types.MUTATE_CLEAR_SEARCH_RESULTS]: state => {
    state.searchResults = [];
    state.searchOffset = 0;
  },
};

const actions = {
  [types.SET_MEMBERSHIP]: ({ commit, rootState }, payload) =>
    portal
      .post('?method=groups.setmembership', {
        groupId: parseInt(payload.groupId, 10),
        groupRole: payload.groupRole,
        institutionProfileId: parseInt(payload.memberId, 10),
      })
      .then(() => {
        if (
          payload.groupRole == 'inactive' &&
          rootState.globalShared.institutions.find(inst => inst.institutionProfileId == payload.memberId) != null
        ) {
          commit(types.MUTATE_SET_MEMBERSHIP, payload);
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_SET_MEMBERSHIP');
      }),
  [types.SET_JOIN_OR_LEAVE_GROUP]: ({ commit, dispatch, rootState }, payload) =>
    portal
      .post('?method=groups.joinOrLeaveGroup', {
        groupId: parseInt(payload.groupId, 10),
        institutionProfileIds: payload.institutionProfileIds,
        groupAccessType: payload.action,
      })
      .then(() => {
        const group = rootState.search.searchResults.find(r => r.docType === docTypes.GROUP && r.id == payload.groupId);
        if (group && payload.groupRole) {
          group.membershipRole = payload.groupRole;
          group.currentUserCanAccessGroupDashboard = payload.groupRole === groupMembershipRoles.MEMBER;
        }
        dispatch(types.ACTION_GET_PROFILE_CONTEXT, {
          portalrole: rootState.globalShared.currentProfile.role,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'ALERT_JOIN_OR_LEAVE_GROUP');
      }),
  [types.SET_SEARCH_STRING_GROUP]: ({ commit }, payload) => {
    commit(types.MUTATE_SET_SEARCH_STRING_GROUP, payload);
  },
  [types.LOAD_GROUP_SEARCH_RESULTS]: async ({ commit, state, dispatch, rootState }, payload) => {
    try {
      const response = await portal.get('?method=search.findGeneric', {
        params: {
          limit: state.searchLimit,
          text: payload.query,
          institutionProfileIds: rootState.global.activeInstitutionProfiles,
          activeChildrenInstitutionProfileIds: rootState.global.activeChildren,
          docType: payload.docType,
          docTypeCount: true,
          groupId: payload.groupId,
        },
      });
      const searchResultsContainer = response.data.data;
      if (searchResultsContainer.size === 0 && searchResultsContainer.docTypeCount.length > 0) {
        dispatch(types.SET_SHOW_RESULT_GROUP, searchResultsContainer.docTypeCount[0].name);
      } else {
        const loadMore = searchResultsContainer.totalSize > state.searchLimit;
        commit(types.MUTATE_LOAD_GROUP_SEARCH_RESULTS, {
          searchResults: searchResultsContainer,
          loadMore: loadMore,
          showResult: payload.docType,
        });
      }
    } catch (error) {
      commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_GENERIC');
    }
  },
  [types.SET_SHOW_RESULT_GROUP]: ({ commit, rootState }, payload) => {
    commit(types.MUTATE_CLEAR_SEARCH_RESULTS);
    return portal
      .get('?method=search.findGeneric', {
        params: {
          limit: state.searchLimit,
          text: state.searchString,
          institutionProfileIds: rootState.global.activeInstitutionProfiles,
          activeChildrenInstitutionProfileIds: rootState.global.activeChildren,
          docType: payload,
          docTypeCount: true,
          groupId: rootState.groupsShared.activeGroup.id,
        },
      })
      .then(response => {
        const searchResults = response.data.data;
        const loadMore = searchResults.totalSize > state.searchLimit;
        commit(types.MUTATE_SHOW_RESULT_GROUP, {
          searchResults: searchResults,
          showResult: payload,
          loadMore: loadMore,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_GENERIC');
      });
  },
  [types.SET_SHOW_SUBRESULTS_GROUP]: ({ commit, rootState }, payload) =>
    portal
      .get('?method=search.findGeneric', {
        params: {
          limit: state.searchLimit,
          text: state.searchString,
          institutionProfileIds: rootState.global.activeInstitutionProfiles,
          activeChildrenInstitutionProfileIds: rootState.global.activeChildren,
          docType: payload.docType,
          docTypeCount: true,
          roles: payload.roles,
          groupId: rootState.groupsShared.activeGroup.id,
        },
      })
      .then(response => {
        const searchResults = response.data.data;
        const loadMore = searchResults.totalSize > state.searchLimit ? true : false;
        commit(types.MUTATE_SHOW_SUBRESULTS_GROUP, {
          showSubresults: payload.roles,
          searchResults: searchResults,
          loadMore: loadMore,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_GENERIC');
      }),
  [types.LOAD_MORE_GROUP_RESULTS]: ({ commit, state, rootState }) =>
    portal
      .get('?method=search.findGeneric', {
        params: {
          limit: state.searchLimit,
          offset: state.searchOffset + state.searchLimit,
          institutionProfileIds: rootState.global.activeInstitutionProfiles,
          activeChildrenInstitutionProfileIds: rootState.global.activeChildren,
          text: state.searchString,
          docType: state.showResult,
          docTypeCount: true,
          groupId: rootState.groupsShared.activeGroup.id,
        },
      })
      .then(response => {
        const searchResults = response.data.data;
        commit(types.MUTATE_LOAD_MORE_GROUP_RESULTS, {
          searchResults: searchResults,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_FIND_GENERIC');
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
