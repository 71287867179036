// Interface for all getters, mutations and actions
// Getters
export const GET_ACTIVITY_LIST = 'presence_employee/GET_ACTIVITY_LIST';
export const GET_HAS_MORE_ACTIVITY = 'presence_employee/GET_HAS_MORE_ACTIVITY';
export const GET_VACATION_LIST = 'presence_employee/GET_VACATION_LIST';
export const GET_WEEK_OVERVIEW_DATA = 'presence_employee/GET_WEEK_OVERVIEW_DATA';
export const GET_PRESENCE_DISTRIBUTION = 'presence_employee/GET_PRESENCE_DISTRIBUTION';
export const GET_MORE_VACATIONS_EXIST = 'presence_employee/GET_MORE_VACATIONS_EXIST';
export const GET_HAS_MORE_WEEK_OVERVIEW = 'presence_employee/GET_HAS_MORE_WEEK_OVERVIEW';
export const GET_HAS_MORE_VACATION_REGISTRATIONS = 'presence_employee/GET_HAS_MORE_VACATION_REGISTRATIONS';
export const GET_TOTAL_VACATIONS = 'presence_employee/GET_TOTAL_VACATIONS';
export const GET_DEPARTMENTS = 'presence_employee/GET_DEPARTMENTS';
export const GET_SELECTED_DEPARTMENT = 'presence_employee/GET_SELECTED_DEPARTMENT';
export const GET_DEPARTMENT_GROUPS = 'presence_employee/GET_DEPARTMENT_GROUPS';
export const GET_FILTER_OPTIONS = 'presence_employee/GET_FILTER_OPTIONS';
export const GET_PRESENCE_STATES = 'presence_employee/GET_PRESENCE_STATES';
export const GET_PRESENCE_ACTIVITIES = 'presence_employee/GET_PRESENCE_ACTIVITIES';
export const GET_ACTIVE_PRESENCE_TEMPLATE_FOR_DATE = 'presence_employee/GET_ACTIVE_PRESENCE_TEMPLATE_FOR_DATE';
export const GET_SELECTED_REGISTRATION_TO_EDIT = 'presence_employee/GET_SELECTED_REGISTRATION_TO_EDIT';
export const GET_SLEEP_INTERVAL_FOR_CHILDREN = 'presence_employee/GET_SLEEP_INTERVAL_FOR_CHILDREN';
export const GET_CHILDREN_PRESENCE_REGISTRATION = 'presence_employee/GET_CHILDREN_PRESENCE_REGISTRATION';
export const GET_PICK_UP_RESPONSIBLES = 'presence_employee/GET_PICK_UP_RESPONSIBLES';
export const GET_PRESENCE_FILTERS = 'presence_employee/GET_PRESENCE_FILTERS';
export const GET_VACATION_REGISTRATION_DATA = 'presence_employee/GET_VACATION_REGISTRATION_DATA';

// Mutations
export const MUTATE_ACTIVITY_FILTER = 'presence_employee/MUTATE_ACTIVITY_FILTER';
export const MUTATE_ACTIVITY_LIST = 'presence_employee/MUTATE_ACTIVITY_LIST';
export const MUTATE_VACATION_LIST = 'presence_employee/MUTATE_VACATION_LIST';
export const MUTATE_WEEK_OVERVIEW_LIST = 'presence_employee/MUTATE_WEEK_OVERVIEW_LIST';
export const MUTATE_PRESENCE_DISTRIBUTION = 'presence_employee/MUTATE_PRESENCE_DISTRIBUTION';
export const MUTATE_PRESENCE_TEMPLATE_FOR_DATE = 'presence_employee/MUTATE_PRESENCE_TEMPLATE_FOR_DATE';
export const MUTATE_FILTER_OPTIONS = 'presence_employee/MUTATE_FILTER_OPTIONS';
export const MUTATE_SELECTED_DEPARTMENT = 'presence_employee/MUTATE_SELECTED_DEPARTMENT';
export const MUTATE_SELECTED_REGISTRATION_TO_EDIT = 'presence_employee/MUTATE_SELECTED_REGISTRATION_TO_EDIT';
export const MUTATE_PRESENCE_REGISTRATION_BY_IDS = 'presence_employee/MUTATE_PRESENCE_REGISTRATION_BY_IDS';
export const MUTATE_PICK_UP_RESPONSIBLES = 'presence_employee/MUTATE_PICK_UP_RESPONSIBLES';
export const MUTATE_VACATION_REGISTRATIONS = 'presence_employee/MUTATE_VACATION_REGISTRATIONS';

// Actions
export const UPDATE_PRESENCE_REGISTRATION = 'presence_employee/UPDATE_PRESENCE_REGISTRATION';
export const UPDATE_SELECTED_CHILDREN_STATUS = 'presence_employee/UPDATE_SELECTED_CHILDREN_STATUS';
export const LOAD_PRESENCE_FILTER = 'presence_employee/LOAD_PRESENCE_FILTER';
export const LOAD_ACTIVITY_LIST = 'presence_employee/LOAD_ACTIVITY_LIST';
export const RELOAD_ACTIVITY_LIST = 'presence_employee/RELOAD_ACTIVITY_LIST';
export const LOAD_VACATION_LIST = 'presence_employee/LOAD_VACATION_LIST';
export const LOAD_WEEK_OVERVIEW = 'presence_employee/LOAD_WEEK_OVERVIEW';
export const LOAD_PRESENCE_DISTRIBUTION = 'presence_employee/LOAD_PRESENCE_DISTRIBUTION';
export const LOAD_PRESENCE_TEMPLATE_FOR_DATE = 'presence_employee/LOAD_PRESENCE_TEMPLATE_FOR_DATE';
export const EMPLOYEE_ADD_SLEEP_INTERVAL = 'presence_employee/EMPLOYEE_ADD_SLEEP_INTERVAL';
export const EMPLOYEE_DELETE_SLEEP_INTERVAL = 'presence_employee/EMPLOYEE_DELETE_SLEEP_INTERVAL';
export const EMPLOYEE_UPDATE_SLEEP_INTERVAL = 'presence_employee/EMPLOYEE_UPDATE_SLEEP_INTERVAL';
export const UPDATE_LOCATION_FOR_CHILDREN = 'presence_employee/UPDATE_LOCATION_FOR_CHILDREN';
export const ADD_SLEEP_INTERVAL_FOR_CHILDREN = 'presence_employee/ADD_SLEEP_INTERVAL_FOR_CHILDREN';
export const UPDATE_PRESENCE_STATUS_FOR_CHILDREN = 'presence_employee/UPDATE_PRESENCE_STATUS_FOR_CHILDREN';
export const ADD_VACATION_FOR_CHILDREN = 'presence_employee/ADD_VACATION_FOR_CHILDREN';
export const REMOVE_VACATION_FOR_CHILDREN = 'presence_employee/REMOVE_VACATION_FOR_CHILDREN';
export const LOAD_PRESENCE_REGISTRATION_BY_IDS = 'presence_employee/LOAD_PRESENCE_REGISTRATION_BY_IDS';
export const LOAD_PICK_UP_RESPONSIBLES = 'presence_employee/LOAD_PICK_UP_RESPONSIBLES';
export const UPDATE_EXIT_TYPE_FOR_CHILD = 'presence_employee/UPDATE_EXIT_TYPE_FOR_CHILD';
export const UPDATE_SLEEP_INTERVAL_FOR_CHILD = 'presence_employee/UPDATE_SLEEP_INTERVAL_FOR_CHILD';
export const LOAD_VACATION_REGISTRATIONS = 'presence_employee/LOAD_VACATION_REGISTRATIONS';
