// Interface for all getters, mutations and actions

// GETTERS
export const GET_ACTIVE_NOTICE_BOARD = 'noticeBoards/GET_ACTIVE_NOTICE_BOARD';
export const GET_NOTICE_BOARD_COMPONENTS = 'noticeBoards/GET_NOTICE_BOARD_COMPONENTS';
export const GET_NOTICE_BOARD_TOKEN = 'noticeBoards/GET_NOTICE_BOARD_TOKEN';
export const HAS_NOTICE_BOARD_CHANGED = 'noticeBoards/HAS_NOTICE_BOARD_CHANGED';

// MUTATIONS
export const MUTATE_ACTIVE_NOTICE_BOARDS = 'noticeBoards/MUTATE_ACTIVE_NOTICE_BOARDS';
export const MUTATE_NOTICE_BOARD_COMPONENTS = 'noticeBoards/MUTATE_NOTICE_BOARD_COMPONENTS';
export const MUTATE_ACTIVE_NOTICE_BOARD = 'noticeBoards/MUTATE_ACTIVE_NOTICE_BOARD';
export const MUTATE_NOTICE_BOARD_TOKEN = 'noticeBoards/MUTATE_NOTICE_BOARD_TOKEN';
export const MUTATE_NOTICE_BOARD_HAS_CHANGED = 'noticeBoards/MUTATE_NOTICE_BOARD_HAS_CHANGED';

// ACTIONS
export const LOAD_NOTICE_BOARD_BY_ID = 'noticeBoards/LOAD_NOTICE_BOARD_BY_ID';
export const SAVE_NOTICE_BOARD = 'noticeBoards/SAVE_NOTICE_BOARD';
export const LOAD_NOTICE_BOARD_COMPONENTS = 'noticeBoards/LOAD_NOTICE_BOARD_COMPONENTS';
export const DELETE_NOTICE_BOARD_BY_ID = 'noticeBoards/DELETE_NOTICE_BOARD_BY_ID';
export const REMOVE_NOTICE_BOARD_DEVICES = 'noticeBoards/REMOVE_NOTICE_BOARD_DEVICES';
export const GENERATE_NOTICE_BOARD_TOKEN = 'noticeBoards/GENERATE_NOTICE_BOARD_TOKEN';
