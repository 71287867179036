// Interface for all getters, mutations and actions
// Getters
export const GET_PERSONAL_REFERENCE_DATA_QUESTION = 'personalReferenceData/GET_PERSONAL_REFERENCE_DATA_QUESTION';
export const GET_PERSONAL_REFERENCE_DATA_ANSWER = 'personalReferenceData/GET_PERSONAL_REFERENCE_DATA_ANSWER';
export const GET_HAS_MORE_ANSWER = 'personalReferenceData/GET_HAS_MORE_ANSWER';

// Mutations
export const MUTATE_PERSONAL_REFERENCE_DATA_QUESTION = 'personalReferenceData/MUTATE_PERSONAL_REFERENCE_DATA_QUESTION';
export const MUTATE_PERSONAL_REFERENCE_DATA_ANSWER = 'personalReferenceData/MUTATE_PERSONAL_REFERENCE_DATA_ANSWER';

// Actions
export const LOAD_PERSONAL_REFERENCE_DATA_QUESTION = 'personalReferenceData/LOAD_PERSONAL_REFERENCE_DATA_QUESTION';
export const LOAD_PERSONAL_REFERENCE_DATA_ANSWER = 'personalReferenceData/LOAD_PERSONAL_REFERENCE_DATA_ANSWER';
