import { types } from '../types/types';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { Cookie } from '../../../shared/assets/plugins/cookie';
import { onboardingStatusEnum } from '../../../shared/enums/onboardingStatusEnum';
import { parentTypes } from '../../../shared/enums/parentTypes.ts';

const state = {
  personalDataPolicies: null,
  additionalData: null,
  additionalDataForOnboarding: null,
  onboardingSteps: [],
  onboardingStatus: null,
  isOnboardingError: false,
};

const getters = {
  [types.IS_ONBOARDING_ERROR]: state => state.isOnboardingError,
  [types.IS_ONBOARDING_NEEDED]: state => state.onboardingStatus,
  [types.GET_ONBOARDING_STEPS]: state => state.onboardingSteps,
  [types.GET_PERSONAL_DATA_POLICIES]: state => state.personalDataPolicies,
  [types.GET_ADDITIONAL_DATA]: state => state.additionalData,
  [types.GET_ADDITIONAL_DATA_FOR_ONBOARDING]: state => state.additionalDataForOnboarding,
};

const mutations = {
  [types.MUTATE_IS_ONBOARDING_NEEDED]: (state, payload) => {
    if (payload.isStepUpNeeded) {
      state.onboardingStatus = onboardingStatusEnum.NEEDS_STEP_UP;
    } else {
      if (payload.stepStatus.length > 0) {
        state.onboardingSteps = payload.stepStatus.sort((step1, step2) => (step1.order > step2.order ? 1 : -1));
        state.onboardingStatus = onboardingStatusEnum.REQUIRED;
      } else {
        state.onboardingStatus = onboardingStatusEnum.COMPLETED;
      }
    }
  },
  [types.MUTATE_ONBOARDING_ERROR]: state => {
    state.isOnboardingError = true;
  },
  [types.MUTATE_MARK_ONBOARDGED]: state => {
    state.onboardingStatus = onboardingStatusEnum.COMPLETED;
  },
  [types.MUTATE_PERSONAL_DATA_POLICIES]: (state, payload) => {
    state.personalDataPolicies = payload;
  },
  [types.MUTATE_ADDITIONAL_DATA]: (state, payload) => {
    state.additionalData = payload;
  },
  [types.MUTATE_ANY_UNANSWERED_ADDITIONAL_DATA_RESPONSES]: (state, payload) => {
    state.anyUnansweredAdditionalDataResponses = payload;
  },
  [types.MUTATE_ADDITIONAL_DATA_FOR_ONBOARDING]: (state, payload) => {
    state.additionalDataForOnboarding = payload;
  },
};

const actions = {
  [types.GET_ONBOARDING_PROFILES]: async ({ dispatch, rootState, rootGetters }) => {
    if (rootState.globalShared.profiles.length > 0) {
      const profiles = rootState.globalShared.profiles;
      const activePortalRole = rootState.globalShared.currentProfile.role;
      return [profiles, activePortalRole];
    } else {
      await dispatch(types.LOAD_PROFILES_BY_LOGIN, {
        returnOnlyPendingConsentResponses: true,
      });

      // Can not use GET_ because when you refresh the onboarding page,
      // the GET_CURRENT_PROFILE return its default value before it is set
      await dispatch(types.ACTION_GET_PROFILE_CONTEXT, { parent: parentTypes.PORTAL });

      const profiles = rootState.globalShared.profiles;
      const activePortalRole = rootGetters['global/GET_CURRENT_PROFILE'].role;

      return [profiles, activePortalRole];
    }
  },
  [types.CHECK_IS_ONBOARDING_NEEDED]: async ({ commit, state, dispatch, rootState }) => {
    let profiles = [];
    let activePortalRole = '';

    await dispatch(types.GET_ONBOARDING_PROFILES).then(results => {
      profiles = results[0];
      activePortalRole = results[1];
    });

    const steps = {
      policy: { isNeeded: false, isLoaded: false, isFailed: false },
      update: { isNeeded: false, isLoaded: false, isFailed: false },
      consent: { isNeeded: false, isLoaded: false, isFailed: false },
      additional: { isNeeded: false, isLoaded: false, isFailed: false },
    };

    const stepStatus = [];
    const profile = profiles.find(p => p.portalRole === activePortalRole);
    let isStepUpNeeded = false;
    let neededUpdateContactInfo = false;

    if (
      profile.institutionProfiles?.some(
        profile => profile.newInstitutionProfile && (profile.role !== portalRoles.GUARDIAN || profile.isPrimary)
      ) ||
      (profile.children?.length > 0 && profile.children.some(c => c.institutionProfile.newInstitutionProfile === true))
    ) {
      neededUpdateContactInfo = true;
    }
    switch (profile.portalRole) {
      case portalRoles.CHILD:
        if (profile.age18AndOlder) {
          if (!profile.isLatestDataPolicyAccepted) {
            steps.policy.isNeeded = true;
          }
          if (neededUpdateContactInfo) {
            steps.update.isNeeded = true;
          }
          steps.consent.isNeeded = true;
        } else {
          if (profile.contactInfoEditable) {
            if (neededUpdateContactInfo) {
              steps.update.isNeeded = true;
            }
            steps.consent.isNeeded = true;
          } else if (profile.overConsentAge) {
            steps.consent.isNeeded = true;
          }
        }
        break;
      case portalRoles.EMPLOYEE:
        if (!profile.isLatestDataPolicyAccepted) {
          steps.policy.isNeeded = true;
        }
        if (neededUpdateContactInfo) {
          steps.update.isNeeded = true;
        }
        steps.additional.isNeeded = true;
        break;
      case portalRoles.GUARDIAN:
        if (!profile.isLatestDataPolicyAccepted) {
          steps.policy.isNeeded = true;
        }
        if (neededUpdateContactInfo) {
          steps.update.isNeeded = true;
        }
        steps.consent.isNeeded = true;
        steps.additional.isNeeded = true;
        break;
      case portalRoles.OTP:
        if (!profile.isLatestDataPolicyAccepted) {
          steps.policy.isNeeded = true;
        } else {
          await dispatch(types.MARK_ONBOARDING_COMPLETED);
        }
        break;
    }

    // The returned profiles from getProfilesByLogin will always be set as guardian if user has logged in as guardian through Unilogin
    // The portalRole set by getProfileContext will set portalRole to OTP if the user chooses to login as OTP.
    // We need to check the portalRole set by getProfileContext explictly to determine if the user is OTP.
    // If Unilogin at some point sets the role as OTP in the future, this code will be redudant and the OTP switchcase above will be sufficient
    if (rootState.globalShared != null && rootState.globalShared.currentProfile.role === portalRoles.OTP) {
      if (profiles.some(profile => !profile.isLatestDataPolicyAccepted)) {
        steps.policy.isNeeded = true;
      } else {
        await dispatch(types.MARK_ONBOARDING_COMPLETED);
      }
    }

    if (steps.policy.isNeeded && profiles.filter(p => p.newProfile === true) != null) {
      stepStatus.push({
        step: 'policy',
        disabledSubmit: false,
        isLocked: false,
        order: 1,
      });
      steps.policy.isLoaded = true;
    }
    if (steps.update.isNeeded) {
      stepStatus.push({
        step: 'update',
        disabledSubmit: false,
        isLocked: true,
        order: 2,
      });
      steps.update.isLoaded = true;
    }
    if (steps.consent.isNeeded) {
      commit(types.MUTATE_RESET_MYPROFILE_PENDING_CONSENT);
      await dispatch(types.ACTION_GET_CONSENTS_RESPONSE, {
        returnOnlyPendingConsentResponses: true,
        isOnboarding: true,
      })
        .then(() => {
          if (rootState.consents.myProfilePendingConsents.length > 0) {
            if (
              rootState.consents.myProfilePendingConsents.filter(
                c => c.consentResponses !== null && c.consentResponses.length > 0
              ).length > 0
            ) {
              if (rootState.globalShared.isSteppedUp) {
                stepStatus.push({
                  step: 'consent',
                  disabledSubmit: false,
                  isLocked: true,
                  order: 3,
                });
              } else {
                isStepUpNeeded = true;
              }
            }
          }
          steps.consent.isLoaded = true;
        })
        .catch(() => {
          steps.consent.isFailed = true;
        });
    }
    if (steps.additional.isNeeded) {
      await dispatch(types.LOAD_UNANSWERED_ADDITIONAL_DATA_RESPONSES)
        .then(() => {
          if (state.anyUnansweredAdditionalDataResponses) {
            if (rootState.globalShared.isSteppedUp) {
              stepStatus.push({
                step: 'additional',
                disabledSubmit: false,
                isLocked: true,
                order: 4,
              });
            } else {
              isStepUpNeeded = true;
            }
          }
          steps.additional.isLoaded = true;
        })
        .catch(() => {
          steps.additional.isFailed = true;
        });
    }
    let isAllStepLoaded = true;
    let isFailed = false;
    for (const key of Object.keys(steps)) {
      if (steps[key].isNeeded != steps[key].isLoaded) {
        isAllStepLoaded = false;
      }
      if (steps[key].isFailed) {
        isFailed = true;
      }
    }
    if (isFailed) {
      if (Cookie.Read('isOnboardingStep')) {
        Cookie.Erase('isOnboardingStep');
      }
      window.location.href = '/';
    }
    if (isAllStepLoaded) {
      return commit(types.MUTATE_IS_ONBOARDING_NEEDED, {
        stepStatus: stepStatus,
        isStepUpNeeded: isStepUpNeeded,
      });
    }
  },
  [types.LOAD_PERSONAL_DATA_POLICIES]: ({ commit, rootState }) =>
    portal
      .get('?method=commonFiles.getPersonalDataPolicies', {
        params: {
          institutionCodes: rootState.global.activeInstitutions,
        },
      })
      .then(response => {
        commit(types.MUTATE_PERSONAL_DATA_POLICIES, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PERSONAL_DATA_POLICIES');
      }),
  [types.UPDATE_PROFILE_MASTER_DATA]: ({ commit }, payload) =>
    portal.post('?method=profiles.updateProfileMasterData', payload).catch(() => {
      commit(types.MUTATE_ONBOARDING_ERROR);
      commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_PROFILE_MASTER_DATA');
    }),
  [types.LOAD_ADDITIONAL_DATA]: ({ commit }, payload) => {
    const apiEndPoint = '?method=profiles.getAdditionalDataResponsesForOwner';
    const params = {};
    if (payload.isResponded != null) {
      params.isResponded = payload.isResponded;
    }
    if (payload.institutionRoles != null) {
      params.institutionRoles = payload.institutionRoles;
    }
    return portal
      .get(apiEndPoint, {
        params,
      })
      .then(response => {
        commit(types.MUTATE_ADDITIONAL_DATA_FOR_ONBOARDING, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ONBOARDING_ERROR);
      });
  },
  [types.LOAD_ADDITIONAL_DATA_ADMIN]: ({ commit }, payload) => {
    const apiEndPoint = '?method=profilesAdmin.getAdditionalDataResponsesForOwner';
    const params = {};
    if (payload.institutionRoles != null) {
      params.institutionRoles = payload.institutionRoles;
    }
    if (payload.isResponded != null) {
      params.isResponded = payload.isResponded;
    }
    return portal
      .get(apiEndPoint, {
        params,
      })
      .then(response => {
        commit(types.MUTATE_ADDITIONAL_DATA, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ADDITIONAL_DATA');
      });
  },
  [types.LOAD_UNANSWERED_ADDITIONAL_DATA_RESPONSES]: ({ commit }) => {
    const apiEndPoint = '?method=profiles.getAnyUnansweredAdditionalDataResponsesForOwner';
    return portal
      .get(apiEndPoint)
      .then(response => {
        commit(types.MUTATE_ANY_UNANSWERED_ADDITIONAL_DATA_RESPONSES, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ANY_UNANSWERED_ADDITIONAL_DATA_RESPONSES');
      });
  },
  [types.UPDATE_ADDITIONAL_DATA]: ({ commit }, payload) =>
    portal
      .post('?method=profiles.updateAdditionalDataResponses', {
        institutionProfilesAdditionalDataResponses: payload,
      })
      .catch(() => {
        commit(types.MUTATE_ONBOARDING_ERROR);
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ADDITIONAL_DATA');
      }),
  [types.UPDATE_ADDITIONAL_DATA_ON_BEHALF]: ({ commit }, payload) =>
    portal
      .post('?method=profiles.updateAdditionalDataResponsesEmployee', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'ADDITIONAL_DATA_SAVED_SUCCESS');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_ADDITIONAL_DATA');
      }),
  [types.MARK_ONBOARDING_COMPLETED]: ({ commit }, payload) => {
    const params = {};
    params.onlyActiveProfile = Boolean(payload?.onlyActiveProfile);
    portal.get('?method=profiles.markOnboardingCompleted', { params }).catch(() => {
      commit(types.MUTATE_ONBOARDING_ERROR);
      commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_MARK_ONBOARDING_COMPLETED');
    });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
