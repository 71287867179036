import { types } from '../types/types';
import { portal } from '../../../shared/assets/plugins/axios/axios';

const state = {
  personalReferenceDataQuestion: [],
  personalReferenceDataAnswer: [],
  answersPerPage: 20,
  hasMoreAnswer: false,
};

const getters = {
  [types.GET_HAS_MORE_ANSWER]: state => state.hasMoreAnswer,
  [types.GET_PERSONAL_REFERENCE_DATA_QUESTION]: state => state.personalReferenceDataQuestion,
  [types.GET_PERSONAL_REFERENCE_DATA_ANSWER]: state => state.personalReferenceDataAnswer,
};

const mutations = {
  [types.MUTATE_PERSONAL_REFERENCE_DATA_QUESTION]: (state, payload) => {
    state.personalReferenceDataQuestion = payload;
  },
  [types.MUTATE_PERSONAL_REFERENCE_DATA_ANSWER]: (state, payload) => {
    if (payload.data.length === 0) {
      state.hasMoreAnswer = false;
    } else {
      state.hasMoreAnswer = payload.data.length >= state.answersPerPage;
    }
    if (payload.isLoadMore) {
      state.personalReferenceDataAnswer.push(...payload.data);
    } else {
      state.personalReferenceDataAnswer = payload.data;
    }
  },
};

const actions = {
  [types.LOAD_PERSONAL_REFERENCE_DATA_QUESTION]: ({ commit }, payload) =>
    portal
      .get('?method=personalReferenceData.getPersonalReferenceDataQuestion', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_PERSONAL_REFERENCE_DATA_QUESTION, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PERSONAL_REFERENCE_DATA_QUESTION');
      }),
  [types.LOAD_PERSONAL_REFERENCE_DATA_ANSWER]: ({ commit }, payload) => {
    let endPoint = '?method=personalReferenceData.getPersonalReferenceDataConsentAnswer';
    if (payload.additionalDataId != null) {
      endPoint = '?method=personalReferenceData.getPersonalReferenceDataAdditionalDataAnswer';
    }
    return portal
      .get(endPoint, { params: payload })
      .then(response => {
        commit(types.MUTATE_PERSONAL_REFERENCE_DATA_ANSWER, {
          data: response.data.data,
          isLoadMore: payload.isLoadMore,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PERSONAL_REFERENCE_DATA_QUESTION');
      });
  },
};

export default {
  state,
  mutations,
  actions,
  getters,
};
