import { types } from '../../../src/store/types/types';
import { portal } from '../../assets/plugins/axios/axios';

const state = {
  municipalIPSList: [],
};

const getters = {
  [types.GET_MUNICIPAL_IPS]: state => state.municipalIPSList,
};

const mutations = {
  [types.MUTATE_SET_MUNICIPAL_IPS]: (state, payload) => {
    state.municipalIPSList = payload;
  },
};
const actions = {
  [types.SET_MUNICIPAL_IPS]: ({ commit }) =>
    portal
      .get('?method=identityProviders.getMunicipalIdPs', {})
      .then(response => commit(types.MUTATE_SET_MUNICIPAL_IPS, response.data.data))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_MUNICIPAL_IDPS');
      }),
  [types.RESET_MUNICIPAL_AND_INSTITUTION]: ({ commit }, payload) => {
    commit(types.MUTATE_SET_MUNICIPAL_IPS, payload);
  },
};

export default {
  state,
  getters,
  actions,
  mutations,
};
