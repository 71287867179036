// Interface for all getters, mutations and actions
// Getters
export const GET_PRESENCE_TIMES = 'presence/GET_PRESENCE_TIMES';
export const GET_PRESENCE_STATES_GUARDIAN = 'presence/GET_PRESENCE_STATES_GUARDIAN';
export const GET_SUGGESTED_PICKUP_NAME = 'presence/GET_SUGGESTED_PICKUP_NAME';
export const GET_PICKUP_RESPONSIBLES = 'presence/GET_PICKUP_RESPONSIBLES';
export const GET_GO_HOME_WITH_LIST = 'presence/GET_GO_HOME_WITH_LIST';
export const GET_DAILY_OVERVIEW = 'presence/GET_DAILY_OVERVIEW';
export const GET_PRESENCE_ACTIVE_MODULES = 'presence/GET_PRESENCE_ACTIVE_MODULES';
export const GET_ALL_PRESENCE_MODULES = 'presence/GET_ALL_PRESENCE_MODULES';
export const GET_PRESENCE_CONFIGURATION = 'presence/GET_PRESENCE_CONFIGURATION';
export const GET_VACATION_REGISTRATIONS_BY_CHILDREN = 'presence/GET_VACATION_REGISTRATIONS_BY_CHILDREN';
export const GET_VACATION_ANNOUNCEMENTS_BY_CHILDREN = 'presence/GET_VACATION_ANNOUNCEMENTS_BY_CHILDREN';
export const GET_VACATION_REQUEST_RESPONSE = 'presence/GET_VACATION_REQUEST_RESPONSE';
export const GET_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD = 'presence/GET_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD';
export const GET_GENERAL_OPENING_HOURS_FOR_GUARDIAN = 'presence/GET_GENERAL_OPENING_HOURS_FOR_GUARDIAN';
export const GET_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN = 'presence/GET_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN';
export const GET_HOLIDAY_CLOSED_DAY = 'presence/GET_HOLIDAY_CLOSED_DAY';
export const GET_SELECTED_PRESENCE_WEEK = 'presence/GET_SELECTED_PRESENCE_WEEK';
export const GET_OVERLAPPING_PRESENCE_TEMPLATES = 'presence/GET_OVERLAPPING_PRESENCE_TEMPLATES';

// Mutations
export const MUTATE_PRESENCE_TIMES = 'presence/MUTATE_PRESENCE_TIMES';
export const MUTATE_PRESENCE_CONFIGURATION = 'presence/MUTATE_PRESENCE_CONFIGURATION';
export const MUTATE_PRESENCE_CONFIGURATIONS = 'presence/MUTATE_PRESENCE_CONFIGURATIONS';
export const MUTATE_PRESENCE_STATES = 'presence/MUTATE_PRESENCE_STATES';
export const MUTATE_SUGGESTED_PICKUP_NAME = 'presence/MUTATE_SUGGESTED_PICKUP_NAME';
export const MUTATE_PICKUP_RESPONSIBLES = 'presence/MUTATE_PICKUP_RESPONSIBLES';
export const MUTATE_DAILY_OVERVIEW = 'presence/MUTATE_DAILY_OVERVIEW';
export const MUTATE_GO_HOME_WITH_LIST = 'presence/MUTATE_GO_HOME_WITH_LIST';
export const MUTATE_REMOVE_PICUP_RESPONSIBLE = 'presence/MUTATE_REMOVE_PICUP_RESPONSIBLE';
export const MUTATE_VACATION_REGISTRATIONS_BY_CHILDREN = 'presence/MUTATE_VACATION_REGISTRATIONS_BY_CHILDREN';
export const MUTATE_VACATION_ANNOUNCEMENTS_BY_CHILDREN = 'presence/MUTATE_VACATION_ANNOUNCEMENTS_BY_CHILDREN';
export const MUTATE_VACATION_REQUEST_RESPONSE = 'presence/MUTATE_VACATION_REQUEST_RESPONSE';
export const MUTATE_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD = 'presence/MUTATE_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD';
export const MUTATE_GENERAL_OPENING_HOURS_FOR_GUARDIAN = 'presence/MUTATE_GENERAL_OPENING_HOURS_FOR_GUARDIAN';
export const MUTATE_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN = 'presence/MUTATE_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN';
export const MUTATE_HOLIDAY_CLOSED_DAY = 'presence/MUTATE_HOLIDAY_CLOSED_DAY';
export const MUTATE_SELECTED_PRESENCE_WEEK = 'presence/MUTATE_SELECTED_PRESENCE_WEEK';
export const MUTATE_OVERLAPPING_PRESENCE_TEMPLATES = 'presence/MUTATE_OVERLAPPING_PRESENCE_TEMPLATES';

// Actions
export const LOAD_PRESENCE_TIMES = 'presence/LOAD_PRESENCE_TIMES';
export const LOAD_PRESENCE_CONFIGURATION = 'presence_portal/LOAD_PRESENCE_CONFIGURATION';
export const UPDATE_SELECTED_CHILDREN_STATUS = 'presence/UPDATE_SELECTED_CHILDREN_STATUS';
export const LOAD_PRESENCE_CONFIGURATIONS_CHILD_IDS = 'presence/LOAD_PRESENCE_CONFIGURATIONS_CHILD_IDS';
export const LOAD_PRESENCE_STATES = 'presence/LOAD_PRESENCE_STATES';
export const UPDATE_PRESENCE_TEMPLATE = 'presence/UPDATE_PRESENCE_TEMPLATE';
export const UPDATE_SICK = 'presence/UPDATE_SICK';
export const ADD_ABSENCE = 'presence/ADD_ABSENCE';
export const LOAD_PICKUP_RESPONSIBLES = 'presence/LOAD_PICKUP_RESPONSIBLES';
export const LOAD_GO_HOME_WITH_LIST = 'presence/LOAD_GO_HOME_WITH_LIST';
export const LOAD_DAILY_OVERVIEW = 'presence/LOAD_DAILY_OVERVIEW';
export const DELETE_PICKUP_RESPONSIBLES = 'presence/DELETE_PICKUP_RESPONSIBLES';
export const SAVE_PICKUP_RESPONSIBLES = 'presence/SAVE_PICKUP_RESPONSIBLES';
export const DELETE_REPEATING_PRESENCE_TEMPLATE = 'presence/DELETE_REPEATING_PRESENCE_TEMPLATE';
export const LOAD_VACATION_REGISTRATIONS_BY_CHILDREN = 'presence/LOAD_VACATION_REGISTRATIONS_BY_CHILDREN';
export const LOAD_VACATION_ANNOUCEMENTS_BY_CHILDREN = 'presence/LOAD_VACATION_ANNOUCEMENTS_BY_CHILDREN';
export const LOAD_VACATION_REQUEST_RESPONSE = 'presence/LOAD_VACATION_REQUEST_RESPONSE';
export const LOAD_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD = 'presence/LOAD_PRESENCE_OPEN_HOURS_GUARDIAN_DASHBOARD';
export const LOAD_GENERAL_OPENING_HOURS_FOR_GUARDIAN = 'presence/LOAD_GENERAL_OPENING_HOURS_FOR_GUARDIAN';
export const LOAD_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN = 'presence/LOAD_SPECIFIC_OPENING_HOURS_FOR_GUARDIAN';
export const LOAD_HOLIDAY_CLOSED_DAY = 'presence/LOAD_HOLIDAY_CLOSED_DAY';
export const LOAD_OVERLAPPING_PRESENCE_TEMPLATES = 'presence/LOAD_OVERLAPPING_PRESENCE_TEMPLATES';
export const RESET_OVERLAPPING_PRESENCE_TEMPLATES = 'presence/RESET_OVERLAPPING_PRESENCE_TEMPLATES';
