// Interface for all getters, mutations and actions

// Getters
export const GET_SEARCH_STRING = 'search/GET_SEARCH_STRING';
export const GET_GLOBAL_SEARCH_RESULTS = 'search/GET_GLOBAL_SEARCH_RESULTS';
export const GET_NUMBER_OF_RESULTS = 'search/GET_NUMBER_OF_RESULTS';
export const GET_SHOW_RESULT = 'search/GET_SHOW_RESULT';
export const GET_SHOW_SUBRESULTS = 'search/GET_SHOW_SUBRESULTS';
export const GET_SEARCH_OFFSET = 'search/GET_SEARCH_OFFSET';
export const GET_CHILD_RELATIONS = 'search/GET_CHILD_RELATIONS';
export const GET_LOAD_MORE = 'search/GET_LOAD_MORE';
export const GET_STATUS_SEARCH_BAR = 'search/GET_STATUS_SEARCH_BAR';
export const GET_SEARCH_LOADING_STATE = 'search/GET_SEARCH_LOADING_STATE';

// Mutations
export const MUTATE_LOAD_GLOBAL_SEARCH_RESULTS = 'search/MUTATE_LOAD_GLOBAL_SEARCH_RESULTS';
export const MUTATE_SET_SEARCH_STRING = 'search/MUTATE_SET_SEARCH_STRING';
export const MUTATE_SHOW_RESULT_ONLY = 'search/MUTATE_SHOW_RESULT_ONLY';
export const MUTATE_SHOW_RESULT = 'search/MUTATE_SHOW_RESULT';
export const MUTATE_SHOW_SUBRESULTS = 'search/MUTATE_SHOW_SUBRESULTS';
export const MUTATE_LOAD_MORE_GLOBAL_RESULTS = 'search/MUTATE_LOAD_MORE_GLOBAL_RESULTS';
export const MUTATE_LOAD_CHILD_RELATIONS = 'search/MUTATE_LOAD_CHILD_RELATIONS';
export const MUTATE_STATUS_SEARCH_BAR = 'search/MUTATE_STATUS_SEARCH_BAR';
export const MUTATE_RESET_SEARCH_RESULTS = 'search/MUTATE_RESET_SEARCH_RESULTS';
export const MUTATE_SEARCH_LOADING_STATE = 'search/MUTATE_SEARCH_LOADING_STATE';
export const MUTATE_CLEAR_SEARCH_RESULTS = 'search/MUTATE_CLEAR_SEARCH_RESULTS';

// Actions
export const LOAD_GLOBAL_SEARCH_RESULTS = 'search/LOAD_GLOBAL_SEARCH_RESULTS';
export const SET_SEARCH_STRING = 'search/SET_SEARCH_STRING';
export const SET_SHOW_RESULT = 'search/SET_SHOW_RESULT';
export const SET_SHOW_SUBRESULTS = 'search/SET_SHOW_SUBRESULTS';
export const LOAD_MORE_GLOBAL_RESULTS = 'search/LOAD_MORE_GLOBAL_RESULTS';
export const SET_STATUS_SEARCH_BAR = 'search/SET_STATUS_SEARCH_BAR';
