export enum parentTypes {
  CONTACT = 'contact',
  PROFILE = 'profile',
  CALENDAR = 'calendar',
  GROUP_CALENDAR = 'group-calendar',
  ADMINISTRATION = 'administration',
  PORTAL = 'portal',
  GROUP = 'group',
  GROUP_TEMPLATES = 'group-templates',
  POSTS = 'posts',
  DOCUMENTS = 'documents',
  EDITOR = 'editor',
  RELATION = 'relation',
  SEARCH = 'search',
  MESSAGES = 'messages',
  GALLERY = 'gallery',
  GROUP_GALLERY = 'group-gallery',
  USERDATA = 'userdata',
  UPLOAD = 'upload',
  GROUP_POSTS = 'group-posts',
  ALERT = 'alert',
  NOTICE_BOARDS = 'notice-boards',
  GROUP_HOME_ADMIN = 'group-home-admin',
  PRESENCE = 'presence',
}
