// Interface for all getters, mutations and actions

// Getters
export const GET_ACTIVE_GROUP = 'groups/GET_ACTIVE_GROUP';
export const GET_GROUP_MEMBERSHIPS = 'groups/GET_GROUP_MEMBERSHIPS';
export const GET_GROUP_MEMBERSHIPS_LIGHT = 'groups/GET_GROUP_MEMBERSHIPS_LIGHT';
export const GET_GROUPS_FROM_SEARCH = 'groups/GET_GROUPS_FROM_SEARCH';
export const GROUP_CREATED = 'groups/GROUP_CREATED';
export const GET_ALL_MODULES = 'groups/GET_ALL_MODULES';
export const GET_ALL_WIDGETS = 'groups/GET_ALL_WIDGETS';
export const GET_ASSIGNED_GROUP_ROLES = 'groups/GET_ASSIGNED_GROUP_ROLES';
export const GET_USER_ROLES_GROUP = 'groups/GET_USER_ROLES_GROUP';
export const GET_TOO_MANY_GROUP_RESULTS_FROM_SEARCH = 'groups/GET_TOO_MANY_GROUP_RESULTS_FROM_SEARCH';
export const GET_ZERO_GROUPS_RESULTS_FROM_SEARCH = 'groups/GET_ZERO_GROUPS_RESULTS_FROM_SEARCH';
export const GET_IS_OPENNED_GROUP_FROM_GLOBAL_SEARCH = 'groups/GET_IS_OPENNED_GROUP_FROM_GLOBAL_SEARCH';
export const GET_HIDE_GROUP_OVERLAYS = 'groups/GET_HIDE_GROUP_OVERLAYS';
export const IS_SEARCH_COMPONENT_LOADING = 'groups/IS_SEARCH_COMPONENT_LOADING';
export const GET_TOTAL_SIZE_OF_GROUPS = 'groups/GET_TOTAL_SIZE_OF_GROUPS';
export const GET_ASSIGNED_USER_ROLES_FOR_GROUPS = 'groups/GET_ASSIGNED_USER_ROLES_FOR_GROUPS';

// Mutations
export const MUTATION_GROUPS_SET_LIST_ADMIN = 'groups/MUTATION_GROUPS_SET_LIST_ADMIN';
export const MUTATE_SET_ACTIVE_GROUP = 'groups/MUTATE_SET_ACTIVE_GROUP';
export const MUTATE_RESET_ACTIVE_GROUP = 'groups/MUTATE_RESET_ACTIVE_GROUP';
export const MUTATE_UPDATE_GROUP = 'groups/MUTATE_UPDATE_GROUP';
export const MUTATE_DELETE_GROUP = 'groups/MUTATE_DELETE_GROUP';
export const MUTATE_LOAD_ALL_MODULES = 'groups/MUTATE_LOAD_ALL_MODULES';
export const MUTATE_LOAD_ALL_WIDGETS = 'groups/MUTATE_LOAD_ALL_WIDGETS';
export const MUTATE_LOAD_ASSIGNED_USER_ROLES_FOR_GROUPS = 'groups/MUTATE_LOAD_ASSIGNED_USER_ROLES_FOR_GROUPS';
export const MUTATE_LOAD_ASSIGNED_GROUP_ROLES = 'groups/MUTATE_LOAD_ASSIGNED_GROUP_ROLES';
export const MUTATE_LOAD_USER_ROLES_GROUP = 'groups/MUTATE_LOAD_USER_ROLES_GROUP';
export const MUTATE_SEARCH_GROUPS_AFTER_EDIT_ROLE = 'groups/MUTATE_SEARCH_GROUPS_AFTER_EDIT_ROLE';
export const MUTATE_RESET_USER_ROLES_GROUP = 'groups/MUTATE_RESET_USER_ROLES_GROUP';
export const MUTATE_LOAD_GROUP_MEMBERSHIPS = 'groups/MUTATE_LOAD_GROUP_MEMBERSHIPS';
export const MUTATE_LOAD_GROUP_MEMBERSHIPS_LIGHT = 'groups/MUTATE_LOAD_GROUP_MEMBERSHIPS_LIGHT';
export const MUTATE_LOAD_GROUP_MEMBERSHIPS_ADMIN = 'groups/MUTATE_LOAD_GROUP_MEMBERSHIPS_ADMIN';
export const MUTATE_IS_OPENNED_GROUP_FROM_GLOBAL_SEARCH = 'groups/MUTATE_IS_OPENNED_GROUP_FROM_GLOBAL_SEARCH';
export const MUTATE_HIDE_ALL_GROUP_OVERLAYED_GROUP = 'groups/MUTATE_HIDE_ALL_GROUP_OVERLAYED_GROUP';
export const MUTATE_DEACTIVATE_GROUPS = 'groups/MUTATE_DEACTIVATE_GROUPS';
export const MUTATE_ACTIVATE_GROUPS = 'groups/MUTATE_ACTIVATE_GROUPS';
export const MUTATE_SEARCH_COMPONENT_LOADING = 'groups/MUTATE_SEARCH_COMPONENT_LOADING';

// Actions
export const SET_ACTIVE_GROUP = 'groups/SET_ACTIVE_GROUP';
export const RESET_ACTIVE_GROUP = 'groups/RESET_ACTIVE_GROUP';
export const SEARCH_GROUPS = 'groups/SEARCH_GROUPS';
export const CREATE_GROUP = 'groups/CREATE_GROUP';
export const UPDATE_GROUP = 'groups/UPDATE_GROUP';
export const DELETE_GROUP = 'groups/DELETE_GROUP';
export const LOAD_ALL_MODULES = 'groups/LOAD_ALL_MODULES';
export const LOAD_ALL_WIDGETS = 'groups/LOAD_ALL_WIDGETS';
export const LOAD_ASSIGNED_USER_ROLES_FOR_GROUPS = 'groups/LOAD_ASSIGNED_USER_ROLES_FOR_GROUPS';
export const LOAD_ASSIGNED_GROUP_ROLES = 'groups/LOAD_ASSIGNED_GROUP_ROLES';
export const ASSIGN_USER_ROLES_TO_GROUPS = 'groups/ASSIGN_USER_ROLES_TO_GROUPS';
export const UNASSIGN_USER_ROLES_FROM_GROUPS = 'groups/UNASSIGN_USER_ROLES_FROM_GROUPS';
export const LOAD_USER_ROLES_GROUP = 'groups/LOAD_USER_ROLES_GROUP';
export const LOAD_GROUP_MEMBERSHIPS = 'groups/LOAD_GROUP_MEMBERSHIPS';
export const LOAD_GROUP_MEMBERSHIPS_ADMIN = 'groups/LOAD_GROUP_MEMBERSHIPS_ADMIN';
export const LOAD_GROUP_MEMBERSHIPS_LIGHT = 'groups/LOAD_GROUP_MEMBERSHIPS_LIGHT';
export const DEACTIVATE_GROUP = 'groups/DEACTIVATE_GROUP';
export const CHECK_GROUPS_IN_USE = 'groups/CHECK_GROUPS_IN_USE';
