// Interface for all getters, mutations and actions

// GETTERS
export const GET_REPORTED_POSTS = 'reportedContent/GET_REPORTED_POSTS';
export const GET_REPORTED_MEDIA = 'reportedContent/GET_REPORTED_MEDIA';
export const GET_REPORTED_COMMENTS = 'reportedContent/GET_REPORTED_COMMENTS';
export const GET_RELATED_POST = 'reportedContent/GET_RELATED_POST';
export const GET_RELATED_MEDIA = 'reportedContent/GET_RELATED_MEDIA';

// MUTATIONS
export const MUTATE_LOAD_REPORTED_POSTS = 'reportedContent/MUTATE_LOAD_REPORTED_POSTS';
export const MUTATE_LOAD_REPORTED_MEDIA = 'reportedContent/MUTATE_LOAD_REPORTED_MEDIA';
export const MUTATE_LOAD_REPORTED_COMMENTS = 'reportedContent/MUTATE_LOAD_REPORTED_COMMENTS';
export const MUTATE_REMOVE_REPORTED_POST = 'reportedContent/MUTATE_REMOVE_REPORTED_POST';
export const MUTATE_REMOVE_REPORTED_MEDIA = 'reportedContent/MUTATE_REMOVE_REPORTED_MEDIA';
export const MUTATE_REMOVE_REPORTED_COMMENT = 'reportedContent/MUTATE_REMOVE_REPORTED_COMMENT';
export const MUTATE_REJECT_REPORTED_POST = 'reportedContent/MUTATE_REJECT_REPORTED_POST';
export const MUTATE_REJECT_REPORTED_MEDIA = 'reportedContent/MUTATE_REJECT_REPORTED_MEDIA';
export const MUTATE_REJECT_REPORTED_COMMENT = 'reportedContent/MUTATE_REJECT_REPORTED_COMMENT';
export const MUTATE_RELATED_POST = 'reportedContent/MUTATE_RELATED_POST';
export const MUTATE_RELATED_MEDIA = 'reportedContent/MUTATE_RELATED_MEDIA';

// ACTIONS
export const LOAD_REPORTED_POSTS = 'reportedContent/LOAD_REPORTED_POSTS';
export const LOAD_REPORTED_MEDIA = 'reportedContent/LOAD_REPORTED_MEDIA';
export const LOAD_REPORTED_COMMENTS = 'reportedContent/LOAD_REPORTED_COMMENTS';
export const REMOVE_REPORTED_POST = 'reportedContent/REMOVE_REPORTED_POST';
export const REMOVE_REPORTED_MEDIA = 'reportedContent/REMOVE_REPORTED_MEDIA';
export const REMOVE_REPORTED_COMMENT = 'reportedContent/REMOVE_REPORTED_COMMENT';
export const REJECT_REPORTED_POST = 'reportedContent/REJECT_REPORTED_POST';
export const REJECT_REPORTED_MEDIA = 'reportedContent/REJECT_REPORTED_MEDIA';
export const REJECT_REPORTED_COMMENT = 'reportedContent/REJECT_REPORTED_COMMENT';
export const LOAD_RELATED_POST_BY_ID = 'reportedContent/LOAD_RELATED_POST_BY_ID';
export const LOAD_RELATED_MEDIA_BY_ID = 'reportedContent/LOAD_RELATED_MEDIA_BY_ID';
