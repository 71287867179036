// Interface for all getters, mutations and actions

// Getters
export const GET_ALBUMS = 'gallery/GET_ALBUMS';
export const GET_ACTIVE_ALBUM = 'gallery/GET_ACTIVE_ALBUM';
export const GET_MORE_ALBUMS_EXIST = 'gallery/GET_MORE_ALBUMS_EXIST';
export const GET_ALBUM_DETAILS = 'gallery/GET_ALBUM_DETAILS';
export const GET_MEDIA_COUNT = 'gallery/GET_MEDIA_COUNT';
export const GET_MEDIA_DETAILS = 'gallery/GET_MEDIA_DETAILS';
export const GET_MORE_MEDIAS_EXIST = 'gallery/GET_MORE_MEDIAS_EXIST';
export const GET_MEDIAS_OF_INSTITUTION_PROFILE_ID = 'gallery/GET_MEDIAS_OF_INSTITUTION_PROFILE_ID';
export const GET_LIMIT_MEDIA = 'gallery/GET_LIMIT_MEDIA';
export const GET_HAS_MEDIA_CHANGED = 'gallery/GET_HAS_MEDIA_CHANGED';
export const GET_NOTICE_BOARD_MEDIA = 'gallery/GET_NOTICE_BOARD_MEDIA';
export const GET_GALLERY_FILTER_BY = 'gallery/GET_GALLERY_FILTER_BY';
export const GET_GALLERY_SORT_ON = 'gallery/GET_GALLERY_SORT_ON';
export const GET_GALLERY_ORDER_DIRECTION = 'gallery/GET_GALLERY_ORDER_DIRECTION';

// Mutations
export const MUTATE_ALBUMS = 'gallery/MUTATE_ALBUMS';
export const MUTATE_RESET_ALBUM_DETAILS = 'gallery/MUTATE_RESET_ALBUM_DETAILS';
export const MUTATE_GALLERY_FILTER_BY = 'gallery/MUTATE_GALLERY_FILTER_BY';
export const MUTATE_GALLERY_SORT_ON = 'gallery/MUTATE_GALLERY_SORT_ON';
export const MUTATE_GALLERY_ORDER_DIRECTION = 'gallery/MUTATE_GALLERY_ORDER_DIRECTION';
export const MUTATE_RESET_ALBUMS = 'gallery/MUTATE_RESET_ALBUMS';
export const MUTATE_HAS_MEDIA_CHANGED = 'gallery/MUTATE_HAS_MEDIA_CHANGED';
export const MUTATE_ALBUM_DETAILS = 'gallery/MUTATE_ALBUM_DETAILS';
export const MUTATE_MEDIA_DETAILS = 'gallery/MUTATE_MEDIA_DETAILS';
export const MUTATE_MEDIAS_OF_INSTITUTION_PROFILE_ID = 'gallery/MUTATE_MEDIAS_OF_INSTITUTION_PROFILE_ID';
export const MUTATE_MEDIA_SELECTED = 'gallery/MUTATE_MEDIA_SELECTED';
export const MUTATE_CLEAR_MEDIAS = 'gallery/MUTATE_CLEAR_MEDIAS';
export const MUTATE_DELETE_MEDIA = 'gallery/MUTATE_DELETE_MEDIA';
export const MUTATE_DELETE_ALBUM = 'gallery/MUTATE_DELETE_ALBUM';
export const MUTATE_REMOVE_EXISTING_MEDIA = 'gallery/MUTATE_REMOVE_EXISTING_MEDIA';
export const MUTATE_UPDATE_ALBUM = 'gallery/MUTATE_UPDATE_ALBUM';
export const MUTATE_NOTICE_BOARD_MEDIA = 'gallery/MUTATE_NOTICE_BOARD_MEDIA';

// Actions
export const CREATE_ALBUM = 'gallery/CREATE_ALBUM';
export const UPDATE_ALBUM = 'gallery/UPDATE_ALBUM';
export const CREATE_MEDIA = 'gallery/CREATE_MEDIA';
export const UPDATE_MEDIA = 'gallery/UPDATE_MEDIA';
export const DELETE_MEDIA = 'gallery/DELETE_MEDIA';
export const ACTION_GET_ALBUMS = 'gallery/ACTION_GET_ALBUMS';
export const ACTION_GET_ALBUMS_ADMIN = 'gallery/ACTION_GET_ALBUMS_ADMIN';
export const ACTION_GET_ALBUM_DETAILS = 'gallery/ACTION_GET_ALBUM_DETAILS';
export const ACTION_GET_ALBUM_DETAILS_ADMIN = 'gallery/ACTION_GET_ALBUM_DETAILS_ADMIN';
export const DELETE_ALBUM = 'gallery/DELETE_ALBUM';
export const REPORT_MEDIA = 'gallery/REPORT_MEDIA';
export const LOAD_NOTICE_BOARD_MEDIA = 'gallery/LOAD_NOTICE_BOARD_MEDIA';
