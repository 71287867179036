export default {
  bind: function (el, binding) {
    if (binding.value === false) {
      return;
    }
    el.addEventListener('keydown', event => {
      if (event.key === 'Enter') {
        el.click();
      }
    });
    el.addEventListener('keyup', event => {
      if (event.code === 'Space') {
        el.click();
      }
    });
    el.setAttribute('tabindex', '0');
    el.setAttribute('role', 'button');
  },
};
