// Interface for all getters, mutations and actions

// Getters
export const GET_TOOLBOX_ITEMS = 'toolbox/GET_TOOLBOX_ITEMS';
export const GET_INDICES = 'toolbox/GET_INDICES';
export const GET_SEARCH_INDEX_RESULT = 'toolbox/GET_SEARCH_INDEX_RESULT';
export const GET_DESCRIBE_USER_RESULT = 'toolbox/GET_DESCRIBE_USER_RESULT';
export const GET_UNILOGIN_LOOKUP_RESULT = 'toolbox/GET_UNILOGIN_LOOKUP_RESULT';
export const GET_SAML_DECODER_RESULT = 'toolbox/GET_SAML_DECODER_RESULT';

// Mutations
export const MUTATE_GET_INDICES = 'toolbox/MUTATE_GET_INDICES';
export const MUTATE_SEARCH_INDEX_RESULT = 'toolbox/MUTATE_SEARCH_INDEX_RESULT';
export const MUTATE_DESCRIBE_USER_RESULT = 'toolbox/MUTATE_DESCRIBE_USER_RESULT';
export const MUTATE_UNILOGIN_LOOKUP_RESULT = 'toolbox/MUTATE_UNILOGIN_LOOKUP_RESULT';
export const MUTATE_SAML_DECODER_RESULT = 'toolbox/MUTATE_SAML_DECODER_RESULT';

// Actions
export const GET_ALL_INDICES = 'toolbox/GET_ALL_INDICES';
export const SEARCH_INDEX = 'toolbox/SEARCH_INDEX';
export const DESCRIBE_USER = 'toolbox/DESCRIBE_USER';
export const UNILOGIN_LOOKUP = 'toolbox/UNILOGIN_LOOKUP';
export const SAML_DECODE = 'toolbox/SAML_DECODE';
