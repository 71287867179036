// Interface for all getters, mutations and actions

// Getters
export const GET_MUNICIPAL_CONFIGURATION = 'transfer_year/GET_MUNICIPAL_CONFIGURATION';
export const GET_INSTITUTIONAL_TRANSFER_YEARS = 'transfer_year/GET_INSTITUTIONAL_TRANSFER_YEARS';
export const GET_SELECTED_INSTITUTION_WITH_TRANSFER_YEAR = 'transfer_year/GET_SELECTED_INSTITUTION_WITH_TRANSFER_YEAR';

// Mutations
export const MUTATE_MUNICIPAL_CONFIGURATION = 'transfer_year/MUTATE_MUNICIPAL_CONFIGURATION';
export const MUTATE_MUNICIPAL_TRANSFER_YEAR = 'transfer_year/MUTATE_MUNICIPAL_TRANSFER_YEAR';
export const MUTATE_MUNICIPAL_CONSENT_AGE = 'transfer_year/MUTATE_MUNICIPAL_CONSENT_AGE';
export const MUTATE_GET_INSTITUTIONAL_TRANSFER_YEARS = 'transfer_year/MUTATE_GET_INSTITUTIONAL_TRANSFER_YEARS';
export const MUTATE_REMOVE_TRANSFER_YEAR = 'transfer_year/MUTATE_REMOVE_TRANSFER_YEAR';
export const MUTATE_SELECT_INSTITUTION_WITH_TRANSFER_YEAR =
  'transfer_year/MUTATE_SELECT_INSTITUTION_WITH_TRANSFER_YEAR';
export const MUTATE_ADD_INSTITUTIONAL_TRANSFER_YEAR = 'transfer_year/MUTATE_ADD_INSTITUTIONAL_TRANSFER_YEAR';
export const MUTATE_UPDATE_INSTITUTIONAL_TRANSFER_YEAR = 'transfer_year/MUTATE_UPDATE_INSTITUTIONAL_TRANSFER_YEAR';

// Actions
export const LOAD_MUNICIPAL_CONFIGURATION = 'transfer_year/LOAD_MUNICIPAL_CONFIGURATION';
export const LOAD_INSTITUTIONAL_TRANSFER_YEARS = 'transfer_year/LOAD_INSTITUTIONAL_TRANSFER_YEARS';
export const SET_MUNICIPAL_TRANSFER_YEAR = 'transfer_year/SET_MUNICIPAL_TRANSFER_YEAR';
export const SET_MUNICIPAL_CONSENT_AGE = 'transfer_year/SET_MUNICIPAL_CONSENT_AGE';
export const DELETE_INSTITUTIONAL_TRANSFER_YEAR = 'transfer_year/DELETE_INSTITUTIONAL_TRANSFER_YEAR';
export const SELECT_INSTITUTION_WITH_TRANSFER_YEAR = 'transfer_year/SELECT_INSTITUTION_WITH_TRANSFER_YEAR';
export const CREATE_TRANSFER_YEAR = 'transfer_year/CREATE_TRANSFER_YEAR';
export const EDIT_INSTITUTIONAL_TRANSFER_YEAR = 'transfer_year/EDIT_INSTITUTIONAL_TRANSFER_YEAR';
