export const documentCategoriesBackend = Object.freeze({
  AGENDA: 'Dagsorden',
  PLANOFACTION: 'Handleplan',
  SETINGTOPPR: 'Indstilling',
  FORCABLESCHEDULE: 'Magtanvendelsesskema',
  OBSERVATION: 'Observation',
  EDUCATIONALNOTE: 'Pædagogisk note',
  SUMMARY: 'Referat',
  SCRATCHSCHEME: 'Rive/kradseskema',
  OPENTEMPLATE: 'Åben skabelon',
  SECURE_NOTE: 'Note',
});
