import moment from 'moment-timezone';
import Vue from 'vue';
import { mediaTypes } from '../../enums/mediaTypes';
import escapeRegExp from 'lodash/escapeRegExp';
import { dateNameFormats } from '../../enums/dateNameFormats';
import { dateFormatTypes } from '../../enums/dateFormatTypes';
import { isValidEmail } from '../../functions/is-valid-email';
import { videoExtensions } from '../../enums/videoExtensions';
import { imageExtensions } from '../../enums/imageExtensions';
import { soundExtensions } from '../../enums/soundExtensions';
import truncate from 'lodash/truncate';

const toFirstLetterUpperCase = function (string) {
  if (string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }
  return '';
};

export const toUpperCase = Vue.filter('toUpperCase', function (string) {
  if (string) {
    return string.toUpperCase();
  }
  return '';
});

export const textWithoutHtml = Vue.filter('textWithoutHtml', function (text) {
  let textWithoutHtml = '';
  if (text) {
    const html = text;
    const div = document.createElement('div');
    div.innerHTML = html;
    textWithoutHtml = div.textContent || div.innerText;
  }
  return textWithoutHtml.trim();
});

export const date = Vue.filter('date', function (format, date = moment()) {
  const momentDate = moment(date);

  switch (format) {
    case dateFormatTypes.shortWeekDateName:
      return dateNameFormats.dateOfWeek[momentDate.isoWeekday()];
    case dateFormatTypes.monthDay:
      return momentDate.format('DD');
    case dateFormatTypes.dayMonthYear:
      return momentDate.format('DD-MM-YYYY');
    case dateFormatTypes.dayMonthYearTime:
      return momentDate.format('DD-MM-YYYY HH:mm');
    case dateFormatTypes.shortDate:
      return momentDate.format('ddd - DD');
    default:
      return momentDate.format(format);
  }
});

export const displayProfileName = Vue.filter('displayProfileName', function (institutionProfile) {
  const names = [];
  if (institutionProfile.fullName != null) {
    names.push(institutionProfile.fullName);
  } else if (institutionProfile.name != null) {
    names.push(institutionProfile.name);
  } else {
    names.push(institutionProfile.firstName, institutionProfile.lastName);
  }

  return names.join(' ');
});

export const displayProfileNameWithMetadata = Vue.filter(
  'displayProfileNameWithMetadata',
  function (institutionProfile) {
    let displayName = displayProfileName(institutionProfile);
    if (institutionProfile.alias) {
      displayName += Vue.filter('fromTextKey')('ALIAS');
    }
    if (institutionProfile.metadata) {
      displayName += ' (' + institutionProfile.metadata + ')';
    }
    return displayName;
  }
);
export const displayProfileShortName = Vue.filter('displayProfileShortName', institutionProfile => {
  const firstname = institutionProfile.firstName;
  const lastname = institutionProfile.lastName;

  if (lastname == null) {
    return firstname;
  }

  const lastNameFirstCharacter = lastname[0].toUpperCase();

  return firstname + ' ' + lastNameFirstCharacter + '.';
});

export const displayProfileInitials = Vue.filter('displayProfileInitials', function (institutionProfile) {
  if (institutionProfile.shortName != null) {
    return institutionProfile.shortName;
  }

  const displayName = displayProfileName(institutionProfile);
  const startIndex = 0;
  const endIndex = 2;
  return displayName
    .split(' ')
    .map(name => name[0])
    .filter(Boolean)
    .slice(startIndex, endIndex)
    .join('')
    .toUpperCase();
});

export const groupHomeEventInviteeWithMetaData = Vue.filter(
  'groupHomeEventInviteeWithMetaData',
  function (groupHomeInvitee) {
    if (groupHomeInvitee != null) {
      let childFirstName = '';

      const regardingChildNames = groupHomeInvitee.regardingChildDisplayName.split(' ');
      if (regardingChildNames.length > 0) {
        childFirstName = regardingChildNames[0];
      }

      const displayName = `${groupHomeInvitee.groupHomeName} (${childFirstName} ${groupHomeInvitee.regardingChildMetaData})`;
      return displayName;
    } else {
      return null;
    }
  }
);

export const groupHomeRelationLabelWithMetadata = Vue.filter('groupHomeRelationLabel', function (groupHome, child) {
  if (
    groupHome != null &&
    groupHome.name != null &&
    child != null &&
    child.name != null &&
    /\s/.exec(child.name) != null
  ) {
    const childFirstName = child.name.split(' ')[0];
    return `${groupHome.name} (${childFirstName} ${child.metadata})`;
  }
  return groupHome.name;
});

export const firstLetterUppercase = Vue.filter('firstLetterUppercase', function (string) {
  if (string) {
    return toFirstLetterUpperCase(string);
  }
  return '';
});

export const truncated = Vue.filter('truncated', function (string, len = 30) {
  if (!string) {
    return '';
  }
  return truncate(string, {
    length: len,
  });
});

// e.g. `new Date() | longDate` or this.$options.filters.shortDate(new Date()) for søndag, 7. jan.
// e.g. `new Date() | longDate(true)` or this.$options.filters.shortDate(new Date(true)) for  søndag, 7. jan. 2018
// e.g. `new Date() | longDate(true, true)` or this.$options.filters.longDate(new Date(true, true)) for søndag, 7. jan. 08:30
export const longDate = Vue.filter('longDate', function (date, year = false, time = false) {
  let format = 'dddd[,] D. MMM[.]';

  if (year) {
    format += ' YYYY';
  }

  if (time) {
    format += ' [kl.] LT';
  }

  return moment(date).format(format);
});

// e.g. `{{ new Date() | shortDate }}` or this.$options.filters.shortDate(new Date()) for 7. jan.
// e.g. `{{ new Date() | shortDate(true) }}` or this.$options.filters.shortDate(new Date(true)) for 7. jan. 2018
// e.g. `{{ new Date() | shortDate(true, true) }}` or this.$options.filters.shortDate(new Date(true, true)) for 7. jan. 2018 08:30
// e.g. `{{ new Date() | shortDate(true, true, true) }}` or this.$options.filters.shortDate(new Date(true, true, true))  for utc date time
export const shortDate = Vue.filter('shortDate', function (date, year = false, time = false, utc = false) {
  let format = 'Do MMM[.]';
  if (year) {
    format += ' YYYY';
    if (time) {
      format += ' [kl.] LT';
    }
  }
  const momentDate = moment(date);
  const outDate = utc ? momentDate.utc() : momentDate;
  return outDate.format(format);
});

export const time = Vue.filter('time', function (date) {
  if (date) {
    return moment(date).format('LT');
  } else {
    return '';
  }
});

export const monthNameToNumber = Vue.filter('monthNameToNumber', function (date) {
  switch (date) {
    case 'Januar': {
      return '01';
    }
    case 'Februar': {
      return '02';
    }
    case 'Marts': {
      return '03';
    }
    case 'April': {
      return '04';
    }
    case 'Maj': {
      return '05';
    }
    case 'Juni': {
      return '06';
    }
    case 'Juli': {
      return '07';
    }
    case 'August': {
      return '08';
    }
    case 'September': {
      return '09';
    }
    case 'Oktober': {
      return '10';
    }
    case 'November': {
      return '11';
    }
    case 'December': {
      return '12';
    }
  }
});

export const isMedia = Vue.filter('isMedia', function (ext) {
  if (imageExtensions.includes(ext.toLowerCase())) {
    return mediaTypes.IMAGE;
  } else if (videoExtensions.includes(ext.toLowerCase())) {
    return mediaTypes.VIDEO;
  } else if (soundExtensions.includes(ext.toLowerCase())) {
    return mediaTypes.SOUND;
  } else {
    return false;
  }
});

export const formatUrl = Vue.filter('formatUrl', function (url) {
  return url.split('&amp;').join('&');
});

export const dateNumberToName = Vue.filter('dateNumberToName', function (date) {
  switch (date) {
    case 0: {
      return Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_SUNDAY');
    }
    case 1: {
      return Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_MONDAY');
    }
    case 2: {
      return Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_TUESDAY');
    }
    case 3: {
      return Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_WEDNESDAY');
    }
    case 4: {
      return Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_THURSDAY');
    }
    case 5: {
      return Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_FRIDAY');
    }
    case 6: {
      return Vue.filter('fromTextKey')('CALENDAR_LABEL_EVENT_SATURDAY');
    }
  }
});

export const isoDateNumberToName = Vue.filter('isoDateNumberToName', function (date) {
  switch (date) {
    case 1: {
      return Vue.filter('fromTextKey')('DATETIME_MONDAY');
    }
    case 2: {
      return Vue.filter('fromTextKey')('DATETIME_TUESDAY');
    }
    case 3: {
      return Vue.filter('fromTextKey')('DATETIME_WEDNESDAY');
    }
    case 4: {
      return Vue.filter('fromTextKey')('DATETIME_THURSDAY');
    }
    case 5: {
      return Vue.filter('fromTextKey')('DATETIME_FRIDAY');
    }
    case 6: {
      return Vue.filter('fromTextKey')('DATETIME_SATURDAY');
    }
    case 7: {
      return Vue.filter('fromTextKey')('DATETIME_SUNDAY');
    }
  }
});

export const unixDate = Vue.filter('unixDate', function (date) {
  return moment(date).format('X');
});

export const stripHTML = Vue.filter('stripHTML', function (string) {
  const div = document.createElement('div');
  div.innerHTML = string;
  const text = div.textContent || div.innerText || '';
  return text;
});

export const displayName = Vue.filter('displayName', function (profile) {
  return profile.firstName + ' ' + profile.lastName;
});

export const firstFewWords = Vue.filter('firstFewWords', function (string) {
  const intWords = 50;
  const arrString = string.trim().split(' ');
  if (arrString.length <= intWords) {
    return string;
  }
  return arrString.slice(0, intWords).join(' ') + '...';
});

export const deviceDimensions = Vue.filter('deviceDimensions', function () {
  if (window.innerWidth < window.innerHeight) {
    if (window.innerWidth < 200) {
      return 200;
    } else if (window.innerWidth < 400) {
      return 400;
    } else if (window.innerWidth < 800) {
      return 800;
    } else if (window.innerWidth < 1200) {
      return 1200;
    } else {
      return 1600;
    }
  } else {
    if (window.innerHeight < 200) {
      return 200;
    } else if (window.innerHeight < 400) {
      return 400;
    } else if (window.innerHeight < 800) {
      return 800;
    } else if (window.innerHeight < 1200) {
      return 1200;
    } else {
      return 1600;
    }
  }
});

export const isPhoneNumber = Vue.filter('isPhoneNumber', function (number) {
  if (number == null || number == '') {
    return true;
  }
  return /^[0-9\-\(\)\/\+\s]{8,15}$/.test(number);
});

export const isEmail = Vue.filter('isEmail', function (email) {
  if (email == null || email == '') {
    return true;
  }

  return isValidEmail(email);
});

export const generateAdress = Vue.filter('generateAdress', function (adressObj) {
  let adress;
  if (adressObj == null) {
    adress = Vue.filter('fromTextKey')('USERS_LABEL_ADDRESS') + ' ' + Vue.filter('fromTextKey')('UNKNOW').toLowerCase();
  } else if (adressObj.street == Vue.filter('fromTextKey')('UNKNOW')) {
    adress = Vue.filter('fromTextKey')('USERS_LABEL_ADDRESS') + ' ' + Vue.filter('fromTextKey')('UNKNOW').toLowerCase();
  } else {
    adress =
      adressObj.street +
      ', ' +
      (adressObj.postalCode != null ? adressObj.postalCode : Vue.filter('fromTextKey')('UNKNOW')) +
      ' ' +
      (adressObj.postalDistrict != null ? adressObj.postalDistrict : Vue.filter('fromTextKey')('UNKNOW'));
  }
  return adress;
});

export const getSiteUrl = Vue.filter('getSiteUrl', function (site = 'portal') {
  const isLocally = process.env.NODE_ENV === 'development';
  const protocol = location.protocol;
  const host = location.host; // Includes port
  let path = '/administration';

  if (site === 'portal') {
    if (isLocally) {
      path = '';
    } else {
      path = '/portal';
    }
  } else if (site === 'group_home_admin') {
    path = '/group_home_admin';
  } else if (site === 'presence') {
    path = '/presence';
  } else if (site === 'notice_boards') {
    path = '/infotavle';
    if (isLocally) {
      path = '/notice_boards';
    }
  }

  if (isLocally) {
    path += '/index.html';
  }

  return `${protocol}//${host}${path}`;
});

export const highlightKeyword = Vue.filter('highlightKeyword', function (string, keyword) {
  const keycode = ['*', '+', '\\'];
  if (keyword === '' || keyword === null || keyword === undefined) {
    return string;
  }
  keyword = escapeRegExp(keyword);
  if (string != null) {
    string = new DOMParser().parseFromString(string, 'text/html').body.textContent || '';
  }
  if (keycode.findIndex(k => k == keyword) == -1 && string != null && string != '') {
    return string.replace(new RegExp('(' + keyword + ')', 'ig'), '<mark class="text__highlight">$1</mark>');
  }
  return string;
});

export const decodeEntity = Vue.filter('decodeEntity', function (string) {
  string = string
    .replace(/&amp;/g, '&')
    .replace(/&lt;/g, '<')
    .replace(/&gt;/g, '>')
    .replace(/&quot;/g, '"')
    .replace(/&apos;/g, "'")
    .replace(/&frasl;/g, '/');
  return string;
});

export const encodeEntity = Vue.filter('encodeEntity', function (string) {
  string = string
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;')
    .replace(/'/g, '&apos;')
    .replace(/\//g, '&frasl;');
  return string;
});

export const toolkitCaseType = Vue.filter('toolkitCaseType', function (key) {
  switch (key) {
    case 'Service Request':
      return 'Anvendelseshjælp';
    case 'Incident':
      return 'Fejlrapport';
    default:
      return key;
  }
});

export const toolkitStatus = Vue.filter('toolkitStatus', function (key) {
  const statuses = {
    '10 - New': 'Ny',
    '11 - Received': 'Sagen er modtaget',
    '12 - Analysed': 'Fejl identificeret',
    '30 - Assigned': 'Under behandling',
    '31 - Started': 'Under behandling',
    '40 - Migrated to test': 'Fejl identificeret',
    '60 - Solved': 'Sagen er løst',
    '80 - Awaiting customer': 'Mangler informationer',
    '81 - Awaiting 3rd party': 'Afventer 3. part',
    '82 - Postponed': 'Sagen er udsat',
    '83 - Awaiting Kombit': 'Afventer Kombit',
    '90 - Closed': 'Sagen er lukket',
    '91 - Rejected': 'Sagen er afvist',
    '92 - Duplicated': 'Fejl identificeret',
    '93 - Cancelled': 'Sagen er annulleret',
    '94 - Closed by third party': 'Lukket af 3. part',
  };

  if (statuses[key] != null) {
    return statuses[key];
  }
  return key;
});
export const prepareAttachments = Vue.filter('prepareAttachments', function (attachmentsArray) {
  const attachments = {
    media: null,
    files: null,
    links: null,
    existingMedia: [],
    attachedSecureDocumentIds: null,
  };
  if (attachmentsArray != null && attachmentsArray.length > 0) {
    for (const att of attachmentsArray) {
      if (att.id == null) {
        if (att.media != null || att.multipartUploadingInfo != null) {
          if (attachments.media == null) {
            attachments.media = [];
          }
          const media = {
            file: att.media.file,
            name: att.name,
            mediaType: att.media ? att.media.mediaType : att.multipartUploadingInfo.mediaType,
            rotationDegree: att.media.rotationDegree != null ? att.media.rotationDegree : 0,
            title: att.media.title,
            description: att.media.description,
            tags: att.media.tags != null ? att.media.tags.map(tag => tag.id) : [],
            albumId: att.media.albumId != null ? att.media.albumId : null,
            sensitivityLevel: att.sensitivityLevel != null ? att.sensitivityLevel : null,
            allowsComments: true,
          };
          if (att.media.file.isLargeFile) {
            media.multipartUploadingInfo = att.media.file.multipartUploadingInfo;
          }
          attachments.media.push(media);
        } else if (att.link != null) {
          if (attachments.links == null) {
            attachments.links = [];
          }
          attachments.links.push({
            accessToken: att.link.accessToken,
            externalFileId: att.link.externalFileId,
            service: att.link.service,
            parentDriveId: att.link.parentDriveId,
          });
        } else if (att.file != null) {
          if (attachments.files == null) {
            attachments.files = [];
          }
          if (attachments.attachedSecureDocumentIds == null) {
            attachments.attachedSecureDocumentIds = [];
          }
          if (att.file.attachedSecureDocumentId == undefined) {
            attachments.files.push({
              name: att.name,
              file: att.file,
            });
          } else {
            attachments.attachedSecureDocumentIds.push(att.file.attachedSecureDocumentId);
          }
        }
      } else {
        attachments.existingMedia.push(att);
      }
    }
  }

  return attachments;
});

export const prepareMedia = Vue.filter('prepareMedia', function (mediaArray) {
  const mediaToUpdate = mediaArray
    .filter(med => med.media != null)
    .map(med => ({
      rotationDegree: med.media.rotationDegree,
      tags: med.media.tags.map(tag => tag.id),
      title: med.media.title,
      description: med.media.description,
      id: med.id,
    }));
  return mediaToUpdate;
});
