import { types } from '../types/types';
import axios from 'axios';
import { portal } from '../../../shared/assets/plugins/axios/axios';

const state = {
  groupFilter: [],
  contactList: [],
  referenceData: [],
  personalReferenceData: [],
  canLoadMoreContacts: false,
};

const getters = {
  [types.GET_GROUP_FILTER]: state => state.groupFilter,
  [types.GET_CONTACT_LIST]: state => state.contactList,
  [types.GET_CAN_LOAD_MORE_CONTACTS]: state => state.canLoadMoreContacts,
};

const mutations = {
  [types.MUTATE_GROUP_FILTER]: (state, payload) => {
    for (const profile of payload) {
      profile.groups = profile.groups.sort((group1, group2) =>
        group1.name.toUpperCase() > group2.name.toUpperCase() ? 1 : -1
      );
    }
    state.groupFilter = payload;
  },
  [types.MUTATE_CONTACT_LIST]: (state, payload) => {
    if (payload.isLoadMore) {
      state.contactList.push(...payload.data);
    } else {
      state.contactList = payload.data;
    }
  },
  [types.MUTATE_CAN_LOAD_MORE_CONTACTS]: (state, canLoadMoreContacts) => {
    state.canLoadMoreContacts = canLoadMoreContacts;
  },
};

const actions = {
  [types.LOAD_GROUP_FILTER]: ({ commit }, { institutionCodes, childInstitutionProfileIds }) =>
    portal
      .get('?method=groups.getGroupsByContext', {
        params: {
          institutionCodes,
          childInstitutionProfileIds,
        },
      })
      .then(response => {
        commit(types.MUTATE_GROUP_FILTER, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_GROUPS_BY_CONTEXT');
      }),
  [types.LOAD_CONTACT_LIST]: ({ commit }, payload) =>
    portal
      .get('?method=profiles.getContactlist', {
        params: payload.params,
        cancelToken: payload.cancelToken,
      })
      .then(response => {
        commit(types.MUTATE_CONTACT_LIST, {
          data: response.data.data,
          isLoadMore: payload.isLoadMore,
        });
        commit(types.MUTATE_CAN_LOAD_MORE_CONTACTS, response.data.data.length >= 20);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_CONTACT_LIST');
        return Promise.reject(error);
      }),
  [types.LOAD_CONTACT_PARENT]: ({ commit }, payload) =>
    portal
      .get('?method=profiles.getContactParents', {
        params: payload.params,
        cancelToken: payload.cancelToken,
      })
      .then(response => {
        commit(types.MUTATE_CONTACT_LIST, {
          data: response.data.data,
          isLoadMore: payload.isLoadMore,
        });
        commit(types.MUTATE_CAN_LOAD_MORE_CONTACTS, response.data.data.length !== 0);
      })
      .catch(error => {
        if (axios.isCancel(error)) {
          return Promise.reject(error);
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_CONTACT_PARENTS');
        return Promise.reject(error);
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
