// Interface for all getters, mutations and actions

// Getters
export const GET_CHILD_RELATION_RESULTS = 'search/GET_CHILD_RELATION_RESULTS';

// Mutations
export const MUTATE_LOAD_CHILD_RELATIONS = 'search/MUTATE_LOAD_CHILD_RELATIONS';

// Actions
export const LOAD_CHILD_RELATIONS = 'search/LOAD_CHILD_RELATIONS';
