export enum documentCategories {
  AGENDA = 'Dagsorden',
  PLANOFACTION = 'Handleplan',
  SETINGTOPPR = 'Indstilling',
  FORCABLESCHEDULE = 'Magtanvendelsesskema',
  OBSERVATION = 'Observation',
  EDUCATIONALNOTE = 'Pædagogisk note',
  SUMMARY = 'Referat',
  SCRATCHSCHEME = 'Rive/kradseskema',
  OPENTEMPLATE = 'Standard skabelon',
  SECURE_NOTE = 'Note',
}
