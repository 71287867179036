import { types } from '../../../src_adm/store/types/types';
import { portal } from '../../assets/plugins/axios/axios.js';

const state = {
  reportedPosts: [],
  reportedMedia: [],
  reportedComments: [],
  relatedPost: {},
  relatedMedia: {},
};

const getters = {
  [types.GET_REPORTED_POSTS]: state => state.reportedPosts,
  [types.GET_REPORTED_MEDIA]: state => state.reportedMedia,
  [types.GET_REPORTED_COMMENTS]: state => state.reportedComments,
  [types.GET_RELATED_POST]: state => state.relatedPost,
  [types.GET_RELATED_MEDIA]: state => state.relatedMedia,
};
const mutations = {
  [types.MUTATE_LOAD_REPORTED_POSTS]: (state, payload) => {
    state.reportedPosts = payload;
  },
  [types.MUTATE_LOAD_REPORTED_MEDIA]: (state, payload) => {
    state.reportedMedia = payload;
  },
  [types.MUTATE_LOAD_REPORTED_COMMENTS]: (state, payload) => {
    state.reportedComments = payload;
  },
  [types.MUTATE_RELATED_POST]: (state, payload) => {
    state.relatedPost = payload;
  },
  [types.MUTATE_RELATED_MEDIA]: (state, payload) => {
    state.relatedMedia = payload;
  },
  [types.MUTATE_REMOVE_REPORTED_POST]: (state, payload) => {
    state.reportedPosts = state.reportedPosts.filter(reportedPost => reportedPost.id !== payload.id);
  },
  [types.MUTATE_REMOVE_REPORTED_MEDIA]: (state, payload) => {
    state.reportedMedia = state.reportedMedia.filter(reportedMedia => reportedMedia.id !== payload.id);
  },
  [types.MUTATE_REMOVE_REPORTED_COMMENT]: (state, payload) => {
    state.reportedComments = state.reportedComments.filter(reportedComment => reportedComment.id !== payload.id);
  },
  [types.MUTATE_REJECT_REPORTED_POST]: (state, payload) => {
    state.reportedPosts = state.reportedPosts.filter(reportedPost => reportedPost.id !== payload.id);
  },
  [types.MUTATE_REJECT_REPORTED_MEDIA]: (state, payload) => {
    state.reportedMedia = state.reportedMedia.filter(reportedMedia => reportedMedia.id !== payload.id);
  },
  [types.MUTATE_REJECT_REPORTED_COMMENT]: (state, payload) => {
    state.reportedComments = state.reportedComments.filter(reportedComment => reportedComment.id !== payload.id);
  },
};
const actions = {
  [types.LOAD_REPORTED_POSTS]: ({ commit }, payload) =>
    portal
      .post('?method=posts.getReportedPosts', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_LOAD_REPORTED_POSTS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_REPORTED_CONTENT');
      }),
  [types.LOAD_REPORTED_MEDIA]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.getReportedMedia', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_LOAD_REPORTED_MEDIA, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_REPORTED_CONTENT');
      }),
  [types.LOAD_REPORTED_COMMENTS]: ({ commit }, payload) =>
    portal
      .post('?method=comments.getReportedComments', {
        params: payload,
      })
      .then(response => {
        commit(types.MUTATE_LOAD_REPORTED_COMMENTS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_REPORTED_CONTENT');
      }),
  [types.REMOVE_REPORTED_POST]: ({ commit }, payload) =>
    portal
      .post('?method=posts.acceptReportedPost', {
        id: payload.id,
        institutionCode: payload.institutionCode,
      })
      .then(() => {
        commit(types.MUTATE_REMOVE_REPORTED_POST, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'MESSAGE_REPORTED_CONTENT_DELETED');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_ACCEPT_REPORTED_CONTENT');
      }),
  [types.REMOVE_REPORTED_MEDIA]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.acceptReportedMedia', {
        mediaId: payload.id,
        institutionCode: payload.institutionCode,
      })
      .then(() => {
        commit(types.MUTATE_REMOVE_REPORTED_MEDIA, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'MESSAGE_REPORTED_CONTENT_DELETED');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_ACCEPT_REPORTED_CONTENT');
      }),
  [types.REMOVE_REPORTED_COMMENT]: ({ commit }, payload) =>
    portal
      .post('?method=comments.acceptReport', {
        commentId: payload.id,
        institutionCode: payload.institutionCode,
      })
      .then(() => {
        commit(types.MUTATE_REMOVE_REPORTED_COMMENT, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'MESSAGE_REPORTED_CONTENT_DELETED');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_ACCEPT_REPORTED_CONTENT');
      }),
  [types.REJECT_REPORTED_POST]: ({ commit }, payload) =>
    portal
      .post('?method=posts.rejectReportedPost', {
        id: payload.id,
        institutionCode: payload.institutionCode,
      })
      .then(() => {
        commit(types.MUTATE_REJECT_REPORTED_POST, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'MESSAGE_REPORTED_CONTENT_REJECTED');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REJECT_REPORTED_CONTENT');
      }),
  [types.REJECT_REPORTED_MEDIA]: ({ commit }, payload) =>
    portal
      .post('?method=gallery.rejectReportedMedia', {
        mediaId: payload.id,
        institutionCode: payload.institutionCode,
      })
      .then(() => {
        commit(types.MUTATE_REJECT_REPORTED_MEDIA, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'MESSAGE_REPORTED_CONTENT_REJECTED');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REJECT_REPORTED_CONTENT');
      }),
  [types.REJECT_REPORTED_COMMENT]: ({ commit }, payload) =>
    portal
      .post('?method=comments.rejectReport', {
        commentId: payload.id,
        institutionCode: payload.institutionCode,
      })
      .then(() => {
        commit(types.MUTATE_REJECT_REPORTED_COMMENT, payload);
        commit(types.MUTATE_SUCCESS_TEXT, 'MESSAGE_REPORTED_CONTENT_REJECTED');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REJECT_REPORTED_CONTENT');
      }),
  [types.LOAD_RELATED_POST_BY_ID]: ({ commit }, payload) =>
    portal
      .get('?method=posts.getPostForGroupAdminById', {
        params: {
          id: payload.postId,
        },
      })
      .then(response => {
        commit(types.MUTATE_RELATED_POST, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_POST_BY_ID');
      }),
  [types.LOAD_RELATED_MEDIA_BY_ID]: ({ commit }, payload) =>
    portal
      .get('?method=gallery.getMediaById', {
        params: {
          id: payload.mediaId,
        },
      })
      .then(response => {
        commit(types.MUTATE_RELATED_MEDIA, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REJECT_REPORTED_CONTENT');
      }),
};
export default {
  state,
  mutations,
  actions,
  getters,
};
