<template>
  <el-option :id="optionId" ref="elementOption" v-bind="$attrs" :aria-selected="`${isSelected}`" role="option">
    <slot />
  </el-option>
</template>

<script>
import uniqueId from 'lodash/uniqueId';
export default {
  data() {
    return {
      elementOptionRef: null,
      optionId: uniqueId('aula-option-'),
    };
  },
  computed: {
    visible() {
      // Used to communicate with the parent component about it's visibility status
      if (this.elementOptionRef != null) {
        return this.elementOptionRef.visible;
      }
      return false;
    },
    isSelected() {
      if (this.elementOptionRef != null) {
        return this.elementOptionRef.itemSelected;
      }
      return false;
    },
  },
  mounted() {
    this.elementOptionRef = this.$refs.elementOption;
  },
};
</script>
