export enum messageFiltersEnum {
  FOLDER_TYPE_COMMON = 'common',
  DEFAULT_FOLDER = 'inbox',
  FILTER_ALL = 'all',
  FILTER_UNREAD = 'unread',
  FILTER_MARKED = 'marked',
  FILTER_DRAFT = 'draft',
  SORT_DATE = 'date',
  SORT_SUBJECT = 'subject',
}
