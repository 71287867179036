import { types } from '../types/types';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';
import { permissionEnum } from '../../../shared/enums/permissionEnum';
import { moduleWidgetPlacements } from '../../../shared/enums/moduleWidgetPlacements';
import { displayMode } from '../../../shared/enums/displayMode';
import { moduleTypes } from '../../../shared/enums/moduleTypes';
import { portalRoles } from '../../../shared/enums/portalRoles';
import { institutionRole } from '../../../shared/enums/institutionRole';
import uniq from 'lodash/uniq';

const state = {
  isPreviewMode: false,
  currentUniLogin: null,
  children: [],
  activeChildren: [],
  activeChildrenUniLogin: [],
  activeInstitutions: [],
  activeInstitutionProfiles: [],
  activeMailboxes: [],
  menuItems: {},
  browserCheck: [],
  showGroupsDropdown: false,
  showSearchDropdown: false,
  showProfileDropdown: false,
  showMobileProfileDropdown: false,
  sessionInitialized: false,
  showHeader: true,
  consentWarningProceeed: false,
  sameAuthorityInstitutions: [],
  groupHomes: [],
  activeGroupHome: null,
};

const getters = {
  [types.GET_CURRENT_UNILOGIN]: state => state.currentUniLogin,
  [types.GET_IS_PREVIEW_MODE]: state => state.isPreviewMode,
  [types.GET_SHOW_HEADER]: state => state.showHeader,
  [types.GET_MENU_ITEMS]: state => state.menuItems,
  [types.GET_CHILDREN]: state => state.children,
  [types.GET_HAS_CUSTODY_OR_EXTENDED_ACCESS]: state => childId => {
    const child = state.children.find(c => c.id === childId);
    if (child) {
      return child.hasCustodyOrExtendedAccess;
    }
    return false;
  },
  [types.GET_ACTIVE_CHILDREN]: state => state.activeChildren,
  [types.GET_GROUP_HOMES]: state => state.groupHomes,
  [types.GET_ACTIVE_GROUP_HOME]: state => state.activeGroupHome,
  [types.GET_ACTIVE_CHILDREN_UNILOGIN]: state => state.activeChildrenUniLogin,
  [types.GET_ACTIVE_INSTITUTIONS]: state => state.activeInstitutions,
  [types.GET_ACTIVE_MAILBOXES]: state => state.activeMailboxes,
  [types.GET_BROWSER_CHECK]: state => {
    const mobileAndTabletcheck = function () {
      let check = false;
      (function (a) {
        if (
          /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino|android|ipad|playbook|silk/i.test(
            a
          ) ||
          /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
            a.substr(0, 4)
          )
        ) {
          check = true;
        }
      })(navigator.userAgent || navigator.vendor || window.opera);
      return check;
    };

    const ua = window.navigator.userAgent;
    if (
      typeof InstallTrigger !== 'undefined' || // Firefox check
      /constructor/i.test(window.HTMLElement) ||
      (function (p) {
        return p.toString() === '[object SafariRemoteNotification]';
        // eslint-disable-next-line no-undef
      })(!window.safari || (typeof safari !== 'undefined' && safari.pushNotification)) || // Safari check
      (!document.documentMode && !!window.StyleMedia) || // Edge Check
      (!!window.chrome && (!!window.chrome.webstore || window.navigator.vendor === 'Google Inc.')) || // Chrome check
      (/Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)) || // More Chrome check
      (ua.indexOf('Chrome') > -1 && ua.indexOf('Android ') > -1) || // Chrome for Android Check
      (!!ua.match(/iPhone|iPad|Macintosh/i) && !!ua.match(/WebKit/i) && !ua.match(/CriOS/i)) || // Safari Check
      mobileAndTabletcheck()
    ) {
      state.browserCheck = false;
    } else {
      state.browserCheck = true;
    }
    return state.browserCheck;
  },
  [types.GET_SHOW_GROUPS_DROPDOWN]: state => state.showGroupsDropdown,
  [types.GET_SHOW_SEARCH_DROPDOWN]: state => state.showSearchDropdown,
  [types.GET_SHOW_PROFILE_DROPDOWN]: state => state.showProfileDropdown,
  [types.GET_SHOW_MOBILE_PROFILE_DROPDOWN]: state => state.showMobileProfileDropdown,
  [types.GET_SAME_AUTHORITY_INSTITUTIONS]: state => state.sameAuthorityInstitutions,
};

const mutations = {
  [types.MUTATE_IS_PREVIEW_MODE]: (state, bool) => (state.isPreviewMode = bool),
  [types.MUTATE_SET_ACTIVE_CHILDREN]: (state, children) => {
    state.activeChildren = children.map(child => child.id);
  },
  [types.MUTATE_SET_ACTIVE_CHILDREN_UNILOGIN]: state => {
    const uniLogin = [];
    state.activeChildren.forEach(function (el) {
      state.children.forEach(function (el2) {
        if (el == el2.id) {
          uniLogin.push(el2.userId);
        }
      });
    });
    state.activeChildrenUniLogin = uniLogin;
    return state.activeChildrenUniLogin;
  },
  [types.MUTATE_SET_ACTIVE_INSTITUTIONS]: (state, institutions) => {
    state.activeInstitutions = institutions.map(institution => institution.institutionCode);
    state.activeInstitutionProfiles = institutions.map(institution => institution.institutionProfileId);
  },
  [types.MUTATE_SET_ACTIVE_FILTERS_DIRECT]: (state, { institutionCodes, children }) => {
    if (institutionCodes?.length > 0) {
      state.activeInstitutions = institutionCodes;
    }
    if (children?.length > 0) {
      state.activeChildrenUniLogin = children;
    }
  },
  [types.MUTATE_SET_ACTIVE_MAILBOXES]: (state, payload) => {
    if (state.activeMailboxes.includes(payload.mailboxId)) {
      const index = state.activeMailboxes.indexOf(payload.mailboxId);
      state.activeMailboxes.splice(index, 1);
    } else {
      state.activeMailboxes.push(payload.mailboxId);
    }
    return state.activeMailboxes;
  },
  [types.MUTATE_TOGGLE_HEADER_DROPDOWN]: (state, payload) => {
    if (payload.dropdown === 'mobileProfile') {
      if (payload.visible === false) {
        state.showSearchDropdown = false;
        state.showProfileDropdown = false;
        state.showGroupsDropdown = true;
        state.showMobileProfileDropdown = true;
      } else {
        state.showMobileProfileDropdown = false;
      }
    } else if (payload.dropdown === 'groups' && payload.visible === false) {
      state.showGroupsDropdown = true;
      state.showSearchDropdown = false;
      state.showProfileDropdown = false;
      state.showMobileProfileDropdown = false;
    } else if (payload.dropdown === 'search' && payload.visible === false) {
      state.showGroupsDropdown = false;
      state.showSearchDropdown = true;
      state.showProfileDropdown = false;
      state.showMobileProfileDropdown = false;
    } else if (payload.dropdown === 'profile' && payload.visible === false) {
      state.showGroupsDropdown = false;
      state.showSearchDropdown = false;
      state.showProfileDropdown = true;
      state.showMobileProfileDropdown = false;
    } else {
      state.showGroupsDropdown = false;
      state.showSearchDropdown = false;
      state.showProfileDropdown = false;
      state.showMobileProfileDropdown = false;
    }
  },
  [types.MUTATE_HIDE_MENU]: (state, payload) => {
    state.hideMenu = payload;
  },
  [types.MUTATE_HIDE_PROFILE_DROPDOWN]: state => {
    state.showProfileDropdown = false;
  },
  [types.MUTATE_HIDE_SEARCH_DROPDOWN]: state => {
    state.showSearchDropdown = false;
  },
  [types.MUTATE_HIDE_GROUPS_DROPDOWN]: state => {
    state.showGroupsDropdown = false;
  },
  [types.MUTATE_SET_SHOW_HEADER]: (state, payload) => {
    state.showHeader = payload;
  },
  [types.MUTATE_SET_MUNICIPAL_IPS]: (state, payload) => {
    state.municipalIPSList = payload.filter(obj => obj.institutionName === obj.municipalityName);
    state.schoolList = payload.filter(obj => obj.institutionName !== obj.municipalityName);
  },
  [types.MUTATE_CALCULATE_MENU_ITEMS]: (state, globalShared) => {
    const modulesWidgets = [];
    if (Object.keys(globalShared.pageConfiguration).length > 0) {
      // get modules
      for (const moduleConfig of globalShared.pageConfiguration.moduleConfigurations) {
        switch (moduleConfig.module.type) {
          case moduleTypes.CALENDAR: {
            moduleConfig.module.permissions = [permissionEnum.SEE_CALENDAR];
            break;
          }
          case moduleTypes.ADMINISTRATION: {
            moduleConfig.module.permissions = [permissionEnum.ADMIN_MODULE];
            break;
          }
          case moduleTypes.PRESENCE: {
            if (globalShared.currentProfile.role == portalRoles.EMPLOYEE) {
              moduleConfig.module.url += '/medarbejder';
            }
            break;
          }
          case moduleTypes.DOCUMENTS: {
            moduleConfig.module.url = '/dokumenter/';
            break;
          }
          default: {
            moduleConfig.module.permissions = [];
            break;
          }
        }
        modulesWidgets.push(moduleConfig);
      }
      // get widgets
      for (const widgetConfig of globalShared.pageConfiguration.widgetConfigurations) {
        modulesWidgets.push(widgetConfig);
      }
    }
    // filter
    const menuItems = {};
    Object.keys(moduleWidgetPlacements).forEach(function (key) {
      menuItems[moduleWidgetPlacements[key]] = [];
    });
    for (const item of modulesWidgets) {
      const menuItem = item.module != null ? item.module : item.widget;
      menuItem.show = item.aggregatedDisplayMode === displayMode.SHOWN;
      menuItem.placement = item.placement;
      menuItem.order = item.order;
      if (menuItem.icon.match(/\.(jpg|svg|jpeg|gif|png)/i)) {
        menuItem.iconImage = menuItem.icon;
        if (
          globalShared.currentProfile.role === portalRoles.EMPLOYEE &&
          typeof menuItem.iconEmployee === 'string' &&
          menuItem.iconEmployee.length > 0
        ) {
          menuItem.iconImage = menuItem.iconEmployee;
        }
      } else {
        menuItem.iconImage = null;
      }
      const isPlacement = Object.values(moduleWidgetPlacements).indexOf(item.placement) > -1;
      if (menuItem.show && isPlacement) {
        menuItems[item.placement].push(menuItem);
      }
    }
    // sorting
    Object.keys(moduleWidgetPlacements).forEach(function (key) {
      menuItems[moduleWidgetPlacements[key]].sort(function (a, b) {
        return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
      });
    });
    state.menuItems = menuItems;
  },
  [types.MUTATE_PREVIEW_MODULES_WIDGETS_CONFIG]: (state, payload) => {
    const menuItems = getMenuItems();
    const previewModules = payload.modules.moduleConfigurationDtos.filter(m => payload.previewModules.includes(m.id));
    for (const module of previewModules) {
      if (Object.values(moduleWidgetPlacements).indexOf(module.placement) > -1) {
        module.module.placement = module.placement;
        menuItems[module.placement].push(module.module);
      }
    }
    if (payload.previewWidgets !== null && payload.widgets !== null) {
      const blueIconInstitutionRoles = [
        institutionRole.EARLY_STUDENT,
        institutionRole.MIDDLE_LATE_STUDENT,
        institutionRole.GUARDIAN,
      ];
      for (const widget of payload.widgets) {
        const index = payload.previewWidgets.findIndex(w => w.id == widget.id);
        if (index > -1) {
          widget.placement = payload.previewWidgets[index].placement;
          widget.restrictedGroups = payload.previewWidgets[index].restrictedGroups;
          if (widget.icon.match(/(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|svg|jpeg|gif|png)/g)) {
            widget.iconImage = !blueIconInstitutionRoles.includes(payload.institutionRole)
              ? widget.iconEmployee
              : widget.icon;
          } else {
            widget.iconImage = null;
          }
          menuItems[widget.placement].push(widget);
        }
      }
    }
    Object.keys(moduleWidgetPlacements).forEach(function (key) {
      menuItems[moduleWidgetPlacements[key]].sort(function (a, b) {
        return a.order > b.order ? 1 : b.order > a.order ? -1 : 0;
      });
    });
    state.menuItems = menuItems;
  },
  [types.MUTATE_SET_SAME_AUTHORITY_INSTITUTIONS]: (state, payload) => {
    let institutions = [];
    for (const response of payload) {
      if (response.data) {
        institutions = institutions.concat(response.data.data);
      }
    }
    institutions = uniq(institutions); // remove duplicate institutions
    state.sameAuthorityInstitutions = institutions;
  },
  [types.MUTATE_SET_GROUP_HOMES]: (state, payload) => {
    state.groupHomes = payload;
  },
  [types.MUTATE_SET_ACTIVE_GROUP_HOME]: (state, payload) => {
    const activeGroupHome = state.groupHomes.find(groupHome => groupHome.id == payload);

    if (activeGroupHome) {
      state.activeGroupHome = activeGroupHome;
      state.children = activeGroupHome.children;
      setActiveChildren();
    }
  },
  [types.MUTATE_SET_ACTIVE_ENTITIES_FOR_CURRENT_PROFILE]: (state, payload) => {
    const profile = payload.profile;
    state.children = [];
    state.groupHomes = [];
    state.activeInstitutions = [];
    state.activeMailboxes = [];
    state.activeChildren = [];

    for (const institution of profile.institutions) {
      institution.isPrimary = institution.institutionCode === profile.institutionProfile.institution.institutionCode;

      const children = institution.children.map(function (child) {
        child.institutionCode = institution.institutionCode;
        child.institutionName = institution.name;
        child.municipalityCode = institution.municipalityCode;
        return child;
      });
      state.children = state.children.concat(children);
    }

    state.groupHomes = profile.groupHomes ? profile.groupHomes : [];

    if (state.groupHomes.length > 0) {
      state.children = state.groupHomes[0].children;
      state.activeGroupHome = state.activeGroupHome ? state.activeGroupHome : state.groupHomes[0];
    }

    setActiveChildren();
    state.currentUniLogin = profile.userId;

    if (state.activeChildren) {
      state.activeChildren.forEach(activeChild => {
        state.children.forEach(child => {
          if (activeChild == child.id) {
            state.activeChildrenUniLogin.push(child.userId);
          }
        });
      });
    }

    if (profile.institutionProfile.role === portalRoles.GUARDIAN || profile.institutions.length < 7) {
      state.activeInstitutions = profile.institutions.map(institution => institution.institutionCode);
      state.activeInstitutionProfiles = profile.institutions.map(institution => institution.institutionProfileId);
      state.activeMailboxes = profile.institutions.map(institution => institution.mailboxId);
    } else {
      const primaryInstitution = profile.institutions.find(i => i.isPrimary);

      if (primaryInstitution != null) {
        state.activeInstitutions = [primaryInstitution.institutionCode];
        state.activeInstitutionProfiles = [primaryInstitution.institutionProfileId];
        state.activeMailboxes = [primaryInstitution.mailboxId];
      }
    }
  },
};

const actions = {
  [types.SET_IS_PREVIEW_MODE]: ({ commit }, payload) => commit(types.MUTATE_IS_PREVIEW_MODE, payload),
  [types.LOAD_PREVIEW_MODULES_WIDGETS_CONFIG]: ({ commit, rootState }, payload) =>
    portal
      .get('?method=dashboards.getAdminDashboardConfiguration', {
        params: {
          institutionCode: payload.institutionCode,
          institutionRole: payload.institutionRole,
          institutionType: payload.institutionType,
        },
      })
      .then(moduleResponse => {
        const widgetIds = payload.previewWidgets.map(w => w.id);
        if (widgetIds.length > 0) {
          return portal
            .get('?method=dashboards.getWidgetsByIds', {
              params: { widgetIds: widgetIds },
            })
            .then(response => {
              const previewModulesWidgets = {
                modules: moduleResponse.data.data,
                previewModules: payload.previewModules,
                widgets: response.data.data,
                previewWidgets: payload.previewWidgets,
                profileRole: rootState.globalShared.currentProfile.role,
                institutionRole: payload.institutionRole,
              };
              commit(types.MUTATE_PREVIEW_MODULES_WIDGETS_CONFIG, previewModulesWidgets);
            })
            .catch(() => {
              commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_WIDGETS_BY_ID');
            });
        } else {
          const previewModulesWidgets = {
            modules: moduleResponse.data.data,
            previewModules: payload.previewModules,
            widgets: null,
            previewWidgets: null,
            profileRole: rootState.globalShared.currentProfile.role,
            institutionRole: payload.institutionRole,
          };
          commit(types.MUTATE_PREVIEW_MODULES_WIDGETS_CONFIG, previewModulesWidgets);
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ADMIN_DASHBOARD_CONFIG');
      }),
  [types.SET_ACTIVE_CHILDREN]: ({ commit }, children) => {
    commit(types.MUTATE_SET_ACTIVE_CHILDREN, children);
    return commit(types.MUTATE_SET_ACTIVE_CHILDREN_UNILOGIN, children);
  },
  [types.SET_ACTIVE_INSTITUTIONS]: ({ commit }, institutions) =>
    commit(types.MUTATE_SET_ACTIVE_INSTITUTIONS, institutions),
  [types.SET_GROUP_HOMES]: ({ commit }, payload) => commit(types.MUTATE_SET_GROUP_HOMES, payload),
  [types.SET_ACTIVE_GROUP_HOME]: ({ commit }, payload) => {
    commit(types.MUTATE_SET_ACTIVE_GROUP_HOME, payload);
  },

  [types.SET_ACTIVE_FILTERS_DIRECT]: ({ commit }, payload) => commit(types.MUTATE_SET_ACTIVE_FILTERS_DIRECT, payload),
  [types.TOGGLE_HEADER_DROPDOWN]: ({ commit }, payload) => {
    commit(types.MUTATE_TOGGLE_HEADER_DROPDOWN, payload);
  },
  [types.ACTIONS_SHOW_HEADER]: ({ commit }) => {
    commit(types.MUTATE_SET_SHOW_HEADER, true);
  },
  [types.ACTIONS_HIDE_HEADER]: ({ commit }) => {
    commit(types.MUTATE_SET_SHOW_HEADER, false);
  },
  [types.CALCULATE_MENU_ITEMS]: ({ commit, rootState }) => {
    commit(types.MUTATE_CALCULATE_MENU_ITEMS, rootState.globalShared);
  },
  [types.ACTION_GET_SAME_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS]: ({ commit }, payload) => {
    const requests = [];
    for (const institution of payload) {
      requests.push(
        portal.get('?method=municipalConfiguration.getSameAdministrativeAuthorityInstitutions', {
          params: { institutionCode: institution.institutionCode },
        })
      );
    }
    return Promise.all(requests)
      .then(responses => {
        commit(types.MUTATE_SET_SAME_AUTHORITY_INSTITUTIONS, responses);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SAME_ADMINISTRATIVE_AUTHORITY_INSTITUTIONS');
      });
  },
};
function getMenuItems(menuItems = null) {
  if (menuItems == null) {
    const initMenus = {};
    Object.keys(moduleWidgetPlacements).forEach(function (key) {
      initMenus[moduleWidgetPlacements[key]] = [];
    });
    return initMenus;
  } else {
    return menuItems;
  }
}

function setActiveChildren() {
  if (Array.isArray(state.children)) {
    const childProfileDictionary = {};
    let childIds = state.children.map(child => child.id);
    state.children.sort((a, b) => a.name.localeCompare(b.name, 'da', { sensitivity: 'base' }));
    for (const child of state.children) {
      childProfileDictionary[child.profileId] = true;
      if (state.children.some(c => c.institutionCode !== child.institutionCode && c.profileId === child.profileId)) {
        child.isMultipleInstitution = true;
      }
    }
    const childProfileIds = Object.keys(childProfileDictionary).map(key => parseInt(key));

    if (childProfileIds.length > 6) {
      const childProfileId = childProfileIds[0];
      childIds = state.children.filter(child => child.profileId === childProfileId).map(child => child.id);
    }

    state.activeChildren = childIds;
  }
}

export default {
  state,
  mutations,
  actions,
  getters,
};
