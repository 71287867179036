<template>
  <portal v-if="showAulaModal || alwaysVisible" :to="modalIdName" :disabled="disablePortal" slim>
    <div :id="modalIdName" ref="aulaModalContainer" class="aula-modal-container" :class="cssClass">
      <div
        :class="{ 'no-backdrop': hideBackDrop }"
        class="modal-aula-backdrop modal-backdrop fade show"
        @click="backdropClicked()"
      />
      <div
        v-bind="{ ...$attrs }"
        class="modal-aula scrollbar"
        role="dialog"
        tabindex="0"
        aria-modal="true"
        :aria-labelledby="labelledByTarget"
        :aria-describedby="describedByTarget"
      >
        <div class="modal-content">
          <slot v-if="!hideHeader" name="header">
            <mobile-topbar
              v-if="isMobile"
              :show-ok="showOk"
              :show-cancel="showCancel"
              :show-left-icon="showLeftIcon"
              :header-text-from-textkey="headerTextFromTextkey"
              :text-key="headerText"
              :text="headerText"
              :right-text-icon="okIcon"
              :right-text="okText"
              :left-text="leftText"
              :show-header="showHeader"
              :is-loading="isLoading"
              :header-icon="headerIcon"
              @leftIconClicked="$emit('cancelClicked')"
              @rightTextClicked="$emit('okClicked')"
            />
            <header v-if="!isMobile" class="modal-header w-100 truncate">
              <i v-if="headerIcon != null" :class="headerIcon" class="pr-2" />
              <span v-if="headerTextFromTextkey" :id="labelledByTarget" class="modal-title">{{
                headerText | fromTextKey
              }}</span>
              <span v-else :id="labelledByTarget" class="modal-title">{{ headerText }}</span>
              <i
                v-if="!disableClose"
                class="icon-Aula_close aula-close"
                tabindex="0"
                role="button"
                :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
                @click="hide(), $emit('closeClicked')"
                @keydown.enter="hide(), $emit('closeClicked')"
              />
            </header>
          </slot>
          <slot name="headline" />
          <div :id="describedByTarget" class="modal-body scrollbar">
            <i
              v-if="hideHeader && !disableClose"
              class="icon-Aula_close aula-close p-2"
              tabindex="0"
              role="button"
              :aria-label="'ARIA_LABEL_CLOSE' | fromTextKey"
              @click="hide(), $emit('closeClicked')"
              @keydown.enter="hide(), $emit('closeClicked')"
            />
            <slot />
          </div>
          <footer v-if="!hideFooter && !isMobile" class="modal-footer w-100">
            <b-btn v-if="showCancel" variant="link" class="cancel-btn px-2" @click="$emit('cancelClicked')">
              <i v-if="cancelIcon" class="icon" :class="cancelIcon" />
              {{ cancelText | fromTextKey }}
            </b-btn>
            <b-btn v-if="showEdit" variant="link" @click="$emit('editClicked')">
              <i v-if="editIcon" class="icon" :class="editIcon" />
              {{ editText | fromTextKey }}
            </b-btn>
            <div class="btn-with-loader">
              <aula-spinner v-if="isLoading" class="aula-spinner" />
              <b-btn
                v-if="showOk"
                variant="primary"
                class="send-btn text-uppercase"
                :disabled="isLoading || disableSubmit"
                @click.stop="$emit('okClicked')"
              >
                {{ okText | fromTextKey }}
              </b-btn>
            </div>
          </footer>
        </div>
      </div>
    </div>
  </portal>
</template>

<script>
import { types } from '../../src/store/types/types';
import { mapGetters, mapMutations } from 'vuex';
import MobileTopbar from './MobileTopbar';
import $ from 'jquery';
export default {
  components: {
    MobileTopbar,
  },
  props: {
    editText: { type: String, default: 'BUTTON_EDIT' },
    editIcon: { type: String, default: null },
    cancelText: { type: String, default: 'CANCEL' },
    cancelIcon: { type: String, default: null },
    okIcon: { type: String, default: null },
    okText: { type: String, default: 'OK' },
    headerText: { type: String, default: 'WARNING_MODAL' },
    headerTextFromTextkey: { type: Boolean, default: true },
    headerIcon: { type: String, default: null },
    showCancel: { type: Boolean, default: true },
    showEdit: { type: Boolean, default: false },
    showOk: { type: Boolean, default: true },
    width: { type: String, default: '' },
    cssClass: { type: String, default: '' },
    isLoading: { type: Boolean, default: false },
    disableSubmit: { type: Boolean, default: false },
    disableClose: { type: Boolean, default: false },
    alwaysVisible: { type: Boolean, default: false },
    hideHeader: { type: Boolean, default: false },
    hideFooter: { type: Boolean, default: false },
    hideBackDrop: { type: Boolean, default: false },
    isScrollTop: { type: Boolean, default: false },
    disablePortal: { type: Boolean, default: false },
    showLeftIcon: { type: Boolean, default: true },
    leftText: { type: String, default: null },
    showHeader: { type: Boolean, default: true },
    describedBy: { type: String, required: false },
    labelledBy: { type: String, required: false },
  },
  data: function () {
    return {
      showAulaModal: false,
      modalId: 0,
      awaitingAnimationFrame: false,
      focusableElementQuery: 'button, [href], input, select, textarea, [tabindex]:not([tabindex="-1"])',
    };
  },
  computed: {
    ...mapGetters({
      openModals: types.GET_OPEN_MODALS,
      isMobile: types.GET_IS_MOBILE,
    }),
    describedByTarget() {
      if (this.describedBy) {
        return this.describedBy;
      }
      return this.modalIdName + '-described';
    },
    labelledByTarget() {
      if (this.labelledBy) {
        return this.labelledBy;
      }
      return this.modalIdName + '-labelled';
    },
    modalIdName() {
      return 'modal-' + this.modalId;
    },
    modalIdSelector() {
      return '#' + this.modalIdName;
    },
  },
  mounted() {
    window.addEventListener('resize', this.updateCssTransform);
    if (this.alwaysVisible) {
      this.show();
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updateCssTransform);
    this.hide();
    $('.scrollbar').off('touchstart');
    $('.scrollbar').off('touchmove');
  },
  methods: {
    ...mapMutations({
      addOpenModal: types.MUTATE_ADD_OPEN_MODAL,
      subtractOpenModal: types.MUTATE_SUBTRACT_OPEN_MODAL,
    }),
    show() {
      if (!this.showAulaModal) {
        if (!this.disablePortal) {
          this.addOpenModal();
        }
        this.modalId = this.openModals;
        this.showAulaModal = true;
        this.$emit('shown');
        const isOverflowed = document.documentElement.clientHeight < document.documentElement.scrollHeight;
        if (isOverflowed) {
          document.documentElement.setAttribute('data-overflowed', '');
        }
        if (!this.isScrollTop) {
          $('html').addClass('aula-open-do-not-reset-position');
        }
        $('html').addClass('aula-modal-open');

        setTimeout(() => {
          this.roundCssTransformMatrix();
          this.setInitialFocus();
        });
        document.addEventListener('keydown', this.trapFocus);
      }
    },
    setInitialFocus() {
      const firstFocusableElement = this.$refs.aulaModalContainer.querySelector(this.focusableElementQuery);
      firstFocusableElement?.focus();
    },
    trapFocus(event) {
      const focusableElements = this.$refs.aulaModalContainer.querySelectorAll(this.focusableElementQuery);
      const isTabPressed = event.keyCode === 9;

      if (!isTabPressed || focusableElements.length === 0) {
        return;
      }

      const firstFocusableElement = focusableElements[0];
      const lastFocusableElement = focusableElements[focusableElements.length - 1];

      if (event.shiftKey) {
        if (document.activeElement === firstFocusableElement) {
          event.preventDefault();
          lastFocusableElement.focus();
        }
      } else {
        if (document.activeElement === lastFocusableElement) {
          event.preventDefault();
          firstFocusableElement.focus();
        }
      }
    },
    /**
     *  Corrects the transform of the modal by removing positional decimal values in the transform matrix - see https://stackoverflow.com/questions/27385126/chrome-font-appears-blurry/42256897#42256897
     */
    roundCssTransformMatrix() {
      const el = document.getElementsByClassName('modal-aula')[0];
      if (el != null) {
        el.style.transform = ''; // resets the redifined matrix to allow recalculation, the original style should be defined in the class not inline.
        let mx = window.getComputedStyle(el, null); // gets the current computed style
        mx =
          mx.getPropertyValue('-webkit-transform') ||
          mx.getPropertyValue('-moz-transform') ||
          mx.getPropertyValue('-ms-transform') ||
          mx.getPropertyValue('-o-transform') ||
          mx.getPropertyValue('transform') ||
          false;
        const values = mx.replace(/ |\(|\)|matrix/g, '').split(',');
        for (const v in values) {
          values[v] = v > 3 ? Math.ceil(values[v]) : values[v];
          if (v == 5 && !this.isMobile) {
            values[v] = values[v] + 30;
          }
        }

        el.style.transform = 'matrix(' + values.join() + ')';
      }
    },
    updateCssTransform() {
      if (!this.awaitingAnimationFrame) {
        this.awaitingAnimationFrame = true;
        window.requestAnimationFrame(() => {
          this.roundCssTransformMatrix();
          this.awaitingAnimationFrame = false;
        });
      }
    },
    hide() {
      if (this.showAulaModal) {
        if (!this.disablePortal) {
          this.subtractOpenModal();
        }
        if (this.openModals == 0) {
          document.documentElement.removeAttribute('data-overflowed');
          if (!this.isScrollTop) {
            $('html').removeClass('aula-open-do-not-reset-position');
          }
          $('html').removeClass('aula-modal-open');
        }
        this.showAulaModal = false;
        this.$emit('hidden');
        document.removeEventListener('keydown', this.trapFocus);
      }
    },
    backdropClicked() {
      if (!this.disableClose) {
        this.hide();
      }
      this.$emit('closeClicked');
    },
  },
};
</script>

<style scoped lang="scss">
@import '../assets/scss/core/variables.scss';
@import '../assets/scss/core/breakpoints.scss';

footer {
  position: relative;
}

.modal-aula {
  min-width: 300px;
  .modal-header {
    padding-right: 60px;
    z-index: $modal-sticky-header-z-index;
    position: sticky;
    top: 0;
  }
}
.aula-spinner {
  width: 70px;
  height: 70px;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.icon-Aula_close {
  position: absolute;
  right: 15px;
  top: 20px;
  cursor: pointer;
  z-index: 1000;
}
.modal-title {
  text-transform: uppercase;
}
.btn-with-loader {
  position: relative;
}
</style>
