import { types } from '../../../src/store/types/types';
import { portal } from '../../assets/plugins/axios/axios.js';
import { comeGoNotificationAreas } from '../../enums/comeGoNotificationAreas';

const state = {
  notifications: [],
  notificationSettings: [],
  showStepUpNotification: false,
  showStepUpMessage: null,
  redirectedUrlAfterStepUp: null,
  cancelUrl: null,
  showStepUpForOnboarding: null,
  parentType: null,
  unsubscribeTokenValid: false,
};

const getters = {
  [types.GET_VALIDATE_UNSUBSCRIBE_NOTIFICATIONS]: state => state.unsubscribeTokenValid,
  [types.GET_NOTIFICATION_SETTINGS]: state => state.notificationSettings,
  [types.GET_NOTIFICATIONS]: state => state.notifications,
  [types.GET_ACTIVE_STEP_UP_NOTIFICATION]: state => ({
    parentType: state.parentType,
    showStepUpNotification: state.showStepUpNotification,
    redirectedUrl: state.redirectedUrlAfterStepUp,
    cancelUrl: state.cancelUrl,
    showStepUpMessage: state.showStepUpMessage,
    showStepUpForOnboarding: state.showStepUpForOnboarding,
  }),
};

const mutations = {
  [types.MUTATE_SET_NOTIFICATION_SETTINGS]: (state, payload) => {
    state.notificationSettings = payload;
  },
  [types.MUTATE_SAVE_NOTIFICATION_SETTINGS]: state => {
    state.notificationSettings = [];
  },
  [types.MUTATE_LOAD_NOTIFICATIONS]: (state, payload) => {
    state.notifications = payload;
  },
  [types.MUTATE_SET_NOTIFICATION_STEP_UP]: (state, payload) => {
    state.parent = payload.parent;
    state.showStepUpNotification = payload.showStepUpNotification;
    state.redirectedUrlAfterStepUp = payload.redirectedUrl;
    state.cancelUrl = payload.cancelUrl;
    if (payload.showStepUpForOnboarding) {
      state.showStepUpForOnboarding = payload.showStepUpForOnboarding;
    }
    if (payload.showStepUpMessage) {
      state.showStepUpMessage = payload.showStepUpMessage;
    }
  },
  [types.MUTATE_DELETE_NOTIFICATIONS]: (state, payload) => {
    const notificationIds = payload.notifications.map(noti => noti.notificationId);
    state.notifications = state.notifications.filter(
      notification => !notificationIds.includes(notification.notificationId)
    );
  },
  [types.MUTATE_VALIDATE_UNSUBSCRIBE_NOTIFICATIONS]: (state, isValid) => {
    state.unsubscribeTokenValid = isValid;
  },
};

const actions = {
  [types.SET_NOTIFICATION_SETTINGS]: ({ commit }) =>
    portal
      .get('?method=Notifications.getNotificationSettingsForActiveProfile', {
        params: {
          includeDeviceTokens: true,
        },
      })
      .then(response => {
        commit(types.MUTATE_SET_NOTIFICATION_SETTINGS, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_NOTIFICATION_SETTINGS');
      }),
  [types.SAVE_NOTIFICATION_SETTINGS]: ({ commit }, payload) => {
    const comeGoNotificationSettings = [
      {
        comeGoType: comeGoNotificationAreas.PICK_UP_ACTIVITY,
        activated: payload.pickUpActivity ? payload.pickUpActivity : false,
      },
      {
        comeGoType: comeGoNotificationAreas.VACATION_REQUEST,
        activated: payload.vacationRequest ? payload.vacationRequest : false,
      },
    ];

    return portal
      .post('?method=Notifications.setNotificationSettingsForActiveProfile', {
        scheduledTime: payload.scheduledTime,
        notifyMessages: payload.notifyMessages,
        notifyMessagesFromEmployees: payload.messagesFromEmployees,
        notifyMessagesFromGuardians: payload.messagesFromGuardians,
        notifyMessagesFromChildren: payload.messagesFromChildren,
        notifyCalendar: payload.calendar,
        notifyAssignedAsSubstituteTeacher: payload.notifyAssignedAsSubstituteTeacher,
        notifyLessonNoteChanged: payload.notifyLessonNoteChanged,
        notifyGallery: payload.gallery,
        notifyPosts: payload.post,
        widgetSettings: payload.widgetSettings,
        comeGoNotificationSettings,
        appDisabled: !payload.app,
        emailDisabled: !payload.email,
        instant: payload.instant,
        monday: payload.monday,
        tuesday: payload.tuesday,
        wednesday: payload.wednesday,
        thursday: payload.thursday,
        friday: payload.friday,
        saturday: payload.saturday,
        sunday: payload.sunday,
      })
      .then(() => {
        commit(types.MUTATE_SAVE_NOTIFICATION_SETTINGS);
        commit(types.MUTATE_SUCCESS_TEXT, 'NOTIFICATION_SETTINGS_SAVED');
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_NOTIFICATION_SETTINGS');
      });
  },
  [types.LOAD_NOTIFICATIONS]: ({ commit, rootState }, payload) => {
    if (rootState.global.isPreviewMode) {
      commit(types.MUTATE_LOAD_NOTIFICATIONS, []);
    } else {
      const activeGroupHome = rootState.global.activeGroupHome;

      let params = {};
      if (activeGroupHome != null) {
        params = {
          activeChildrenIds: payload.activeChildrenIds,
          activeGroupHomes: activeGroupHome ? [activeGroupHome.id] : null,
        };
      } else {
        params = {
          activeChildrenIds: payload.activeChildrenIds,
          activeInstitutionCodes: payload.activeInstitutionCodes,
        };
      }
      return portal
        .get('?method=notifications.getNotificationsForActiveProfile', {
          params,
        })
        .then(response => {
          commit(types.MUTATE_LOAD_NOTIFICATIONS, response.data.data);
        })
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_NOTIFICATIONS');
        });
    }
  },
  [types.UNREGISTER_ALL]: ({ commit }) =>
    portal
      .post('?method=notifications.unregisterAllDevices')
      .then(() => true)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UNREGISTER_DEVICE');
      }),
  [types.DELETE_NOTIFICATIONS]: ({ commit }, payload) =>
    portal
      .post('?method=Notifications.deleteNotifications', {
        notifications: payload.notifications,
      })
      .then(() => {
        if (!payload.skipMutate) {
          commit(types.MUTATE_DELETE_NOTIFICATIONS, payload);
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_NOTIFICATIONS');
      }),
  [types.DELETE_MODULE_NOTIFICATIONS]: ({ commit, dispatch }, payload) =>
    portal
      .post('?method=notifications.deleteBadgeNotificationByModule', {
        notificationAreas: payload.notificationAreas,
      })
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'DELETE_NOTIFICATION_SUCCEED_TOASTR');
        dispatch(types.LOAD_NOTIFICATIONS, {
          activeChildrenIds: payload.activeChildrenIds,
          activeInstitutionCodes: payload.activeInstitutionCodes,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_NOTIFICATIONS');
      }),
  [types.UNSUBSCRIBE_NOTIFICATIONS]: ({ commit }, { token }) =>
    portal
      .post('?method=notifications.unsubscribeNotifications', {
        token,
      })
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'ERROR_TOASTR_TEXT');
        return Promise.reject(error);
      }),
  [types.VALIDATE_UNSUBSCRIBE_NOTIFICATIONS]: ({ commit }, { token }) =>
    portal
      .get('?method=notifications.isUnsubscribeTokenValid', {
        params: {
          token,
        },
      })
      .then(response => {
        commit(types.MUTATE_VALIDATE_UNSUBSCRIBE_NOTIFICATIONS, response.data.data);
      }),
};

export default {
  state,
  mutations,
  actions,
  getters,
};
