export enum docTypes {
  GROUP = 'Group',
  PROFILE = 'Profile',
  CHILD_RELATION = 'ChildRelation',
  THREAD_MESSAGE = 'ThreadMessage',
  COMMON_INBOX = 'CommonInbox',
  IMPORTANT_FILE = 'CommonFile',
  EXTERNAL_SECURE_FILE = 'ExternalSecureFile',
  INTERNAL_SECURE_FILE = 'InternalSecureFile',
  POST = 'Post',
  EVENT = 'Event',
  RESOURCE = 'Resource',
  FOLDER = 'Folder',
  FILE = 'File',
  INSTITUTION = 'Institution',
  MEDIA = 'Media',
}
