// Interface for all getters, mutations and actions

// Getters
export const GET_COMMUNICATION_CHANNELS = 'communicationChannels/GET_COMMUNICATION_CHANNELS';

// Mutations
export const MUTATE_GET_COMMUNICATION_CHANNELS = 'communicationChannels/MUTATE_GET_COMMUNICATION_CHANNELS';
export const MUTATE_REMOVE_COMMUNICATION_CHANNEL = 'communicationChannels/MUTATE_REMOVE_COMMUNICATION_CHANNEL';
export const MUTATE_ADD_COMMUNICATION_CHANNEL = 'communicationChannels/MUTATE_ADD_COMMUNICATION_CHANNEL';

// Actions
export const LOAD_COMMUNICATION_CHANNELS = 'communicationChannels/LOAD_COMMUNICATION_CHANNELS';
export const REMOVE_COMMUNICATION_CHANNEL = 'communicationChannels/REMOVE_COMMUNICATION_CHANNEL';
export const SAVE_COMMUNICATION_CHANNEL = 'communicationChannels/SAVE_COMMUNICATION_CHANNEL';
