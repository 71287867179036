import { types } from '../../../src/store/types/types';
import { portal, axios } from '../../assets/plugins/axios/axios.js';
import loadImage from 'blueimp-load-image';
import JSZipUtils from 'jszip-utils';
import { errorAttachmentStatus } from '../../enums/errorAttachmentStatus';
import { errorSubCodeTypes } from '../../enums/errorSubCodeTypes';

const state = {
  profilePictures: [],
  limitOfUploadingFileSize: 1024 * 1024 * 250,
};

const getters = {
  [types.GET_LIMIT_OF_UPLOADING_FILE_SIZE]: state => state.limitOfUploadingFileSize,
};

const mutations = {
  [types.MUTATE_CACHE_PROFILE_PICTURE]: (state, payload) => {
    state.profilePictures[payload.id] = payload.src;
  },
};

const actions = {
  [types.CREATE_ATTACHMENTS]: ({ commit }, payload) => {
    if (payload.files == null) {
      payload.files = [];
    } else {
      for (const file of payload.files) {
        file.uploadId = guid();
      }
    }
    if (payload.links == null) {
      payload.links = [];
    } else {
      for (const file of payload.links) {
        file.uploadId = guid();
      }
    }
    if (payload.media == null) {
      payload.media = [];
      return createAttachments(commit, payload);
    } else {
      let rotateCounter = 0;
      const rotationRequests = [];
      for (const media of payload.media) {
        rotateCounter++;
        media.uploadId = guid();
        if (media.rotationDegree && media.rotationDegree != 0) {
          const filenameArray = media.file.name.split('.');
          const ext = filenameArray[filenameArray.length - 1].toLowerCase();
          let newOrientation = 1;
          const rotateImage = loadImage.parseMetaData(media.file).then(function (data) {
            const currentOrientation = data.exif ? data.exif[0x0112] : 0;
            newOrientation = calculateOrientation(currentOrientation, media.rotationDegree);
            return loadImage(media.file, {
              orientation: newOrientation,
              canvas: true,
            }).then(data => {
              const blobBin = atob(data.image.toDataURL().split(',')[1]);
              const array = [];
              for (let i = 0; i < blobBin.length; i++) {
                array.push(blobBin.charCodeAt(i));
              }
              const blob = new Blob([new Uint8Array(array)], {
                type: 'image/' + ext,
              });
              blob.lastModifiedDate = media.file.lastModifiedDate;
              blob.name = media.file.name;
              media.file = blob;
              media.rotationDegree = null;
              return;
            });
          });
          rotationRequests.push(rotateImage);
        }
        if (rotateCounter === payload.media.length) {
          if (rotationRequests.length > 0) {
            return Promise.all(rotationRequests).then(() => createAttachments(commit, payload));
          } else {
            return createAttachments(commit, payload);
          }
        }
      }
    }
  },
  [types.UPDATE_ATTACHMENTS]: ({ commit }, payload) =>
    portal
      .post('?method=files.updateAttachments', payload)
      .then(response => response.data.data)
      .catch(error => {
        if (error.response.data.status.subCode == errorAttachmentStatus.CROSS_MUNICIPAL) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CANNOT_TAG_OTHER_MUNICIPAL');
        } else if (error.response.data.status.subCode === errorSubCodeTypes.invalidMediaTag) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CANNOT_TAG_OTHER_INSTITUTION');
        } else {
          commit(types.MUTATE_ERROR_TEXT, 'ATTACHMENT_UPDATE_WARNING_NOT_EXIST');
        }
        throw error;
      }),
  [types.UPLOAD_PROFILE_PICTURE]: ({ commit, rootState }, payload) =>
    portal
      .post('?method=profiles.updateProfilePicture', payload)
      .then(response => {
        commit(types.MUTATE_PROFILE_PICTURE, {
          institutionProfileId: payload.institutionProfileId,
          media: response.data.data,
          children: rootState.global.children,
        });
        return response.data.data;
      })
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_PROFILE_PICTURE');
        throw error;
      }),
  [types.CREATE_DOCUMENT_LINKS]: ({ commit }, payload) =>
    portal
      .post('?method=files.createDocumentLinks', payload)
      .then(response => response.data.data)
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_ATTACHMENTS');
        throw new Error(error);
      }),
};

function createAttachments(commit, payload) {
  return portal
    .post('?method=files.createAttachments', payload)
    .then(async response => {
      const s3Requests = [];
      const data = response.data.data;
      const multiplartUploadAttachments = [];
      const uploadAttchments = [];
      for (const media of data.media) {
        if (media.multipartFileUploadInformation) {
          multiplartUploadAttachments.push(media);
        } else {
          uploadAttchments.push(media);
        }
      }
      const mediaUpload = payload.media.filter(m => uploadAttchments.find(u => u.uploadId == m.uploadId) != null);
      const uploads = payload.files.concat(payload.links).concat(mediaUpload);

      for (const file of multiplartUploadAttachments) {
        const attachmentFile = payload.media.find(m => m.uploadId == file.uploadId);
        if (attachmentFile) {
          if (attachmentFile.file.isFromGoogleDrive) {
            const multiPartUploadFile = {
              id: file.id,
              cloudFileId: attachmentFile.file.id,
              parts: file.multipartFileUploadInformation.parts,
              accessToken: attachmentFile.file.accessToken,
              size: attachmentFile.file.size,
              sizeOfPart: state.limitOfUploadingFileSize,
              source: attachmentFile.file.source,
            };
            await transferLargeFileFromGoogleDriveToS3(multiPartUploadFile);
          }
          if (attachmentFile.file.isFromOneDrive) {
            const multiPartUploadFile = {
              id: file.id,
              cloudFileId: attachmentFile.file.id,
              parts: file.multipartFileUploadInformation.parts,
              accessToken: attachmentFile.file.accessToken,
              size: attachmentFile.file.size,
              sizeOfPart: state.limitOfUploadingFileSize,
              source: attachmentFile.file['@microsoft.graph.downloadUrl'],
              odata: attachmentFile.file['@odata.context'],
            };
            await transferLargeFileFromOneDriveToS3(multiPartUploadFile);
          }
        }
      }

      const fileUploadInformationArray = data.files.concat(uploadAttchments);
      uploads.forEach(upload => {
        const uploadObj = new FormData();
        // file name extension may be tolowered in backend.
        const uploadData = fileUploadInformationArray.find(att => att.uploadId === upload.uploadId);
        if (uploadData) {
          for (const i in uploadData.fileUploadInformation) {
            if (i != 'action') {
              uploadObj.append(i.split('_').join('-'), uploadData.fileUploadInformation[i]);
            }
          }
          // S3 requires that the file content is the last part of the form data
          uploadObj.append('file', upload.file);
          s3Requests.push(
            axios({
              url: uploadData.fileUploadInformation.action,
              data: uploadObj,
              crossdomain: true,
              method: 'post',
              withCredentials: false,
            })
          );
        } else {
          // eslint-disable-next-line no-console
          console.error('Could not get s3 upload information for: ', upload);
        }
        for (let i = 0; i < fileUploadInformationArray.length; i++) {
          if (fileUploadInformationArray[i].uploadId === upload.uploadId) {
            fileUploadInformationArray.splice(i, 1);
            break;
          }
        }
      });
      return Promise.all(s3Requests).then(() => response.data.data);
    })
    .catch(error => {
      // NB! Popup should not be shown if the error is about too many media files...
      if (error.response.data.status.subCode !== errorSubCodeTypes.mediaUploadExceeded) {
        if (error.response.data.status.subCode == errorAttachmentStatus.CROSS_MUNICIPAL) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CANNOT_TAG_OTHER_MUNICIPAL');
        } else if (error.response.data.status.subCode === errorSubCodeTypes.invalidMediaTag) {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CANNOT_TAG_OTHER_INSTITUTION');
        } else {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_ATTACHMENTS');
        }
      }
      return Promise.reject(error);
    });
}
async function transferLargeFileFromGoogleDriveToS3(multiPartUploadFile) {
  const uploadedParts = [];
  const promises = [];
  const url = 'https://www.googleapis.com/drive/v3/files/' + multiPartUploadFile.cloudFileId + '?alt=media';
  for (const part of multiPartUploadFile.parts) {
    const uploadPart = await axios(url, {
      responseType: 'blob',
      method: 'get',
      headers: {
        Authorization: 'Bearer ' + multiPartUploadFile.accessToken,
        'Cache-Control': 'no-cache',
        Range:
          'bytes=' + calculateRangeOfBytes(part.partIndex, multiPartUploadFile.size, multiPartUploadFile.sizeOfPart),
      },
    }).then(downloadResponse => {
      axios({
        url: part.preSignedUrl,
        data: downloadResponse.data,
        crossdomain: true,
        method: 'put',
        withCredentials: false,
      }).then(uploadResponse => {
        uploadedParts.push({
          eTag: uploadResponse.headers.etag,
          partNumber: part.partIndex,
        });
        if (multiPartUploadFile.parts.length === uploadedParts.length) {
          const payload = {
            requests: [
              {
                fileId: multiPartUploadFile.id,
                parts: uploadedParts.sort((a, b) => (a.partNumber > b.partNumber ? 1 : -1)),
              },
            ],
          };
          return portal.post('?method=files.completeMultipartUploading', payload).then(rsp => rsp);
        }
      });
    });
    promises.push(uploadPart);
  }
  return Promise.all(promises);
}

async function transferLargeFileFromOneDriveToS3(multiPartUploadFile) {
  const url = multiPartUploadFile.source;
  await JSZipUtils.getBinaryContent(url, async (err, data) => {
    const limitOfUploadingFileSize = state.limitOfUploadingFileSize;
    const uploadedParts = [];
    let loaded = 0;
    for (const part of multiPartUploadFile.parts) {
      if (loaded > 0) {
        loaded = loaded + limitOfUploadingFileSize;
      }
      await axios({
        url: part.preSignedUrl,
        data: data.slice(loaded, limitOfUploadingFileSize),
        crossdomain: true,
        method: 'put',
        withCredentials: false,
      }).then(uploadResponse => {
        uploadedParts.push({
          eTag: uploadResponse.headers.etag,
          partNumber: part.partIndex,
        });
        if (multiPartUploadFile.parts.length == uploadedParts.length) {
          const payload = {
            requests: [
              {
                fileId: multiPartUploadFile.id,
                parts: uploadedParts.sort((a, b) => (a.partNumber > b.partNumber ? 1 : -1)),
              },
            ],
          };
          return portal.post('?method=files.completeMultipartUploading', payload).then(rsp => rsp);
        }
      });
    }
  });
}

function calculateRangeOfBytes(partIndex, fileSize, sizeOfPart) {
  let fromByte = (partIndex - 1) * sizeOfPart;
  if (partIndex > 1) {
    fromByte = fromByte + 1;
  }
  let toByte = partIndex * sizeOfPart;
  if (toByte > fileSize) {
    toByte = fileSize;
  }
  return fromByte + '-' + toByte;
}

function calculateOrientation(currentOrientation, rotationDegree) {
  let newOrientation = 1;
  if (currentOrientation > 0 && currentOrientation < 9) {
    const normalOrientation = [1, 6, 3, 8];
    const mirroredOrientation = [2, 5, 4, 7];

    const isNormalOrientation = normalOrientation.includes(currentOrientation);
    const moveStep = rotationDegree / 90;

    if (isNormalOrientation) {
      let currentStep = normalOrientation.indexOf(currentOrientation);
      const step = (currentStep += moveStep) % 4;
      newOrientation = normalOrientation[step];
    } else {
      let currentStep = mirroredOrientation.indexOf(currentOrientation);
      const step = (currentStep += moveStep) % 3;
      newOrientation = mirroredOrientation[step];
    }
  } else {
    if (rotationDegree === 90) {
      newOrientation = 6;
    } else if (rotationDegree === 180) {
      newOrientation = 3;
    } else if (rotationDegree === 270) {
      newOrientation = 8;
    }
  }
  return newOrientation;
}
function guid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }

  return s4() + s4() + '-' + s4() + '-' + s4() + '-' + s4() + '-' + s4() + s4() + s4();
}

export default {
  state,
  mutations,
  actions,
  getters,
};
