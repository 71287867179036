export const GLOBAL_LOADING_AULA = 'Indlæser Aula';
export const IMPERSONATION_WARNING = 'ADVARSEL: Du agerer nu på vegne af';
export const END_IMPERSONATION_LABEL = 'Afslut impersonering';
export const MY_PROFILE_LABEL = 'MIN PROFIL';
export const MY_PROFILE_LABEL_CHILD = 'Min profil';
export const PROFILE_LABEL = 'PROFIL';
export const BROWSER_CHECK_TEXT_1 =
  'Din browser er ikke understøttet af AULA. Det kan betyde, at der er vigtige funktioner, som du ikke har adgang til.';
export const BROWSER_CHECK_TEXT_2 = 'Vi anbefaler, at du bruger en af følgende browsere:';
export const BROWSER_CHROME_DOWNLOAD = 'Google Chrome';
export const BROWSER_EDGE_DOWNLOAD = 'Microsoft Edge';
export const BROWSER_SAFARI_DOWNLOAD = 'Apple Safari';
export const BROWSER_FIREFOX_DOWNLOAD = 'Mozilla Firefox';
export const PREVIEW_MODE_PAGE_TITLE = 'Aula - Preview-tilstand';
export const ERROR_TOASTR_TEXT = 'Noget gik galt: ';
export const MY_GROUPS = 'MINE GRUPPER';
export const OTHER_GROUPS = 'ANDRE GRUPPER';
export const NO_DATA = 'Ingen data';
export const SHARED_WITH = 'Delt med';
export const SUFFIX_OTHER_GROUP = 'anden gruppe';
export const SUFFIX_OTHER_GROUPS = 'andre grupper';
export const EDITED = 'redigeret';
export const CREATED = 'oprettet';
export const REPLY = 'svar';
export const REPORTED = 'anmeldt';

export const DATE_PLACEHOLDER = 'dd/mm/åååå';
export const TIME_PLACEHOLDER = 'tt/mm';
export const NUMBER_PLACEHOLDER = 'Skriv antal';
export const SCHOOLS = 'Skoler';
export const DAYCARE_INSTITUTIONS = 'Daginstitutioner';
export const DAYCARE_INSTITUTION = 'Daginstitution';
export const OK = 'OK';
export const YES = 'JA';
export const YES_DELETE = 'JA, SLET';
export const YES_SMALL = 'Ja';
export const OPEN = 'Åbn';
export const AT = 'kl.';
export const NO = 'NEJ';
export const NO_SMALL = 'Nej';
export const YES_ALL = 'Ja til alle';
export const NO_ALL = 'Nej til alle';
export const ALL = 'Alle';
export const OR = 'eller';
export const AND = 'og';
export const ONLY = 'Kun';
export const SUBMIT = 'GEM';
export const BUTTON_CREATE = 'OPRET';
export const BUTTON_EDIT = 'REDIGER';
export const BUTTON_EDIT_SMALL = 'Rediger';
export const BUTTON_ADD = 'TILFØJ';
export const BUTTON_SAVE = 'GEM';
export const BUTTON_SEND = 'SEND';
export const BUTTON_SAVE_SMALL = 'Gem';
export const BUTTON_CLOSE = 'LUK';
export const BUTTON_DELETE = 'SLET';
export const BUTTON_YES = 'JA';
export const BUTTON_BACK = 'TILBAGE';
export const CANCEL = 'Annuller';
export const UNDO = 'Fortryd';
export const DELETE = 'Slet';
export const EDIT = 'Rediger';
export const DOWNLOAD = 'Download';
export const WARNING = 'ADVARSEL';
export const WARNING_MODAL = 'ADVARSEL';
export const RESTORE = 'Gendan';

export const ERROR_MODAL = 'FEJL';
export const API_DEBUG_ERROR_MODAL = 'SERVER ERROR';
export const EMPLOYEE = 'Medarbejder';
export const EMPLOYEES = 'Medarbejdere';
export const SWITCH_EMPLOYEE = 'medarbejder';
export const SWITCH_GUARDIAN = 'forælder';
export const SWITCH_CHILD = 'barn';
export const CHILD = 'Barn';
export const CHILDREN = 'BØRN';
export const PARENT = 'FORÆLDER';
export const PARENTS = 'FORÆLDRE';
export const GUARDIAN = 'Værge';
export const OTP = 'Officielt tilknyttet person';
export const SELECT = 'Vælg';
export const DAYCARE = 'Dagtilbud';
export const SCHOOL = 'Skole';
export const PUPILS = 'Børn';
export const TOTAL = 'total';
export const ADULT = 'voksen';
export const ADULTS = 'voksne';
export const SMALL_CHILDREN = 'børn';
export const SMALL_CHILD = 'barn';
export const GROUP = 'Grupper';
export const GROUP_HOMES = 'Andre';
export const GROUP_HOME = 'Andet';
export const SELECT_OTP_SENDER = 'Vælg hvilket barn, beskeden vedrører';
export const LIMIT_NUMBER_OF_ACTIVE_INSTITUTION_PROFILES = 'Du kan højst filtrere på 6 profiler på samme tid';
export const LAST_ACTIVITY = 'Seneste aktivitet';
export const HAS_CUSTODY = 'Har forældremyndighed';
export const RELATION = 'Relation';
export const ARIA_LABEL_MORE_MENU = 'Flere menuer';
export const ARIA_LABEL_MY_GROUPS = 'Mine grupper';
export const ARIA_LABEL_GLOBAL_SEARCH = 'Søg efter indhold i Aula';
export const ARIA_LABEL_MY_PROFILE = 'Mine profil';
export const ARIA_LABEL_IMPORTANT_DATA = 'Vigtige datoer';
export const ARIA_LABEL_OTHER_FUNCTIONS = 'Andre funktioner';
export const ARIA_LABEL_SEE_CONTENT_FROM = 'Se indhold fra ';
export const ARIA_LABEL_HIDE_CONTENT_FROM = 'Skjul indhold fra ';
export const ARIA_LABEL_CLOSE = 'Luk';
export const ARIA_LABEL_CREATE_BUTTON = 'Opret';
export const ARIA_LABEL_CLOSE_NOTIFICATION = 'Luk notifikation';
export const ARIA_LABEL_DELETE_NOTIFICATION = 'Slet notifikation';
export const ARIA_LABEL_CLOSE_HELP = 'Luk hjælp';
export const ARIA_LABEL_CLOSE_CALENDAR = 'Luk kalender';
export const ARIA_LABEL_CLOSE_CREATE_MENU = 'Luk menu';
export const ARIA_LABEL_NOTIFICATION = 'Ny notifikation';
export const ARIA_LABEL_OPEN_SUBSCRIPTION = 'Åbn beskedtråd med emne ';
export const ARIA_LABEL_OPEN = 'Åben';
export const ARIA_LABEL_SEARCH = 'Søg';
export const ARIA_LABEL_ENTER_KEY_SEARCH = 'Indtast nøglesøgning';
export const ARIA_LABEL_CHOOSE_MESSAGE = 'Vælg denne meddelelse';
export const ARIA_LABEL_SHOW_RECIPIENT_LIST_POPOVER = 'Vis liste over modtagere';
export const ARIA_LABEL_CHOOSE_DOCUMENT = 'Vælg dette dokument';
export const ARIA_LABEL_GIVE_DOCUMENT_SHARE = 'Giv {username} rettighed til at redigere dette dokument';
export const ARIA_LABEL_DEACTIVATED = 'Skjul';
export const ARIA_LABEL_READABLE = 'Vis';
export const ARIA_LABEL_EDITABLE = 'Rediger';
export const ARIA_LABEL_ON = 'på';
export const ARIA_LABEL_DELETE = 'Slet';
export const ARIA_LABEL_EDIT = 'Rediger';
export const ARIA_LABEL_BACK = 'Tilbage';
export const ARIA_LABEL_APP_STORE = 'App Store';
export const ARIA_LABEL_GOOGLE_PLAY = 'App Store';
export const ARIA_LABEL_NEW_CONTENT = 'Nyt indhold';
export const ARIA_LABEL_SHOW_POSTS_FROM = 'Vis opslag fra ';
export const ARIA_LABEL_OPEN_EDIT_DOCUMENT = 'Åben/rediger dokument';
export const ARIA_LABEL_COLLAPSE = 'Åben eller luk';
export const ARIA_LABEL_COLLAPSE_HELP = 'Hjælp';
export const ARIA_LABEL_COLLAPSE_HELP_TO = 'Hjælp til ';
export const ARIA_LABEL_OPEN_FILTERING_DROPDOWN = 'Åben filtrering dropdown';
export const ARIA_LABEL_SICK = 'Syg';
export const ARIA_LABEL_SLEEP = 'Sover';
export const ARIA_LABEL_ABSENCE = 'Fravær';
export const ARIA_LABEL_VACATION = 'Ferie/fri';
export const ARIA_LABEL_SPARE_TIME_ACTIVITY = 'Fritidsaktivitet';
export const ARIA_LABEL_PRESENT = 'Til stede';
export const ARIA_LABEL_INFORMATION = 'Information';
export const ARIA_LABEL_SEE_DETAILS = 'Se detaljer';
export const ARIA_LABEL_DOWNLOAD_DOCUMENT = 'Download dokument';
export const ARIA_LABEL_REMOVE_PARTICIPANT = 'Fjern deltager';
export const ARIA_LABEL_HOME_PHONE = 'Hjemme telefonnummer';
export const ARIA_LABEL_MOBILE_PHONE = 'Mobil telefonnummer';
export const ARIA_LABEL_WORK_PHONE = 'Arbejds telefonnummer';
export const ARIA_LABEL_EMAIL = 'E-mail';
export const ARIA_LABEL_POSTS = 'Opslag';
export const ARIA_LABEL_MESSAGE_FROM = 'Besked fra ';
export const ARIA_LABEL_WRITE_MESSAGE_TO_THREAD = 'Svar alle i beskeden';
export const ARIA_LABEL_CALENDAR_NEXT = 'Skift til næste periode, som er ';
export const ARIA_LABEL_CALENDAR_PREV = 'Skift til forrige periode, som er ';
export const ARIA_LABEL_CALENDAR_TO = ' til ';
export const ARIA_LABEL_CALENDAR_SEARCH_FOR_INVITEES = 'Søg efter personer eller grupper, du vil invitere';
export const ARIA_LABEL_CALENDAR_SEARCH = 'Søg';
export const ARIA_LABEL_CALENDAR_START_DATE = 'Vælg start dato';
export const ARIA_LABEL_CALENDAR_END_DATE = 'Vælg slut dato';
export const ARIA_LABEL_CALENDAR_START_TIME = 'Vælg start tid';
export const ARIA_LABEL_CALENDAR_END_TIME = 'Vælg slut tid';
export const ARIA_LABEL_PRESENCE_SEARCH_FOR_CHILD = 'Søg efter det barn du vil eksportere data for';
export const ARIA_LABEL_GROUP_PAGE = 'Overblik over gruppen';
export const ARIA_LABEL_ATTACH_FILES = 'Vedhæft filer';
export const ARIA_LABEL_WRITE_MESASGE_CONTENT = 'Skriv besked indhold her';
export const ARIA_LABEL_WRITE_CONTENT = 'Skriv indhold her';
export const ARIA_LABEL_WRITE_EVENT_CONTENT = 'Skriv begivenhed indhold her';
export const ARIA_LABEL_WRITE_DOCUMENT_CONTENT = ' Skriv dokument indhold her';
export const ARIA_LABEL_MEDIA_ATTACHMENT = 'Vedhæftet fil til medier';
export const ARIA_LABEL_CALENDAR_SEARCH_FOR_ORGANIZERS = 'Søg efter medarbejdere, der må administrere begivenheden';
export const ARIA_LABEL_CALENDAR_MEETING_SEARCH_FOR_ORGANIZERS = 'Søg efter medarbejdere, der må administrere samtalen';

export const USE_SAVED_CHOICE = 'Benyt mit valg af loginmetode';
export const REMEMBER_PROFILE_ROLE = 'Husk mit valg af loginmetode';
export const LOGIN_GUIDE = 'Vil du vide';
export const LOGIN_GUIDE_2 = 'mere om Aula?';
export const REMEMBER_MUNICIPALITY = 'Husk Mit Valg af IdP';
export const MUNICIPALITY_SELECT_PLACEHOLDER = 'Vælg Kommune';
export const SCHOOL_SELECT_PLACEHOLDER = 'Vælg Institution';
export const INSTITUTIONAL_GROUP = 'Aula gruppe';
export const MUNICIPAL_GROUP = 'Tværgående gruppe';
export const CROSS_INSTITUTIONAL_GROUP = 'Tværgående gruppe';
export const MUNICIPALITY_IDP = 'KOMMUNAL IDP';
export const CONTEXT_HANDLER = 'FÆLLESKOMMUNAL ADGANGSSTYRING';
export const LOGIN_OTHER_OPTIONS = 'ANDRE LOGINMULIGHEDER';
export const LOGIN_OPTIONS_FOR_EMPLOYEE_ONLY = 'Kun for medarbejdere';
export const INSTITUTION_SELECT_CHECKIN_DASHBOARD = 'Opsætning af komme-gå';
export const LOGIN_PAGE_CHOOSE_LOGIN_METHOD = 'Vælg, hvordan du vil logge ind';
export const LOGIN_PAGE_CHOOSE_LOGIN_METHOD_EMPLOYEE =
  'Her kan du som medarbejder opsætte check-in tavlen for komme/gå på denne enhed. Vælg hvordan du vil logge ind.';
export const UNI_LOGIN = 'Unilogin';
export const LOKALT_LOGIN = 'Lokalt login';
export const MIT_LOGO = 'UNILOGIN';
export const ARIA_LABEL_AULA_LOGIN = 'Aula login';
export const COULD_NOT_LOGIN = 'Du kunne ikke logges ind';
export const NO_ACCESS = 'Dit login giver ikke adgang til Aula.';
export const NO_ACCESS_PAGE_TITLE = 'UNI-Login fejl';
export const DEACTIVATED_USER_TITLE = 'Du kan ikke logge ind, da din profil ikke længere findes.';
export const UNAUTHORIZED_PAGE_TITLE = 'Utilstrækkeligt sikkerhedsniveau';
export const UNAUTHORIZED_TITLE = 'Utilstrækkeligt sikkerhedsniveau';
export const UNAUTHORIZED_LINK =
  'Den loginmetode, du har valgt, har utilstrækkelig sikkerhedsniveau. Du skal derfor vælge en anden loginmetode for at logge ind i Aula.';
export const IDP_ASSURANCE_LEVEL_ERROR_PAGE_TITLE = 'Utilstrækkeligt sikkerhedsniveau';
export const IDP_ASSURANCE_LEVEL_ERROR_TITLE = 'Utilstrækkeligt sikkerhedsniveau';
export const IDP_ASSURANCE_LEVEL_ERROR_TEXT =
  'Institutionens Identity Provider er fejlkonfigureret. Kontakt venligst adminstratoren på din institution.';
export const IDP_CONFIGURATION_ERROR_PAGE_TITLE = 'IdP konfigurationsfejl';
export const IDP_CONFIGURATION_ERROR_TITLE = 'Identity Provider konfigurationsfejl';
export const IDP_CONFIGURATION_ERROR_TEXT =
  'Institutionens Identity Provider er fejlkonfigureret. Kontakt venligst adminstratoren på din institution.';
export const ACCESS_NOT_GRANTED_YET_PAGE_TITLE = 'Adgang ikke tildelt endnu';
export const ACCESS_NOT_GRANTED_YET_TITLE = 'Adgang ikke tildelt endnu';
export const ACCESS_NOT_GRANTED_YET_LINK =
  'Du er endnu ikke oprettet i Aula. Kontakt venligst administratoren på din institution.';
export const MISSING_USER_SYSTEM_ROLE_PAGE_TITLE =
  'Det lykkedes ikke at logge ind på Aula med Fælleskommunal adgangstyring.';
export const MISSING_USER_SYSTEM_ROLE_TITLE =
  'Det lykkedes ikke at logge ind på Aula med Fælleskommunal adgangstyring.';
export const MISSING_USER_SYSTEM_ROLE_LINK =
  'Der er enten sket en fejl i loginprocessen eller du har ikke fået tildelt brugersystemrrollen "Aula adgang" af din administrator. Kontakt din administrator for at sikre du har fået tildelt den nødvendige brugersystemrolle. Gå tilbage til loginsiden ved at klikke her.';

export const URL_COPIED_TO_CLIPBOARD = 'Link blev kopieret til clipboard';
export const ALIAS = '(alias)';
export const ACCESSIBILITY_STATEMENT = 'Tilgængelighedserklæring';
export const PRIVACY_POLICY = 'Privatlivspolitik';

export const DATETIME_MONDAY = 'Mandag';
export const DATETIME_TUESDAY = 'Tirsdag';
export const DATETIME_WEDNESDAY = 'Onsdag';
export const DATETIME_THURSDAY = 'Torsdag';
export const DATETIME_FRIDAY = 'Fredag';
export const DATETIME_SATURDAY = 'Lørdag';
export const DATETIME_SUNDAY = 'Søndag';
export const DATETIME_DAY = 'dag';
export const DATETIME_WEEK = 'uge(r)';
export const DATETIME_MONTH = 'måned(er)';
export const DATETIME_YEAR = 'år';
export const MESSAGE_SERVER_TIMEOUT = 'Indholdet blev ikke sendt';
export const PRIMARY_INSTITUTION = 'Primær institution';
export const PRIMARY_INSTITUTION_HELP_TEXT_1 =
  'Din primære institution står først de steder i brugergrænsefladen, hvor du har mulighed for at vælge institution. Du kan kun have én primær institution. Når du opretter opslag, beskeder, begivenheder etc. skal du angive hvilken institution det udgår fra.';
export const PRIMARY_INSTITUTION_HELP_TEXT_2 =
  'Din primære institution er valgt som standard, men dette er muligt at ændre.';

export const GROUP_ROLE_UPDATE_SUCCESS =
  'Rollerne er blevet opdateret, men der kan gå op til 1 minut før det er synligt på gruppen.';
export const USER_ROLE_UPDATE_SUCCESS =
  'Rollerne er blevet opdateret, men der kan gå op til 1 minut før det er synligt for brugeren.';

// - SESSION TIMEOUT WARNING -
export const MESSAGE_SESSION_TIMEOUT_WARNING_1 = 'Vil du forblive';
export const MESSAGE_SESSION_TIMEOUT_WARNING_1_CONTINUE = 'logget ind?';
export const MESSAGE_SESSION_TIMEOUT_WARNING_2 = 'Du bliver logget ud om: ';
export const MESSAGE_SESSION_TIMEOUT_WARNING_3 = 'min.';
export const ARIA_LABEL_CHECK_ALL = 'Tjek alle';
export const ARIA_LABEL_CLEAR_SEARCH = 'Ryd søgning';

// Aula Select

export const AULA_SELECT_EMPTY_LIST = 'Ingen elementer';

// Unsubscribe
export const AULA_THIS_LINK_IS_INVALID = 'Dette link er ugyldigt';
export const AULA_NOTIFICATION_CANCELLED = 'Du er nu afmeldt notifikationer på e-mail fra Aula.';
export const AULA_NOTIFICATION_CONFIRM = 'Er du sikker på at du vil afmelde notifikationer på e-mail fra Aula?';
export const AULA_NOTIFICATION_UNSUBSCRIBE = 'Afmeld';
export const AULA_GO_TO_AULA = 'Gå til Aula';

export const GO_TO = 'Gå til {goto}';

// Menu titles
export const MENU_OVERVIEW = 'Overblik';
export const MENU_CALENDAR = 'Kalender';
export const MENU_MESSAGES = 'Beskeder';
export const MENU_DOCUMENTS = 'Filer';
export const MENU_GALLERY = 'Galleri';
export const MENU_PRESENCE = 'Komme/gå';
export const MENU_ADMINISTRATION = 'Admin';
export const MENU_CONTACTS = 'Kontakter';
export const MENU_PERSONAL_REFERENCE_DATA = 'Stamkort';
export const MENU_OTP_ADMINISTRATION = 'Bosteds adm.';

// Warnings
export const UNSAVED_DATA_WARNING_1 = 'Ændringerne vil ikke blive gemt.';
export const UNSAVED_DATA_WARNING_2 = 'Er du sikker på, du vil forlade siden?';
