// Interface for all getters, mutations and actions
// Getters
export const GET_DOCUMENT_TEMPLATES_DATA = 'document_templates/GET_DOCUMENT_TEMPLATES_DATA';
export const GET_SELECTED_DOCUMENT_TEMPLATE = 'document_templates/GET_SELECTED_DOCUMENT_TEMPLATE';

// Mutations
export const MUTATE_DOCUMENT_TEMPLATE_LIST_DATA = 'document_templates/MUTATE_DOCUMENT_TEMPLATE_LIST_DATA';
export const MUTATE_SELECTED_DOCUMENT_TEMPLATE = 'document_templates/MUTATE_SELECTED_DOCUMENT_TEMPLATE';

// Actions
export const CREATE_DOCUMENT_TEMPLATE = 'document_templates/CREATE_DOCUMENT_TEMPLATE';
export const DOCUMENT_TEMPLATE_SET_PUBLISHED_STATE = 'document_templates/DOCUMENT_TEMPLATE_SET_PUBLISHED_STATE';
export const DOCUMENT_TEMPLATE_SET_ENABLED_STATE = 'document_templates/DOCUMENT_TEMPLATE_SET_ENABLED_STATE';
export const LOAD_DOCUMENT_TEMPLATE_DETAILS = 'document_templates/LOAD_DOCUMENT_TEMPLATE_DETAILS';
export const UPDATE_DOCUMENT_TEMPLATE = 'document_templates/UPDATE_DOCUMENT_TEMPLATE';
export const DELETE_DOCUMENT_TEMPLATE = 'document_templates/DELETE_DOCUMENT_TEMPLATE';
