import Vue from 'vue';
import Vuex from 'vuex';
import messages from '../../shared/store/modules/messages';
import gallery from './modules/gallery';
import onboarding from './modules/onboarding';
import presence from './modules/presence';
import presence_employee from './modules/presence_employee';
import documents from '../../shared/store/modules/documents';
import posts from './modules/posts';
import auth from '../../shared/store/modules/auth';
import fileImport from '../../shared/store/modules/fileImport';
import global from './modules/global';
import globalShared from '../../shared/store/modules/global';
import calendar from '../../shared/store/modules/calendar';
import users from '../../shared/store/modules/users';
import groups from './modules/groups';
import groupsShared from '../../shared/store/modules/groups';
import search from './modules/search';
import commonFiles from '../../src_adm/store/modules/commonFiles';
import administrativeAuthorities from '../../src_adm/store/modules/administrativeAuthorities';
import notifications from '../../shared/store/modules/notifications';
import accesscontrol from '../../shared/store/modules/accesscontrol';
import upload from '../../shared/store/modules/files';
import reportedContent from '../../shared/store/modules/reportedContent';
import consents from '../../shared/store/modules/consents';
import contacts from './modules/contacts';
import comments from '../../shared/store/modules/comments';
import personalReferenceData from './modules/personalReferenceData';
import videoCache from '../../shared/store/modules/videoCache';

Vue.use(Vuex);

export const store = new Vuex.Store({
  modules: {
    messages,
    gallery,
    onboarding,
    presence,
    presence_employee,
    documents,
    posts,
    auth,
    global,
    globalShared,
    calendar,
    users,
    groups,
    search,
    notifications,
    accesscontrol,
    upload,
    commonFiles,
    administrativeAuthorities,
    groupsShared,
    reportedContent,
    consents,
    fileImport,
    contacts,
    comments,
    personalReferenceData,
    videoCache,
  },
});
