class BrowserUtil {
  isSafari() {
    const isChromeUserAgent = navigator.userAgent.indexOf('Chrome') > -1;
    const isSafariUserAgent = navigator.userAgent.indexOf('Safari') > -1;
    if (isSafariUserAgent) {
      return !isChromeUserAgent;
    }
    return false;
  }
}

export const browserUtil = new BrowserUtil();
