// Interface for all getters, mutations and actions
// Getters
export const GET_COMMENTS = 'comments/GET_COMMENTS';
export const GET_COMMENTS_COUNT = 'comments/GET_COMMENTS_COUNT';
export const GET_WRITING_COMMENT = 'comments/GET_WRITING_COMMENT';
export const GET_COMMENTABLE_INSTITUTION_PROFILES = 'comments/GET_COMMENTABLE_INSTITUTION_PROFILES';

// Mutations
export const MUTATE_LOAD_COMMENTS = 'comments/MUTATE_LOAD_COMMENTS';
export const MUTATE_UPDATE_COMMENT = 'comments/MUTATE_UPDATE_COMMENT';
export const MUTATE_DELETE_COMMENT = 'comments/MUTATE_DELETE_COMMENT';
export const MUTATE_CLEAR_COMMENTS = 'comments/MUTATE_CLEAR_COMMENTS';
export const MUTATE_WRITING_COMMENT = 'comments/MUTATE_WRITING_COMMENT';

// Actions
export const LOAD_COMMENTS = 'comments/LOAD_COMMENTS';
export const CREATE_COMMENT = 'comments/CREATE_COMMENT';
export const UPDATE_COMMENT = 'comments/UPDATE_COMMENT';
export const DELETE_COMMENT = 'comments/DELETE_COMMENT';
export const REPORT_COMMENT = 'comments/REPORT_COMMENT';
