<template>
  <b-btn :class="{ 'btn-clear': fill === 'clear' }" v-bind="{ ...$props, ...$attrs }" v-on="$listeners">
    <slot />
  </b-btn>
</template>
<script lang="js">
export default {
  name: 'AulaButton',
  props: {
    fill: {
      default: 'solid',
      type: String,
      validator: (value) => ['solid', 'clear'].includes(value),
    },
  }
}
</script>

<style lang="scss" scoped>
button.btn-clear {
  background-color: transparent;
  &:not(:disabled):hover,
  &:not(:disabled):active {
    background-color: transparent;
  }
}
</style>
