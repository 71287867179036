export const providerKeyTypes = Object.freeze({
  onClickAlphabetSelector: Symbol(),
  alphabetSelector: Symbol(),
  loadChildDetails: Symbol(),
  alphabetChoices: Symbol(),
  onUpdateEventTypeFilters: Symbol(),
  eventTypeFilters: Symbol(),
  existingSharedCalendars: Symbol(),
  onUpdateExistingSharedCalendars: Symbol(),
  institutionCalendars: Symbol(),
  onUpdateInstitutionCalendars: Symbol(),
  onUpdateDelegatedContext: Symbol(),
  delegatedContext: Symbol(),
  onRemoveMediaFromAlbum: Symbol(),
  canHandleOthersEvents: Symbol(),
  onDeleteEventClicked: Symbol(),
  isCurrentEventCreator: Symbol(),
  isCurrentEventCoOrganizer: Symbol(),
  isCurrentEventInvitee: Symbol(),
  isCurrentEventInviteeAsOtp: Symbol(),
  isHandlingOthersEvent: Symbol(),
  onClickPrint: Symbol(),
  canViewAllContactInformation: Symbol(),
  onLoadGroups: Symbol(),
  groups: Symbol(),
  contactGroups: Symbol(),
  setContactGroupSearchQuery: Symbol(),
  setGroupId: Symbol(),
  groupId: Symbol(),
  viewModes: Symbol(),
  viewMode: Symbol(),
  setViewMode: Symbol(),
  profileType: Symbol(),
  setProfileType: Symbol(),
  profileTypes: Symbol(),
  profile: Symbol(),
  contacts: Symbol(),
  orderDirection: Symbol(),
  sortBy: Symbol(),
  onSortBy: Symbol(),
  loading: Symbol(),
  groupSearch: Symbol(),
  showTooManyGroupWarning: Symbol(),
  canViewParentCustody: Symbol(),
  postObserver: Symbol(),
  canUseInstitutionLogin: Symbol(),
  loadEventLocations: Symbol(),
  occurrenceDateTime: Symbol(),
  defaultCalendars: Symbol(),
});
