// Interface for all getters, mutations and actions

// Getters
export const GET_KEEP_ALIVE = 'global/GET_KEEP_ALIVE';
export const GET_OPEN_MODALS = 'global/GET_OPEN_MODALS';
export const GET_CURRENT_PROFILE = 'global/GET_CURRENT_PROFILE';
export const GET_PROFILES_BY_LOGIN = 'global/GET_PROFILES_BY_LOGIN';
export const GET_USER_GROUPS = 'global/GET_USER_GROUPS';
export const GET_INSTITUTIONS = 'global/GET_INSTITUTIONS';
export const GET_DATE = 'global/GET_DATE';
export const GET_TIME = 'global/GET_TIME';
export const GET_IS_MOBILE = 'global/GET_IS_MOBILE';
export const GET_IS_MOBILE_DEVICE = 'global/GET_IS_MOBILE_DEVICE';
export const GET_IS_TABLET = 'global/GET_IS_TABLET';
export const GET_CLIENT_HEIGHT = 'global/GET_CLIENT_HEIGHT';
export const GET_CONTENT_WRAPPER_HEIGHT = 'global/GET_CONTENT_WRAPPER_HEIGHT';
export const GET_CLIENT_WIDTH = 'global/GET_CLIENT_WIDTH';
export const GET_SHOW_SPINNER = 'global/GET_SHOW_SPINNER';
export const IS_PROFILE_LOADED = 'global/IS_PROFILE_LOADED';
export const GET_GLOBAL_STEPPED_UP = 'global/GET_GLOBAL_STEPPED_UP';
export const GET_ERROR_TEXT = 'global/GET_ERROR_TEXT';
export const GET_SUCCESS_TEXT = 'global/GET_SUCCESS_TEXT';
export const GET_SELECTED_INSTITUTION = 'global/GET_SELECTED_INSTITUTION';
export const GET_ACTIVE_PROFILE = 'global/GET_ACTIVE_PROFILE';
export const GET_BLOCKED_COMMUNICATION_PROFILES = 'global/GET_BLOCKED_COMMUNICATION_PROFILES';
export const GET_TOAST_TIMER = 'global/GET_TOAST_TIMER';
export const GET_IS_IMPERSONATION_MODE = 'global/GET_IS_IMPERSONATION_MODE';
export const GET_HAS_SUPPORT_ROLE = 'global/GET_HAS_SUPPORT_ROLE';
export const GET_DEFAULT_DATE_FORMAT = 'global/GET_DEFAULT_DATE_FORMAT';
export const GET_DEFAULT_DATE_VALUE_FORMAT = 'global/GET_DEFAULT_DATE_VALUE_FORMAT';
export const GET_DEFAULT_TIME_FORMAT = 'global/GET_DEFAULT_TIME_FORMAT';
export const GET_DEFAULT_TIME_VALUE_FORMAT = 'global/GET_DEFAULT_TIME_VALUE_FORMAT';
export const GET_DEFAULT_DATE_OPTIONS = 'global/GET_DEFAULT_DATE_OPTIONS';
export const GET_PAGE_CONFIGURATION = 'global/GET_PAGE_CONFIGURATION';
export const GET_EDITOR_PLUGIN_DETAILS = 'global/GET_EDITOR_PLUGIN_DETAILS';
export const GET_PHP_SESSION_ID = 'global/GET_PHP_SESSION_ID';
export const GET_PROFILE_CHANGE = 'global/GET_PROFILE_CHANGE';
export const GET_DATA_POLICY = 'settings/GET_DATA_POLICY';
export const GET_OPEN_DATA_POLICY = 'settings/GET_OPEN_DATA_POLICY';
export const GET_IS_DOCUMENT_STATE_VISIBLE = 'global/GET_IS_DOCUMENT_STATE_VISIBLE';
export const GET_IS_MUNICIPAL_ADMINISTRATOR = 'global/GET_IS_MUNICIPAL_ADMINISTRATOR';
export const GET_IS_LOADING_PROFILE_CONTEXT = 'global/GET_IS_LOADING_PROFILE_CONTEXT';
export const GET_DEBUG_API_ERROR = 'global/GET_DEBUG_API_ERROR';

// Mutations
export const MUTATE_DISABLE_IMPERSONATION_MODE = 'global/MUTATE_DISABLE_IMPERSONATION_MODE';
export const MUTATE_KEEP_ALIVE = 'global/MUTATE_KEEP_ALIVE';
export const MUTATE_IS_MOBILE = 'global/MUTATE_IS_MOBILE';
export const MUTATE_IS_TABLET = 'global/MUTATE_IS_TABLET';
export const MUTATE_ADD_OPEN_MODAL = 'global/MUTATE_ADD_OPEN_MODAL';
export const MUTATE_SUBTRACT_OPEN_MODAL = 'global/MUTATE_SUBTRACT_OPEN_MODAL';
export const MUTATE_SET_LOADING_PROFILE_CONTEXT = 'global/MUTATE_SET_LOADING_PROFILE_CONTEXT';
export const MUTATE_SET_CURRENT_PROFILE = 'global/MUTATE_SET_CURRENT_PROFILE';
export const MUTATE_SET_PROFILES_BY_LOGIN = 'global/MUTATE_SET_PROFILES_BY_LOGIN';
export const MUTATE_LOAD_PROFILE_CONTEXT = 'global/MUTATE_LOAD_PROFILE_CONTEXT';
export const MUTATE_TOGGLE_SPINNER = 'global/MUTATE_TOGGLE_SPINNER';
export const MUTATE_SET_STEPPED_UP = 'global/MUTATE_SET_STEPPED_UP';
export const MUTATE_ERROR_TEXT = 'global/MUTATE_ERROR_TEXT';
export const MUTATE_SUCCESS_TEXT = 'global/MUTATE_SUCCESS_TEXT';
export const MUTATE_SET_SELECTED_INSTITUTION = 'global/MUTATE_SET_SELECTED_INSTITUTION';
export const MUTATE_SET_ACTIVE_PROFILE = 'global/MUTATE_SET_ACTIVE_PROFILE';
export const MUTATE_REMOVE_GROUP_FROM_USER_GROUPS = 'global/MUTATE_REMOVE_GROUP_FROM_USER_GROUPS';
export const MUTATE_SET_BLOCKED_COMMUNICATION_PROFILES = 'global/MUTATE_SET_BLOCKED_COMMUNICATION_PROFILES';
export const MUTATE_SET_TOAST_TIMER = 'global/MUTATE_SET_TOAST_TIMER';
export const MUTATE_SET_IMPERSONATION_MODE = 'global/MUTATE_SET_IMPERSONATION_MODE';
export const MUTATE_SET_MEMBERSHIP = 'global/MUTATE_SET_MEMBERSHIP';
export const MUTATE_ADD_GROUP = 'global/MUTATE_ADD_GROUP';
export const MUTATE_PROFILE_PICTURE = 'global/MUTATE_PROFILE_PICTURE';
export const MUTATE_PHP_SESSION_ID = 'global/MUTATE_PHP_SESSION_ID';
export const MUTATE_PROFILE_CHANGE = 'global/MUTATE_PROFILE_CHANGE';
export const MUTATE_DATA_POLICY = 'global/MUTATE_DATA_POLICY';
export const MUTATE_OPEN_DATA_POLICY = 'global/MUTATE_OPEN_DATA_POLICY';
export const MUTATE_SET_DOCUMENT_VISIBLE_STATE = 'global/MUTATE_SET_DOCUMENT_VISIBLE_STATE';
export const MUTATE_DEBUG_API_ERROR = 'global/MUTATE_DEBUG_API_ERROR';

// Actions
export const ACTION_GET_PROFILE_CONTEXT = 'global/ACTION_GET_PROFILE_CONTEXT';
export const SWITCH_CURRENT_PROFILE = 'global/SWITCH_CURRENT_PROFILE';
export const LOAD_PROFILES_BY_LOGIN = 'global/LOAD_PROFILES_BY_LOGIN';
export const LOAD_PROFILE_CONTEXT = 'global/LOAD_PROFILE_CONTEXT';
export const TOGGLE_SPINNER = 'global/TOGGLE_SPINNER';
export const ACTIONS_SESSION_STEP_UP = 'global/ACTIONS_SESSION_STEP_UP';
export const CLEAR_ERROR_TEXT = 'global/CLEAR_ERROR_TEXT';
export const SET_SELECTED_INSTITUTION = 'global/SET_SELECTED_INSTITUTION';
export const REMOVE_GROUP_FROM_USER_GROUPS = 'global/REMOVE_GROUP_FROM_USER_GROUPS';
export const ACTION_KEEPALIVE = 'global/ACTION_KEEPALIVE';
export const SET_BLOCKED_COMMUNICATION_PROFILES = 'global/SET_BLOCKED_COMMUNICATION_PROFILES';
export const IMPERSONATE_PROFILE = 'global/IMPERSONATE_PROFILE';
export const DISABLE_IMPERSONATION_MODE = 'global/DISABLE_IMPERSONATION_MODE';
export const END_IMPERSONATION_MODE = 'global/END_IMPERSONATION_MODE';
export const LOAD_DATA_POLICY = 'global/LOAD_DATA_POLICY';
export const LOAD_OPEN_DATA_POLICY = 'global/LOAD_OPEN_DATA_POLICY';
export const SET_DOCUMENT_VISIBLE_STATE = 'global/SET_DOCUMENT_VISIBLE_STATE';
export const EMPLOYEE_LOGIN_CHECKIN_DASHBOARD = 'global/EMPLOYEE_LOGIN_CHECKIN_DASHBOARD';
