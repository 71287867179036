import { types } from '../types/types';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';
import { httpStatus } from '../../../shared/enums/httpStatus';
import { exitTypes } from '../../../shared/enums/exitTypes';
import { presenceStates } from '../../../shared/enums/presenceStates';
import * as presenceTexts from '../../texts/presence.js';
import { fromTextKey } from '../../../shared/texts/fromTextKey';
import { activityListSortTypes } from '../../../shared/enums/activityListSortTypes';
import { orderDirections } from '../../../shared/enums/orderDirections';
import { dailyNoteFilters } from '../../../shared/enums/dailyNoteFilters';

const state = {
  activityList: {},
  weekOverviewData: null,
  presenceVacationRegistrationData: null,
  presenceDistribution: null,
  activeTemplateForDate: null,
  hasMoreActivity: false,
  moreWeekOverviewChildren: false,
  moreVacationRegistrations: false,
  departments: [],
  activityFilter: [],
  selectedDepartment: null,
  departmentGroups: [],
  selectedRegistrations: [],
  childrenPresenceRegistration: [],
  pickupResponsibles: [],
  variableNameToRememberDepartmentId: '',
  variableNameToRememberGroupIds: '',
  profilePictureMap: new Map(),
  filterOptions: {
    departmentId: null,
    groupIds: [],
    states: [],
    locationIds: [],
    hasDailyNote: false,
    nextActivity: null,
    institutionCode: null,
    activityListSort: activityListSortTypes.NEXT_ACTIVITY_DATE,
    orderDirection: orderDirections.ASCENDING,
    statusFilters: null,
  },
  states: [
    {
      label: fromTextKey('PRESENCE_STATE_PRESENT', null, presenceTexts),
      options: [
        {
          value: presenceStates.PRESENT,
          label: fromTextKey('PRESENCE_ACTIVITY_CHECK_IN', null, presenceTexts),
          class: 'present',
          icon: 'icon-presence-check',
        },
        {
          value: presenceStates.SLEEPING,
          label: fromTextKey('PRESENCE_STATE_SLEEPING', null, presenceTexts),
          class: 'sleeping',
          icon: 'icon-sleep',
        },
      ],
      value: 'present',
    },
    {
      label: fromTextKey('PRESENCE_STATE_OFF_PREMISES', null, presenceTexts),
      options: [
        {
          value: presenceStates.FIELDTRIP,
          label: fromTextKey('PRESENCE_STATE_FIELDTRIP', null, presenceTexts),
          class: 'fieldtrip',
          icon: 'icon-backpack',
        },
        {
          value: presenceStates.SPARE_TIME_ACTIVITY,
          label: fromTextKey('PRESENCE_STATE_SPARE_TIME_ACTIVITY', null, presenceTexts),
          class: 'spare-time-activity',
          icon: 'icon-walking-person',
        },
      ],
      value: 'offPremises',
    },
    {
      label: fromTextKey('PRESENCE_STATE_NOT_PRESENT', null, presenceTexts),
      options: [
        {
          value: presenceStates.NOT_PRESENT,
          label: fromTextKey('PRESENCE_STATE_NOT_CHECKED_IN', null, presenceTexts),
          class: 'not-present',
          icon: 'icon-Aula_questionmark',
        },
        {
          value: presenceStates.CHECKED_OUT,
          label: fromTextKey('PRESENCE_ACTIVITY_CHECK_OUT', null, presenceTexts),
          class: 'checked-out',
          icon: 'icon-presence-check',
        },
      ],
      value: 'notPresent',
    },
    {
      label: fromTextKey('PRESENCE_STATE_ABSENCE', null, presenceTexts),
      options: [
        {
          value: presenceStates.SICK,
          label: fromTextKey('PRESENCE_STATE_SICK', null, presenceTexts),
          class: 'sick',
          icon: 'icon-Syg_ikon',
        },
        {
          value: presenceStates.REPORTED_ABSENT,
          label: fromTextKey('PRESENCE_STATE_REPORTED_ABSENT', null, presenceTexts),
          class: 'reported-absent',
          icon: 'icon-Ferie_ikon',
        },
      ],
      value: 'absence',
    },
  ],
  activities: [
    {
      value: exitTypes.PICKED_UP_BY,
      label: fromTextKey('PRESENCE_ACTIVITY_PICKED_UP_BY', null, presenceTexts),
      labelPast: fromTextKey('PRESENCE_ACTIVITY_CHECK_OUT', null, presenceTexts),
    },
    {
      value: exitTypes.SELF_DECIDER,
      label: fromTextKey('PRESENCE_ACTIVITY_SELF_DECIDER', null, presenceTexts),
      labelPast: fromTextKey('PRESENCE_ACTIVITY_CHECK_OUT', null, presenceTexts),
    },
    {
      value: exitTypes.SEND_HOME,
      label: fromTextKey('PRESENCE_ACTIVITY_SEND_HOME', null, presenceTexts),
      labelPast: fromTextKey('PRESENCE_ACTIVITY_CHECK_OUT', null, presenceTexts),
    },
    {
      value: exitTypes.GO_HOME_WITH,
      label: fromTextKey('PRESENCE_ACTIVITY_GO_HOME_WITH', null, presenceTexts),
      labelPast: fromTextKey('PRESENCE_ACTIVITY_CHECK_OUT', null, presenceTexts),
    },
    {
      value: exitTypes.DROP_OFF_TIME,
      label: fromTextKey('PRESENCE_ACTIVITY_CHECK_IN_FUTURE', null, presenceTexts),
      labelPast: fromTextKey('PRESENCE_ACTIVITY_CHECK_IN_PAST', null, presenceTexts),
    },
    {
      value: exitTypes.SPARE_TIME,
      label: fromTextKey('PRESENCE_ACTIVITY_SPARE_TIME', null, presenceTexts),
      labelPast: fromTextKey('PRESENCE_ACTIVITY_SPARE_TIME', null, presenceTexts),
    },
    {
      value: exitTypes.CHECK_IN,
      label: fromTextKey('PRESENCE_ACTIVITY_CHECK_IN_FUTURE', null, presenceTexts),
      labelPast: fromTextKey('PRESENCE_ACTIVITY_CHECK_IN_PAST', null, presenceTexts),
    },
    {
      value: exitTypes.SLEEPING,
      label: fromTextKey('PRESENCE_STATE_SLEEPING_PAST', null, presenceTexts),
      labelPast: fromTextKey('PRESENCE_STATE_SLEEPING_PAST', null, presenceTexts),
    },
  ],
};

const getters = {
  [types.GET_PRESENCE_FILTERS]: state => state.activityFilter,
  [types.GET_SLEEP_INTERVAL_FOR_CHILDREN]: state => state.sleepIntervals,
  [types.GET_CHILDREN_PRESENCE_REGISTRATION]: state => state.childrenPresenceRegistration,
  [types.GET_SELECTED_REGISTRATION_TO_EDIT]: state => state.selectedRegistrations,
  [types.GET_PICK_UP_RESPONSIBLES]: state => state.pickupResponsibles,
  [types.GET_HAS_MORE_ACTIVITY]: state => state.hasMoreActivity,
  [types.GET_PRESENCE_DISTRIBUTION]: state => state.presenceDistribution,
  [types.GET_ACTIVITY_LIST]: state => state.activityList,
  [types.GET_WEEK_OVERVIEW_DATA]: state => state.weekOverviewData,
  [types.GET_HAS_MORE_WEEK_OVERVIEW]: state => state.moreWeekOverviewChildren,
  [types.GET_HAS_MORE_VACATION_REGISTRATIONS]: state => state.moreVacationRegistrations,
  [types.GET_DEPARTMENTS]: state => state.departments,
  [types.GET_DEPARTMENT_GROUPS]: state => state.departmentGroups,
  [types.GET_SELECTED_DEPARTMENT]: state => state.selectedDepartment,
  [types.GET_FILTER_OPTIONS]: state => state.filterOptions,
  [types.GET_PRESENCE_STATES]: state => state.states,
  [types.GET_PRESENCE_ACTIVITIES]: state => state.activities,
  [types.GET_ACTIVE_PRESENCE_TEMPLATE_FOR_DATE]: state => state.activeTemplateForDate,
  [types.GET_VACATION_REGISTRATION_DATA]: state => state.presenceVacationRegistrationData,
};

const mutations = {
  [types.MUTATE_ACTIVITY_FILTER]: (state, payload) => {
    if (state.variableNameToRememberDepartmentId == '' || state.variableNameToRememberGroupIds == '') {
      state.variableNameToRememberDepartmentId = payload.profileId + 'FilteringDepartmentId';
      state.variableNameToRememberGroupIds = payload.profileId + 'FilteringGroupIds';
    }
    if (payload.presenceFilters.length > 0) {
      state.activityFilter = payload.presenceFilters;
      state.departments = [];
      if (payload.resetFilterOptions) {
        resetFilterOptions();
      }
      for (const institution of payload.presenceFilters) {
        for (const department of institution.departments) {
          department.locations = institution.locations;
          department.presenceStates = institution.presenceStates;
          department.presenceNextActivities = institution.presenceNextActivities;
          department.institutionCode = institution.institutionCode;
          department.institutionName = institution.institutionName;
          state.departments.push(department);
        }
      }
      if (state.departments.length > 0) {
        state.selectedDepartment = state.departments[0];
        state.filterOptions.departmentId = state.selectedDepartment.id;
        state.filterOptions.institutionCode = state.selectedDepartment.institutionCode;
        state.departmentGroups = state.selectedDepartment.filteringGroups;
      }
      checkLocalStorage();
    } else {
      state.activityList.activities = [];
      state.weekOverviewData = null;
      state.presenceVacationRegistrationData = null;
      state.selectedDepartment = null;
      state.filterOptions.departmentId = null;
      state.filterOptions.groups = [];
      state.departments = [];
      state.departmentGroups = [];
      resetFilterOptions();
      localStorage.setItem(state.variableNameToRememberDepartmentId, '');
      localStorage.setItem(state.variableNameToRememberGroupIds, '');
    }
  },
  [types.MUTATE_PRESENCE_DISTRIBUTION]: (state, payload) => {
    state.presenceDistribution = payload;
  },
  [types.MUTATE_ACTIVITY_LIST]: (state, payload) => {
    if (payload.loadMore) {
      state.activityList.activities.push(...payload.activities);
    } else {
      state.activityList = payload;
    }
    applyProfilePictures(state.activityList);
    state.hasMoreActivity = payload.totalNumberOfChildren > state.activityList.activities.length;
  },
  [types.MUTATE_WEEK_OVERVIEW_LIST]: (state, payload) => {
    if (payload.loadMore) {
      state.weekOverviewData.childActivities.push(...payload.data.childActivities);
    } else {
      state.weekOverviewData = payload.data;
    }
    applyProfilePicturesForWeekOverview(state.weekOverviewData);
    state.moreWeekOverviewChildren =
      state.weekOverviewData.childActivities.length < payload.data.totalNumberOfChildrenInFilter;
  },
  [types.MUTATE_SELECTED_DEPARTMENT]: (state, payload) => {
    state.selectedDepartment = payload;
    state.departmentGroups = state.selectedDepartment.filteringGroups;
  },
  [types.MUTATE_SELECTED_REGISTRATION_TO_EDIT]: (state, payload) => {
    state.selectedRegistrations = payload.selectedRegistrations;
  },
  [types.MUTATE_PRESENCE_REGISTRATION_BY_IDS]: (state, payload) => {
    state.childrenPresenceRegistration = payload;
  },
  [types.MUTATE_PICK_UP_RESPONSIBLES]: (state, payload) => {
    state.pickupResponsibles = payload;
  },
  [types.MUTATE_FILTER_OPTIONS]: (state, payload) => {
    if (payload.groupIds !== undefined) {
      state.filterOptions.groupIds = payload.groupIds;
      localStorage.setItem(state.variableNameToRememberGroupIds, state.filterOptions.groupIds);
    }
    if (payload.departmentId !== undefined) {
      state.filterOptions.departmentId = payload.departmentId;
      state.filterOptions.groupIds = [];
      resetFilterOptions();
      localStorage.setItem(state.variableNameToRememberDepartmentId, state.filterOptions.departmentId);
      localStorage.setItem(state.variableNameToRememberGroupIds, '');
      const selectedDepartment = state.departments.find(
        department => department.id === state.filterOptions.departmentId
      );
      if (selectedDepartment != null) {
        state.selectedDepartment = selectedDepartment;
        state.filterOptions.departmentId = state.selectedDepartment.id;
        state.filterOptions.institutionCode = state.selectedDepartment.institutionCode;
        state.departmentGroups = state.selectedDepartment.filteringGroups;
      }
    }
    if (payload.states !== undefined) {
      state.filterOptions.states = payload.states;
    }
    if (payload.nextActivity !== undefined) {
      state.filterOptions.nextActivity = payload.nextActivity;
    }
    if (payload.locationIds !== undefined) {
      state.filterOptions.locationIds = payload.locationIds;
    }
    if (payload.hasDailyNote !== undefined) {
      state.filterOptions.hasDailyNote = payload.hasDailyNote;
    }
    if (payload.orderDirection !== undefined) {
      let direction = orderDirections.ASCENDING;
      if (
        state.filterOptions.activityListSort === payload.activityListSort &&
        payload.orderDirection === orderDirections.ASCENDING
      ) {
        direction = orderDirections.DESCENDING;
      }
      state.filterOptions.orderDirection = direction;
    }

    if (payload.activityListSort !== undefined) {
      state.filterOptions.activityListSort = payload.activityListSort;
    }

    if (payload.statusFilters !== undefined) {
      state.filterOptions.statusFilters = payload.statusFilters;
    }
  },
  [types.MUTATE_PRESENCE_TEMPLATE_FOR_DATE]: (state, payload) => {
    state.activeTemplateForDate = payload;
  },
  [types.MUTATE_VACATION_REGISTRATIONS]: (state, payload) => {
    if (payload.loadMore && state.presenceVacationRegistrationData) {
      state.presenceVacationRegistrationData.vacationRegistrations.push(...payload.data.vacationRegistrations);
    } else {
      state.presenceVacationRegistrationData = payload.data;
    }
    state.moreVacationRegistrations =
      state.presenceVacationRegistrationData.vacationRegistrations.length < payload.data.totalNumber;
  },
};
function resetFilterOptions() {
  state.filterOptions.states = [];
  state.filterOptions.locationIds = [];
  state.filterOptions.nextActivity = null;
  state.orderDirection = orderDirections.ASCENDING;
  state.activityListSort = activityListSortTypes.NEXT_ACTIVITY_DATE;
  state.filterOptions.hasDailyNote = false;
}
function checkLocalStorage() {
  if (
    localStorage.getItem(state.variableNameToRememberDepartmentId) != null &&
    state.departments.find(
      department => department.id == localStorage.getItem(state.variableNameToRememberDepartmentId)
    ) != null
  ) {
    state.filterOptions.departmentId = parseInt(localStorage.getItem(state.variableNameToRememberDepartmentId));
    state.selectedDepartment = state.departments.find(
      department => department.id == localStorage.getItem(state.variableNameToRememberDepartmentId)
    );
    state.filterOptions.departmentId = state.selectedDepartment.id;
    state.filterOptions.institutionCode = state.selectedDepartment.institutionCode;
    state.departmentGroups = state.selectedDepartment.filteringGroups;
  } else {
    localStorage.setItem(state.variableNameToRememberDepartmentId, state.selectedDepartment.id);
    localStorage.setItem(state.variableNameToRememberGroupIds, '');
    resetFilterOptions();
  }

  if (localStorage.getItem(state.variableNameToRememberGroupIds) != null) {
    const cachedDepartmentGroupIds = localStorage
      .getItem(state.variableNameToRememberGroupIds)
      .split(',')
      .map(id => parseInt(id));
    const groupIdsToBeShown = [];
    for (const groupId of cachedDepartmentGroupIds) {
      for (const departmentGroup of state.departmentGroups) {
        if (parseInt(departmentGroup.id) === groupId) {
          groupIdsToBeShown.push(groupId);
        }
      }
    }
    if (groupIdsToBeShown.length > 0) {
      state.filterOptions.groupIds = [...groupIdsToBeShown];
      const newCachedGroupIds = [];
      for (const groupId of groupIdsToBeShown) {
        const groupIdToString = groupId.toString();
        newCachedGroupIds.push(groupIdToString);
      }
      localStorage.setItem(state.variableNameToRememberGroupIds, newCachedGroupIds.join(','));
    } else {
      state.filterOptions.groupIds = [];
      localStorage.setItem(state.variableNameToRememberGroupIds, '');
    }
  } else {
    state.filterOptions.groupIds = [];
  }
}

const actions = {
  [types.UPDATE_PRESENCE_REGISTRATION]: (
    { commit },
    { registrationId, remark, entryTime, checkoutType, selfDecider, pickupBy, sendHome, goHomeWith }
  ) =>
    portal
      .post('?method=presence.updatePresenceRegistration', {
        registrationId,
        remark,
        entryTime,
        checkoutType,
        selfDecider,
        pickupBy,
        sendHome,
        goHomeWith,
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_PRESENCE_STATUS');
      }),
  [types.UPDATE_SELECTED_CHILDREN_STATUS]: ({ commit }, payload) =>
    portal
      .post('?method=presence.bulkUpdatePresenceStatus', payload)
      .then(() => commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_UPDATE_STATUS'))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_PRESENCE_STATUS');
      }),
  [types.LOAD_PRESENCE_FILTER]: ({ commit, rootState }, payload) =>
    portal
      .get('?method=presence.getPresenceFilters', { params: payload })
      .then(response => {
        const params = {
          presenceFilters: response.data.data,
          resetFilterOptions: payload.resetFilterOptions,
          profileId: rootState.globalShared.currentProfile.id,
        };
        commit(types.MUTATE_ACTIVITY_FILTER, params);
      })
      .catch(error => {
        if (error.response.data.status.code == httpStatus.FORBIDDEN) {
          const params = {
            presenceFilters: [],
            profileId: rootState.globalShared.currentProfile.id,
          };
          commit(types.MUTATE_ACTIVITY_FILTER, params);
        } else {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ACTIVITY_FILTER');
        }
      }),
  [types.LOAD_PRESENCE_DISTRIBUTION]: ({ commit }, payload) => {
    const params = {
      departmentId: state.filterOptions.departmentId,
      groupIds: state.filterOptions.groupIds,
      statusFilters: state.filterOptions.statusFilters,
      date: payload.date,
    };
    return portal
      .get('?method=presence.getPresenceDistribution', { params })
      .then(response => {
        commit(types.MUTATE_PRESENCE_DISTRIBUTION, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PRESENCE_DISTRIBUTION');
      });
  },
  [types.LOAD_WEEK_OVERVIEW]: ({ commit }, payload) => {
    if (!state.filterOptions.departmentId) {
      return;
    }
    const params = {
      departmentId: state.filterOptions.departmentId,
      groupIds: state.filterOptions.groupIds,
      offset:
        payload.loadMore && state.weekOverviewData.childActivities ? state.weekOverviewData.childActivities.length : 0,
      ...payload.params,
    };
    const cancelToken = payload.cancelToken;
    return portal
      .get('?method=presence.getActivityOverview', { params, cancelToken })
      .then(response => {
        commit(types.MUTATE_WEEK_OVERVIEW_LIST, {
          data: response.data.data,
          loadMore: payload.loadMore,
        });
      })
      .catch(error => {
        if (portal.isCancel(error)) {
          return Promise.reject(error);
        }
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_WEEK_OVERVIEW');
      });
  },
  [types.LOAD_ACTIVITY_LIST]: ({ commit }, payload) => {
    if (!state.filterOptions.departmentId) {
      return;
    }
    const params = {
      departmentId: state.filterOptions.departmentId,
      filterOptions: {
        groupIds: state.filterOptions.groupIds,
        states: state.filterOptions.states,
        locationIds: state.filterOptions.locationIds,
        nextActivity: state.filterOptions.nextActivity,
        dailyNote: state.filterOptions.hasDailyNote ? dailyNoteFilters.ONLY_DAILY_NOTE : dailyNoteFilters.ALL,
      },
      limit: payload.loadAll ? state.activityList.totalNumberOfChildren : 20,
      offset: payload.loadMore && state.activityList.activities != null ? state.activityList.activities.length : 0,
      sortOn: state.filterOptions.activityListSort,
      orderDirection: state.filterOptions.orderDirection,
    };
    return portal
      .post('?method=presence.getActivityList', params)
      .then(response => {
        response.data.data.loadMore = payload.loadMore;
        response.data.data.limit = payload.limit ? payload.limit : 20;
        commit(types.MUTATE_ACTIVITY_LIST, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ACTIVITY_LIST');
      });
  },
  [types.RELOAD_ACTIVITY_LIST]: ({ commit }) => {
    const params = {
      departmentId: state.filterOptions.departmentId,
      filterOptions: {
        groupIds: state.filterOptions.groupIds,
        states: state.filterOptions.states,
        locationId: state.filterOptions.locationIds,
        nextActivity: state.filterOptions.nextActivity,
        dailyNote: state.filterOptions.hasDailyNote ? dailyNoteFilters.ONLY_DAILY_NOTE : dailyNoteFilters.ALL,
      },
      offset: 0,
      limit: 20,
      sortOn: state.filterOptions.activityListSort,
    };
    return portal
      .post('?method=presence.getActivityList', params)
      .then(response => {
        response.data.data.loadMore = false;
        commit(types.MUTATE_ACTIVITY_LIST, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ACTIVITY_LIST');
      });
  },
  [types.UPDATE_LOCATION_FOR_CHILDREN]: (_, payload) =>
    portal.post('?method=presence.updateLocation', payload).then(() => null),
  [types.ADD_SLEEP_INTERVAL_FOR_CHILDREN]: ({ commit }, payload) =>
    portal
      .post('?method=presence.addSleepIntervals', payload)
      .then(response => response.data.data)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENTS');
      }),
  [types.UPDATE_SLEEP_INTERVAL_FOR_CHILD]: ({ commit }, payload) =>
    portal
      .post('?method=presence.updateSleepInterval', payload)
      .then(response => response.data.data)
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENTS');
      }),
  [types.REMOVE_VACATION_FOR_CHILDREN]: ({ commit }, payload) =>
    portal.post('?method=presence.deleteSleepIntervals', payload).catch(() => {
      commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_SECURE_DOCUMENTS');
    }),
  [types.UPDATE_PRESENCE_STATUS_FOR_CHILDREN]: (_, payload) =>
    portal.post('?method=presence.bulkUpdatePresenceStatus', payload),
  [types.ADD_VACATION_FOR_CHILDREN]: ({ commit }, payload) =>
    portal.post('?method=calendar.addVacation', payload).then(() => {
      commit(types.MUTATE_SUCCESS_TEXT, 'PRESENCE_CHILD_VACATION_CREATED');
    }),
  [types.LOAD_PRESENCE_REGISTRATION_BY_IDS]: ({ commit }, payload) => {
    payload.departmentId = state.filterOptions.departmentId;
    return portal
      .get('?method=presence.getPresenceRegistrations', { params: payload })
      .then(response => commit(types.MUTATE_PRESENCE_REGISTRATION_BY_IDS, response.data.data));
  },
  [types.LOAD_PICK_UP_RESPONSIBLES]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getPickupResponsibles', { params: payload })
      .then(response => commit(types.MUTATE_PICK_UP_RESPONSIBLES, response.data.data)),
  /**
   * @deprecated
   */
  [types.UPDATE_EXIT_TYPE_FOR_CHILD]: ({ commit }, payload) =>
    portal.post('?method=presence.updateCheckoutActivity', payload).catch(() => {
      commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ACTIVITY_LIST');
    }),
  [types.LOAD_PRESENCE_TEMPLATE_FOR_DATE]: ({ commit }, payload) =>
    portal
      .get('?method=presence.getTemplateForDate', { params: payload })
      .then(response => {
        commit(types.MUTATE_PRESENCE_TEMPLATE_FOR_DATE, response.data.data);
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_PRESENCE_TEMPLATE_FOR_DATE');
      }),
  [types.LOAD_VACATION_REGISTRATIONS]: ({ commit }, payload) => {
    if (!state.filterOptions.departmentId) {
      return;
    }
    const params = {
      departmentId: state.filterOptions.departmentId,
      filterGroups: state.filterOptions.groupIds,
      offset:
        payload.loadMore && state.presenceVacationRegistrationData.vacationRegistrations
          ? state.presenceVacationRegistrationData.vacationRegistrations.length
          : 0,
      limit: 20,
      dailyNote: state.filterOptions.hasDailyNote ? dailyNoteFilters.ONLY_DAILY_NOTE : dailyNoteFilters.ALL,
    };
    return portal
      .get('?method=presence.getVacationRegistrations', { params })
      .then(response => {
        commit(types.MUTATE_VACATION_REGISTRATIONS, {
          data: response.data.data,
          loadMore: payload.loadMore,
        });
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_VACATION_REGISTRATIONS');
      });
  },
};
export default {
  state,
  mutations,
  actions,
  getters,
};

const applyProfilePictures = payload => {
  clearOutdatedProfilePictures();

  const now = new Date().getTime();
  const ttl = 1000 * 60 * 55;

  payload.activities.forEach(activity => {
    const profilePicture = activity.uniStudent.profilePicture;
    if (profilePicture) {
      if (state.profilePictureMap.has(profilePicture.id)) {
        activity.uniStudent.profilePicture = state.profilePictureMap.get(profilePicture.id).profilePicture;
      } else {
        state.profilePictureMap.set(profilePicture.id, {
          cacheTTL: now + ttl,
          profilePicture,
        });
      }
    }
  });
};

const applyProfilePicturesForWeekOverview = payload => {
  clearOutdatedProfilePictures();

  const now = new Date().getTime();
  const ttl = 1000 * 60 * 55;

  payload.childActivities.forEach(activity => {
    const profilePicture = activity.child.profilePicture;
    if (profilePicture) {
      if (state.profilePictureMap.has(profilePicture.id)) {
        activity.child.profilePicture = state.profilePictureMap.get(profilePicture.id).profilePicture;
      } else {
        state.profilePictureMap.set(profilePicture.id, {
          cacheTTL: now + ttl,
          profilePicture,
        });
      }
    }
  });
};

const clearOutdatedProfilePictures = () => {
  const outdatedPictureIds = [];
  const now = new Date().getTime();

  state.profilePictureMap.forEach(({ cacheTTL, profilePicture }) => {
    if (now > cacheTTL) {
      outdatedPictureIds.push(profilePicture.id);
    }
  });

  for (const pictureId of outdatedPictureIds) {
    state.profilePictureMap.delete(pictureId);
  }
};
