export const dateNameFormats = {
  dateOfWeek: {
    1: 'Man',
    2: 'Tir',
    3: 'Ons',
    4: 'Tor',
    5: 'Fre',
    6: 'Lør',
    7: 'Søn',
  },
};
