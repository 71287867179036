// Interface for all getters, mutations and actions

// Getters
export const GET_USERS_FROM_SEARCH = 'users/GET_USERS_FROM_SEARCH';
export const GET_TOTAL_USERS_FROM_SEARCH = 'users/GET_TOTAL_USERS_FROM_SEARCH';
export const GET_USER_ROLES = 'users/GET_USER_ROLES';
export const GET_ASSIGNED_USER_ROLES = 'users/GET_ASSIGNED_USER_ROLES';
export const GET_ROLE_DEFINITIONS = 'users/GET_ROLE_DEFINITIONS';
export const GET_SELECTED_USER_EXPLICIT_ROLES = 'users/GET_SELECTED_USER_EXPLICIT_ROLES';
export const GET_SELECTED_USER_IMPLICIT_ROLES = 'users/GET_SELECTED_USER_IMPLICIT_ROLES';
export const GET_CAN_SELECTED_USER_ACCESS_GROUPS = 'users/GET_CAN_SELECTED_USER_ACCESS_GROUPS';
export const GET_TOO_MANY_USERS_RESULTS_FROM_SEARCH = 'users/GET_TOO_MANY_USERS_RESULTS_FROM_SEARCH';
export const GET_ZERO_USERS_RESULTS_FROM_SEARCH = 'users/GET_ZERO_USERS_RESULTS_FROM_SEARCH';
export const GET_SELECTED_USER_GROUPS_WITH_ROLE_DEFINITIONS = 'users/GET_SELECTED_USER_GROUPS_WITH_ROLE_DEFINITIONS';
export const GET_SELECTED_USER_NUMBER_OF_GROUPS = 'users/GET_SELECTED_USER_NUMBER_OF_GROUPS';

// Mutations
export const MUTATE_SEARCH_QUERY = 'users/MUTATE_SEARCH_QUERY';
export const MUTATE_SEARCH_USERS = 'users/MUTATE_SEARCH_USERS';
export const MUTATE_SEARCH_USERS_AFTER_EDIT_ROLE = 'users/MUTATE_SEARCH_USERS_AFTER_EDIT_ROLE';
export const MUTATE_TOTAL_SEARCH_USERS = 'users/MUTATE_TOTAL_SEARCH_USERS';
export const MUTATE_LOAD_USER_ROLES = 'users/MUTATE_LOAD_USER_ROLES';
export const MUTATE_ROLE_DEFINITIONS = 'users/MUTATE_ROLE_DEFINITIONS';
export const MUTATE_LOAD_ASSIGNED_USER_ROLES = 'users/MUTATE_LOAD_ASSIGNED_USER_ROLES';
export const MUTATE_SELECTED_USER_EXPLICIT_ROLES = 'users/MUTATE_SELECTED_USER_EXPLICIT_ROLES';
export const MUTATE_SELECTED_USER_IMPLICIT_ROLES = 'users/MUTATE_SELECTED_USER_IMPLICIT_ROLES';
export const MUTATE_CAN_SELECTED_USER_ACCESS_GROUPS = 'users/MUTATE_CAN_SELECTED_USER_ACCESS_GROUPS';
export const MUTATE_SELECTED_USER_NUMBER_OF_GROUPS = 'users/MUTATE_SELECTED_USER_NUMBER_OF_GROUPS';
export const MUTATE_SELECTED_USER_GROUPS_WITH_ROLE_DEFINITIONS =
  'users/MUTATE_SELECTED_USER_GROUPS_WITH_ROLE_DEFINITIONS';

// Actions
export const CREATE_PROFILE_LOCK_REASON = 'users/CREATE_PROFILE_LOCK_REASON';
export const SEARCH_USERS = 'users/SEARCH_USERS';
export const LOAD_USER_ROLES = 'users/LOAD_USER_ROLES';
export const LOAD_ASSIGNED_USER_ROLES = 'users/LOAD_ASSIGNED_USER_ROLES';
export const ACTION_GET_ROLE_DEFINITIONS = 'users/ACTION_GET_ROLE_DEFINITIONS';
export const ACTION_GET_SELECTED_USER_ROLE_DEFINITIONS = 'users/ACTION_GET_SELECTED_USER_ROLE_DEFINITIONS';
export const ACTION_GET_LIST_ASSIGNED_ROLES = 'users/ACTION_GET_LIST_ASSIGNED_ROLES';
export const ACTION_GET_SELECTED_USER_ASSIGNED_ROLES = 'users/ACTION_GET_SELECTED_USER_ASSIGNED_ROLES';
export const ACTION_SET_ROLES_IN_INSTITUTION = 'users/ACTION_SET_ROLES_IN_INSTITUTION';
export const ASSIGN_USER_ROLES = 'users/ASSIGN_USER_ROLES';
export const ASSIGN_ROLES_TO_SELECTED_USER = 'users/ASSIGN_ROLES_TO_SELECTED_USER';
export const ACTION_GET_ASSIGNED_GROUP_ROLES = 'users/ACTION_GET_ASSIGNED_GROUP_ROLES';
export const ASSIGN_GROUP_ROLES = 'users/ASSIGN_GROUP_ROLES';
