import { types } from '../types/types';
import Vue from 'vue';
import { portal } from '../../../shared/assets/plugins/axios/axios.js';
import AES from 'crypto-js/aes';
import ENC from 'crypto-js/enc-utf8';
import { parentTypes } from '../../../shared/enums/parentTypes';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import { storageKeyTypes } from '../../../shared/enums/storageKeyTypes';

const encrypt = function (text, key) {
  return AES.encrypt(text, key).toString();
};

const decrypt = function (ciphertext, key) {
  const bytes = AES.decrypt(ciphertext, key);
  return bytes.toString(ENC);
};

const state = {
  draftPost: {},
  posts: {},
  singlePost: {},
  morePostsExist: true,
  profileLastSeenPostDate: '',
  lastInstitutionProfileIdsFilter: [],
  attachmentMap: new Map(),
};

const getters = {
  [types.GET_POSTS]: state => state.posts,
  [types.GET_SINGLE_POST]: state => state.singlePost,
  [types.GET_MORE_POSTS_EXIST]: state => state.morePostsExist,
  [types.GET_DRAFT_POST]: state => state.draftPost,
  [types.GET_LAST_SEEN_DATE]: state => state.profileLastSeenPostDate,
};

const mutations = {
  [types.MUTATE_ClEAR_NEW_POST]: (state, { profile, group }) => {
    setDraftInStorage({ profile, group });
  },
  [types.MUTATE_SAVE_NEW_POSTS_LOCALLY]: (state, { profile, group, draft }) => {
    setDraftInStorage({ profile, group, draft });
  },
  [types.MUTATE_INIT_LOCAL_NEW_POSTS]: (state, payload) => {
    const savedPosts = (localStorage.getItem('draftPosts') && JSON.parse(localStorage.getItem('draftPosts'))) || {};
    if (savedPosts[payload.id]) {
      try {
        state.draftPost = JSON.parse(decrypt(savedPosts[payload.id], payload.encryptionKey));
      } catch (e) {
        state.draftPost = {};
      }
    } else {
      state.draftPost = {};
    }
  },
  [types.MUTATE_REMOVE_POSTS_LOCALLY]: () => {
    clearTimeout(savePostTimeout);
    localStorage.removeItem('draftPosts', {});
  },
  [types.MUTATE_EMPTY_POSTS]: state => {
    state.posts = {};
  },
  [types.MUTATE_LAST_INSTITUTION_PROFILE_ID_FILTER]: (state, payload) => {
    state.lastInstitutionProfileIdsFilter = payload;
  },
  [types.MUTATE_LOAD_SINGLE_POST]: (state, payload) => {
    state.singlePost = payload;
  },
  [types.MUTATE_LOAD_POSTS]: (state, payload) => {
    if (payload.parent === parentTypes.NOTICE_BOARDS) {
      applyAttachmentURLs(payload);
      if (state.posts[payload.parent] && payload.index) {
        state.posts[payload.parent].push(...payload.posts);
      } else {
        Vue.set(state.posts, payload.parent, payload.posts);
      }
    } else if (
      payload.institutionProfileIds != null &&
      isEqual(payload.institutionProfileIds.sort(), state.lastInstitutionProfileIdsFilter.sort())
    ) {
      if (state.posts[payload.parent] && payload.index) {
        state.posts[payload.parent].push(...payload.posts);
      } else {
        Vue.set(state.posts, payload.parent, payload.posts);
      }
    }
    state.profileLastSeenPostDate = payload.profileLastSeenPostDate;
    state.morePostsExist = payload.hasMorePosts;
  },
  [types.MUTATE_SLICE_ATTACHMENT_FROM_POST]: (state, { post, attachment, parent }) => {
    const statePostIndex = state.posts[parent].findIndex(statePost => statePost.id === post.id);
    const statePost = state.posts[parent][statePostIndex];
    const attachmentIndex = statePost.attachments.findIndex(stateAttachment => stateAttachment.id === attachment.id);
    if (attachmentIndex > -1) {
      statePost.attachments.splice(attachmentIndex, 1);
    }
    state.posts[parent][statePostIndex] = statePost;
  },
};
const getLocalStorageKeyName = ({ profile, group }) => {
  let id = profile.id;
  if (group.id != null) {
    id = 'group_' + group.id + '_' + id;
  }
  return id;
};

const actions = {
  [types.ACTIONS_CLEAR_NEW_POST]: ({ commit }, { profile, group }) => {
    commit(types.MUTATE_ClEAR_NEW_POST, { profile, group });
  },
  [types.ACTIONS_ADD_NEW_POST]: ({ commit }, payload) =>
    portal
      .post('?method=posts.createPost', payload)
      .then(response => {
        commit(types.MUTATE_REMOVE_POSTS_LOCALLY);
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_POST_CREATE');
        return response;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_CREATE_POST');
      }),
  [types.ACTIONS_EDIT_POST]: ({ commit }, payload) =>
    portal
      .post('?method=posts.updatePost', payload)
      .then(response => {
        commit(types.MUTATE_REMOVE_POSTS_LOCALLY);
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_POST_EDIT');
        return response;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_UPDATE_POST');
      }),
  [types.ACTIONS_SAVE_NEW_POSTS_LOCALLY]: ({ commit }, { profile, group, draft }) => {
    commit(types.MUTATE_SAVE_NEW_POSTS_LOCALLY, { profile, group, draft });
  },
  [types.ACTIONS_INIT_LOCAL_NEW_POSTS]: ({ commit, rootState }, payload) => {
    if (payload.parent === 'group') {
      commit(types.MUTATE_INIT_LOCAL_NEW_POSTS, {
        id: 'group_' + rootState.groupsShared.activeGroup.id + '_' + rootState.globalShared.currentProfile.id,
        encryptionKey: rootState.globalShared.currentProfile.encryptionKey,
      });
    } else {
      commit(types.MUTATE_INIT_LOCAL_NEW_POSTS, {
        id: rootState.globalShared.currentProfile.id,
        encryptionKey: rootState.globalShared.currentProfile.encryptionKey,
      });
    }
  },
  [types.ACTION_GET_POSTS]: ({ commit, rootState }, payload) => {
    if (rootState.global.isPreviewMode) {
      const data = [];
      if (data !== undefined) {
        commit(types.MUTATE_LOAD_POSTS, { ...data, ...payload });
      }
    } else {
      commit(types.MUTATE_LAST_INSTITUTION_PROFILE_ID_FILTER, payload.institutionProfileIds);
      const params = payload;
      return portal
        .get('?method=posts.getAllPosts', { params })
        .then(response => {
          const data = response.data.data;
          if (data !== undefined) {
            commit(types.MUTATE_LOAD_POSTS, { ...data, ...payload });
          }
        })
        .catch(error => {
          if (error.response.data.status.code === 403 && payload.groupId) {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_NOT_A_MEMBER');
            commit(types.MUTATE_REMOVE_GROUP_FROM_USER_GROUPS, payload.groupId);
          } else {
            commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ALL_POSTS');
          }
        });
    }
  },
  [types.ACTION_GET_POSTS_ADMIN]: ({ commit }, payload) => {
    payload.limit += 1;
    commit(types.MUTATE_LAST_INSTITUTION_PROFILE_ID_FILTER, payload.institutionProfileIds);
    const params = payload;
    return portal
      .get('?method=posts.getAllPostsForAdmin', { params })
      .then(response => {
        const data = response.data.data;
        if (data !== undefined) {
          commit(types.MUTATE_LOAD_POSTS, { ...data, ...payload });
        }
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_ALL_POSTS');
      });
  },
  [types.ACTION_GET_POST]: ({ commit }, payload) => {
    if (Number.isInteger(payload.id)) {
      return portal
        .get('?method=posts.getById', {
          params: {
            id: payload.id,
          },
        })
        .then(response => commit(types.MUTATE_LOAD_SINGLE_POST, response.data.data))
        .catch(() => {
          commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_GET_POST_BY_ID');
          return Promise.reject();
        });
    }
  },
  [types.ACTIONS_DELETE_POST]: ({ commit }, payload) =>
    portal
      .post('?method=posts.deletePost', payload)
      .then(() => {
        commit(types.MUTATE_SUCCESS_TEXT, 'SUCCESS_TOAST_POST_DELETE');
      })
      .catch(error => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_DELETE_POST');
        if (error.response.data.status.code == 1000) {
          window.location.href = '/auth/stepup.php';
        } else {
          throw error;
        }
      }),
  [types.REPORT_POST]: ({ commit }, payload) =>
    portal
      .post('?method=posts.reportPost', payload)
      .then(response => {
        commit(types.MUTATE_SUCCESS_TEXT, 'REPORTED_CONTENT_SUCCESS_TOASTR');
        return response.data.data;
      })
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_REPORT_POST');
      }),
  [types.SLICE_ATTACHMENT_FROM_POST]: ({ commit }, { post, attachment, parent }) => {
    commit(types.MUTATE_SLICE_ATTACHMENT_FROM_POST, { post, attachment, parent });
  },
  [types.LOAD_NOTICE_BOARD_POSTS]: ({ commit }, payload) =>
    portal
      .get('?method=NoticeBoardDevice.getPosts', { params: payload })
      .then(response => commit(types.MUTATE_LOAD_POSTS, { ...response.data.data, ...payload }))
      .catch(() => {
        commit(types.MUTATE_ERROR_TEXT, 'API_ERROR_LOAD_NOTICE_BOARD_EVENTS');
      }),
};

const savePostTimeout = undefined;

export default {
  state,
  mutations,
  actions,
  getters,
};

const applyAttachmentURLs = payload => {
  clearOutdatedAttachments();
  const now = new Date().getTime();
  const ttl = 1000 * 60 * 55;
  payload.posts.forEach(post => {
    post.attachments = post.attachments.map(attachment => {
      if (state.attachmentMap.has(attachment.id)) {
        return state.attachmentMap.get(attachment.id).attachment;
      }
      state.attachmentMap.set(attachment.id, {
        cacheTTL: now + ttl,
        attachment,
      });
      return attachment;
    });
  });
};

const clearOutdatedAttachments = () => {
  const attachmentIds = [];
  const now = new Date().getTime();
  state.attachmentMap.forEach(({ cacheTTL, attachment }) => {
    if (now > cacheTTL) {
      attachmentIds.push(attachment.id);
    }
  });
  for (const attachmentId of attachmentIds) {
    state.attachmentMap.delete(attachmentId);
  }
};

const setDraftInStorage = ({ profile, group, draft }) => {
  const id = getLocalStorageKeyName({ profile, group });
  const draftsItem = localStorage.getItem(storageKeyTypes.draftPosts) || '{}';
  const drafts = JSON.parse(draftsItem);
  if (draft == null) {
    delete drafts[id];
  } else {
    const encryptionKey = profile.encryptionKey || '';
    const draftClone = cloneDeep(draft);
    draftClone.attachments = [];
    drafts[id] = encrypt(JSON.stringify(draftClone), encryptionKey);
  }

  localStorage.setItem(storageKeyTypes.draftPosts, JSON.stringify(drafts));
};
